import React, { useMemo } from "react"
import {
  Chains,
  Field,
  MAGIC_ADDRESS,
  NATIVE_SYMBOL,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  WRAPPED_ADDRESS,
  wrapAddr,
} from "@tangleswap/sdk"

import { useLiquidityState } from "components/Liquidity/utils/actions"

import { useWSCContext } from "context/MilkomedaContext"

import { isAddress } from "utils"
import { getNativeAddress } from "../AddLiquid/useNative"

export function useNativeToken() {
  const { l1ChainId } = useWSCContext()

  const nativeTokenAddress = getNativeAddress(l1ChainId)
  const tokenListStored: any = localStorage.getItem(
    `tangleswapChainId${l1ChainId}Tokens`
  )

  const TangleswapTokenList = JSON.parse(tokenListStored)
  const token =
    TangleswapTokenList &&
    TangleswapTokenList?.find((token: any) => {
      const tokenInfo = token.address.includes(`${nativeTokenAddress}`)

      return tokenInfo
    })
  return useMemo(() => {
    if (token) return token

    return undefined
  }, [token])
}

export const fetchUnknownLP = async (
  tokenAddress?: any | string | undefined,
  chainId?: any,
  tangleship?: any,
  tokenInfo: any = null
) => {
  if (!tokenAddress || !chainId || !isAddress(tokenAddress)) return
  const avoidAddresses = [NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]]
  const blacklist = avoidAddresses.map((a) => a?.toLowerCase())
  if (blacklist.includes(tokenAddress.toLowerCase())) return

  try {
    const addr = wrapAddr(tokenAddress, chainId).tokenAddress
    const token = tokenInfo || (await tangleship?.fetchUnknownTokenData(addr))
    if (
      token?.name === null &&
      token?.symbol === null &&
      token?.decimals === null
    )
      return

    return {
      address: tokenAddress,
      name: token?.name,
      symbol: token?.symbol,
      chainId: chainId,
      decimals: token?.decimals,
      logoURI: undefined,
      l1Address: token?.l1Address,
      l1Decimals: token?.l1Decimals,
      l1EncodedName: token?.l1EncodedName,
    }
  } catch (error) {
    console.error("Fetch Token Error:", error)
    return
  }
}

export function useToken(tokenAddress?: string | undefined) {
  const { l1ChainId } = useWSCContext()
  const tokenListStored: any = localStorage.getItem(
    `tangleswapChainId${l1ChainId}Tokens`
  )
  const TangleswapTokenList = JSON.parse(tokenListStored)
  const token = tokenAddress
    ? TangleswapTokenList &&
      TangleswapTokenList?.find((address: any) => {
        const token =
          String(address?.chainId) === String(l1ChainId) &&
          address?.address?.toLowerCase() === tokenAddress?.toLowerCase()
        return token
      })
    : undefined

  return useMemo(() => {
    if (token) return token
    return undefined
  }, [token])
}

export function useCurrencyLP(address?: any, field?: Field) {
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useLiquidityState()

  const { chainId } = useWSCContext()

  const token = useToken(address)
  const nativeTokenAddress = getNativeAddress(chainId)

  const nativeToken = useNativeToken()

  if (field === "INPUT" && inputCurrencyId === `${nativeTokenAddress}`)
    return nativeToken

  if (field === "OUTPUT" && outputCurrencyId === `${nativeTokenAddress}`)
    return nativeToken

  return token
}

export function useLPCurrency(address: string | undefined) {
  const token = useToken(address)

  return useMemo(() => {
    if (token) return token

    return undefined
  }, [token])
}

export function fetchToken(tokenAddress?: string | undefined, chainId?: any) {
  if (!isAddress(tokenAddress)) return undefined

  const isL1 = [Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(chainId)
  if (isL1 && tokenAddress === NATIVE_SYMBOL[chainId])
    tokenAddress = WRAPPED_ADDRESS[chainId]

  const tokenListStored: any = localStorage.getItem(
    `tangleswapChainId${chainId}Tokens`
  )
  const TangleswapTokenList = JSON.parse(tokenListStored)
  const token = tokenAddress
    ? TangleswapTokenList &&
      TangleswapTokenList?.find((token: any) => {
        return (
          String(token?.chainId) === String(chainId) &&
          String(token?.address)?.trim()?.toLowerCase() ===
            String(tokenAddress)?.trim().toLowerCase()
        )
      })
    : undefined
  if (token) return token
  return undefined
}

export function fetchCurrency(address: string | undefined, chainId?: any) {
  const token = fetchToken(address, chainId)

  if (token) return token
  return undefined
}
