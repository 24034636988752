import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"
import { StakingMainButton } from "styles/ButtonStyles"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
interface InputActiveProps {
  active?: boolean
  disabled?: boolean
}
interface AddMoreStakingProps {
  voidValue?: number
  dollarValue?: number
  balance?: number
  stakeDisable?: boolean
  handleStakeAction?: () => void
  register?: any
  watch?: any
  errors?: any
  handleStakeInputChange?: (val: string) => void
  stakeInputValue?: string
  tokenBalanceAmount?: number
  tokenUSDFiat?: number
  handleMax?: () => void
  insufficientBalance?: boolean
}

const AddMoreStaking: FC<AddMoreStakingProps> = (props) => {
  const {
    handleStakeAction,
    register,
    stakeInputValue,
    tokenBalanceAmount,
    tokenUSDFiat,
    handleMax,
    insufficientBalance,
  } = props
  return (
    <Container>
      <Inputs>
        <InputContainer>
          <InputBackground>
            <Input
              autoComplete="off"
              type="text"
              placeholder="1,000"
              {...register("stakeInputValue")}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                const parts = e.target.value.split(".")

                if (parts.length > 2) {
                  e.target.value = parts[0] + "." + parts.slice(1).join("")
                }
              }}
            />
            <InputAfterElement>
              {tokenUSDFiat > 0
                ? `~$${
                    isNaN(Number(tokenUSDFiat))
                      ? 0
                      : formatNumber(Number(tokenUSDFiat), 3)
                  }`
                : null}
            </InputAfterElement>
          </InputBackground>
          <StakeBottomWrapperRow>
            {/* <StakeErrorText>
              {!!stakeInputValue && Number(stakeInputValue) < 100 ? (
                <>100 VOID required</>
              ) : null}
            </StakeErrorText> */}
            <BalanceText>
              Balance:{" "}
              <BalanceNumber onClick={handleMax}>
                {formatNumber(Number(tokenBalanceAmount ?? 0), 3)} VOID
              </BalanceNumber>
            </BalanceText>
          </StakeBottomWrapperRow>
        </InputContainer>
      </Inputs>
      <Action>
        <StakeButton
          disabled={!stakeInputValue || !!insufficientBalance}
          onClick={() => handleStakeAction()}
        >
          {!!insufficientBalance ? <>Insufficient VOID</> : <>Add VOID</>}
        </StakeButton>
      </Action>
    </Container>
  )
}

const Input = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  color: ${TangleColors.white};
  font-size: 24px;
  text-align: right;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "DM Sans Regular";
  font-size: 18px;
`

// const InputBackground = styled.div`
//   background: ${TangleColors.purpleBorder};
//   border-radius: 16px;
//   width: 100%;
//   padding: 12px 24px !important;
//   font-weight: 600;
//   display: flex;
//   flex-direction: column;
//   gap: 4px;
// `

const InputAfterElement = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  text-align: right;
  margin: 0;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BalanceText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-right: 12px; */
  white-space: nowrap;
  margin: 3px 0 0 0;
`

const BalanceNumber = styled(CaptionSmall)<AddMoreStakingProps>`
  color: ${(props) =>
    props.insufficientBalance
      ? `${TangleColors.red}`
      : `${TangleColors.lighthover}`};
  margin: 0 0 0 8px;
  cursor: pointer;
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 0 0 30px 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StakeButton = styled(StakingMainButton)``

const Container = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`
const WeekSelectItem = styled.div<InputActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${(props) =>
    props.active ? `${TangleColors.cta}` : `${TangleColors.dockBG}`};
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.tangleYellow}`};
  border-radius: 9999px;
  padding: 4px 12px;
  cursor: pointer;
`
const InputBackground = styled.div<InputActiveProps>`
  background: ${TangleColors.tangleBlack};

  border-radius: 16px;
  border: ${(props) =>
    props.disabled
      ? `1.2px solid ${TangleColors.red}`
      : `1.2px solid  ${TangleColors.grayLighter}`};
  width: 100%;
  padding: 12px 24px !important;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: 0.4s ease-in;
  :focus {
    transition: 0.4s ease-in;
    border: 0.5px solid ${TangleColors.lighthover};
  }
  :hover {
    transition: 0.4s ease-in;
    /* opacity: 0.8; */

    border: 0.5px solid ${TangleColors.lighthover};
  }
`

const StakeWrapperRow = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`
const StakeBottomWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StakeErrorText = styled(CaptionSmall)`
  color: ${TangleColors.red};
  margin: 3px 0 0 0;
  white-space: nowrap;
`
export default AddMoreStaking
