import { motion } from "framer-motion"
import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"

import TokenImages from "../TokenImages"
import TokenInfo from "../TokenInfo"
import { BiChevronUp, BiChevronDown } from "react-icons/bi"
import { isInvalid, tickToPrice } from "@tangleswap/sdk"
import TopTVL from "./FarmTop/TopTVL"

import HarvestAction from "./components/HarvestAction"
import TopRewards from "./FarmTop/TopRewards"
import TopAPRDisplay from "./FarmTop/TopAprDisplay"
import TopRewardsCriteria from "./FarmTop/TopRewardsCriteria"
import { useAppSelector } from "store/hooks"

interface FarmCardsProps {
  detailsShown?: boolean
  isEnded?: boolean | any
}
interface FarmTopProps {
  token0Address?: any
  token1Address?: any

  toggleDetails?: () => void
  farmAddress?: string

  token0Logo?: any
  token1Logo?: any
  token0Symbol?: any
  token1Symbol?: any
  rewardLowerTick?: any
  rewardUpperTick?: any
  token0decimals?: any
  token1decimals?: any
  feeTier?: number
  showDetails?: any
  isEnded?: boolean
  endBlock?: any
  token0Data?: any
  token0Qty?: any
  token1Qty?: any
  farmAPIData?: any
  token0DataAddress?: any
  token1DataAddress?: any
  token0DataSymbol?: any
  token1DataSymbol?: any
  rewardsAddress0?: string | undefined | null
  rewardsAddress1?: string | undefined | null
  currentCycle?: any
  token0PendingRewards?: number | undefined | null
  token1PendingRewards?: number | undefined | null
  rewardToken0Symbol?: any
  rewardToken1Symbol?: any
  rewardToken0Logo?: any
  rewardToken1Logo?: any
  rewardToken0decimals?: any
  rewardToken1decimals?: any
  calculatedAPR?: number
  calculatedTVL?: number
}
const FarmItemsTop: FC<FarmTopProps & FarmCardsProps> = (props) => {
  const {
    token0Logo,
    token1Logo,
    token0Symbol,
    token1Symbol,
    farmAddress,

    toggleDetails,
    feeTier,
    token0decimals,
    token1decimals,
    showDetails,
    isEnded,
    rewardLowerTick,
    rewardUpperTick,
    token0PendingRewards,
    token1PendingRewards,
    rewardsAddress0,
    rewardsAddress1,
    rewardToken0Symbol,
    rewardToken1Symbol,
    rewardToken0Logo,
    rewardToken1Logo,
    rewardToken0decimals,
    rewardToken1decimals,

    calculatedAPR,
    calculatedTVL,
  } = props

  const [lowerTick, setLowerTick] = useState<any>(undefined)
  const [upperTick, setUpperTick] = useState<any>(undefined)
  const [harvestAvailable, setHarvestAvailable] = useState<boolean>(false)
  // const [calculatedAPR, setCalculatedAPR] = useState<any>(undefined)
  // const [calculatedTVL, setCalculatedTVL] = useState<any>(undefined)

  const calculateHarvestAvailability = useCallback(() => {
    setHarvestAvailable(
      Number(token0PendingRewards) > 0 || Number(token1PendingRewards) > 0
    )
  }, [token0PendingRewards, token1PendingRewards])

  useEffect(() => {
    calculateHarvestAvailability()

    return () => {
      setHarvestAvailable(false)
    }
  }, [calculateHarvestAvailability])

  // useEffect(() => {
  //   if (
  //     isInvalid([
  //       token0decimals,
  //       token1decimals,
  //       rewardUpperTick,
  //       rewardLowerTick,
  //     ])
  //   )
  //     return

  //   const lowerTickValue: any = tickToPrice(
  //     rewardLowerTick,
  //     token0decimals,
  //     token1decimals
  //   )
  //   const upperTickValue: any = tickToPrice(
  //     rewardUpperTick,
  //     token0decimals,
  //     token1decimals
  //   )
  //   setLowerTick(lowerTickValue)
  //   setUpperTick(upperTickValue)
  //   return () => {
  //     setLowerTick(undefined)
  //     setUpperTick(undefined)
  //   }
  // }, [
  //   token0decimals,
  //   token1decimals,
  //   rewardLowerTick,
  //   rewardUpperTick,
  //   blockNumber,
  // ])
  const lowerTickValue = useMemo(
    () => tickToPrice(rewardLowerTick, token0decimals, token1decimals),
    [rewardLowerTick, token0decimals, token1decimals]
  )

  const upperTickValue = useMemo(
    () => tickToPrice(rewardUpperTick, token0decimals, token1decimals),
    [rewardUpperTick, token0decimals, token1decimals]
  )

  useEffect(() => {
    if (isInvalid([lowerTickValue, upperTickValue])) return

    setLowerTick(lowerTickValue)
    setUpperTick(upperTickValue)

    return () => {
      setLowerTick(undefined)
      setUpperTick(undefined)
    }
  }, [lowerTickValue, upperTickValue])

  return (
    <FarmCoverRow detailsShown={showDetails}>
      <TokenBasic>
        <TokenImages
          token0Logo={token0Logo}
          token1Logo={token1Logo}
          token0Name={token0Symbol}
          token1Name={token1Symbol}
        />
      </TokenBasic>
      <Container>
        <TokenInfo
          token0Name={token0Symbol}
          token1Name={token1Symbol}
          feeRate={Number(feeTier) / 10000}
        />
      </Container>
      <SmallContainer>
        <TopAPRDisplay apr={calculatedAPR} isEnded={isEnded} />
      </SmallContainer>
      <Container>
        <TopTVL tvl={Number(calculatedTVL)} />
      </Container>
      <ContainerLarge>
        <TopRewardsCriteria
          lowerTick={lowerTick}
          upperTick={upperTick}
          token0Symbol={token0Symbol}
          token1Symbol={token1Symbol}
        />
      </ContainerLarge>
      <ContainerLarge>
        <TopRewards
          rewardsAddress0={rewardsAddress0}
          rewardsAddress1={rewardsAddress1}
          token0PendingRewards={token0PendingRewards}
          token1PendingRewards={token1PendingRewards}
          token0Symbol={rewardToken0Symbol}
          token1Symbol={rewardToken1Symbol}
          token0Logo={rewardToken0Logo}
          token1Logo={rewardToken1Logo}
          token0Decimals={rewardToken0decimals}
          token1Decimals={rewardToken1decimals}
        />
      </ContainerLarge>

      <Actions>
        {harvestAvailable ? <Divider /> : null}
        <HarvestAction
          harvestAvailable={harvestAvailable}
          farmAddress={farmAddress}
          rewardToken0={rewardsAddress0}
          rewardToken1={rewardsAddress1}
        />
        <Button onClick={toggleDetails}>
          {showDetails ? (
            <>
              <DetailsWrapper>
                <DetailsHideText>Hide</DetailsHideText>{" "}
                <DetailsIconWrapper>
                  <ShowUpIcon />
                </DetailsIconWrapper>
              </DetailsWrapper>
            </>
          ) : (
            <>
              <DetailsWrapper>
                {" "}
                <DetailsShowText>Details</DetailsShowText>{" "}
                <DetailsIconWrapper>
                  <ShowDownIcon />
                </DetailsIconWrapper>
              </DetailsWrapper>
            </>
          )}
        </Button>
      </Actions>
    </FarmCoverRow>
  )
}

const TokenBasic = styled(motion.div)`
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: 100%;
  max-width: 64px;
`

const DetailsIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 2px;
`
const ShowUpIcon = styled(BiChevronUp)`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ShowDownIcon = styled(BiChevronDown)`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const DetailsHideText = styled(Caption)``
const DetailsShowText = styled(Caption)``

const DetailsWrapper = styled(motion.span)`
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FarmCoverRow = styled(motion.div)<FarmCardsProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 0.4fr 1.2fr 0.8fr 1.5fr 3fr 1.7fr 2fr;
  align-items: center;
  justify-content: center;
  gap: 12px;
  grid-gap: 12px;
  border-radius: 24px;
  position: relative;

  padding: 20px;
  margin: 6px 0;
  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;

  @media only screen and (max-width: 850px) {
    justify-content: space-between;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 650px) {
    justify-content: space-between;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Actions = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  justify-self: flex-end;
  gap: 16px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

const SmallContainer = styled(motion.div)`
  flex-basis: 96px;
`

const Container = styled(motion.div)`
  flex-basis: 160px;
`

const ContainerLarge = styled(motion.div)`
  flex-basis: 240px;
  @media (max-width: 768px) {
    flex-basis: 160px;
  }
`

const Button = styled(motion.button)`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  padding: 8px;
  border: 0px;
  color: #eab741;
`

const Divider = styled(motion.hr)`
  width: 1px;
  height: 48px;
  background: #444;
  margin: 0 0.5em 0 -32px;
  @media (max-width: 1024px) {
    display: none;
  }
`

export default FarmItemsTop
