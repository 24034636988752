import { FC, useEffect, useRef, useState } from "react"
import {
  LOVELACE_UNIT,
  REQUIRED_FEE_ADA,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"
import BigNumber from "bignumber.js"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"
import { useGetOriginBalance, useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import useInterval from "components/utils/useInterval"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import { getBridgeExplorerUrl } from "utils/milkomeda/transaction"
import { TxPendingStatus } from "milkomeda-wsc"
import { useWSCContext } from "context/MilkomedaContext"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  ButtonContainer,
  Link,
} from "components/milkomeda/styles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { extractEvmFunctionName } from "@tangleswap/sdk"
import { ethers } from "ethers"
import { playSound } from "components/milkomeda/component/PlaySound"

interface IWrapMultipleStep {
  nextStep?: () => void
  data?: IWrapMultipleData
  actionType?: string
  subStep?: number
  isLastStep?: boolean
}

export const statusWrapMessages = {
  [TxStatus.Init]: "Confirm Wrapping",
  [TxStatus.Pending]: "Wrapping your token",
  [TxStatus.WaitingL1Confirmation]: "Waiting for L1 confirmation",
  [TxStatus.WaitingBridgeConfirmation]: "Waiting for bridge confirmation",
  [TxStatus.WaitingL2Confirmation]: "Waiting for L2 confirmation",
  [TxStatus.Confirmed]: "Your asset has been successfully wrapped.",
}

const WrapMultipleStep: FC<IWrapMultipleStep> = (props) => {
  const { nextStep, data, actionType, subStep, isLastStep } = props
  const [txHash, setTxHash] = useState<string | undefined>()
  const [currentTokenIndex, setCurrentTokenIndex] = useState(subStep ?? 0)
  const [wrapErrors, setWrapErrors] = useState<{ [index: number]: string }>({})
  const [isWrapPending, setIsWrapPending] = useState<boolean>(false)
  const [isEnoughEVMBalance, setIsEnoughEVMBalance] = useState<{
    [index: number]: boolean
  }>({})

  const { wrappingFee, evmEstimatedFee, adaLocked, unwrappingFee, bridgeFees } =
    useTransactionFees()
  const {
    txStatus,
    txStatusError,
    setTxStatusError,
    setTxStatus,
    isIdle,
    isLoading,
    isError,
    isSuccess,
    isErrorWhileFetching,
  } = useTransactionStatus()
  const { wscProvider } = useWSCProvider()
  const { chainId, destinationTokens, destinationBalanceADA } = useWSCContext()

  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )
  const isAda = data
    ? data?.tokenIn[currentTokenIndex]?.unit === LOVELACE_UNIT
    : undefined
  const currentToken = data?.tokenIn[currentTokenIndex]

  useEffect(() => {
    if (!tangleProgress) return
    if (tangleProgress.stepTitle === "Wrap") {
      if (!tangleProgress.txHash) {
        nextStep()
      } else {
        setTxHash(tangleProgress.txHash)
        setTxStatus(TxStatus.WaitingBridgeConfirmation)
        setIsWrapPending(true)
      }
    }
  }, [])

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (
      tangleSwapTransactions &&
      !autoExecute.current &&
      data?.tokenIn[currentTokenIndex]
    ) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        wrapToken()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, data?.tokenIn[currentTokenIndex]])

  useEffect(() => {
    if (currentToken && destinationTokens) {
      const foundToken = destinationTokens?.data?.find(
        (token) =>
          String(token.contractAddress)?.trim().toLowerCase() ===
          String(currentToken.address)?.trim().toLowerCase()
      )

      if (foundToken || (isAda && destinationBalanceADA.data)) {
        const tokenInDestination = isAda
          ? destinationBalanceADA.data
          : ethers.utils.formatUnits(foundToken.balance, foundToken.decimals)

        const isEnough = isAda
          ? Number(tokenInDestination) >= currentToken.amount + REQUIRED_FEE_ADA
          : Number(tokenInDestination) >= currentToken.amount

        setIsEnoughEVMBalance({
          ...isEnoughEVMBalance,
          [currentTokenIndex]: isEnough,
        })

        if (isEnough) {
          setCurrentTokenIndex(currentTokenIndex + 1)
        }
      } else {
        setIsEnoughEVMBalance({
          ...isEnoughEVMBalance,
          [currentTokenIndex]: false,
        })
      }
    }
  }, [currentToken])

  useEffect(() => {
    if (
      currentTokenIndex === data?.tokenIn.length &&
      Object.values(isEnoughEVMBalance).every((value) => value)
    ) {
      nextStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnoughEVMBalance, currentTokenIndex])

  useEffect(() => {
    if (
      !isEnoughEVMBalance[currentTokenIndex] &&
      isEnoughEVMBalance[currentTokenIndex] !== undefined &&
      currentTokenIndex > 0 &&
      Object.values(isEnoughEVMBalance).every((value) => value === false)
    ) {
      wrapToken()
    }
  }, [isEnoughEVMBalance, currentTokenIndex])

  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      try {
        const response = await wscProvider.getTxStatus(txHash)
        setTxStatus(response)

        if (
          response === TxStatus.WaitingBridgeConfirmation &&
          currentTokenIndex < data?.tokenIn?.length - 1
        )
          setCurrentTokenIndex(currentTokenIndex + 1)

        if (response === TxStatus.Confirmed) {
          if (currentTokenIndex < data?.tokenIn?.length - 1) {
            // setCurrentTokenIndex(currentTokenIndex + 1)
            setTxHash(undefined)
          } else {
            if (tangleSwapTXEffects) playSound()
            executeStep(txHash, true)
            nextStep()
          }
        }
      } catch (err) {
        setTxStatus(TxStatus.FetchError)
        console.error(err)
      }
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )

  const dispatch = useAppDispatch()
  const executeStep = (tx: any, completed = false) => {
    const stepperParams = {
      completed,
      stepTitle: "Wrap",
      subStep: currentTokenIndex,
      actionType,
      actionSubType: extractEvmFunctionName(data?.evmFunction),
      txHash: tx,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      isLastStep,
    }

    dispatch(updateUserWSCProgress(stepperParams))
  }

  const wrapToken = async () => {
    const tokenIn = data?.tokenIn[currentTokenIndex]
    if (!tokenIn || !unwrappingFee || !wrappingFee) return

    setTxHash(undefined)
    setTxStatus(TxStatus.Init)

    const totalFees = adaLocked
      .multipliedBy(10 ** 6)
      .plus(unwrappingFee.multipliedBy(10 ** 6))
      .plus(evmEstimatedFee.multipliedBy(10 ** 6))
      .plus(wrappingFee.multipliedBy(10 ** 6))

    try {
      const wrapAmount =
        tokenIn.unit === LOVELACE_UNIT
          ? convertTokensToWei({
              value: new BigNumber(tokenIn.amount),
              token: { decimals: 6 },
            })
              .plus(totalFees)
              .minus(wrappingFee)
              .dp(0, BigNumber.ROUND_UP)
          : convertTokensToWei({
              value: new BigNumber(tokenIn.amount),
              token: { decimals: tokenIn.l1Decimals },
            })

      const policyIdAssetNameConcat = tokenIn.unit + tokenIn.l1EncodedName

      const txHash = await wscProvider?.wrap(
        undefined,
        policyIdAssetNameConcat,
        wrapAmount.toNumber(),
        tokenIn.unit !== LOVELACE_UNIT ? totalFees.toNumber() : 0
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)

      executeStep(txHash)
    } catch (err) {
      console.error(err)
      setWrapErrors({ ...wrapErrors, [currentTokenIndex]: err.message })
      setTxStatus(TxStatus.Error)
    }
  }

  const retryWrapToken = (index: number) => {
    setCurrentTokenIndex(index)
    setTxHash(undefined)
    setWrapErrors({ ...wrapErrors, [index]: undefined })
    wrapToken()
  }

  return (
    <>
      <ActionWrapper>
        {isLoading && (
          <WrapSpinnerCover>
            <SpinnerCombined />
            <span>{statusWrapMessages[txStatus]}</span>
          </WrapSpinnerCover>
        )}
        {isError && <div>Oops, something went wrong. Please try again.</div>}
        {(isError || wrapErrors[currentTokenIndex]) && (
          <ButtonContainer>
            <ActionButton onClick={() => retryWrapToken(currentTokenIndex)}>
              Retry
            </ActionButton>
          </ButtonContainer>
        )}
        {isSuccess && (
          <ButtonContainer>
            <Link href={`${getBridgeExplorerUrl(chainId)}/wrap/${txHash}`}>
              {statusWrapMessages[TxPendingStatus.Confirmed]}
            </Link>
            <ActionButton onClick={nextStep}>Continue</ActionButton>
          </ButtonContainer>
        )}
        {isIdle && (
          <>
            <ActionText>Wrapping may take a few minutes (2-4 min)</ActionText>
            <ButtonContainer>
              <ActionButton
                disabled={!data?.tokenIn[currentTokenIndex]}
                onClick={wrapToken}
              >
                Confirm
              </ActionButton>
            </ButtonContainer>
          </>
        )}
      </ActionWrapper>
    </>
  )
}
const WrapSpinnerCover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default WrapMultipleStep
