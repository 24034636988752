import { ADA_TX_EFFECT, ADA_TRANSACTIONS } from "./actionsTypes"

export const txEffectSelected = (tangleSwapTXEffects: any) => {
  return {
    type: ADA_TX_EFFECT,
    tangleSwapTXEffects: tangleSwapTXEffects,
  }
}
export const transactionsSelected = (tangleSwapTransactions: any) => {
  return {
    type: ADA_TRANSACTIONS,
    tangleSwapTransactions: tangleSwapTransactions,
  }
}

export const updateTransactionsMode = (tangleSwapTransactions: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleSwapTransactions", tangleSwapTransactions)
    dispatch(transactionsSelected(tangleSwapTransactions))
  }
}
export const updateTXEffects = (tangleSwapTXEffects: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleSwapTXEffects", tangleSwapTXEffects)
    dispatch(txEffectSelected(tangleSwapTXEffects))
  }
}

export const proCheckTransactionState = () => {
  return (dispatch: any) => {
    const tangleSwapTransactions = localStorage.getItem(
      "tangleSwapTransactions"
    )

    const isTradingProMode = tangleSwapTransactions === "true"
    dispatch(updateTransactionsMode(isTradingProMode))
  }
}

export const proCheckTXEffectStatus = () => {
  return (dispatch: any) => {
    const tangleSwapTXEffects = localStorage.getItem("tangleSwapTXEffects")

    const status =
      tangleSwapTXEffects !== null ? tangleSwapTXEffects === "true" : false
    dispatch(updateTXEffects(status))
  }
}
