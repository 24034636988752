import React, { FC } from "react"
import styled from "styled-components"
import FarmLiquidityActionRow from "./FarmLiquidityActionRow"

interface LiquidityActionProps {
  token0DataAddress?: string
  token0DataSymbol?: string
  token1DataSymbol?: string
  token1DataAddress?: string
  formattedPrice?: any
  feeTier?: number
  endBlock?: number
  farmAddress?: string
  minPrice?: any
  maxPrice?: any
}
const FarmLiquidityAction: FC<LiquidityActionProps> = (props) => {
  const {
    token0DataAddress,
    token0DataSymbol,
    token1DataSymbol,
    token1DataAddress,
    formattedPrice,
    feeTier,
    farmAddress,
    endBlock,
    minPrice,
    maxPrice,
  } = props

  return (
    <LiquidityContainer>
      <FarmLiquidityActionRow
        token0DataAddress={token0DataAddress}
        token0DataSymbol={token0DataSymbol}
        token1DataSymbol={token1DataSymbol}
        token1DataAddress={token1DataAddress}
        formattedPrice={formattedPrice}
        farmAddress={farmAddress}
        feeTier={feeTier}
        endBlock={endBlock}
        minPrice={minPrice}
        maxPrice={maxPrice}
      />
    </LiquidityContainer>
  )
}

const LiquidityContainer = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 4px 0 10px 0;
  padding: 2px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    justify-content: center;
    max-width: 800px;
    align-items: center;
  }
`

export default FarmLiquidityAction
