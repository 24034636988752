import React from "react"
import styled from "styled-components"

const ComingSoon = () => {
  return (
    <React.Fragment>
      <Body></Body>
    </React.Fragment>
  )
}
const Body = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 21;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/coming-soon.webp")
    no-repeat top 30px center/80%;

  overflow: hidden;
  @media only screen and (max-width: 850px) {
    background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/portrait-coming-soon.webp")
      no-repeat top -10px center/contain;
    height: 100vh;
    margin: -20px 0 0 0;
  }
`

export default ComingSoon
