import React, { useContext } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { StakingMainButton } from "styles/ButtonStyles"
import { DurationMainInput } from "styles/InputStyles"
import StakeContext from "./StakeContext"

import { Dots } from "components/utils/LoadingDots"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { useWSCContext } from "context/MilkomedaContext"
import { useAppSelector } from "store/hooks"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
interface InputActiveProps {
  active?: boolean
}
interface ReStakeProps {
  duration?: number
  stakeDisable: boolean
  existingDuration?: number | null
  handleWeekSelect?: (val: number) => void
  handleDurationChange?: (val: string) => void
  balance?: number
  weekSelectActive?: number | null | undefined
  stakingApproving: boolean
}

const ReStake: React.FC<ReStakeProps> = (props) => {
  const {
    duration,
    existingDuration,
    handleWeekSelect,
    handleDurationChange,
    stakingApproving,
    weekSelectActive,
  } = props
  const { handleReStakeAction, fireRestakeWSCModal } = useContext(StakeContext)
  const { isWSCConnected } = useWSCContext()
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }
  return (
    <Container>
      <Inputs>
        <DurationContainer>
          <WeekSelectRow>
            <WeekSelectItem
              active={weekSelectActive === 1}
              onClick={() =>
                handleWeekSelect?.(
                  existingDuration + 1 > 200 ? 200 - existingDuration : 1
                )
              }
            >
              1W
            </WeekSelectItem>
            <WeekSelectItem
              active={weekSelectActive === 4}
              onClick={() =>
                handleWeekSelect(
                  existingDuration + 4 > 200 ? 200 - existingDuration : 4
                )
              }
            >
              4W
            </WeekSelectItem>
            <WeekSelectItem
              active={weekSelectActive === 12}
              onClick={() =>
                handleWeekSelect(
                  existingDuration + 12 > 200 ? 200 - existingDuration : 12
                )
              }
            >
              12W
            </WeekSelectItem>
            <WeekSelectItem
              active={weekSelectActive === 52}
              onClick={() =>
                handleWeekSelect(
                  existingDuration + 52 > 200 ? 200 - existingDuration : 52
                )
              }
            >
              52W
            </WeekSelectItem>
            <WeekSelectItem
              active={
                weekSelectActive === 172 ||
                weekSelectActive === 200 ||
                weekSelectActive > 52
              }
              onClick={() =>
                handleWeekSelect(
                  existingDuration > 0 ? 200 - existingDuration : 200
                )
              }
            >
              MAX
            </WeekSelectItem>
          </WeekSelectRow>
          <InputContainer>
            <Duration>
              <DurationInput
                type="number"
                autoComplete="off"
                placeholder="0"
                value={duration}
                onChange={(e) => handleDurationChange(e.target.value)}
              />
              <WeeksText>Weeks</WeeksText>
            </Duration>
          </InputContainer>
        </DurationContainer>
      </Inputs>
      <Action>
        <StakeButton
          disabled={weekSelectActive === undefined || !!stakingApproving}
          onClick={
            isWSCConnected
              ? !!blockTransactionState
                ? handlePendingTxModal
                : () => fireRestakeWSCModal(duration)
              : () => handleReStakeAction(duration)
          }
          blockTransactionState={!!blockTransactionState}
        >
          {" "}
          {!!blockTransactionState ? (
            <>
              <SwapButtonSpinner />
            </>
          ) : null}
          {!!blockTransactionState ? (
            <>Continue</>
          ) : (
            <>
              {!!stakingApproving ? (
                <>
                  Approving
                  <Dots />
                </>
              ) : (
                <> Re-stake VOID</>
              )}
            </>
          )}
        </StakeButton>
      </Action>
    </Container>
  )
}

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const WeekSelectRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const WeekSelectItem = styled.div<InputActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: ${(props) =>
    props.active ? `${TangleColors.cta}` : `${TangleColors.dockBG}`};
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.tangleYellow}`};
  border-radius: 9999px;
  padding: 4px 12px;
  cursor: pointer;
`
const DurationInput = styled(DurationMainInput)``

const Duration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const WeeksText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 4px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StakeButton = styled(StakingMainButton)``

const Container = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`

export default ReStake
