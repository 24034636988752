import React, { FC } from "react"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import TransactionInfoRow from "./TransactionInfoRow"
import TransactionHrefRow from "./TransactionHrefRow"
import { Caption, Subtitle } from "styles/TextStyles"
import { TickCircle } from "iconsax-react"

interface OverviewProps {
  hash?: string
  timestamp?: string | number
  type?: string
  completed?: boolean
}
const TransactionDetails: FC<OverviewProps> = (props) => {
  const { hash, timestamp, type, completed } = props
  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC", // Add this if you want to display in UTC, or remove it for local time
    }
    return date.toLocaleString("en-US", options)
  }

  const isWrap = type === "Wrap"

  const explorerUrl = isWrap
    ? "https://preprod.cardanoscan.io/transaction/"
    : "https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/"

  return (
    <>
      <OverviewStepperCover>
        <OverviewWrapper>
          <TransactionHrefRow text="Link" link={explorerUrl} hash={hash} />
          <TransactionInfoRow
            text="Time"
            subText={formatDate(Number(timestamp))}
          />
          <TransactionInfoRow text="Type" subText={type} />
        </OverviewWrapper>
        <OverviewStatus>
          {completed ? (
            <TransactionText completed={completed}>
              <CheckComplete size={20} />
              Completed
            </TransactionText>
          ) : (
            <TransactionText completed={completed}>Ongoing</TransactionText>
          )}
        </OverviewStatus>
      </OverviewStepperCover>
    </>
  )
}
const OverviewStepperCover = styled.div<OverviewProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  color: ${TangleColors.white};
  padding: 10px;
  margin: 8px 0;
  border-radius: 6px;
  border: 1px solid ${TangleColors.lightPurple};
`
const OverviewStatus = styled.div`
  margin: 12px 0 0 0;
`
const TransactionText = styled(Caption)<OverviewProps>`
  color: ${(props) =>
    props.completed ? `${TangleColors.tangleGreen}` : `${TangleColors.white}`};
  margin: 0 0 0 4px;
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const OverviewWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const CheckComplete = styled(TickCircle)`
  color: ${TangleColors.tangleGreen};
  margin: 0 8px 0 0;
`
export default TransactionDetails
