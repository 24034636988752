import { useWSCContext } from "context/MilkomedaContext"
import { FC, useCallback, useEffect, useState } from "react"
import { GrClose } from "react-icons/gr"
import { CiSearch } from "react-icons/ci"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import TokenList from "./TokenList"
import TokenRow from "./TokenRow"
import UnknownToken from "./UnknownToken"
import { Field } from "@tangleswap/sdk"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUnknownToken } from "store/actions/UnknownTokenAction"
import TopTokens from "data/TopTokens"
import { useForm } from "react-hook-form"

import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { UnknownTokenSearchProps } from "components/swap/types"

import { isAddress } from "utils"
import { useTangleship } from "utils/useTangleship"

interface TokenValues {
  search: string
}

interface ModalInputProps {
  max?: string
  symbol?: string
  onSelectMax?: () => void

  placeholder?: string
  value?: string
  addLiquidityUrl?: string
  inputTitle?: string
  decimals?: number
}

interface TokenProps {
  activeField?: Field
  closeTokenModal?: () => void
  closeModal?: (value: any) => void
  modalRef?: any
  otherCurrency?: any
  showCurrencyAmount?: boolean
  selectedCurrency?: any
  tokenOpenModal: boolean
  selectCurrency?: (field: any, address: string) => void
  activateCustomModal?: () => void
}
const TokensModal: FC<TokenProps & ModalInputProps> = (props) => {
  const {
    closeTokenModal,
    closeModal,
    otherCurrency,
    selectedCurrency,
    tokenOpenModal,
    activeField,
    modalRef,

    selectCurrency,

    activateCustomModal,
  } = props
  const dispatch = useAppDispatch()
  const { l1ChainId } = useWSCContext()
  const [tangleSwapUnknownToken, setTangleSwapUnknownToken] =
    useState<any>(undefined)
  const [unknownTokenInfo, setUnknownTokenInfo] = useState<any>(undefined)
  const [tangleswapTokens, setTangleswapTokens] = useState<any>(undefined)
  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )
  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )

  const { tangleship } = useTangleship()
  const handleCurrencySelect = useCallback(
    (address: string, activeField: any) => {
      selectCurrency?.(activeField, address)
      closeTokenModal?.()
    },
    [selectCurrency]
  )

  const { register, reset, watch } = useForm<TokenValues>()

  useEffect(() => {
    if (!tokenOpenModal) {
      reset()
    }
  }, [])

  const { topTokensData } = TopTokens()
  const trendingTokens = topTokensData?.filter((res: any) => {
    return String(res?.chainId) === String(l1ChainId)
  })

  useEffect(() => {
    const tangleswapTokenList = tangleswapTokenListOnChain?.filter(
      (token: any) => {
        return (
          String(token.address)
            ?.toLowerCase()
            .includes(String(watch("search"))?.toLowerCase()) ||
          String(token.name)
            ?.toLowerCase()
            .includes(String(watch("search"))?.toLowerCase()) ||
          String(token.symbol)
            ?.toLowerCase()
            .includes(String(watch("search"))?.toLowerCase())
        )
      }
    )
    setTangleswapTokens(tangleswapTokenList)
  }, [tangleswapTokenListOnChain, watch("search")])

  useEffect(() => {
    // if (!watch("search") || !isAddress(watch("search"))) return

    if (!watch("search") && !isAddress(watch("search"))) return
    fetchTangleCurrency(
      String(watch("search")).trim().toLowerCase(),
      l1ChainId,
      tangleship
    ).then((res: UnknownTokenSearchProps) => {
      if (!res || !res.name || !res.decimals || !res.symbol) return
      setUnknownTokenInfo(res)
      const tokenName = res?.name
      const tokenDecimals = res?.decimals
      const tokenSymbol = res?.symbol
      const tokenAddress = String(watch("search")).trim().toLowerCase()
      const tokenl1Address = res?.l1Address
      const tokenl1Decimals = res?.l1Decimals
      const tokenl1EncodedName = res?.l1EncodedName

      if (tokenName && tokenDecimals && tokenSymbol) {
        setTangleSwapUnknownToken([
          {
            tokenName,
            tokenAddress,
            tokenSymbol,
            tokenDecimal: tokenDecimals,
          },
        ])
        dispatch(
          updateUnknownToken(
            tokenName,
            tokenAddress,
            tokenSymbol,
            tokenDecimals,
            tokenl1Address,
            tokenl1Decimals,
            tokenl1EncodedName
          )
        )
      }
    })
  }, [watch("search"), tangleswapTokens])

  return (
    <>
      {tokenOpenModal ? (
        <>
          <Body ref={modalRef} onClick={closeModal}>
            <Cover>
              <ModalClose onClick={closeTokenModal} />
              <ModalTop>
                <SwapName>Select Token</SwapName>
              </ModalTop>
              <ModalSearchSection>
                <SearchIcon />
                <TokenSearchDiv
                  type="text"
                  id="search"
                  {...register("search")}
                  placeholder="Search token name or paste contract address"
                />
              </ModalSearchSection>
              <ModalTokens>
                {/* <TrendingTitle>Trending</TrendingTitle> */}
                <>
                  {/* start of  Native token 1 */}
                  {trendingTokens?.map((token: any, index: number) => (
                    <TokenRow
                      key={index}
                      selectCurrency={() =>
                        handleCurrencySelect(token?.address, activeField)
                      }
                      otherCurrency={otherCurrency === token?.address}
                      address={token?.address}
                      selectedCurrency={selectedCurrency === token?.address}
                      tokenSymbol={token?.symbol}
                      tokenLogo={token?.logoURI}
                      tokenName={token?.name}
                    />
                  ))}
                </>
              </ModalTokens>
              <ListedTokens>
                {tangleswapTokens?.length > 0 ? (
                  <>
                    {tangleswapTokens?.map((tokens: any, index: number) => {
                      const tokenBalanceFig =
                        tangleswapTokenListBalances?.[
                          tokens?.address?.toLowerCase()
                        ]

                      return (
                        <TokenList
                          key={index}
                          selectCurrency={() =>
                            handleCurrencySelect(tokens.address, activeField)
                          }
                          address={tokens.address}
                          selectedCurrency={selectedCurrency === tokens.address}
                          balance={tokenBalanceFig || "0"}
                          tokenSymbol={tokens.symbol}
                          tokenLogo={tokens.logoURI}
                          tokenName={tokens.name}
                          otherCurrency={otherCurrency === tokens.address}
                        />
                      )
                    })}
                  </>
                ) : (
                  <NoTokenFetch>
                    {unknownTokenInfo !== null ? (
                      <>
                        <>
                          {tangleSwapUnknownToken &&
                            tangleSwapUnknownToken?.map(
                              (tokens: any, index: number) => {
                                return (
                                  <UnknownToken
                                    key={index}
                                    activeField={activeField}
                                    activateCustomModal={activateCustomModal}
                                    address={tokens?.tokenAddress}
                                    selectedCurrency={
                                      selectedCurrency === tokens.tokenAddress
                                    }
                                    tokenSymbol={tokens?.tokenSymbol}
                                    tokenName={tokens?.tokenName}
                                    otherCurrency={
                                      otherCurrency === tokens?.tokenAddress
                                    }
                                  />
                                )
                              }
                            )}
                        </>
                      </>
                    ) : (
                      <>
                        {" "}
                        <RowBody>No token with such address or name.</RowBody>
                      </>
                    )}
                  </NoTokenFetch>
                )}
              </ListedTokens>

              <ModalBottom>
                <EditHref>
                  {/* <EditIcon />
            <ModalSubtitle>Manage Your Token Lists</ModalSubtitle> */}
                </EditHref>
              </ModalBottom>
            </Cover>
          </Body>
        </>
      ) : null}
    </>
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 888;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
`
const Cover = styled.div`
  min-height: 590px;
  position: relative;
  max-width: 480px;
  overflow: hidden;
  width: 100%;
  margin: 24px auto;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  z-index: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  /* justify-content: space-between; */
  @media only screen and (max-width: 650px) {
    height: 480px;
  }
`

const ModalClose = styled(GrClose)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const ModalTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 8px 28px;
  margin: 5px 0 0 0;
`
const SwapName = styled(Caption)`
  text-align: left;
  width: 100%;
`
const ModalSearchSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto 2px auto;
  padding: 0 20px;
`

const SearchIcon = styled(CiSearch)`
  position: absolute;
  top: 50%;
  left: 40px;
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
  color: ${TangleColors.white};
  opacity: 0.8;
`
const TokenSearchDiv = styled.input`
  height: 42px;
  max-width: 440px;

  padding: 12px 12px 12px 40px;
  width: 100%;
  outline: none;
  border: none;
  background: ${TangleColors.tangleBlack};
  margin: 8px auto;
  color: ${TangleColors.white};
  border: 0.5px solid ${TangleColors.grayLighter};
  font-family: "DM Sans Regular";

  font-size: 14px;
  border-radius: 12px;
  ::placeholder {
    font-family: "DM Sans Regular";
    font-size: 14px;
    color: ${TangleColors.white};
    opacity: 0.4;
  }
  transition: 0.4s ease-in;
  :focus {
    transition: 0.4s ease-in;
    border: 0.5px solid ${TangleColors.lighthover};
  }
  :hover {
    transition: 0.4s ease-in;
    /* opacity: 0.8; */

    border: 0.5px solid ${TangleColors.lighthover};
  }
`
const ModalTokens = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  flex-wrap: wrap;

  padding: 0 20px;
  margin: 0;
`
const ModalBottom = styled.div`
  cursor: pointer;
  width: 100%;
  max-width: 490px;
  height: 0px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  display: flex;

  align-items: center;
  justify-content: center;
`

// const EditIcon = styled(TbEdit)`
//   height: 24px;
//   width: 24px;
//   color: ${TangleColors.lightPurple};
// `
// const ModalSubtitle = styled(CaptionSmall)`
//   color: ${TangleColors.lightPurple};
//   margin: 0 8px;
// `
const EditHref = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`
const RowBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* padding: 16px; */
`
const NoTokenFetch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 18px 0 0 0;
  /* padding: 16px; */
`

const ListedTokens = styled.div`
  height: 440px;
  width: 100%;
  margin: 14px 0 8px 0;
  overflow-y: scroll;

  /* padding: 4px 0 2px 0; */
  border-top: 1px solid ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  ::-webkit-scrollbar {
    width: 6px;
    cursor: pointer;
    transition: 0.4s ease-in;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${TangleColors.lightPurple};
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: 0.4s ease-in;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${TangleColors.white};
    border-radius: 10px;
    -webkit-border-radius: 10px;
    cursor: pointer;
    transition: 0.4s ease-in;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${TangleColors.heroCTA};
    cursor: pointer;
    transition: 0.4s ease-in;
  }
`

export default TokensModal
