import {
  Chains,
  MAGIC_ADDRESS,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
} from "@tangleswap/sdk"

import { defaultChainId, useWSCContext } from "context/MilkomedaContext"
import React from "react"

export function getNativeAddress(chainId: any): { address: string } {
  const addr = !chainId
    ? NATIVE_SYMBOL[defaultChainId]
    : chainId === Chains.SHIMMER || chainId === Chains.SHIMMER_TEST
    ? MAGIC_ADDRESS[chainId]
    : chainId === Chains.L1_CARDANO || chainId === Chains.L1_CARDANO_TEST
    ? WRAPPED_ADDRESS[chainId]
    : NATIVE_SYMBOL[chainId]
  return addr
}

export default function useNative(): {
  nativeToken: any
} {
  const [nativeToken, setNativeToken] = React.useState<any>(undefined)
  const { l1ChainId } = useWSCContext()
  const tokenListStored: any = localStorage.getItem(
    `tangleswapChainId${l1ChainId}Tokens`
  )
  const TangleswapTokenList = JSON.parse(tokenListStored)
  const nativeTokenAddress = getNativeAddress(l1ChainId)

  const nativeTokenValue = React.useMemo(() => {
    return TangleswapTokenList?.tokens?.filter((token: any) => {
      return token.address.includes(`${nativeTokenAddress}`)
    })
  }, [TangleswapTokenList, nativeTokenAddress])

  React.useEffect(() => {
    setNativeToken(nativeTokenValue)
  }, [nativeTokenValue])

  return { nativeToken }
}
