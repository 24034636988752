import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"

const SwapChart = () => {
  return (
    <ChartBackground>
      <ChartText>Coming Soon</ChartText>
    </ChartBackground>
  )
}

const ChartBackground = styled.div`
  min-height: 500px;
  width: 100%;
  margin: 24px auto;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ChartText = styled(CaptionSmall)`
  color: ${TangleColors.tangleYellow};
  text-align: center;
`

export default SwapChart
