import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import AccountWrapper from "./AccountWrapper"
import { useWSCContext } from "context/MilkomedaContext"
import { useLocation } from "react-router-dom"

const AccountNavbar = () => {
  const [isAddressOpen, setIsAddressOpen] = useState<boolean>(false)
  const [networkDropDownOpen, setNetworkDropDownOpen] = useState<boolean>(false)
  const [activeMenu, setActiveMenu] = useState("mainDropdown")

  const wrapRef = useRef<HTMLDivElement | any>()
  const modalWrapperRef = useRef<any>(null)
  const { chainId } = useWSCContext()
  const location = useLocation()
  useEffect(() => {
    if (!chainId) return
    setNetworkDropDownOpen(false)
  }, [chainId])
  useEffect(() => {
    setIsAddressOpen(false)
    setNetworkDropDownOpen(false)
  }, [location])
  const openNetworkDropdown = () => {
    setNetworkDropDownOpen(true)
    setIsAddressOpen(false)
  }
  const closeNetworkDropdown = () => {
    setNetworkDropDownOpen(false)
    setIsAddressOpen(false)
  }
  const openMainAddressMenu = () => {
    setIsAddressOpen(true)
    setActiveMenu("mainDropdown")
    setNetworkDropDownOpen(false)
  }
  const closeMainAddressMenu = () => {
    setIsAddressOpen(false)
    setActiveMenu("mainDropdown")
    setNetworkDropDownOpen(false)
  }

  const goBackToMainMenu = () => {
    setIsAddressOpen(true)
    setActiveMenu("mainDropdown")
    setNetworkDropDownOpen(false)
  }
  const closeTransactionMainAddressMenu = () => {
    setIsAddressOpen(false)
    setActiveMenu("mainDropdown")
    setNetworkDropDownOpen(false)
  }
  const openTransactionMenu = () => {
    setNetworkDropDownOpen(false)
    setIsAddressOpen(true)
    setActiveMenu("transactionHistory")
  }
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        modalWrapperRef.current &&
        !modalWrapperRef.current.contains(e.target)
      ) {
        setIsAddressOpen(false)
        setNetworkDropDownOpen(false)
        setActiveMenu("mainDropdown")
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <NavButtonCover ref={modalWrapperRef}>
      <AccountWrapper
        goBackToMainMenu={goBackToMainMenu}
        openNetworkDropdown={
          networkDropDownOpen ? closeNetworkDropdown : openNetworkDropdown
        }
        isAddressOpen={isAddressOpen}
        activeMenu={activeMenu}
        openMainAddressMenu={
          // activeMenu === "mainDropdown" &&
          isAddressOpen ? closeMainAddressMenu : openMainAddressMenu
        }
        openTransactionMenu={openTransactionMenu}
        networkDropDownOpen={networkDropDownOpen}
      />
    </NavButtonCover>
  )
}

const NavButtonCover = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 840 !important;
  @media only screen and (max-width: 650px) {
    max-width: 800px;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
  }
`

export default AccountNavbar
