export interface HomeCardsProps {
  image?: any
  degree?: any
  cardMargin?: any
  className?: any
}
export const homeCardsData: HomeCardsProps[] = [
  {
    image: "/dapp-swap.png",
    degree: -12,
  },
  {
    degree: 12,
    image: "/dapp-liquidity.png",
    cardMargin: 80,
    className: "hide_mobile",
  },
  {
    degree: -8.272,
    image: "/dapp-farm.png",
    cardMargin: 80,
  },
  {
    degree: 20,
    className: "hide_mobile",
    image: "/dapp-ve-staking.png",
  },
]
