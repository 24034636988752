import React, { FC, useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import { GrClose } from "react-icons/gr"
import { TangleColors } from "styles/ColorStyles"
import { RiSettings2Line } from "react-icons/ri"
import ModalSettings from "../ModalSettings"

import { ModalTopBar, TopText } from "../styles"

import { CancelPendingContext } from "context/CancelModalContext"

interface ModalProps {
  title?: string
  closeModal?: () => void
  cancelTransaction?: () => void
}
const ModalTopIcon: FC<ModalProps> = (props) => {
  const { title, closeModal, cancelTransaction } = props
  const { openModalSettings, setOpenModalSettings } =
    useContext(CancelPendingContext)

  const modalSettingsRef = useRef<HTMLDivElement | any>()
  useEffect(() => {
    const closeSlippageDropdownBody = (event) => {
      if (
        openModalSettings &&
        !modalSettingsRef.current.contains(event.target)
      ) {
        setOpenModalSettings(false)
      }
    }

    document.addEventListener("mousedown", closeSlippageDropdownBody)

    return () => {
      document.removeEventListener("mousedown", closeSlippageDropdownBody)
    }
  }, [openModalSettings])
  const toggleOpenModal = () => {
    setOpenModalSettings(!openModalSettings)
  }

  return (
    <ModalIconsCover ref={modalSettingsRef}>
      <ModalTitleSettingsCover>
        <ModalTopBar>
          <TopText>{title}</TopText>
        </ModalTopBar>
        <SettingsIconCover>
          <SettingsWrapper>
            <SettingsIcon onClick={toggleOpenModal} />
          </SettingsWrapper>
        </SettingsIconCover>{" "}
      </ModalTitleSettingsCover>
      <CloseIconWrapper onClick={closeModal}>
        <CloseIcon />
      </CloseIconWrapper>
      <ModalSettings
        modalOpen={openModalSettings}
        cancelTransaction={cancelTransaction}
      />
    </ModalIconsCover>
  )
}
const ModalIconsCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 850;
  /* position: absolute;
  top: 16px;
  right: 24px; */
`

const SettingsWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: ${TangleColors.white};
`
const SettingsIcon = styled(RiSettings2Line)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  transition: 0.4s ease-in;
  color: ${TangleColors.lighthover};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.white};
  }
`
const SettingsIconCover = styled.div`
  position: relative;
`
const ModalTitleSettingsCover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;

  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default ModalTopIcon
