import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmallRegular, Header7 } from "styles/TextStyles"
interface WalletAboutProps {
  active?: boolean
}
const WalletAboutView: FC<WalletAboutProps> = (props) => {
  const { active } = props
  return (
    <StepperInfoCover active={active}>
      <StepperTopRow>
        {" "}
        <StepperRowTitle>
          What are Cardano Wrapped Smart Contracts?
          <br />
          <br />
        </StepperRowTitle>
        <StepperText>
          Wrapped Smart Contracts (WSCs) serve as a key infrastructure component
          for both TangleSwap and Cardano, enabling seamless interactions
          between apps on Layer-2 (L2) solutions and Cardano. This technological
          advancement is bleeding-edge (released in Q3 '23) and the first of its
          kind in the Cardano ecosystem.
          <br />
          <br />
        </StepperText>
        <StepperText>
          In short, TangleSwap leverages WSCs to utilize the Cardano Layer-1
          (L1) as a robust coordination layer, which provides a user-friendly
          experience as users can use any app without changing wallets or
          needing a deep understanding of the underlying processes. This system
          grants users with a native-like experience that harnesses Cardano's
          decentralization and security, while simultaneously benefiting from
          the best EVM features, namely superior interoperability and
          composability with the rest of the industry.
          <br />
          <br />
          <StepperText>
            A great example of these features is the fact that TangleSwap is not
            only the first Concentrated Liquidity DEX on Cardano (involving
            highly complex smart contracts), but our interoperable
            infrastructure is also able to bring an inflow of major external
            stablecoins and global cryptocurrencies — such as USDC, USDT, BTC
            and ETH — into Cardano. As a user, this means that you can now use
            your Nami or Eternl wallet to trade USDC or ETH, a feat that has
            never been possible in the ecosystem until very recently. ✨
            <br />
            <br />
          </StepperText>
          <StepperText>
            Combined with the overall value propositon behind the TangleSwap
            protocol, our team sincerely envisions that the unique
            interoperabitity provided by WSC will streamline the onboarding of
            users and protocols from various chains in the industry, therefore
            creating a myriad of growth opportunities for the Cardano ecosystem.
          </StepperText>
        </StepperText>
      </StepperTopRow>
      {/* <StepperTopRow>
        {" "}
        <StepperRowTitle>How it works</StepperRowTitle>
        <StepperText>
          Every single step requires user interaction in the form of a
          transaction.
        </StepperText>
      </StepperTopRow>{" "}
      <StepperTopRow>
        <StepperInfoRow>
          <StepperSubText>
            <span>User Action:</span> The user initiates an action on a dApp
            while on the main blockchain. This request is translated into
            specific parameters for a proxy smart contract.
          </StepperSubText>
        </StepperInfoRow>
      </StepperTopRow> */}
    </StepperInfoCover>
  )
}
const StepperInfoCover = styled.div<WalletAboutProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
`
const StepperTopRow = styled.div`
  display: flex;
  width: 100%;
  margin: 12px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepperRowTitle = styled(Header7)`
  color: ${TangleColors.white};
  margin: 12px 0 6px 0;
`
const StepperText = styled(CaptionSmallRegular)`
  color: ${TangleColors.white};
  margin: 4px 0;
  line-height: 1.7 !important;
`

export default WalletAboutView
