import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { DurationMainInput } from "styles/InputStyles"
import { StakingMainButton } from "styles/ButtonStyles"
import { Caption, CaptionSmall, Subtitle } from "styles/TextStyles"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import { Chains } from "@tangleswap/sdk"

import { Dots } from "components/utils/LoadingDots"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { useWSCContext } from "context/MilkomedaContext"
import { useAppSelector } from "store/hooks"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
interface InputActiveProps {
  active?: boolean
  disabled?: boolean
}
interface StakeInputProps {
  voidValue?: number
  dollarValue?: number
  duration?: number
  existingDuration?: number | null
  balance?: number
  handleStakeAction?: () => void
  handleStakeInputChange?: (val: string) => void
  handleWeekSelect?: (val: number) => void
  handleDurationChange?: (val: string) => void
  weekSelectActive?: number | null | undefined
  stakeDisable?: boolean
  register?: any
  tokenBalance?: any
  stakeInputValue?: string
  durationInputValue?: string
  chainId?: Chains
  tokenUSDFiat?: number
  tokenBalanceAmount?: number
  stakingApproving?: boolean
  userAllowance?: boolean
  insufficientBalance?: boolean
}

const StakeInput: React.FC<StakeInputProps> = (props) => {
  const {
    handleStakeAction,
    handleWeekSelect,

    register,
    stakingApproving,
    stakeInputValue,
    durationInputValue,
    tokenUSDFiat,
    tokenBalanceAmount,
    userAllowance,
    insufficientBalance,
  } = props
  const { isWSCConnected } = useWSCContext()
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }
  return (
    <Container>
      <Inputs>
        <InputContainer>
          <InputBackground
            disabled={!!stakeInputValue && Number(stakeInputValue) < 100}
          >
            <Input
              type="text"
              placeholder="1,000"
              {...register("stakeInputValue")}
              onInput={(e: any) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                const parts = e.target.value.split(".")

                if (parts.length > 2) {
                  e.target.value = parts[0] + "." + parts.slice(1).join("")
                }
              }}
              autoComplete="off"
            />

            <InputAfterElement>
              {tokenUSDFiat > 0
                ? `~$${
                    isNaN(Number(tokenUSDFiat))
                      ? 0
                      : formatNumber(Number(tokenUSDFiat), 3)
                  }`
                : null}
            </InputAfterElement>
          </InputBackground>
          <StakeBottomWrapperRow>
            <StakeErrorText>
              {!!stakeInputValue && Number(stakeInputValue) < 100 ? (
                <>100 VOID required</>
              ) : (
                <>
                  {" "}
                  <BalanceText>
                    Balance:{" "}
                    <BalanceNumber>
                      {formatNumber(Number(tokenBalanceAmount ?? 0), 3)} VOID
                    </BalanceNumber>
                  </BalanceText>
                </>
              )}
            </StakeErrorText>
          </StakeBottomWrapperRow>
        </InputContainer>

        <DurationContainer>
          <DurationTitle>
            <DarkPurpleText>ADD</DarkPurpleText> DURATION
          </DurationTitle>
          <WeekSelectRow>
            <WeekSelectItem
              active={Number(durationInputValue) === 1}
              onClick={() => handleWeekSelect(1)}
            >
              <WeekSelectorText> 1W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 4}
              onClick={() => handleWeekSelect(4)}
            >
              <WeekSelectorText>4W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 12}
              onClick={() => handleWeekSelect(12)}
            >
              <WeekSelectorText>12W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 52}
              onClick={() => handleWeekSelect(52)}
            >
              <WeekSelectorText>52W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={
                Number(durationInputValue) === 200 ||
                Number(durationInputValue) > 52
              }
              onClick={() => handleWeekSelect(200)}
            >
              <WeekSelectorText>MAX</WeekSelectorText>
            </WeekSelectItem>
          </WeekSelectRow>
          <InputContainer>
            <DurationRow>
              <StakeWrapperRow>
                <DurationInput
                  autoComplete="off"
                  placeholder="69"
                  {...register("durationInputValue")}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                    const parts = e.target.value.split(".")

                    if (parts.length > 2) {
                      e.target.value = parts[0] + "." + parts.slice(1).join("")
                    }
                  }}
                />
                <StakeErrorText>
                  {(!durationInputValue && !!stakeInputValue) ||
                  (!!durationInputValue && Number(durationInputValue) < 1) ? (
                    <>Select at least 1 week</>
                  ) : null}
                </StakeErrorText>
              </StakeWrapperRow>
              <WeeksText>Weeks</WeeksText>
            </DurationRow>
          </InputContainer>
        </DurationContainer>
      </Inputs>
      <Action>
        <StakeButton
          disabled={
            !!insufficientBalance ||
            !!stakingApproving ||
            !stakeInputValue ||
            !durationInputValue ||
            (!!durationInputValue && Number(durationInputValue) < 1) ||
            (!!stakeInputValue && Number(stakeInputValue) < 100)
          }
          onClick={
            !!isWSCConnected && !!blockTransactionState
              ? handlePendingTxModal
              : handleStakeAction
          }
          blockTransactionState={!!blockTransactionState}
        >
          {" "}
          {!!blockTransactionState ? (
            <>
              <SwapButtonSpinner />
            </>
          ) : null}
          {!!blockTransactionState ? (
            <>Continue </>
          ) : (
            <>
              {" "}
              {!!stakingApproving ? (
                <>
                  <ApprovingText> Approving</ApprovingText> <Dots />
                </>
              ) : (
                <>
                  {" "}
                  {!!insufficientBalance ? (
                    <>Insufficient VOID</>
                  ) : (
                    <>{!!userAllowance ? <>Approve VOID</> : <>Stake VOID</>}</>
                  )}
                </>
              )}
            </>
          )}
        </StakeButton>
      </Action>
    </Container>
  )
}

const DurationTitle = styled(Subtitle)`
  display: flex;
  color: ${TangleColors.white};
  gap: 4px;
  font-weight: 600;
  margin: 2px 0;
  margin: 0 0 6px 0;
`

const DarkPurpleText = styled.div`
  color: ${TangleColors.lightPurple};
`

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const WeekSelectRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`
const Input = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  color: ${TangleColors.white};
  font-size: 24px;
  text-align: right;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "DM Sans Regular";
  font-size: 18px;
`
const WeekSelectItem = styled.div<InputActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.active
      ? `1px solid ${TangleColors.lighthover}`
      : `1px solid transparent`};
  background: ${(props) =>
    props.active ? `${TangleColors.cta}` : `${TangleColors.dockBG}`};
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.tangleYellow}`};
  border-radius: 9999px;
  padding: 4px 10px;
  cursor: pointer;
`
const WeekSelectorText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`
const DurationInput = styled(DurationMainInput)``

const InputBackground = styled.div<InputActiveProps>`
  background: ${TangleColors.tangleBlack};

  border-radius: 16px;
  border: ${(props) =>
    props.disabled
      ? `1.2px solid ${TangleColors.red}`
      : `1.2px solid  ${TangleColors.grayLighter}`};
  width: 100%;
  padding: 12px 24px !important;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: 0.4s ease-in;
  :focus {
    transition: 0.4s ease-in;
    border: 0.5px solid ${TangleColors.lighthover};
  }
  :hover {
    transition: 0.4s ease-in;
    /* opacity: 0.8; */

    border: 0.5px solid ${TangleColors.lighthover};
  }
`

const WeeksText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 4px;
`

const InputAfterElement = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  text-align: right;
  margin: 0;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const BalanceText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-right: 12px; */
  white-space: nowrap;
  margin: 3px 0 0 0;
`

const BalanceNumber = styled(CaptionSmall)<StakeInputProps>`
  color: ${(props) =>
    props.insufficientBalance
      ? `${TangleColors.red}`
      : `${TangleColors.lighthover}`};
  margin: 0 0 0 8px;
  cursor: pointer;
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StakeButton = styled(StakingMainButton)``
const ApprovingText = styled.p`
  margin: 4px 0 0 0;
`
const Container = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`

const DurationRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin: 6px 0 0 0;
`
const StakeWrapperRow = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`
const StakeBottomWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const StakeErrorText = styled(CaptionSmall)`
  color: ${TangleColors.red};
  margin: 3px 0 0 0;
  white-space: nowrap;
`

export default StakeInput
