import HoverBox from "components/utils/HoverBox"
import { FC, useState } from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"

interface AprDisplayProps {
  apr?: number
  isEnded?: boolean
}

const TopAPRDisplay: FC<AprDisplayProps> = (props) => {
  const { apr, isEnded } = props
  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  // const { isEnded } = useContext(FarmInfoContext)
  const mouseEnter = () => {
    setShowHelpText(true)
  }

  const mouseLeave = () => {
    setShowHelpText(false)
  }

  return (
    <Body>
      <TextTitle>APR</TextTitle>
      <AprTextContainer>
        <AprText>
          {isEnded
            ? "ENDED"
            : !!apr
            ? `${toSignificantDigits(apr, 4, true, 18)}%`
            : "0 %"}
        </AprText>
        {!isEnded ? (
          <>
            {" "}
            <InfoButton>
              <InfoCircle onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />

              {showHelpText ? (
                <>
                  {" "}
                  <HoverBox
                    left={-30}
                    top={20}
                    text={`Estimated from the rewards received in the past 30 days by LP positions that match *exactly* the reward range of this farm.`}
                  />
                </>
              ) : null}
            </InfoButton>
          </>
        ) : null}
      </AprTextContainer>
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 0 2px 0;
`

const AprText = styled(Caption)`
  color: ${TangleColors.white};
`

const AprTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const InfoCircle = styled(AiOutlineInfoCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
`

const InfoButton = styled.div`
  color: ${TangleColors.white};
  cursor: pointer;
  position: relative;
`

export default TopAPRDisplay
