import React, { FC, RefObject, useEffect, useState } from "react"
import Confetti from "react-confetti"
import { GrClose } from "react-icons/gr"
import styled from "styled-components"
import { ParticipateMainButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Header3, Subtitle } from "styles/TextStyles"
import { formatCountdown } from "./utils/formatNumber"
import LoadingDots from "components/utils/LoadingDots"
import AuctionCountdownTimer from "./AuctionCountdownTimer"

interface ConfettiProps {
  success?: boolean
  showFinalize?: boolean
  finalizeAuction?: () => void
  buttonRef?: RefObject<any>
  closeFinalize?: () => void
  timeUntilFinalizeWindowExpires?: number
  closeFinalizeModal?: (value: any) => void
  userAbleToFinalize?: boolean
  isAdmin?: boolean
  isOperator?: boolean
  showWithdrawTokens?: boolean
  showAlreadyWithdrawnTokens?: boolean
  finalizeState?: boolean
}
const ReactConfetti: FC<ConfettiProps> = (props) => {
  const {
    success,
    showFinalize,
    finalizeAuction,
    buttonRef,
    closeFinalize,
    closeFinalizeModal,
    timeUntilFinalizeWindowExpires,
    userAbleToFinalize,
    isAdmin,
    isOperator,
    finalizeState,
  } = props

  // const [isUseWeb3ReactLoaded, setIsUseWeb3ReactLoaded] = useState<boolean>(false)
  // setTimeout(() =>
  //   setIsUseWeb3ReactLoaded(true)
  // , 1000)

  // const [showInfo, setShowInfo] = useState<boolean>(false)
  // useEffect(() => {
  //   const intervalId = setInterval(() => setShowInfo(true), 1000)
  //   return () => clearInterval(intervalId)
  // }, [])

  return (
    <>
      {showFinalize ? (
        <>
          <ConfettiBackground onClick={closeFinalizeModal}>
            {success ? <Confetti /> : null}
            <ConfettiCover>
              <CloseIcon onClick={closeFinalize} />
              <ConfettiTextRowCover>
                <ConfettiTitle success={success}>
                  {success ? <>Congratulations!</> : <>Shoot!</>}
                </ConfettiTitle>
                <ConfettiSubtext success={success}>
                  {success ? (
                    <>
                      {userAbleToFinalize ? (
                        <>This auction succeeded to meet the minimum raise!</>
                      ) : (
                        <>
                          <ConfettiSubtext success={success}>
                            This auction succeeded to meet the minimum raise!
                          </ConfettiSubtext>
                          <ConfettiSubtext success={success}>
                            Now, the admin needs to finalize the auction before
                            anyone can withdraw their tokens. Otherwise, if the
                            auction is not finalized after 7 days, any person
                            will be able to finalize it.
                          </ConfettiSubtext>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {userAbleToFinalize ? (
                        <>This auction failed to reach its minimum raise!</>
                      ) : (
                        <>
                          <ConfettiSubtext success={success}>
                            This auction failed to reach its minimum raise!
                          </ConfettiSubtext>
                          <ConfettiSubtext success={success}>
                            Now, the admin needs to finalize the auction before
                            anyone can withdraw their tokens. Otherwise, if the
                            auction is not finalized after 7 days, any person
                            will be able to finalize it.
                          </ConfettiSubtext>
                        </>
                      )}
                    </>
                  )}
                </ConfettiSubtext>
                <ConfettiButton
                  ref={buttonRef}
                  onClick={finalizeAuction}
                  disabled={!userAbleToFinalize}
                >
                  {userAbleToFinalize ? (
                    <>
                      {" "}
                      {finalizeState ? (
                        <>
                          <LoadingDots />
                        </>
                      ) : (
                        <>Finalize Auction</>
                      )}
                    </>
                  ) : (
                    <>
                      {isAdmin || isOperator ? (
                        <>
                          {finalizeState ? (
                            <>
                              <LoadingDots />
                            </>
                          ) : (
                            <>Finalize Auction</>
                          )}
                        </>
                      ) : (
                        <>
                          Time Left:{" "}
                          {formatCountdown(timeUntilFinalizeWindowExpires)}
                        </>
                      )}
                    </>
                  )}
                </ConfettiButton>
              </ConfettiTextRowCover>
            </ConfettiCover>
          </ConfettiBackground>
        </>
      ) : null}
    </>
  )
}

const ConfettiBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 30;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.02
  ); // Semi-transparent fallback for browsers without backdrop-filter support
  backdrop-filter: blur(20px); // This will blur the background
`
const ConfettiCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`
const ConfettiTextRowCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: 24px auto;
  text-align: center;
`
const ConfettiTitle = styled(Header3)<ConfettiProps>`
  text-align: center;
  color: ${(props) =>
    props.success ? `${TangleColors.tangleGreen}` : `${TangleColors.red}`};
`
const ConfettiSubtext = styled(Subtitle)<ConfettiProps>`
  color: ${(props) =>
    props.success ? `${TangleColors.tangleGreen}` : `${TangleColors.red}`};
  margin: 16px 0;
  text-align: center;
`

const ConfettiButton = styled(ParticipateMainButton)`
  margin: 32px auto;
  max-width: 550px;
  width: 100%;

  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.grayLight};

    border: none;
  }
`
const CloseIcon = styled(GrClose)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

export default ReactConfetti
