import { wagmiClientTestnet } from "config/milkomeda/wagmi"
import MilkomedaContext from "context/MilkomedaContext"
import { ConnectWSCProvider } from "milkomeda-wsc-ui-test-beta"
import { FC, ReactNode } from "react"
import { themeVars } from "styles/WscStyles"
import { WagmiConfig } from "wagmi"

interface AppWrapperProps {
  children?: ReactNode
}

const AppWrapper: FC<AppWrapperProps> = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClientTestnet}>
      <ConnectWSCProvider customTheme={themeVars}>
        <MilkomedaContext>{children}</MilkomedaContext>
      </ConnectWSCProvider>
    </WagmiConfig>
  )
}

export default AppWrapper
