import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { toast as TangleToast } from "sonner"
import {
  Chains,
  MAX_UINT128,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
  WRAPPED_SYMBOL,
  calcAmount,
  wrapAddr,
  isInvalid,
  sqrtPriceToPrice,
  stringToBigInt,
  tickToPrice,
} from "@tangleswap/sdk"
import LPPrice from "components/Liquidity/AddLiquid/LPPrice"
import RemoveLiquidityModal from "components/Liquidity/RemoveLiquidity/RemoveLiquidityModal"
import PositionsLoadingPlaceholder from "components/Liquidity/component/PositionsLoadingPlaceholder"
import { useWSCContext } from "context/MilkomedaContext"
import RemoveLPConfirmationModal from "components/confirmation/RemoveLPConfirmationModal"
import HoverBox from "components/utils/HoverBox"
import LoadingDots from "components/utils/LoadingDots"
import SlippageContainer from "components/utils/SlippageContainer"

import { ZERO_ADDRESS } from "components/utils/misc"

import addChainToMetamask from "connect/switchChain"
import { PendingStateContext } from "context/PendingContext"
import { toast as SonnerToast } from "sonner"
import { BsArrowLeft } from "react-icons/bs"
import { RiSettings2Line } from "react-icons/ri"

import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  ChainChangeButton,
  SwapConnectButton,
  SwapTokenButton,
} from "styles/ButtonStyles"
import { Caption, Header2, Header5Gothic, Subtitle } from "styles/TextStyles"
import LPType from "utils/LPType"
import { convertNumber, toSignificantDigits } from "utils/toSignificant"
import DappsDivider from "utils/DappsDivider"
import RemoveLiquidityLoading from "components/Liquidity/component/RemoveLiquidityLoading"
import IncreaseRemoveAuthLayout from "components/Liquidity/component/IncreaseRemoveAuthLayout"
import CollectAsWrappedNative from "components/Liquidity/CollectAsWrappedNative"
import { useIsSupportedNetwork } from "constants/hooks"

import { useNavigate, useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { fetchTangleCurrency } from "../utils/liquidity/useFetchLPCurrency"

import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { useQuery } from "react-query"
import { useTangleship } from "utils/useTangleship"
import CurrencyTokenImages from "../component/CurrencyTokenImages"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"
import useTangleArray from "utils/useTangleArray"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import RemoveLiqModal, {
  useRemoveLiqModalControl,
} from "components/milkomeda/liquidity/RemoveLiqModal"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import { sk } from "date-fns/locale"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"

interface LiquidityProps {
  account?: boolean
}
const RemoveLiquidityContainer = () => {
  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()

  const { tangleship } = useTangleship()
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const { id: liquidityID } = useParams()
  const [switchChainModal, setSwitchChainModal] = useState<boolean>(false)
  const navigateRouter = useNavigate()
  const [tx, setTx] = useState<any>(undefined)
  const [txSent, setTxSent] = useState<any>(undefined)
  const [poolToken0, setPoolToken0] = useState<any>(undefined)
  const [poolToken1, setPoolToken1] = useState<any>(undefined)
  const [outOfRange, setOutOfRange] = useState<any>(undefined)
  const [priceUpper, setPriceUpper] = useState<any>(undefined)
  const [priceLower, setPriceLower] = useState<any>(undefined)
  const [price, setPrice] = useState<any>(undefined)
  const [burnLpNft, setBurnLpNft] = useState<boolean>(false)

  const { setPendingTransaction }: any = useContext(PendingStateContext)
  const [rangeValue, setRangeValue] = useState<string>("" || "0")
  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  const [removeModalActive, setRemoveModalActive] = useState<boolean>(false)
  const [showSlippage, setShowSlippage] = useState<boolean>(false)
  const [poolAddress, setPoolAddress] = useState<string>(ZERO_ADDRESS)
  const [positionInfo, setPositionInfo] = useState<any>()
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false)
  const [txpending, setTxPending] = useState<boolean>(true)
  const [isWrappedToggled, setIsWrappedToggled] = useState<any>(undefined)
  const [tokenAAddress, setTokenAAddress] = useState<any>(undefined)
  const [tokenBAddress, setTokenBAddress] = useState<any>(undefined)
  const [wrappedAddress0, setWrappedAddress0] = useState<any>(undefined)
  const [wrappedAddress1, setWrappedAddress1] = useState<any>(undefined)
  const [nativeSymbol, setNativeSymbol] = useState<any>(undefined)
  const [wrappedSymbol, setWrappedSymbol] = useState<any>(undefined)
  const [closedLP, setClosedLP] = useState<boolean>(false)
  const [toggledAddress, setToggledAddress] = useState<any>(undefined)
  const [wrappedAddress, setWrappedAddress] = useState<any>(undefined)
  const [token0Value, setToken0Value] = useState<any>(undefined)
  const [token1Value, setToken1Value] = useState<any>(undefined)
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const lpModalRef = useRef<HTMLDivElement | any>()
  const slippageRef = useRef<HTMLDivElement | any>()
  const closeRef = useRef<HTMLDivElement | any>()
  const bodyRef = useRef<HTMLDivElement | any>()
  const swapRef = useRef<HTMLDivElement | any>()
  const modalRef = useRef<HTMLDivElement | any>()

  const switchRef = useRef<HTMLDivElement | any>()
  const wscModalRef = useRef<any>(null)
  const showSlippageDropdown = () => {
    setShowSlippage(!showSlippage)
  }
  const mouseEnter = () => {
    setShowHelpText(true)
  }

  const closeSlippageDropdown = useCallback(
    (event: any) => {
      if (
        event?.target === closeRef.current &&
        event?.target !== slippageRef.current
      ) {
        setShowSlippage(false)
      }
    },
    [slippageRef]
  )

  const slippageTolerance = useAppSelector(
    (state) => state.settings.userSlippageTolerance
  )

  const deadlineMinute = useAppSelector(
    (state) => state.settings.deadlineMinute
  )

  const showRemoveModal = () => {
    setRemoveModalActive(true)
  }

  const closeRemoveModal = () => {
    setRemoveModalActive(false)
  }

  const mouseLeave = () => {
    setShowHelpText(false)
  }
  const onClickSeletor = (value: string) => {
    setRangeValue(value)
  }

  const handleRangeChange = (e: any) => {
    setRangeValue(e.target.value)
  }

  const { TangleswapUserPools, refetchAllPool } = useTangleArray(
    account,
    chainId
  )
  useEffect(() => {
    if (!TangleswapUserPools) return
    const info = TangleswapUserPools?.find((pool) => pool.id === liquidityID)
    const formattedInfo = {
      fee: Number(info?.feeTier),
      liquidity: Number(info?.liquidity),
      tickLower: Number(info?.tickLower),
      tickUpper: Number(info?.tickUpper),
      token0: info?.token0Address,
      token1: info?.token1Address,
    }
    setPositionInfo(formattedInfo)
  }, [TangleswapUserPools])

  const {
    data: currencyA,
    isLoading: poolsTokenALoading,
    isError,
    refetch: tokenARefetch,
  } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapRemoveLPToken0", tokenAAddress, l1ChainId],
    () => fetchTangleCurrency(tokenAAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenAAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const {
    data: currencyB,
    isLoading: poolsTokenBLoading,
    refetch: tokenBRefetch,
  } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapRemoveLPToken1", tokenBAddress, l1ChainId],
    () => fetchTangleCurrency(tokenBAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenBAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const {
    data: toggledToken,
    isLoading: poolsToggledTokenLoading,
    refetch: tokenToggleRefetch,
  } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapRemoveLPTokenToggle", toggledAddress, l1ChainId],
    () => fetchTangleCurrency(toggledAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!toggledAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  useEffect(() => {
    const nativeSymbolValue = NATIVE_SYMBOL[chainId]
    const wrappedSymbolValue = WRAPPED_SYMBOL[chainId]
    const wrappedAddressValue = WRAPPED_ADDRESS[chainId]
    const tokenAAddressValue =
      wrappedAddress === positionInfo?.token0
        ? NATIVE_SYMBOL[chainId]
        : positionInfo?.token0
    const tokenBAddressValue =
      wrappedAddress === positionInfo?.token1
        ? NATIVE_SYMBOL[chainId] // this is what we use as address for the native currency
        : positionInfo?.token1

    const wrappedAddress0Value = wrapAddr(
      currencyA?.address,
      chainId
    ).tokenAddress
    const wrappedAddress1Value = wrapAddr(
      currencyB?.address,
      chainId
    ).tokenAddress
    const toggledAddressValue = isWrappedToggled ? wrappedAddress : nativeSymbol
    setTokenAAddress(tokenAAddressValue)
    setTokenBAddress(tokenBAddressValue)
    setWrappedAddress0(wrappedAddress0Value)
    setWrappedAddress1(wrappedAddress1Value)
    setNativeSymbol(nativeSymbolValue)
    setWrappedSymbol(wrappedSymbolValue)
    setToggledAddress(toggledAddressValue)
    setWrappedAddress(wrappedAddressValue)
    return () => {
      setTokenAAddress(undefined)
      setTokenBAddress(undefined)
      setWrappedAddress0(undefined)
      setToggledAddress(undefined)
      setWrappedAddress1(undefined)
      setNativeSymbol(undefined)
      setWrappedSymbol(undefined)
      setWrappedAddress(undefined)
    }
  }, [
    chainId,
    currencyA,
    currencyB,
    isWrappedToggled,
    nativeSymbol,
    positionInfo?.token0,
    positionInfo?.token1,
    wrappedAddress,
  ])

  const isSameBlockhainOrder = isInvalid([wrappedAddress0, wrappedAddress1])
    ? null
    : wrappedAddress0.toLowerCase() < wrappedAddress1.toLowerCase() // this is enforced by contract

  const showToggleWrapped =
    wrappedAddress === positionInfo?.token0 ||
    wrappedAddress === positionInfo?.token1

  const tokenASymbol =
    currencyA?.symbol === wrappedSymbol ? nativeSymbol : currencyA?.symbol
  const tokenBSymbol =
    currencyB?.symbol === wrappedSymbol ? nativeSymbol : currencyB?.symbol
  const logoCurrencyA =
    wrappedAddress === positionInfo?.token0
      ? toggledToken && toggledToken?.logoURI
      : currencyA?.logoURI
  const logoCurrencyB =
    wrappedAddress === positionInfo?.token1
      ? toggledToken && toggledToken?.logoURI
      : currencyB?.logoURI
  const decimalsA = currencyA === undefined ? 0 : currencyA?.decimals
  const decimalsB = currencyB === undefined ? 0 : currencyB?.decimals

  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )

  useEffect(() => {
    const closeSlippageDropdownBody = (event) => {
      if (showSlippage && !slippageRef.current.contains(event.target)) {
        setShowSlippage(false)
      }
    }

    document.addEventListener("mousedown", closeSlippageDropdownBody)

    return () => {
      document.removeEventListener("mousedown", closeSlippageDropdownBody)
    }
  }, [showSlippage])

  useEffect(() => {
    const addrA = currencyA?.address?.toLowerCase()
    const addrB = currencyB?.address?.toLowerCase()
    const isSameOrder = addrA < addrB
    const addr0 = isSameOrder ? addrA : addrB
    const addr1 = isSameOrder ? addrB : addrA
    const feeTier = positionInfo?.fee
    if (
      isInvalid([addr0, addr1, feeTier, chainId]) ||
      positionInfo === undefined
    )
      return

    const storageKey = `poolAddress${chainId}${addr0}${addr1}${feeTier}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setPoolAddress(storageValue)
      return
    }

    tangleship?.getPool(addrA, addrB, feeTier).then((res) => {
      localStorage.setItem(storageKey, res)
      setPoolAddress(res)
    })
  }, [currencyA, currencyB, positionInfo])

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        currencyA?.address,
        currencyB?.address,
        currencyA?.decimals,
        currencyB?.decimals,
      ])
    )
      return

    const params = {
      token0: currencyA?.address,
      token1: currencyB?.address,
      decimals0: currencyA?.decimals,
      decimals1: currencyB?.decimals,
      feeTier: positionInfo?.fee,
    }
    checkSlot0Price(params)
  }, [
    checkSlot0Price,
    currencyA?.address,
    currencyA?.decimals,
    currencyB?.address,
    currencyB?.decimals,
    positionInfo?.fee,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const address0 = currencyA?.address
    const address1 = currencyB?.address
    const addr0 = wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0

    const poolKey = `${token0}_${token1}_${positionInfo?.fee}`
    const listItem = slot0PricesList?.[poolKey]
    if (!listItem) return

    setPrice(listItem?.price)
  }, [
    currencyA?.address,
    currencyB?.address,
    positionInfo?.fee,
    slot0PricesList,
  ])

  const uiPrice = useCallback(() => {
    if (!isInvalid([price, isSameBlockhainOrder])) {
      const uiAdjustedPrice = isSameBlockhainOrder ? price : 1 / price
      if (price !== 0) return uiAdjustedPrice
    }
  }, [isSameBlockhainOrder, price])

  useEffect(() => {
    if (
      isInvalid([
        currencyA?.address,
        currencyB?.address,
        positionInfo?.liquidity,
      ])
    )
      return
    const poolTokenValue =
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        true,
        positionInfo?.liquidity,
        price || 0,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
      10 ** currencyA?.decimals
    setPoolToken0(poolTokenValue)
  }, [chainId, currencyA, currencyB, decimalsA, decimalsB, positionInfo, price])

  useEffect(() => {
    if (
      isInvalid([
        currencyA?.address,
        currencyB?.address,
        positionInfo?.liquidity,
      ])
    )
      return
    const poolTokenValue =
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        false,
        positionInfo?.liquidity,
        price || 0,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
      10 ** currencyB?.decimals
    setPoolToken1(poolTokenValue)
  }, [chainId, currencyA, currencyB, decimalsA, decimalsB, positionInfo, price])

  useEffect(() => {
    if (isInvalid([positionInfo, decimalsA, decimalsB])) return
    const priceValue = tickToPrice(
      positionInfo?.tickLower,
      decimalsA,
      decimalsB
    )
    setPriceLower(priceValue)
  }, [positionInfo, decimalsA, decimalsB])

  useEffect(() => {
    if (isInvalid([positionInfo, decimalsA, decimalsB])) return
    const priceValue = tickToPrice(
      positionInfo?.tickUpper,
      decimalsA,
      decimalsB
    )
    setPriceUpper(priceValue)
  }, [positionInfo, decimalsA, decimalsB])

  useEffect(() => {
    const token0Info =
      rangeValue === "0" ? "0" : (poolToken0 * Number(rangeValue)) / 100
    const token1Info =
      rangeValue === "0" ? "0" : (poolToken1 * Number(rangeValue)) / 100
    setToken0Value(token0Info)
    setToken1Value(token1Info)
    return () => {
      setToken0Value(undefined)
      setToken1Value(undefined)
    }
  }, [poolToken0, poolToken1, rangeValue])

  const lpBodyClick = useCallback((event?: any) => {
    if (lpModalRef.current === event?.target) {
      setRemoveModalActive(false)
    }
  }, [])
  const isSupportedNetwork = useIsSupportedNetwork()
  const supported = isSupportedNetwork
  const { openWSCModal } = useOpenWSCActionModal()

  const prepRemoveLiqFn = () => {
    const deadline = Math.floor(Date.now() / 1000 + Number(deadlineMinute)) // deadlineMinute is stored in seconds, despite its name
    const slippage =
      slippageTolerance === "auto"
        ? 0.95
        : 1 - Number(slippageTolerance) / 10000 // slippageTolerance is e.g. "400" for "4%""

    const burnLp = Number(rangeValue) === 100
    // setBurnLpNft(burnLp)

    const currentLiquidity = positionInfo?.liquidity
    const liquidityToRemove = burnLp
      ? BigInt(currentLiquidity)
      : BigInt(
          Math.floor(Number(currentLiquidity) * (Number(rangeValue) / 100))
        )
    const relativeLiquidityRemoval =
      Number(rangeValue) === 100
        ? 1
        : Number(liquidityToRemove) / Number(currentLiquidity)

    const amount0Min = stringToBigInt(
      String(Number(poolToken0) * slippage * relativeLiquidityRemoval),
      decimalsA
    )
    const amount1Min = stringToBigInt(
      String(Number(poolToken1) * slippage * relativeLiquidityRemoval),
      decimalsB
    )
    const isUnwrapNeeded = showToggleWrapped ? !!isWrappedToggled : undefined

    const fn = tangleship?.decreaseLiquidity
    const fnParams = [
      Number(liquidityID),
      liquidityToRemove,
      currencyA?.address,
      currencyB?.address,
      amount0Min,
      amount1Min,
      deadline,
      account,
      MAX_UINT128,
      MAX_UINT128,
      isUnwrapNeeded,
      // burnLp,
    ] as const

    const fnFeedbackParams = { chainId, liquidityID, burnLp, rangeValue }
    const fnFeedback = (params, txReceipt) => {
      if (txReceipt?.status !== 0) {
        const liqChangeKey = `liquidityIDchange${params.chainId}${params.liquidityID}`
        const prev = sessionStorage.getItem(liqChangeKey)
        const next = params.burnLp
          ? -1
          : Number(prev) - Number(params.rangeValue) / 100
        sessionStorage.setItem(liqChangeKey, String(next))

        const storageKey0 = `liquidityID0fees${params.chainId}${params.liquidityID}`
        const storageKey1 = `liquidityID1fees${params.chainId}${params.liquidityID}`
        sessionStorage.setItem(storageKey0, "0")
        sessionStorage.setItem(storageKey1, "0")
      }
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }

  const removeLiquidityFn = async () => {
    if (!supported) {
      showSwitchWallet()
    } else {
      // toast.info(`Waiting for confirmation...`)
      setRemoveModalActive(false)
      setShowPendingModal(true)
      setTxPending(true)
      if (account === undefined || account === null) return

      const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepRemoveLiqFn()

      fn?.(...fnParams)?.then((res) => {
        setTx(res)
        setPendingTransaction(true)
        setTxPending(false)
        setTxSent(res?.hash)

        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback(fnFeedbackParams, receipt)

            setPendingTransaction(false)
            // tokenARefetch()
            // tokenBRefetch()
            // tokenToggleRefetch()
            TangleToast.success("Transaction successful", {
              description: `Removing liqudity for ${currencyA?.symbol} and ${currencyB?.symbol} successful`,
            })
          })
        } else {
          setRemoveModalActive(true)
          setShowPendingModal(false)
          setTxPending(false)
          setTx(undefined)
          setTxPending(false)
          setPendingTransaction(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  useEffect(() => {
    const closedLPValue = parseInt(positionInfo?.liquidity) === parseInt("0")
    setClosedLP(closedLPValue)
  }, [positionInfo?.liquidity])

  useEffect(() => {
    if (isInvalid([price, priceLower, priceUpper])) return
    const rangeValue = Boolean(
      price &&
        priceLower &&
        priceUpper &&
        (price < priceLower || price > priceUpper)
    )
    setOutOfRange(rangeValue)
  }, [priceUpper, priceLower, price])

  const closePendingModal = () => {
    if (txpending) {
      setShowPendingModal(false)
    } else {
      setShowPendingModal(false)
      setRangeValue("0")
      navigateRouter(burnLpNft ? `pools` : `/pools/${liquidityID}`)
    }
  }
  const closeModal = (event: any) => {
    if (txpending) {
      if (modalRef.current === event?.target) {
        setShowPendingModal(false)
      }
    } else {
      if (modalRef.current === event?.target) {
        setShowPendingModal(false)
        setRangeValue("0")
        navigateRouter(burnLpNft ? `pools` : `/pools/${liquidityID}`)
      }
    }
  }

  const closeSwitchWalletModalFn = (e: any) => {
    if (e?.target === switchRef.current) {
      setSwitchChainModal(false)
    }
  }
  const closeSwitchWalletModal = () => {
    setSwitchChainModal(false)
  }

  const showSwitchWallet = () => {
    setSwitchChainModal(true)
  }

  const onWrappedChange = () => {
    setIsWrappedToggled(true)
  }
  const offWrappedChange = () => {
    setIsWrappedToggled(false)
  }

  const disableElements =
    isNaN(Number(uiPrice())) || JSON.stringify(uiPrice()) === "null"

  const buttonDisabled =
    rangeValue === "0" ||
    (Number(token0Value) > 0 && !token0Value) ||
    isNaN(Number(token0Value)) ||
    Number.isNaN(token0Value) ||
    isNaN(Number(token1Value)) ||
    Number.isNaN(token1Value) ||
    (Number(token1Value) > 0 && !token1Value) ||
    Boolean(closedLP) ||
    disableElements

  const inputPrice = String(uiPrice())
  const handlePendingTxModal = () => {
    openWSCModal()
  }

  const [wrapData, setWrapData] = useState<IWrapMultipleData>(undefined)
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useRemoveLiqModalControl()

  const isWSCReady = () =>
    isWSCConnected && currencyA?.address && currencyB?.address

  const fireWSCModal = () => {
    if (!isWSCReady()) return

    const tokenOutData = []
    const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepRemoveLiqFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      if (Number(token0Value) > 0) {
        tokenOutData.push({
          address: currencyA?.address,
          name: currencyA?.name,
          symbol: currencyA?.symbol,
          chainId: chainId,
          decimals: currencyA?.decimals,
          logoURI: currencyA?.logoURI,
          amount: Number(token0Value),
          unit: getTokenUnit(currencyA?.l1Address),
          l1Address: currencyA?.l1Address,
          l1EncodedName: currencyA?.l1EncodedName,
          l1Decimals: currencyA?.l1Decimals,
        })
      }

      if (Number(token1Value) > 0) {
        tokenOutData.push({
          address: currencyB?.address,
          name: currencyB?.name,
          symbol: currencyB?.symbol,
          chainId: chainId,
          decimals: currencyB?.decimals,
          logoURI: currencyB?.logoURI,
          amount: Number(token1Value),
          unit: getTokenUnit(currencyB?.l1Address),
          l1Address: currencyB?.l1Address,
          l1EncodedName: currencyB?.l1EncodedName,
          l1Decimals: currencyB?.l1Decimals,
        })
      }

      setWrapData({
        tokenOut: tokenOutData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: fnFeedbackParams,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    toggleWSCModal()
  }

  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleWSCModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
      closeRemoveModal()
    }
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
    closeRemoveModal()
    setOpenModalSettings(false)
    setOpenModalSettings(false)
  }
  return (
    <>
      {(account && isSupportedNetwork && !currencyA) ||
      poolsTokenALoading ||
      poolsTokenBLoading ||
      poolsToggledTokenLoading ? (
        <RemoveLiquidityLoading />
      ) : (
        <Body ref={bodyRef}>
          <>
            {" "}
            <Cover account={Boolean(!account)} ref={swapRef}>
              {account ? (
                <>
                  {" "}
                  {showPendingModal ? (
                    <>
                      <RemoveLPConfirmationModal
                        modalRef={modalRef}
                        amount0={token0Value}
                        amount1={token1Value}
                        pending={txpending}
                        currency0={tokenASymbol}
                        currency1={tokenBSymbol}
                        closePendingModal={closePendingModal}
                        closeModal={closeModal}
                        link={txSent}
                      />
                    </>
                  ) : null}
                  {isWSCConnected && (
                    <RemoveLiqModal
                      isVisible={isWSCModalVisible}
                      toggleModal={toggleWSCModal}
                      wrapData={wrapData}
                      skipWallet={skipWallet}
                      openWalletOverview={openWalletOverview}
                      wscModalRef={wscModalRef}
                      closeWSCContinue={closeWSCContinue}
                      cancelTransaction={cancelTransaction}
                    />
                  )}
                  {removeModalActive ? (
                    <RemoveLiquidityModal
                      lpModalRef={lpModalRef}
                      inputLogo={logoCurrencyA}
                      outputLogo={logoCurrencyB}
                      inputSymbol={
                        isWrappedToggled &&
                        wrappedAddress === positionInfo?.token0
                          ? wrappedSymbol
                          : tokenASymbol
                      }
                      outputSymbol={
                        isWrappedToggled &&
                        wrappedAddress === positionInfo?.token1
                          ? wrappedSymbol
                          : tokenBSymbol
                      }
                      inputValue={token0Value}
                      outputValue={token1Value}
                      removeClick={
                        isWSCConnected
                          ? () => {
                              fireWSCModal()
                              closeRemoveModal()
                            }
                          : removeLiquidityFn
                      }
                      closeRemoveModal={closeRemoveModal}
                      lpBodyClick={lpBodyClick}
                    />
                  ) : null}
                  <LPNavigation>
                    <Link to={`/pools/${liquidityID}`}>
                      <NavTop>
                        <BackIcon />
                      </NavTop>
                    </Link>
                    <IconText> Remove Liquidity</IconText>
                    <SettingsWrap>
                      {" "}
                      <SettingsWrapper
                        ref={closeRef}
                        onClick={closeSlippageDropdown}
                      >
                        <SettingsIcon onClick={showSlippageDropdown} />
                      </SettingsWrapper>
                      <SlippageContainer
                        slippageTop={32}
                        showSlippage={showSlippage}
                        slippageRef={slippageRef}
                      />
                    </SettingsWrap>
                  </LPNavigation>
                  <DappsDivider />
                  {account && supported ? (
                    <>
                      <LPPrice
                        inputPrice={String(uiPrice())}
                        inputCurrency={tokenASymbol}
                        outputCurrency={tokenBSymbol}
                      />
                    </>
                  ) : null}
                  <BoxTop>
                    <SizeTop>
                      {account && supported && (
                        <>
                          <CurrencyTokenImages
                            token0Info={currencyA}
                            token1Info={currencyB}
                          />
                        </>
                      )}
                      {account && supported && currencyA ? (
                        <>
                          {" "}
                          <SizeName>
                            {" "}
                            {tokenASymbol}/{tokenBSymbol}
                          </SizeName>
                        </>
                      ) : (
                        <>
                          {" "}
                          <PositionsLoadingPlaceholder />
                        </>
                      )}
                    </SizeTop>
                    <>
                      {account && supported && currencyA ? (
                        <>
                          {Boolean(userTradingMode) === true ? (
                            <>
                              {positionInfo ? (
                                <>
                                  {Number.isNaN(inputPrice) ||
                                  isNaN(Number(inputPrice)) ? (
                                    <LoadingDots />
                                  ) : (
                                    <LPTypeCover
                                      onMouseEnter={mouseEnter}
                                      onMouseLeave={mouseLeave}
                                    >
                                      {showHelpText && (
                                        <>
                                          <HoverBox
                                            left={68}
                                            text={
                                              (closedLP &&
                                                `Your position has zero liquidity, hence it's not earning LP fees.`) ||
                                              (!outOfRange &&
                                                !closedLP &&
                                                `The price of this pool is within your predefined range — your position is currently earning LP fees!`) ||
                                              (outOfRange &&
                                                !closedLP &&
                                                `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                              //||
                                              // (outOfRange &&
                                              //   !closedLP &&
                                              //   `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                            }
                                          />
                                        </>
                                      )}
                                      <LPType
                                        positionInfo={positionInfo}
                                        closedLP={closedLP}
                                        outOfRange={outOfRange}
                                      />
                                    </LPTypeCover>
                                  )}
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {" "}
                          <PositionsLoadingPlaceholder />
                        </>
                      )}
                    </>
                  </BoxTop>
                  {/* selected range box start */}
                  <PercentBox>
                    {account && supported && currencyA ? (
                      <>
                        {" "}
                        <PercentTop>
                          <PercentTextRow>
                            <BoxLargeTitle>{rangeValue}%</BoxLargeTitle>
                          </PercentTextRow>
                          <PercentSelector>
                            <SelectValues
                              disabled={closedLP || disableElements}
                              onClick={() => onClickSeletor("25")}
                            >
                              <ValuesText>25%</ValuesText>
                            </SelectValues>
                            <SelectValues
                              disabled={closedLP || disableElements}
                              onClick={() => onClickSeletor("50")}
                            >
                              <ValuesText>50%</ValuesText>
                            </SelectValues>
                            <SelectValues
                              disabled={closedLP || disableElements}
                              onClick={() => onClickSeletor("75")}
                            >
                              <ValuesText>75%</ValuesText>
                            </SelectValues>
                            <SelectValues
                              disabled={closedLP || disableElements}
                              onClick={() => onClickSeletor("100")}
                            >
                              <ValuesText>100%</ValuesText>
                            </SelectValues>
                          </PercentSelector>
                        </PercentTop>{" "}
                        <PercentBottom>
                          <TangleRange
                            type="range"
                            min="0"
                            max="100"
                            step="1"
                            disabled={closedLP || disableElements}
                            value={rangeValue}
                            onChange={handleRangeChange}
                          />
                        </PercentBottom>
                      </>
                    ) : (
                      <>
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                      </>
                    )}
                  </PercentBox>
                  <InfoTierTop>
                    {/* start of  box */}
                    <LiquidityBox>
                      <BoxBottom>
                        {account && isSupportedNetwork && currencyA ? (
                          <>
                            {" "}
                            <BowRow>
                              <LPBoxValue>
                                {isWrappedToggled &&
                                wrappedAddress === positionInfo?.token0
                                  ? wrappedSymbol
                                  : tokenASymbol}{" "}
                                to redeem:
                              </LPBoxValue>
                              <IconWrap>
                                <IconName>
                                  {" "}
                                  {isNaN(Number(token0Value)) ? (
                                    <>
                                      <LoadingDots />
                                    </>
                                  ) : (
                                    <>
                                      {token0Value &&
                                        toSignificantDigits(
                                          convertNumber(token0Value),
                                          6,
                                          true
                                        )}
                                    </>
                                  )}
                                </IconName>
                                {!!logoCurrencyA ? (
                                  <>
                                    <BoxIcon
                                      src={logoCurrencyA}
                                      alt="tangleswap liquidity"
                                    />
                                  </>
                                ) : (
                                  <CustomSizeWrapper>
                                    <ConvertTokenSymbol text={tokenASymbol} />
                                  </CustomSizeWrapper>
                                )}

                                <IconName>
                                  {" "}
                                  {isWrappedToggled &&
                                  wrappedAddress === positionInfo?.token0
                                    ? wrappedSymbol
                                    : tokenASymbol}
                                </IconName>
                              </IconWrap>
                            </BowRow>
                            <BowRow>
                              <LPBoxValue>
                                {isWrappedToggled &&
                                wrappedAddress === positionInfo?.token1
                                  ? wrappedSymbol
                                  : tokenBSymbol}{" "}
                                to redeem:
                              </LPBoxValue>
                              <IconWrap>
                                <IconName>
                                  {" "}
                                  {isNaN(Number(token1Value)) ? (
                                    <>
                                      <LoadingDots />
                                    </>
                                  ) : (
                                    <>
                                      {token1Value &&
                                        toSignificantDigits(
                                          convertNumber(token1Value),
                                          6,
                                          true
                                        )}
                                    </>
                                  )}
                                </IconName>
                                {!!logoCurrencyB ? (
                                  <>
                                    {" "}
                                    <BoxIcon
                                      src={logoCurrencyB}
                                      alt="tangleswap liquidity"
                                    />
                                  </>
                                ) : (
                                  <CustomSizeWrapper>
                                    <ConvertTokenSymbol text={tokenBSymbol} />
                                  </CustomSizeWrapper>
                                )}

                                <IconName>
                                  {" "}
                                  {isWrappedToggled &&
                                  wrappedAddress === positionInfo?.token1
                                    ? wrappedSymbol
                                    : tokenBSymbol}
                                </IconName>
                              </IconWrap>
                            </BowRow>
                          </>
                        ) : (
                          <>
                            {" "}
                            <PositionsLoadingPlaceholder />
                          </>
                        )}
                      </BoxBottom>
                      {showToggleWrapped && !isWSCConnected ? (
                        <CollectAsWrappedNative
                          wrappedSymbol={wrappedSymbol}
                          isToggled={isWrappedToggled}
                          toggleOnButton={onWrappedChange}
                          toggleOffButton={offWrappedChange}
                        />
                      ) : null}
                    </LiquidityBox>

                    {/* end of  box */}
                  </InfoTierTop>
                  <SwapButtons>
                    {account !== null && isSupportedNetwork ? (
                      <>
                        <SwapButton
                          onClick={
                            !!isWSCConnected && !!blockTransactionState
                              ? handlePendingTxModal
                              : rangeValue && rangeValue === "0"
                              ? null
                              : showRemoveModal
                          }
                          blockTransactionState={!!blockTransactionState}
                          disabled={buttonDisabled}
                        >
                          {closedLP ? (
                            <>Closed</>
                          ) : (
                            <>
                              {" "}
                              {!!blockTransactionState ? (
                                <>
                                  <SwapButtonSpinner />
                                </>
                              ) : null}
                              {!!blockTransactionState ? (
                                <>Continue Previous Transaction</>
                              ) : (
                                <>
                                  {" "}
                                  {rangeValue === "0"
                                    ? "Enter a Percent"
                                    : "Remove"}
                                </>
                              )}
                            </>
                          )}
                        </SwapButton>
                      </>
                    ) : null}
                  </SwapButtons>
                </>
              ) : (
                <>
                  <IncreaseRemoveAuthLayout />
                  <>
                    {account && !isSupportedNetwork ? (
                      <>
                        <ChainButton
                          onClick={() => addChainToMetamask(Chains.SHIMMER)}
                        >
                          Switch to Shimmer
                        </ChainButton>
                      </>
                    ) : (
                      <>
                        <ConnectWalletButton />
                      </>
                    )}
                  </>
                </>
              )}
            </Cover>
          </>
        </Body>
      )}
    </>
  )
}

export default React.memo(RemoveLiquidityContainer)

const Body = styled.div`
  width: 100%;
  margin: 24px 0 0 0;
  position: relative;
`
const Cover = styled.div<LiquidityProps>`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  min-height: 440px;
  border-radius: 40px;

  padding: ${(props) =>
    props.account ? "12px 20px 32px 20px" : "12px 20px 24px 20px"};
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
`
const SettingsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  position: relative;
`
const SettingsIcon = styled(RiSettings2Line)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
`
const BackIcon = styled(BsArrowLeft)`
  height: 32px;
  width: 32px;

  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`
const IconText = styled(Header5Gothic)`
  margin: 0 8px;
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const SizeTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  max-width: 340px;
  margin: 0;
`

const SizeName = styled(Subtitle)`
  /* width: 100%; */
  margin: 0 0 0 -8px;

  text-align: left;
`

const LPTypeCover = styled.div`
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
  cursor: pointer;
  align-items: flex-end;
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
`
const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0 16px 0;
`

const BoxBottom = styled.div`
  height: 120px;
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BowRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`
const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;

  border-radius: 9999px;
`
const LPBoxValue = styled.div``

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconName = styled(Caption)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const PercentBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.tangleBlack};
  padding: 20px 13px 0 18px;
  display: flex;
  height: 180px;
  flex-direction: column;
  margin: -8px 0 0 0;
`
const ValuesText = styled(Subtitle)``
const BoxLargeTitle = styled(Header2)`
  color: ${TangleColors.white};
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 16px 0;
`
const ConnectButton = styled(SwapConnectButton)``
const SwapButton = styled(SwapTokenButton)``

const PercentTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0 0 0;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const PercentTextRow = styled.div`
  width: 100%;
`
const PercentSelector = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  grid-gap: 8px;
  margin: 0;
`

const SelectValues = styled.button`
  height: 34px;
  cursor: pointer;
  max-width: 68px;
  width: 100%;
  border: none;
  background: ${TangleColors.heroCTA};
  border-radius: 6px;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;

  :hover {
    background: ${TangleColors.black};
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
  }
  :disabled {
    background: ${TangleColors.grayDark};
    color: ${TangleColors.white};
    :hover {
      cursor: not-allowed;
    }
  }
`
const PercentBottom = styled.div`
  width: 100%;
  padding: 0 7px 0 2px;
  margin: 24px 0;
`
const TangleRange = styled.input`
  width: 100%;
  max-width: 642px;

  -webkit-appearance: none;
  height: 4px;
  background: ${TangleColors.lighthover};
  margin: 8px auto;
  outline: none;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.cta};
  }
  :disabled {
    transition: 0.4s ease-in;
    background: ${TangleColors.grayDark};
    color: ${TangleColors.white};
    :hover {
      cursor: not-allowed;
    }
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${TangleColors.lighthover};
    height: 24px;
    width: 24px;

    border-radius: 999px;
    cursor: pointer;
    transition: 0.4s ease-in;

    :hover {
      background: ${TangleColors.cta};
    }
    :disabled {
      background: ${TangleColors.grayDark};
      color: ${TangleColors.white};
      :hover {
        cursor: not-allowed;
      }
    }
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    background: ${TangleColors.primary};
    height: 24px;
    width: 24px;

    border-radius: 999px;
    cursor: pointer;
    transition: 0.4s ease-in;

    :disabled {
      background: ${TangleColors.grayDark};
      color: ${TangleColors.white};
      :hover {
        cursor: not-allowed;
      }
    }
  }
`

const SettingsWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: ${TangleColors.white};
`
const BoxEnd = styled.div`
  width: 100%;
  max-width: 680px;
  margin: 6px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const ChainButton = styled(ChainChangeButton)``

const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
