import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionTiny, Subtitle } from "styles/TextStyles"

interface TokenInfoProps {
  token0Name?: string
  token1Name?: string
  feeRate?: number | any
}

const TokenInfo = (props: TokenInfoProps) => {
  const { token0Name, token1Name, feeRate } = props

  return (
    <Body>
      <InfoTop>
        <>
          {" "}
          <SizeName>
            {token1Name === undefined && token1Name === undefined ? (
              <TokenLoadingPlaceholder>
                <PositionsLoadingPlaceholder />
              </TokenLoadingPlaceholder>
            ) : (
              <>
                {" "}
                {token1Name} {""}/ {""}
                {token0Name}
              </>
            )}
          </SizeName>
        </>
      </InfoTop>
      <InfoBottom>
        <FeeRateCover>
          {/* <FeeRate>Fee tier: {feeRate.toFixed(2)}%</FeeRate> */}
          <FeeRate>{feeRate} %</FeeRate>
        </FeeRateCover>
      </InfoBottom>
    </Body>
  )
}

const Body = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 2px;
`

const FeeRateCover = styled.div`
  background: ${TangleColors.feeRateBg};
  padding: 6px 14px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 70px;
  margin: auto 0 0 0;
`

const FeeRate = styled(CaptionTiny)`
  color: ${TangleColors.white};
  white-space: nowrap;
`
const SizeName = styled(Subtitle)`
  width: 100%;
  margin: 0;
  text-align: left;
  white-space: nowrap;
`

const InfoTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const InfoBottom = styled.div`
  display: flex;
  /* justify-content: center;
  align-items: flex-start; */
  width: 100%;
`

const TokenLoadingPlaceholder = styled.div`
  height: 32px;
  width: 100%;
`
export default TokenInfo
