import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body2 } from "styles/TextStyles"
import { Link } from "react-router-dom"
import { AlienProps, aliensInfo } from "data/AlienData"
import { BsMedium } from "react-icons/bs"
import { FaDiscord, FaTwitter } from "react-icons/fa"

import BlockBottom from "./BlockBottom"
import BottomAliens from "./BottomAliens"

import { useLocation } from "react-router-dom"

interface MinFooterProp {
  LPMainDapp?: boolean
}

const MinimalFooter = () => {
  const socials = [
    { social: <FaTwitter />, link: "https://twitter.com/TangleSwap" },
    { social: <FaDiscord />, link: "https://chat.tangleswap.exchange" },
    { social: <BsMedium />, link: "https://blog.tangleswap.exchange" },
  ]

  const location = useLocation()
  const path = location.pathname
  const alienDetails = aliensInfo.filter((link) => {
    const linkInfo = link.route === path
    return linkInfo
  })

  const LPMainDapp = location.pathname === "/pools"

  return (
    <>
      <Body LPMainDapp={LPMainDapp}>
        <Cover>
          <BlockBottom />{" "}
          {alienDetails.map((alien: AlienProps) => (
            <BottomAliens
              key={alien.id}
              alienHref={`${alien.alienHref}`}
              alienText={`${alien.alienText}`}
              alienImage={`${alien.alienImage}`}
            />
          ))}
          <LogoRow>
            <Link to="/">
              <FooterLogo src="/logo.webp" alt="tangleswap white logo" />
            </Link>
          </LogoRow>
          <BottomFotter>
            <BottomFooterHref
              href="https://docs.tangleswap.exchange/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BottomText>
                {/* &copy; {new Date().getFullYear()} TangleSwap */}
                Welcome aboard, Explorer.
              </BottomText>
            </BottomFooterHref>
          </BottomFotter>
          <TopVoid>
            {socials.map((social, index) => (
              <VoidSocial
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                {social.social}
              </VoidSocial>
            ))}
          </TopVoid>
        </Cover>
      </Body>
    </>
  )
}

const Body = styled.div<MinFooterProp>`
  background: transparent;
  position: relative;
  max-width: 1530px;
  min-height: 120px;
  margin: ${(props) =>
    props.LPMainDapp ? `200px auto 0 auto` : `50px auto 0 auto`};
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 24px 0 0 0;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`
const Cover = styled.div`
  max-width: 50%;
  width: 100%;
  margin: 24px auto 0 auto;

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: 0.5px solid ${TangleColors.grayLight};

  @media only screen and (max-width: 850px) {
    justify-content: center;
    flex-direction: column;
    border-top: none;
  }
`

const BottomFotter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  z-index: 10;
  cursor: pointer;
  margin: 0 auto;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`
const BottomFooterHref = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 200px;
  z-index: 10;
  cursor: pointer;
  margin: 0 auto;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const BottomText = styled(Body2)`
  color: ${TangleColors.smokeWhite};
  text-align: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const LogoRow = styled.div`
  width: 100%;
  max-width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 -4px;
`
const FooterLogo = styled.img`
  height: 80px;
  max-width: 80px;
  width: 100%;

  object-fit: cover;
  border-radius: 9999px;
  @media only screen and (max-width: 850px) {
    display: none;
  }
  /* margin-top: -16px; */
`

const TopVoid = styled.div`
  margin: 0 8px 0 0;
  width: 100%;
  max-width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 88;
  transition: 0.4s ease-in;
  svg {
    color: ${TangleColors.white};
    width: 24px;
    height: 24px;
    margin: 8px;

    transition: 0.4s ease-in;
    :hover {
      color: ${TangleColors.lighthover};
      opacity: 0.7;
    }
  }
`

const VoidSocial = styled.a`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default MinimalFooter
