import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Header4Gothic } from "styles/TextStyles"

import { useWSCContext } from "context/MilkomedaContext"
import { useTangleship } from "utils/useTangleship"
import AccountStatCard from "./AccountStatCard"
import AffiliateInfoRow from "./AffiliateInfoRow"

const AccountContainer = () => {
  const { account, chainId } = useWSCContext()
  const [tradedVolume, setTradedVolume] = useState<any>(undefined)
  const [referralTradedVolume, setReferralTradedVolume] =
    useState<any>(undefined)
  const [totalReferrals, setTotalReferrals] = useState<any>(undefined)
  const [referralsList, setReferralsList] = useState<any>(undefined)
  const [blockSync, setBlockSync] = useState<any>(undefined)
  const [totalTrades, setTotalTrades] = useState<any>(undefined)

  const { tangleship } = useTangleship()

  const fetchUserTradedVolume = useCallback(async () => {
    if (!chainId || !account) return
    const res = await tangleship?.getUserTradedVolume(account)
    if (res) {
      const [totalTrades, volume] = res
      setTotalTrades(totalTrades)
      setTradedVolume(volume)
    }
  }, [account, chainId])

  useEffect(() => {
    fetchUserTradedVolume()
  }, [fetchUserTradedVolume])

  const fetchReferralVolume = useCallback(async () => {
    if (!chainId || !account) return

    const res = await tangleship?.getReferralsVolume(account)
    if (res) {
      const totalSummedData = res.reduce((acc: any, cur: any) => {
        Object.keys(cur).forEach((key) => {
          // Ensure the field exists in the accumulator
          if (!acc[key]) {
            acc[key] = 0
          }

          // Add the current value to the accumulator
          acc[key] +=
            typeof cur[key] === "string" ? parseFloat(cur[key]) : cur[key]
        })
        return acc
      }, {})

      setReferralTradedVolume(totalSummedData?.amountUSD)
    }
  }, [account, chainId])

  useEffect(() => {
    fetchReferralVolume()
  }, [fetchReferralVolume])

  const fetchReferrals = useCallback(async () => {
    if (!chainId || !account) return
    const res = await tangleship?.getReferralsAddresses(account)
    if (res) {
      const [addresses, block] = res
      setReferralsList(addresses)
      setTotalReferrals(addresses?.length)
      setBlockSync(block)
    }
  }, [account, chainId])

  useEffect(() => {
    fetchReferrals()
  }, [fetchReferrals])

  return (
    <AccountBody>
      <AccountRow>
        <AccountTitleRow>
          <AccountTitleText>Airdrop overview</AccountTitleText>
        </AccountTitleRow>

        <AccountBottom>
          <AccountStatCard
            tradedVolume={tradedVolume}
            referralTradedVolume={referralTradedVolume}
            totalReferrals={totalReferrals}
            totalTrades={totalTrades}
          />
          <AffiliateInfoRow
            referralsList={referralsList}
            blockSync={blockSync}
          />
        </AccountBottom>
      </AccountRow>
    </AccountBody>
  )
}

const AccountBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 6px auto;
  min-height: 300px;
`
const AccountRow = styled.div`
  width: 100%;
`
const AccountTitleRow = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  width: 100%;
  margin: 64px auto 12px auto;
`

// const  = styled(Header5)`
//   color: ${TangleColors.white};
//   margin: 0;
// `
const AccountTitleText = styled(Header4Gothic)`
  text-align: center;
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;

  text-transform: capitalize;
`
const AccountBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;

  margin: 0 auto;
`

export default AccountContainer
