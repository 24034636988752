import { Chains, Tangleship } from "@tangleswap/sdk"
import {
  DEVNET_BLOCKFROST_ID,
  MAINNET_BLOCKFROST_ID,
} from "config/milkomeda/config"
import { useWSCContext } from "context/MilkomedaContext"
import { useMemo } from "react"

let cache = null

export function useTangleship() {
  const { ethereum } = window
  const { l1ChainId, account } = useWSCContext()

  const tangleship = useMemo(() => {
    if (!l1ChainId) return null
    if (cache && cache.chain === l1ChainId && cache.account === account)
      return cache.tangleship

    const apiKey =
      l1ChainId === Chains.L1_CARDANO
        ? MAINNET_BLOCKFROST_ID
        : l1ChainId === Chains.L1_CARDANO_TEST
        ? DEVNET_BLOCKFROST_ID
        : null

    try {
      const tship = new Tangleship(ethereum, l1ChainId, "default", true, apiKey)
      if (tship)
        cache = {
          tangleship: tship,
          chain: l1ChainId,
          account: account,
        }
      return cache.tangleship
    } catch (e) {
      console.warn(e)
      return null
    }
  }, [l1ChainId, account])

  return { tangleship }
}
