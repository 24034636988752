import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"
import { useWSCContext } from "context/MilkomedaContext"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { useQuery } from "react-query"
import { QueryError, TangleTokenProps } from "../types"
import { useTangleship } from "utils/useTangleship"

interface SwapRouteprops {
  toggleRouter?: () => void
  index?: number
  routeFeeTiers?: any
  routePercentages?: any
  routeTokenAddresses?: any
}
const RouteForOrbitRouter: FC<SwapRouteprops> = (props) => {
  const { l1ChainId } = useWSCContext()

  const { routeFeeTiers, index, routePercentages, routeTokenAddresses } = props
  const token0Address = routeTokenAddresses && routeTokenAddresses[index][0]
  const token1Address =
    routeTokenAddresses && routeTokenAddresses[index]?.length > 3
      ? routeTokenAddresses[index][3]
      : routeTokenAddresses && routeTokenAddresses[index]?.length > 2
      ? routeTokenAddresses[index][2]
      : routeTokenAddresses[index][1]
  const tokenMiddleAddress =
    routeTokenAddresses && routeTokenAddresses[index]?.length > 3
      ? routeTokenAddresses[index][1]
      : routeTokenAddresses && routeTokenAddresses[index]?.length > 2
      ? routeTokenAddresses[index][1]
      : null
  const { tangleship } = useTangleship()

  // const tokenMiddleAddress =
  //   routeTokenAddresses && routeTokenAddresses[index]?.length > 2
  //     ? routeTokenAddresses[index][1]
  //     : routeTokenAddresses[index][0]
  const tokenLastAddress =
    routeTokenAddresses && routeTokenAddresses[index]?.length > 3
      ? routeTokenAddresses[index][2]
      : null

  const { data: token0Info } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserOrbitToken0Info", token0Address, l1ChainId],
    () => fetchTangleCurrency(token0Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token0Address && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: token1Info } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserOrbitToken1Info", token1Address, l1ChainId],
    () => fetchTangleCurrency(token1Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token1Address && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: tokenMiddleInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserOrbitTokenMiddleInfo", tokenMiddleAddress, l1ChainId],
    () => fetchTangleCurrency(tokenMiddleAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenMiddleAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: tokenLastInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserOrbitTokenLastInfo", tokenLastAddress, l1ChainId],
    () => fetchTangleCurrency(tokenLastAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenLastAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  return (
    <RouteRow>
      <DottedLine />
      <OpaqueBadge>
        <AutoRow>
          {" "}
          <SizeImages>
            {" "}
            {!!token0Info?.logoURI ? (
              <SizeFirstIcon>
                <PositionIcon
                  src={token0Info?.logoURI}
                  alt={token0Info?.symbol}
                />
              </SizeFirstIcon>
            ) : (
              <>
                {" "}
                <ConvertTokenSymbol text={token0Info?.symbol} />
              </>
            )}
          </SizeImages>
        </AutoRow>
        <MixedWrapper>
          <TitleWrap>
            {routePercentages[index] ? (
              <>
                <BadgeText>{routePercentages[index]}%</BadgeText>
              </>
            ) : null}
          </TitleWrap>
          {!!tokenMiddleAddress ? (
            <>
              <MidBadgeWrapper>
                {routeFeeTiers[index]?.length > 2 ? (
                  <>
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        <SizeImages>
                          {!!token0Info?.logoURI ? (
                            <SizeFirstIcon>
                              {" "}
                              <BadgeOne src={token0Info?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <SizeFirstIconCover>
                              {" "}
                              <ConvertTokenSymbol text={token0Info?.symbol} />
                            </SizeFirstIconCover>
                          )}
                          {!!tokenMiddleInfo?.logoURI ? (
                            <SizeSecondIcon>
                              {" "}
                              <BadgeTwo src={tokenMiddleInfo?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <SizeSecondIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenMiddleInfo?.symbol}
                              />
                            </SizeSecondIconCover>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][0] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        <SizeImages>
                          {!!tokenMiddleInfo?.logoURI ? (
                            <SizeFirstIcon>
                              <BadgeOne src={tokenMiddleInfo?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <SizeFirstIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenMiddleInfo?.symbol}
                              />
                            </SizeFirstIconCover>
                          )}

                          {!!tokenLastInfo?.logoURI ? (
                            <SizeSecondIcon>
                              {" "}
                              <BadgeTwo src={tokenLastInfo?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <SizeSecondIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenLastInfo?.symbol}
                              />
                            </SizeSecondIconCover>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][1] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        <SizeImages>
                          {!!tokenLastInfo?.logoURI ? (
                            <SizeFirstIcon>
                              {" "}
                              <BadgeOne src={tokenLastInfo?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <>
                              {" "}
                              <SizeFirstIconCover>
                                <ConvertTokenSymbol
                                  text={tokenLastInfo?.symbol}
                                />
                              </SizeFirstIconCover>
                            </>
                          )}

                          {!!token1Info?.logoURI ? (
                            <SizeSecondIcon>
                              <BadgeTwo src={token1Info?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <>
                              <SizeSecondIconCover>
                                <ConvertTokenSymbol text={token1Info?.symbol} />
                              </SizeSecondIconCover>
                            </>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][2] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                  </>
                ) : (
                  <>
                    {routeFeeTiers[index]?.length > 1 ? (
                      <>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <SizeImages>
                              {!!token0Info?.logoURI ? (
                                <SizeFirstIcon>
                                  {" "}
                                  <BadgeOne src={token0Info?.logoURI} />
                                </SizeFirstIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeFirstIconCover>
                                    <ConvertTokenSymbol
                                      text={token0Info?.symbol}
                                    />
                                  </SizeFirstIconCover>
                                </>
                              )}

                              {!!tokenMiddleInfo?.logoURI ? (
                                <SizeSecondIcon>
                                  {" "}
                                  <BadgeTwo src={tokenMiddleInfo?.logoURI} />
                                </SizeSecondIcon>
                              ) : (
                                <>
                                  <SizeSecondIconCover>
                                    <ConvertTokenSymbol
                                      text={tokenMiddleInfo?.symbol}
                                    />
                                  </SizeSecondIconCover>
                                </>
                              )}
                            </SizeImages>
                          </BadgeWrapper>
                          <BadgeMidText>
                            {routeFeeTiers[index][0] / 10000}%
                          </BadgeMidText>
                        </MixedProtocolBadge>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <SizeImages>
                              {!!tokenMiddleInfo?.logoURI ? (
                                <SizeFirstIcon>
                                  {" "}
                                  <BadgeOne src={tokenMiddleInfo?.logoURI} />
                                </SizeFirstIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeFirstIconCover>
                                    <ConvertTokenSymbol
                                      text={tokenMiddleInfo?.symbol}
                                    />
                                  </SizeFirstIconCover>
                                </>
                              )}
                              {!!token1Info?.logoURI ? (
                                <SizeSecondIcon>
                                  <BadgeTwo src={token1Info?.logoURI} />
                                </SizeSecondIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeSecondIconCover>
                                    <ConvertTokenSymbol
                                      text={token1Info?.symbol}
                                    />
                                  </SizeSecondIconCover>
                                </>
                              )}
                            </SizeImages>
                          </BadgeWrapper>
                          <BadgeMidText>
                            {routeFeeTiers[index][1] / 10000}%
                          </BadgeMidText>
                        </MixedProtocolBadge>
                      </>
                    ) : (
                      <>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <SizeImages>
                              {!!token0Info?.logoURI ? (
                                <SizeFirstIcon>
                                  <BadgeOne src={token0Info?.logoURI} />
                                </SizeFirstIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeFirstIconCover>
                                    <ConvertTokenSymbol
                                      text={token0Info?.symbol}
                                    />
                                  </SizeFirstIconCover>
                                </>
                              )}
                              {!!token1Info?.logoURI ? (
                                <SizeSecondIcon>
                                  {" "}
                                  <BadgeTwo src={token1Info?.logoURI} />
                                </SizeSecondIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeSecondIconCover>
                                    <ConvertTokenSymbol
                                      text={token1Info?.symbol}
                                    />
                                  </SizeSecondIconCover>
                                </>
                              )}
                            </SizeImages>
                          </BadgeWrapper>
                          <BadgeMidText>
                            {routeFeeTiers[index][0] / 10000}%
                          </BadgeMidText>
                        </MixedProtocolBadge>
                      </>
                    )}
                  </>
                )}
              </MidBadgeWrapper>
            </>
          ) : (
            <>
              <MidBadgeWrapper>
                {routeFeeTiers[index]?.length > 2 ? (
                  <>
                    {" "}
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        <SizeImages>
                          {!!token0Info?.logoURI ? (
                            <SizeFirstIcon>
                              {" "}
                              <BadgeOne src={token0Info?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <SizeFirstIconCover>
                              {" "}
                              <ConvertTokenSymbol text={token0Info?.symbol} />
                            </SizeFirstIconCover>
                          )}
                          {!!tokenMiddleInfo?.logoURI ? (
                            <SizeSecondIcon>
                              {" "}
                              <BadgeTwo src={tokenMiddleInfo?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <SizeSecondIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenMiddleInfo?.symbol}
                              />
                            </SizeSecondIconCover>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][0] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        {" "}
                        <SizeImages>
                          {!!tokenMiddleInfo?.logoURI ? (
                            <SizeFirstIcon>
                              <BadgeOne src={tokenMiddleInfo?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <SizeFirstIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenMiddleInfo?.symbol}
                              />
                            </SizeFirstIconCover>
                          )}

                          {!!tokenLastInfo?.logoURI ? (
                            <SizeSecondIcon>
                              {" "}
                              <BadgeTwo src={tokenLastInfo?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <SizeSecondIconCover>
                              {" "}
                              <ConvertTokenSymbol
                                text={tokenLastInfo?.symbol}
                              />
                            </SizeSecondIconCover>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][1] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                    <MixedProtocolBadge>
                      <BadgeWrapper>
                        <SizeImages>
                          {!!tokenLastInfo?.logoURI ? (
                            <SizeFirstIcon>
                              <BadgeOne src={tokenLastInfo?.logoURI} />
                            </SizeFirstIcon>
                          ) : (
                            <>
                              <SizeFirstIconCover>
                                <ConvertTokenSymbol
                                  text={tokenLastInfo?.symbol}
                                />
                              </SizeFirstIconCover>
                            </>
                          )}
                          {!!token1Info?.logoURI ? (
                            <SizeSecondIcon>
                              <BadgeTwo src={token1Info?.logoURI} />
                            </SizeSecondIcon>
                          ) : (
                            <>
                              <SizeSecondIconCover>
                                <ConvertTokenSymbol text={token1Info?.symbol} />
                              </SizeSecondIconCover>
                            </>
                          )}
                        </SizeImages>
                      </BadgeWrapper>

                      <BadgeMidText>
                        {routeFeeTiers[index][2] / 10000}%
                      </BadgeMidText>
                    </MixedProtocolBadge>
                  </>
                ) : (
                  <>
                    {routeFeeTiers[index]?.length > 1 ? (
                      <>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <SizeImages>
                              {!!token0Info?.logoURI ? (
                                <SizeFirstIcon>
                                  <BadgeOne src={token0Info?.logoURI} />
                                </SizeFirstIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeFirstIconCover>
                                    <ConvertTokenSymbol
                                      text={token0Info?.symbol}
                                    />
                                  </SizeFirstIconCover>
                                </>
                              )}
                              {!!tokenMiddleInfo?.logoURI ? (
                                <SizeSecondIcon>
                                  {" "}
                                  <BadgeTwo src={tokenMiddleInfo?.logoURI} />
                                </SizeSecondIcon>
                              ) : (
                                <>
                                  <SizeSecondIconCover>
                                    <ConvertTokenSymbol
                                      text={tokenMiddleInfo?.symbol}
                                    />
                                  </SizeSecondIconCover>
                                </>
                              )}
                            </SizeImages>
                          </BadgeWrapper>
                          <BadgeMidText>
                            {routeFeeTiers[index][0] / 10000}%
                          </BadgeMidText>
                        </MixedProtocolBadge>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <SizeImages>
                              {!!tokenMiddleInfo?.logoURI ? (
                                <SizeFirstIcon>
                                  {" "}
                                  <BadgeOne src={tokenMiddleInfo?.logoURI} />
                                </SizeFirstIcon>
                              ) : (
                                <>
                                  {" "}
                                  <SizeFirstIconCover>
                                    <ConvertTokenSymbol
                                      text={tokenMiddleInfo?.symbol}
                                    />
                                  </SizeFirstIconCover>
                                </>
                              )}
                              {!!token1Info?.logoURI ? (
                                <SizeSecondIcon>
                                  {" "}
                                  <BadgeTwo src={token1Info?.logoURI} />
                                </SizeSecondIcon>
                              ) : (
                                <>
                                  <SizeSecondIconCover>
                                    <ConvertTokenSymbol
                                      text={token1Info?.symbol}
                                    />
                                  </SizeSecondIconCover>
                                </>
                              )}
                            </SizeImages>
                          </BadgeWrapper>
                          <BadgeMidText>
                            {routeFeeTiers[index][1] / 10000}%
                          </BadgeMidText>
                        </MixedProtocolBadge>
                      </>
                    ) : (
                      <>
                        <MixedProtocolBadge>
                          <BadgeWrapper>
                            <CustomSizeWrapper>
                              <SizeImages>
                                {!!token0Info?.logoURI ? (
                                  <SizeFirstIcon>
                                    {" "}
                                    <BadgeOne src={token0Info?.logoURI} />
                                  </SizeFirstIcon>
                                ) : (
                                  <>
                                    <SizeFirstIconCover>
                                      <ConvertTokenSymbol
                                        text={token0Info?.symbol}
                                      />
                                    </SizeFirstIconCover>
                                  </>
                                )}
                                {!!token1Info?.logoURI ? (
                                  <SizeSecondIcon>
                                    {" "}
                                    <BadgeTwo src={token1Info?.logoURI} />
                                  </SizeSecondIcon>
                                ) : (
                                  <>
                                    {" "}
                                    <SizeSecondIconCover>
                                      <ConvertTokenSymbol
                                        text={token1Info?.symbol}
                                      />
                                    </SizeSecondIconCover>
                                  </>
                                )}
                              </SizeImages>
                            </CustomSizeWrapper>
                          </BadgeWrapper>
                          <CustomBadge>
                            {" "}
                            <BadgeMidText>
                              {routeFeeTiers[index][0] / 10000}%
                            </BadgeMidText>
                          </CustomBadge>
                        </MixedProtocolBadge>
                      </>
                    )}
                  </>
                )}
              </MidBadgeWrapper>
            </>
          )}

          <ProtocolBadge />
        </MixedWrapper>
        {/* to fix here */}
        <AutoRow>
          {!!token1Info?.logoURI ? (
            <>
              {" "}
              <PositionIcon
                src={token1Info?.logoURI}
                alt={token1Info?.symbol}
              />
            </>
          ) : (
            <>
              <ConvertTokenSymbol text={token1Info?.symbol} />
            </>
          )}
        </AutoRow>
      </OpaqueBadge>
    </RouteRow>
  )
}

const RouteRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  overflow: hidden;
  max-width: 660px;

  position: relative;

  width: 100%;
  margin: 4px auto;
`
const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  max-width: 530px;
  width: calc(90%);
  margin: 0 auto;
  flex-wrap: wrap;

  z-index: 1;
  top: 45%;
  left: 16px;
  border-top: 1px dotted ${TangleColors.lighthover};
  opacity: 0.3;
`

const AutoRow = styled.div`
  width: 100%;
  max-width: 32px;
  height: 32px;
  margin: 8px;
  position: relative;
  z-index: 2;
  /* justify-content: ; */
`

const PositionIcon = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 99999px;
  object-fit: cover;
  border: 1px solid ${TangleColors.white};
`
const OpaqueBadge = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 580px;
  margin: 0 auto;
`
const MixedProtocolBadge = styled.div`
  height: 38px;

  border-radius: 10px;
  padding: 6px 12px;
  margin: 4px 12px;
  background: ${TangleColors.icon};
  display: flex;
  cursor: pointer;
  flex-direction: row;

  align-items: center;
  position: relative;
  z-index: 2;
`

const MidBadgeWrapper = styled.div`
  /* max-width: 300px; */
  /* width: 100%; */

  display: flex;
  flex-direction: row;

  position: relative;
  z-index: 2;
`

const TitleWrap = styled.div`
  height: 38px;

  border-radius: 10px;
  padding: 6px 10px;
  background: ${TangleColors.cta};
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-left: -8px;
`

const MixedWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const BadgeText = styled(CaptionSmall)``
const BadgeMidText = styled(CaptionSmall)`
  margin: 0 2px 0 12px;
`
const CustomBadge = styled.div`
  margin: 0 2px 0 24px;
`
const BadgeWrapper = styled.div`
  position: relative;
  max-width: 64px;
  margin: 0 4px 0 0;
`
const BadgeOne = styled.img`
  height: 24px;

  width: 24px;
  border-radius: 99999px;
  object-fit: cover;
  position: relative;
  top: 3px;
  z-index: 3;
  border-radius: 9999px;
  border: 1px solid ${TangleColors.white};
`
const BadgeTwo = styled.img`
  height: 24px;
  width: 24px;

  object-fit: cover;
  border: 1px solid ${TangleColors.white};
  position: absolute;
  z-index: 2;
  top: 3px;
  margin-left: -12px;
  border-radius: 9999px;
`

const ProtocolBadge = styled.div`
  @media only screen and (max-width: 650px) {
    display: none;
  }
`
const CustomSizeWrapper = styled.div`
  max-width: 64px;
  width: 100%;
  position: relative;
  margin-top: -16px;
  margin-left: 11px;
`

const SizeImages = styled.div`
  max-width: 64px;
  width: 100%;
  position: relative;
`
const SizeFirstIcon = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;
  position: absolute;
  top: 0;
  border-radius: 9999px;
  left: 0;
  z-index: 5;
`
const SizeSecondIcon = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;
  position: absolute;
  top: 0;
  border-radius: 9999px;
  left: 50%;
  z-index: 3;
  margin-left: -16px;
`
const SizeFirstIconCover = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;
  position: absolute;
  top: 0;
  border-radius: 9999px;
  left: 0;
  z-index: 5;
`
const SizeSecondIconCover = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;
  position: absolute;
  top: 0;
  border-radius: 9999px;
  left: 50%;
  z-index: 3;
  margin-left: -16px;
`

export default RouteForOrbitRouter
