import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import { FC, useEffect, useRef, useState } from "react"
import {
  LOVELACE_UNIT,
  REQUIRED_FEE_ADA,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"
import BigNumber from "bignumber.js"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"
import { useGetOriginBalance, useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import useInterval from "components/utils/useInterval"
import { IWrapData, IWrapMultipleData } from "interfaces/wscSwap.interface"
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  ButtonContainer,
  Link,
} from "components/milkomeda/styles"
import { getBridgeExplorerUrl } from "utils/milkomeda/transaction"
import { TxPendingStatus } from "milkomeda-wsc"
import { useWSCContext } from "context/MilkomedaContext"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { ethers } from "ethers"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { extractEvmFunctionName } from "@tangleswap/sdk"
import { playSound } from "../component/PlaySound"

interface IWrapGas {
  nextStep?: () => void
  data?: IWrapData | IWrapMultipleData
  actionType?: string
}

export const statusWrapMessages = {
  [TxStatus.Init]: "Confirm Wrapping",
  [TxStatus.Pending]: "Wrapping your token",
  [TxStatus.WaitingL1Confirmation]: "Waiting for L1 confirmation",
  [TxStatus.WaitingBridgeConfirmation]: "Waiting for bridge confirmation",
  [TxStatus.WaitingL2Confirmation]: "Waiting for L2 confirmation",
  [TxStatus.Confirmed]: "Your asset has been successfully wrapped.",
}

const WrapGas: FC<IWrapGas> = (props) => {
  const { nextStep, actionType, data } = props
  const [txHash, setTxHash] = useState<string | undefined>()
  const [isWrapPending, setIsWrapPending] = useState<boolean>(false)

  const { wrappingFee, evmEstimatedFee, adaLocked, unwrappingFee, bridgeFees } =
    useTransactionFees()
  const {
    txStatus,
    txStatusError,
    setTxStatusError,
    setTxStatus,
    isIdle,
    isLoading,
    isError,
    isSuccess,
    isErrorWhileFetching,
  } = useTransactionStatus()
  const { wscProvider } = useWSCProvider()
  const { chainId } = useWSCContext()

  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  useEffect(() => {
    if (!tangleProgress) return
    if (tangleProgress.stepTitle === "GasWrap") {
      setTxHash(tangleProgress.txHash)
      setTxStatus(TxStatus.WaitingBridgeConfirmation)
      setIsWrapPending(true)
    }
  }, [])

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (tangleSwapTransactions && !autoExecute.current) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        wrapToken()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions])

  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      try {
        const response = await wscProvider.getTxStatus(txHash)
        setTxStatus(response)
        if (response === TxStatus.Confirmed) {
          if (tangleSwapTXEffects) playSound()
          executeStep(txHash, true)
          nextStep()
        }
      } catch (err) {
        setTxStatus(TxStatus.FetchError)
        console.error(err)
      }
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )

  const dispatch = useAppDispatch()
  const executeStep = (tx: any, completed = false) => {
    const stepperParams = {
      completed,
      stepTitle: "GasWrap",
      subStep: 0,
      actionType,
      actionSubType: extractEvmFunctionName(data?.evmFunction),
      txHash: tx,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }

  const wrapToken = async () => {
    if (!unwrappingFee || !wrappingFee) return
    setTxStatus(TxStatus.Init)
    const totalFees = adaLocked
      .multipliedBy(10 ** 6)
      .plus(unwrappingFee.multipliedBy(10 ** 6))
      .plus(evmEstimatedFee.multipliedBy(10 ** 6))
      .plus(wrappingFee.multipliedBy(10 ** 6))

    try {
      const wrapAmount = convertTokensToWei({
              value: new BigNumber(REQUIRED_FEE_ADA),
              token: { decimals: 6 },
            })

      const policyIdAssetNameConcat = LOVELACE_UNIT

      const txHash = await wscProvider?.wrap(
        undefined,
        policyIdAssetNameConcat,
        wrapAmount.toNumber(),
        0
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)

      executeStep(txHash)
    } catch (err) {
      setTxStatus(TxStatus.Error)
      console.error(err)

      if (err instanceof Error) {
        setTxStatusError(err.message)
      }
    }
  }

  return (
    <>
      <ActionWrapper>
        {(isLoading || isWrapPending) && (
          <WrapSpinnerCover>
            <SpinnerCombined />
            <span>{statusWrapMessages[txStatus]}</span>
          </WrapSpinnerCover>
        )}
        {isError && <div>Oops, something went wrong. Please try again.</div>}
        {isSuccess && (
          <ButtonContainer>
            <Link href={`${getBridgeExplorerUrl(chainId)}/wrap/${txHash}`}>
              {statusWrapMessages[TxPendingStatus.Confirmed]}
            </Link>
            <ActionButton onClick={nextStep}>Continue</ActionButton>
          </ButtonContainer>
        )}
        {(isIdle || isError) && !isWrapPending && (
          <>
            <ActionText>
              Wrapping may take a few minutes (2-4 min)
              <br />
              <br />
            </ActionText>
            <ButtonContainer>
              <ActionButton onClick={wrapToken}>
                Confirm
              </ActionButton>
            </ButtonContainer>
          </>
        )}
      </ActionWrapper>
    </>
  )
}
const WrapSpinnerCover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export default WrapGas
