import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

interface WithdrawButtonProps {
  onClick: () => void
}

const WithdrawButton: FC<WithdrawButtonProps> = ({ onClick }) => {
  return <StakeButton onClick={onClick}>WithdrawButton</StakeButton>
}

const StakeButton = styled.a`
  background: ${TangleColors.cta};
  color: white;
  border-radius: 16px;
  padding: 12px 24px;
  display: flex;
  cursor: pointer;
  transition: 0.4s ease-in;
  width: max-content;
  &:hover {
    background: ${TangleColors.lighthover};
  }
`

export default WithdrawButton
