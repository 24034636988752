import BeginFarmModal from "components/milkomeda/farm/BeginFarmModal"
import HarvestFarmModal from "components/milkomeda/farm/HarvestFarmModal"
import StopFarmModal from "components/milkomeda/farm/StopFarmModal"
import FinalizeModal from "components/milkomeda/ihub/FinalizeModal"
import ParticipateModal from "components/milkomeda/ihub/ParticipateModal"
import WithdrawModal from "components/milkomeda/ihub/WithdrawModal"
import CollectFeesModal from "components/milkomeda/liquidity/CollectFeesModal"
import IncreaseLiqModal from "components/milkomeda/liquidity/IncreaseLiqModal"
import LPModal from "components/milkomeda/liquidity/LPModal"
import RemoveLiqModal from "components/milkomeda/liquidity/RemoveLiqModal"
import BeginStakeModal from "components/milkomeda/staking/BeginStakeModal"
import RestakeModal from "components/milkomeda/staking/RestakeModal"
import UnstakeModal from "components/milkomeda/staking/UnstakeModal"
import SwapModal from "components/milkomeda/swap/SwapModal"
import { PendingWscTxStepDataProps } from "components/milkomeda/types"
import { CancelPendingContext } from "context/CancelModalContext"
import { useWSCContext } from "context/MilkomedaContext"
import React, { useContext, useRef } from "react"
import { toast as TangleToast } from "sonner"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { useAppDispatch, useAppSelector } from "store/hooks"

const ContinueTransactions = () => {
  const dispatch = useAppDispatch()

  const { account, isWSCConnected } = useWSCContext()
  const wscModalRef = useRef<any>(null)
  const pendingWscTxStep: PendingWscTxStepDataProps = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const openWSCModal = useAppSelector(
    (state) => state.WscProgressReducer.openWSCModal
  )
  const toggleSwapModal = () => {
    dispatch(openWSCProgressModal(false))
  }
  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    dispatch(openWSCProgressModal(false))
  }

  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef.current) {
      dispatch(openWSCProgressModal(false))
    }
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    setOpenModalSettings(false)
  }
  const skipWallet = () => {
    dispatch(openWSCProgressModal(false))
    dispatch(updateUserWSCProgress(null))
  }

  const swapAction =
    pendingWscTxStep?.actionType === "Swap" &&
    pendingWscTxStep?.actionSubType === "exactInput"

  const collectLPFees =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "collect"
  const addLiquidity =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "mint"
  const initializePool =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "createAndInitializePoolIfNecessary"
  const removeLP =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "decreaseLiquidity"
  const increaseLiquidity =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "increaseLiquidity"

  const beginFarming =
    pendingWscTxStep?.actionType === "Farm" &&
    pendingWscTxStep?.actionSubType === "deposit"
  const stopFarming =
    pendingWscTxStep?.actionType === "Farm" &&
    pendingWscTxStep?.actionSubType === "withdraw"
  const harvestFarming =
    pendingWscTxStep?.actionType === "Farm" &&
    pendingWscTxStep?.actionSubType === "collectRewards"

  const beginStaking =
    pendingWscTxStep?.actionType === "VEStaking" &&
    pendingWscTxStep?.actionSubType === "stake"
  const stopStaking =
    pendingWscTxStep?.actionType === "VEStaking" &&
    pendingWscTxStep?.actionSubType === "unstake"
  const reStartStaking =
    pendingWscTxStep?.actionType === "VEStaking" &&
    pendingWscTxStep?.actionSubType === "restake"
  const addTokensStaking =
    beginStaking && pendingWscTxStep?.data?.evmFunction?.args?.[0] > 0
  const addDurationStaking =
    beginStaking && pendingWscTxStep?.data?.evmFunction?.args?.[1] > 0

  const participateAuction =
    pendingWscTxStep?.actionType === "IHub" &&
    (pendingWscTxStep?.actionSubType === "commitTokens" ||
      pendingWscTxStep?.actionSubType === "commitEth")
  const claimFromAuction =
    pendingWscTxStep?.actionType === "IHub" &&
    pendingWscTxStep?.actionSubType === "withdrawTokens"
  const finalizeAuction =
    pendingWscTxStep?.actionType === "IHub" &&
    pendingWscTxStep?.actionSubType === "finalize"

  return (
    <>
      {account && isWSCConnected && !!openWSCModal ? (
        <>
          {/* done  and tested */}
          {!!swapAction ? (
            <>
              <SwapModal
                swapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                cancelTransaction={cancelTransaction}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}
          {/* done  and tested */}
          {!!addLiquidity || !!initializePool ? (
            <>
              <LPModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                cancelTransaction={cancelTransaction}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}
          {/* done  and tested */}
          {!!removeLP ? (
            <>
              <RemoveLiqModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                openWalletOverview={openWalletOverview}
                cancelTransaction={cancelTransaction}
              />
            </>
          ) : null}
          {/* done  and tested */}
          {!!increaseLiquidity ? (
            <>
              <IncreaseLiqModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                cancelTransaction={cancelTransaction}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}
          {/* done  and tested */}
          {!!beginFarming ? (
            <>
              <BeginFarmModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                cancelTransaction={cancelTransaction}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}

          {!!stopFarming ? (
            <>
              <StopFarmModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                cancelTransaction={cancelTransaction}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}
          {!!harvestFarming ? (
            <>
              <HarvestFarmModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                cancelTransaction={cancelTransaction}
                skipWallet={skipWallet}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}

          {!!participateAuction ? (
            <>
              <ParticipateModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                cancelTransaction={cancelTransaction}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}

          {!!beginStaking ? (
            <>
              <BeginStakeModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                skipWallet={skipWallet}
                cancelTransaction={cancelTransaction}
                openWalletOverview={openWalletOverview}
              />
            </>
          ) : null}
          {/* pendingWscTxStep?.actionType === "VEStaking" &&
          pendingWscTxStep?.actionSubType === "stake" */}
          {!!reStartStaking ? (
            <>
              <RestakeModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                cancelTransaction={cancelTransaction}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}
          {!!stopStaking ? (
            <>
              <UnstakeModal
                wrapData={pendingWscTxStep?.data}
                cancelTransaction={cancelTransaction}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}
          {!!collectLPFees ? (
            <>
              <CollectFeesModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                cancelTransaction={cancelTransaction}
              />
            </>
          ) : null}
          {!!finalizeAuction ? (
            <>
              <FinalizeModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                toggleModal={toggleSwapModal}
                cancelTransaction={cancelTransaction}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}
          {!!claimFromAuction ? (
            <>
              <WithdrawModal
                wrapData={pendingWscTxStep?.data}
                isVisible={openWSCModal}
                cancelTransaction={cancelTransaction}
                toggleModal={toggleSwapModal}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
              />
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}

export default ContinueTransactions
