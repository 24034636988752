import styled from "styled-components"

import { CaptionSmall } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import TopRewardsRow from "./TopRewardsRow"
import { useAppSelector } from "store/hooks"
import { useWSCContext } from "context/MilkomedaContext"

interface RewardProps {
  tokenAddress?: any
  rewardsAddress0?: string | undefined | null
  rewardsAddress1?: string | undefined | null
  earnings?: string[] | any
  token0PendingRewards?: number | undefined | null
  token1PendingRewards?: number | undefined | null
  token0Symbol?: any
  token0Logo?: any
  token0Decimals?: any
  token1Symbol?: any
  token1Logo?: any
  token1Decimals?: any
}

const TopRewards = (props: RewardProps) => {
  const {
    token0PendingRewards,
    token1PendingRewards,
    rewardsAddress0,
    rewardsAddress1,
    token0Symbol,
    token0Logo,
    token0Decimals,
    token1Symbol,
    token1Logo,
    token1Decimals,
  } = props

  return (
    <Body>
      <TextTitle>
        <>Rewards Earned</>
      </TextTitle>

      <RewardContainer>
        <TopRewardsRow
          pendingRewards={token0PendingRewards}
          rewardsAddress={rewardsAddress0}
          tokenSymbol={token0Symbol}
          tokenLogo={token0Logo}
          tokenDecimals={token0Decimals}
        />
        <TopRewardsRow
          pendingRewards={token1PendingRewards}
          rewardsAddress={rewardsAddress1}
          tokenSymbol={token1Symbol}
          tokenLogo={token1Logo}
          tokenDecimals={token1Decimals}
        />
      </RewardContainer>
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Reward = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

// const Text = styled(CaptionSmall)`
//   color: ${TangleColors.white};
// `
const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 0 2px 0;
`

const CriteriaRow = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  margin-right: 8px;
  width: 18px;
  height: 18px;
  border-radius: 9999px;
`

const RewardsText = styled.span`
  font-weight: 500;
  font-size: 18px;
`

export default TopRewards
