import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"

interface TvlProps {
  tvl?: number
}

const TopTVL = (props: TvlProps) => {
  const { tvl } = props

  return (
    <Body>
      <TextTitle>TVL</TextTitle>
      <TvlText>
        &#36; {!!tvl ? `${toSignificantDigits(tvl, 4, true, 18)}` : "0"}
      </TvlText>
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 0 2px 0;
`

const TvlText = styled(Caption)`
  color: ${TangleColors.white};
`

export default TopTVL
