/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { TiWarningOutline } from "react-icons/ti"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { toast as SonnerToast } from "sonner"
import { useForm } from "react-hook-form"

import { CaptionSmall, Subtitle } from "styles/TextStyles"

import { useAppDispatch, useAppSelector } from "store/hooks"
import { useWSCContext } from "context/MilkomedaContext"
import StakeContext from "./components/StakeContext"
import StakingOverviewContainer from "./StakingOverviewContainer"
import StakeInput from "./components/StakeInput"
import ExtendStaking from "./components/ExtendStaking"
import StakingAuthenication from "./components/StakingAuthenication"
import InfoRow from "./components/InfoRow"
import WithdrawModal from "./components/WithdrawModal"
import ReStake from "./components/ReStake"
import AddMoreStaking from "./components/AddMoreStaking"
import { useTangleship } from "utils/useTangleship"
import {
  Chains,
  MAX_UINT256,
  VE_STAKING_ADDRESS,
  VOID_TOKEN,
} from "@tangleswap/sdk"
import { BigNumber, ethers } from "ethers"
import { toast } from "sonner"
import { PendingStateContext } from "context/PendingContext"
import UserStakingModal from "./modals/UserStakingModal"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import useStakingArray from "utils/useStakingArray"
import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"
import BeginStakeModal, {
  useBeginStakeModalControl,
} from "components/milkomeda/staking/BeginStakeModal"
import { IWrapData } from "interfaces/wscSwap.interface"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"

interface BodyProps {
  tokenBalanceAmount: any
  tokenFiat: any
}
interface StakingProps {
  stakeInputValue: any
  durationInputValue?: any
}

const StakeBody: FC<BodyProps> = (props) => {
  const { tokenBalanceAmount, tokenFiat } = props
  const { tangleship } = useTangleship()
  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const { account, chainId, isWSCConnected, l1ChainId } = useWSCContext()
  const { setPendingTransaction } = useContext(PendingStateContext)

  const [stakingApproving, setStakingApproving] = useState<boolean>(false)
  const [stakingActionPending, setStakingActionPending] =
    useState<boolean>(false)
  const [userStaking, setUserStaking] = useState<boolean>(false)
  const [showStakingModal, setShowStakingModal] = useState<boolean>(false)
  const [stakingTx, setStakingTx] = useState<any>(undefined)
  const [tokenUSDFiat, setTokenUSDFiat] = useState<any>(undefined)
  const [stakingApproved, setStakingApproved] = useState<boolean>(false)
  const [modalTxError, setModalTxError] = useState<boolean>(false)
  const [modalStatus, setModalStatus] = useState<boolean>(false)
  const [voidEnergyGenerated, setVoidEnergyGenerated] = useState<number>(0)
  const [voidAllowance, setVoidAllowance] = useState<any>(undefined)
  const [newEstimatedRewards, setNewEstimatedRewards] =
    useState<number>(undefined)
  const wscModalRef = useRef<any>(null)
  const [insufficientBalance, setInsufficientBalance] = useState<boolean>(false)
  const {
    loadUserData,
    dollarValue,
    balance,
    handleStakeInputChange,
    stakeDisable,
    isLockEnded,
    existingDuration,
    startDate,
    existingVoid,
    stakingActive,
    voidEnergy,
    voidRewardRate,
    globalVE,
  } = useContext(StakeContext)

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<StakingProps>({
    defaultValues: {
      stakeInputValue: "",
      durationInputValue: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const voidValue = Number(watch("stakeInputValue"))
  const durationValue = watch("durationInputValue")

  useEffect(() => {
    if (!tokenFiat) return // skip watch("stakeInputValue") on purpose
    const tokenUSDValue = tokenFiat * Number(watch("stakeInputValue"))
    setTokenUSDFiat(tokenUSDValue)
  }, [watch("stakeInputValue"), tokenFiat])

  const stakingModalRef = useRef<any>(null)

  useEffect(() => {
    if (!tokenBalanceAmount || !voidValue) return
    const value = voidValue > Number(tokenBalanceAmount)
    setInsufficientBalance(Boolean(value))
  }, [tokenBalanceAmount, voidValue])

  const checkAllowance = useCallback(
    async (refetch = false) => {
      if (!account || !chainId) return

      if (isWSCConnected) {
        setVoidAllowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const storageKey = `veStakeAllowance${chainId}${account}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setVoidAllowance(Number(storageValue))
        return
      }

      const res = await tangleship?.getAllowance(
        VOID_TOKEN[chainId],
        account,
        "VEStaking"
      )
      const allowance = ethers.utils.formatEther(res)
      sessionStorage.setItem(storageKey, allowance)
      setVoidAllowance(Number(allowance))
    },
    [chainId, account]
  )

  useEffect(() => {
    checkAllowance(false)
  }, [checkAllowance])

  useEffect(() => {
    if (!startDate) return

    if (voidValue > 0 || durationValue > 0) {
      const startTime = startDate.getTime()
      const nowTime = new Date().getTime()
      const diff = (nowTime - startTime) / (1000 * 60 * 60 * 24)
      const remainingDuration = existingDuration - diff
      const newDuration = durationValue * 7

      const oldVoidWithOldDuration = voidEnergy
      const oldVoidWithNewDuration = existingVoid * newDuration
      const newVoidWithOldDuration = voidValue * remainingDuration
      const newVoidWithNewDuration = voidValue * newDuration

      const totalVE =
        oldVoidWithOldDuration +
        oldVoidWithNewDuration +
        newVoidWithOldDuration +
        newVoidWithNewDuration

      setVoidEnergyGenerated(totalVE)
    } else setVoidEnergyGenerated(voidEnergy)
  }, [
    voidValue,
    durationValue,
    existingVoid,
    existingDuration,
    voidEnergy,
    startDate,
  ])

  useEffect(() => {
    if (!(voidValue > 0 || durationValue > 0)) {
      setNewEstimatedRewards(undefined)
      return
    }

    const newGlobalVE = globalVE + (voidEnergyGenerated - voidEnergy)
    const userRewardsWeight = voidEnergyGenerated / newGlobalVE
    const totalDuration = existingDuration + durationValue * 7
    const globalRewardsPerDay = voidRewardRate * (60 * 60 * 24)
    const userRewardsPerDay = userRewardsWeight * globalRewardsPerDay
    const userRewards = userRewardsPerDay * totalDuration

    setNewEstimatedRewards(userRewards)
  }, [voidEnergyGenerated])

  const loadedDuration = Number((existingDuration / 7).toFixed(0))
  const handleWeekSelect = (value?: number) => {
    if (value === 1) {
      const data = existingDuration + 1 > 200 ? 200 - existingDuration : 1
      setValue("durationInputValue", data)
    } else if (value === 4) {
      const data = existingDuration + 4 > 200 ? 200 - existingDuration : 4
      setValue("durationInputValue", data)
    } else if (value === 12) {
      const data = existingDuration + 12 > 200 ? 200 - existingDuration : 12
      setValue("durationInputValue", data)
    } else if (value === 52) {
      const data = existingDuration + 52 > 200 ? 200 - existingDuration : 52
      setValue("durationInputValue", data)
    } else {
      const data = existingDuration > 0 ? 200 - existingDuration : 200
      setValue("durationInputValue", data)
    }
  }

  const handleDurationChange = (value?: any) => {
    setValue("durationInputValue", value)
  }
  const handleDurationSelect = (value?: number) => {
    if (value === 1) {
      const data = loadedDuration + 1 > 200 ? 200 - loadedDuration : 1
      setValue("durationInputValue", data)
    } else if (value === 4) {
      const data = loadedDuration + 4 > 200 ? 200 - loadedDuration : 4
      setValue("durationInputValue", data)
    } else if (value === 12) {
      const data = loadedDuration + 12 > 200 ? 200 - loadedDuration : 12
      setValue("durationInputValue", data)
    } else if (value === 52) {
      const data = loadedDuration + 52 > 200 ? 200 - loadedDuration : 52
      setValue("durationInputValue", data)
    } else {
      const data = loadedDuration > 0 ? 200 - loadedDuration : 200
      setValue("durationInputValue", data)
    }
  }

  const handleApprove = async () => {
    setStakingApproved(false)
    setPendingTransaction(true)
    setStakingApproving(true)
    tangleship
      .approveContract(VOID_TOKEN[chainId], VE_STAKING_ADDRESS[chainId])
      .then((res) => {
        setStakingApproved(false)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            checkAllowance(true)
          })
          setStakingApproved(true)
          setStakingApproving(false)
          setPendingTransaction(false)
          toast.success("Approval successful")
        } else {
          setStakingApproving(false)
          setPendingTransaction(false)
          setStakingApproved(false)
          toast.error("Approval failed ")
        }
      })
  }
  const userAllowance =
    voidAllowance === 0 ||
    Number(voidAllowance) < Number(watch("stakeInputValue"))
  const {
    refetchAllInfo,
    refetchAllContractInfo,
    refetchRewards,
    allStakingLoading,
    allStakingContractLoading,
    allRewardsLoading,
  } = useStakingArray(account, chainId)

  const prepBeginStakeFn = (type: string) => {
    const parsedType = type === "first" || type === "add"
    const parsedExtendType = type === "first" || type === "extend"
    // Only calculate based on first stake, adding more void or extending duration
    const parsedVoidValue = parsedType
      ? ethers.utils.parseEther(voidValue.toString())
      : BigNumber.from(0)
    const calculatedDuration = parsedExtendType ? durationValue * 7 * 86400 : 0

    const fn = tangleship?.stakeVE
    const fnParams = [parsedVoidValue, calculatedDuration] as const

    const fnFeedback = (params?) => {
      loadUserData()
      refetchAllInfo()
      refetchAllContractInfo()
      refetchRewards()
    }

    return { fn, fnParams, fnFeedback }
  }

  const handleStakeClick = async (type: string) => {
    if (!!userAllowance) {
      await handleApprove()
    } else {
      if (
        (!stakingActive && voidValue < 100) ||
        (!stakingActive && durationValue === 0)
      ) {
        toast.error("Something went wrong. Please check the input values.")
        return
      }

      setModalTxError(false)
      setStakingActionPending(true)
      if (type === "extend") setUserStaking(false)
      if (type === "first" || type === "add") setUserStaking(true)
      setPendingTransaction(true)
      setShowStakingModal(true)

      if (type === "extend") toast.message("Staking extension started...")
      if (type === "first" || type === "add")
        toast.message("Staking started...")

      const { fn, fnParams, fnFeedback } = prepBeginStakeFn(type)

      fn?.(...fnParams)?.then((res) => {
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback()

            setModalTxError(false)
            setStakingTx(res?.hash)
            setShowStakingModal(true)
            setStakingActionPending(false)
            setPendingTransaction(false)
            setValue("durationInputValue", "")
            setValue("stakeInputValue", "")
            toast.success("Staking successful")
          })
        } else {
          setModalTxError(true)
          setPendingTransaction(false)
          setShowStakingModal(true)
          setStakingActionPending(false)
          toast.error("Error while staking")
        }
      })
    }
  }

  const toggleModalStatus = () => setModalStatus(!modalStatus)
  const closeStakingPendingModal = () => {
    setShowStakingModal(false)
  }

  const closeStakingModal = (event: any) => {
    if (stakingModalRef.current === event?.target) {
      setShowStakingModal(false)
    }
  }
  const closeStakingConfirmationModal = () => {
    setShowStakingModal(false)
  }

  const handleMax = useCallback(() => {
    const amount = formatNumber(tokenBalanceAmount, 6)
    setValue("stakeInputValue", amount)
  }, [tokenBalanceAmount])

  const [wrapData, setWrapData] = useState<IWrapData>(undefined)
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useBeginStakeModalControl()

  const isWSCReady = () => isWSCConnected

  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const fireWSCModal = (type: string) => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepBeginStakeFn(type)
    if (!fn) return

    const VoidTokenInfo = tangleswapTokenListOnChain?.find(
      (token: any) =>
        String(token.address).toLowerCase() ===
          String(VOID_TOKEN[chainId]).toLowerCase() &&
        String(token.chainId) === String(l1ChainId)
    )

    fn?.(...fnParams).then((res: any) => {
      const tokenInData = {
        ...VoidTokenInfo,
        amount: Number(voidValue),
        unit: getTokenUnit(VoidTokenInfo.l1Address),
        chainId: chainId,
      }

      setWrapData({
        tokenIn: type === "first" || type === "add" ? tokenInData : undefined,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }
  const dispatch = useAppDispatch()
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    toggleWSCModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
    }
  }
  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleWSCModal()
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
    setOpenModalSettings(false)
  }

  return (
    <Body>
      <Cover>
        {showStakingModal ? (
          <>
            <UserStakingModal
              pending={stakingActionPending}
              stakingAction={userStaking}
              link={stakingTx}
              modalRef={stakingModalRef}
              closeModal={closeStakingModal}
              txError={modalTxError}
              closePendingModal={closeStakingPendingModal}
              closeConfirmationModal={closeStakingConfirmationModal}
            />
          </>
        ) : null}
        {isWSCConnected && (
          <BeginStakeModal
            isVisible={isWSCModalVisible}
            toggleModal={toggleWSCModal}
            wrapData={wrapData}
            skipWallet={skipWallet}
            openWalletOverview={openWalletOverview}
            closeWSCContinue={closeWSCContinue}
            wscModalRef={wscModalRef}
            cancelTransaction={cancelTransaction}
          />
        )}

        <InfoRow
          stakingLoading={
            allStakingLoading || allStakingContractLoading || allRewardsLoading
          }
        />
        {modalStatus && <WithdrawModal closeModal={toggleModalStatus} />}
        <Staking>
          {account && chainId ? (
            <>
              <ActionColumn>
                {allStakingLoading ||
                allStakingContractLoading ||
                allRewardsLoading ? (
                  <>
                    {" "}
                    <PositionsLoadingPlaceholder />
                    <PositionsLoadingPlaceholder />
                    <PositionsLoadingPlaceholder />
                    <PositionsLoadingPlaceholder />
                    <PositionsLoadingPlaceholder />
                  </>
                ) : (
                  <>
                    {" "}
                    {isLockEnded ? (
                      <EndedStake>
                        <LockNotification>
                          <LockTitle>
                            <LockTitleIcon />
                            <LockTitleText>
                              Your lock period has now ended! Select a new
                              duration and re-stake your tokens to keep earning
                              VOID + VE rewards.
                            </LockTitleText>
                          </LockTitle>
                        </LockNotification>
                        <LockBody>
                          <ReStake
                            stakingApproving={stakingApproving}
                            stakeDisable={stakeDisable}
                            handleWeekSelect={handleWeekSelect}
                            handleDurationChange={handleDurationChange}
                            duration={durationValue}
                            existingDuration={existingDuration}
                            balance={Number(balance)}
                          />
                        </LockBody>
                      </EndedStake>
                    ) : existingDuration && existingVoid ? (
                      <ExistingStake>
                        <InputGroup>
                          <StakeTitle>
                            <DarkPurpleText>VOID</DarkPurpleText> TO STAKE
                          </StakeTitle>
                          <AddMoreStaking
                            handleMax={handleMax}
                            tokenBalanceAmount={tokenBalanceAmount}
                            tokenUSDFiat={tokenUSDFiat}
                            register={register}
                            stakeInputValue={watch("stakeInputValue")}
                            watch={watch}
                            errors={errors}
                            insufficientBalance={insufficientBalance}
                            voidValue={voidValue}
                            dollarValue={dollarValue}
                            balance={Number(balance)}
                            stakeDisable={stakeDisable}
                            handleStakeAction={
                              isWSCConnected
                                ? () => fireWSCModal("add")
                                : () => handleStakeClick("add")
                            }
                            handleStakeInputChange={handleStakeInputChange}
                          />
                        </InputGroup>
                        <InputGroup>
                          <StakeTitle>
                            <DarkPurpleText>ADD</DarkPurpleText> DURATION
                          </StakeTitle>
                          <ExtendStaking
                            register={register}
                            durationInputValue={watch("durationInputValue")}
                            handleDurationSelect={handleDurationSelect}
                            handleStakeAction={
                              isWSCConnected ? fireWSCModal : handleStakeClick
                            }
                          />
                        </InputGroup>
                      </ExistingStake>
                    ) : (
                      <InputGroup>
                        <StakeTitle>
                          <DarkPurpleText>VOID</DarkPurpleText> TO STAKE
                        </StakeTitle>
                        <StakeInput
                          userAllowance={userAllowance}
                          tokenBalanceAmount={tokenBalanceAmount}
                          tokenUSDFiat={tokenUSDFiat}
                          chainId={chainId}
                          stakeInputValue={watch("stakeInputValue")}
                          durationInputValue={watch("durationInputValue")}
                          register={register}
                          tokenBalance={tangleswapTokenListBalances}
                          voidValue={voidValue}
                          dollarValue={dollarValue}
                          duration={durationValue}
                          balance={Number(balance)}
                          stakeDisable={stakeDisable}
                          stakingApproving={stakingApproving}
                          existingDuration={
                            existingDuration > 0 ? existingDuration : null
                          }
                          insufficientBalance={insufficientBalance}
                          handleStakeAction={
                            isWSCConnected
                              ? () => fireWSCModal("first")
                              : () => handleStakeClick("first")
                          }
                          handleStakeInputChange={handleStakeInputChange}
                          handleWeekSelect={handleWeekSelect}
                          handleDurationChange={handleDurationChange}
                        />
                      </InputGroup>
                    )}
                  </>
                )}
              </ActionColumn>
              <StakingOverviewContainer
                stakingLoading={
                  allStakingLoading ||
                  allStakingContractLoading ||
                  allRewardsLoading
                }
                voidValue={voidValue}
                existingVoid={existingVoid}
                duration={durationValue}
                existingDuration={existingDuration}
                startDate={startDate}
                isLockEnded={isLockEnded}
                voidEnergyGenerated={voidEnergyGenerated}
                newEstimatedRewards={newEstimatedRewards}
                toggleModalStatus={toggleModalStatus}
              />
            </>
          ) : (
            <>
              <StakingAuthenication
                register={register}
                tokenUSDFiat={tokenUSDFiat}
                tokenBalanceAmount={tokenBalanceAmount}
                tangleswapTokenListBalances={tangleswapTokenListBalances}
                stakeDisable={stakeDisable}
              />
            </>
          )}
        </Staking>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  padding: 16px;
`

const Staking = styled.div`
  margin: 2em 0;
  width: 100%;
  height: 100%;

  padding: 48px 24px;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
  justify-content: space-between;
  border-radius: 16px;
  position: relative;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
`

const ExistingStake = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const EndedStake = styled.div`
  border: 2px solid ${TangleColors.purpleBorder};
  border-radius: 16px;
`

const LockNotification = styled.div`
  background: ${TangleColors.cta};
  border: 0.4px solid ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  align-items: center;
  border-radius: 12px 12px 0 0;
`

const LockTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const LockTitleText = styled(CaptionSmall)`
  display: flex;
  color: ${TangleColors.white};
`

const LockTitleIcon = styled(TiWarningOutline)`
  color: ${TangleColors.white};
  display: flex;
  width: 40px;
  height: 40px;
  margin: 0 2px 0 0;
`

const LockBody = styled.div`
  padding: 16px 8px;
`

const InputGroup = styled.div`
  border: 1px solid ${TangleColors.dockBG};
  border-radius: 16px;
  padding: 16px;
`

const ActionColumn = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
  height: 100%;
`

const DarkPurpleText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
`

const StakeTitle = styled(Subtitle)`
  display: flex;
  color: ${TangleColors.white};
  gap: 4px;
  font-weight: 600;
`

const Cover = styled.div`
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
`

export default StakeBody
