import { MAX_UINT256 } from "@tangleswap/sdk"
import {
  useState,
  Fragment,
  FC,
  useEffect,
  useRef,
  RefObject,
  useContext,
} from "react"
import {
  ActionButton,
  Body,
  ButtonContainer,
  CloseIcon,
  CloseIconWrapper,
  CurrentStepWrapper,
  ModalTopBar,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  TopText,
  WrapContainer,
} from "../styles"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import WrapMultipleStep from "../transactionStepper/multiaction/WrapMultipleStep"
import MultipleTokenAllowanceStep from "../transactionStepper/multiaction/MultipleTokenAllowanceStep"
import ModalTopIcon from "../component/ModalTopIcon"
import WSCModalCover from "../component/WSCModalCover"
import { useAppSelector } from "store/hooks"
import ModalActionState from "../ModalActionState"
import { useBackup } from "../component/Backup"
import { PendingWscTxStepDataProps } from "../types"

interface LPModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IWrapMultipleData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  skipWallet?: () => void
  openWalletOverview?: () => void
  cancelTransaction?: () => void
}

const LPModal: FC<LPModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    wscModalRef,
    closeWSCContinue,
    skipWallet,
    openWalletOverview,
    cancelTransaction,
  } = props
  const { tangleship } = useTangleship()
  const { account, chainId } = useWSCContext()
  const pendingWscTxStep: PendingWscTxStepDataProps = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [backupSubStep, setBackupSubStep] = useState<number>(0)
  const [tokensToApprove, setTokensToApprove] = useState<any[]>()
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const actionType = "LP"
  const { data, backup } = useBackup(
    steps,
    actionType,
    setCurrentStep,
    setBackupSubStep
  ) as {
    data: IWrapMultipleData
    backup: boolean
  }

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    const newSteps = [
      {
        number: 1,
        title: "Wrap",
        actionTitle: "Wrapping",
        component: (
          <WrapMultipleStep
            data={backup ? data : wrapData}
            nextStep={nextStep}
            actionType={actionType}
          />
        ),
      },
    ]

    if (tokensToApprove && tokensToApprove.length > 0) {
      newSteps.push({
        number: 2,
        title: "Approve",
        actionTitle: "Approving",
        component: (
          <MultipleTokenAllowanceStep
            nextStep={nextStep}
            tokensToCheck={tokensToApprove}
            actionType={actionType}
            evmFunction={wrapData?.evmFunction}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: tokensToApprove && tokensToApprove.length > 0 ? 3 : 2,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          isBackup={backup}
          toggleModal={toggleModal}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, data, backup, tokensToApprove])

  const fetchAllowance = async (token, refetch = false) => {
    if (!account || !token?.address || !token?.decimals) return

    const storageKey = `wscAllowance${chainId}${account}${token.address}${actionType}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      return Number(storageValue)
    }

    const res = await tangleship?.getAllowance(
      token.address,
      account,
      actionType,
      true
    )
    const allowance = Number(res?._hex) / 10 ** token.decimals
    sessionStorage.setItem(storageKey, String(allowance))
    return allowance
  }

  const checkTokenAllowances = async () => {
    if (!tangleship || !wrapData || !chainId || !account) return
    const tokens = backup ? data?.tokenIn : wrapData?.tokenIn
    // TODO: Investigate why Sev had the cant read .length here. Maybe for some reason he had only one token as an Object somehow and loaded as backup?
    if (!Array.isArray(tokens)) return
    let tokensToApprove = []

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]
      const allowance =
        token.l1Address === "ADA"
          ? MAX_UINT256 // native ADA doesn't need to be approved
          : await fetchAllowance(token)

      if (Number(allowance) < token.amount) {
        tokensToApprove.push(token)
      }
    }

    setTokensToApprove(tokensToApprove)
  }

  useEffect(() => {
    checkTokenAllowances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapData, chainId, account])
  const addLiquidity =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "mint"
  const initializePool =
    pendingWscTxStep?.actionType === "LP" &&
    pendingWscTxStep?.actionSubType === "createAndInitializePoolIfNecessary"
  const lpTitle = !!addLiquidity
    ? "Add Liquidity"
    : !!initializePool
    ? "Initialize Liquidity"
    : "Add Liquidity"
  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title={lpTitle}
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <ModalActionState
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          actionTitle={steps[currentStep - 1]?.actionTitle}
        />

        <StepWrapper>
          {steps.map((step, index) => (
            <Fragment key={step.number}>
              <StepContainer>
                <StepButton
                  active={currentStep === step.number}
                  onClick={() => {
                    if (currentStep === step.number) return
                    setCurrentStep(step.number)
                  }}
                >
                  {step.number}
                </StepButton>
                <StepTitle active={currentStep === step.number}>
                  {step.title}
                </StepTitle>
              </StepContainer>
              {index < steps.length - 1 && <StepDivider />}
            </Fragment>
          ))}
        </StepWrapper>
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useLPModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default LPModal
