import React, { useCallback, useContext, useState } from "react"
import { LiaCloudUploadAltSolid } from "react-icons/lia"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"
import Papa from "papaparse"
import { AiOutlineCloudDownload } from "react-icons/ai"
import {
  ActionContainer,
  BackButton,
  CounterColumn,
  InputGroup,
  InputLabel,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  NextButton,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
import { SubmitHandler, useForm } from "react-hook-form"
import { Caption, CaptionSmall, CaptionTiny, Header6 } from "styles/TextStyles"

import tangleswap_purplelist from "assets/tangleswap_purplelist.csv"
import { isAddress } from "utils"
import PurplelistToggle from "./PurplelistToggle"
import { ethers } from "ethers"

interface PurplelistValue {
  fileInput?: any
  purplelistEnabled?: any
}

interface ListProps {
  active?: boolean
  fileUploaded?: boolean
  fileError?: boolean
}
const LaunchPadPurplelist = () => {
  const { tokenDetails, setTokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,

    formState: { errors },
  } = useForm<PurplelistValue>({
    defaultValues: {
      purplelistEnabled: false,
    },
  })

  const [fileDragging, setFileDragging] = useState<boolean>(false)
  const [fileIsInvalid, setFileIsInvalid] = useState<boolean>(false)
  const [csvData, setCsvData] = useState<any>(undefined)
  const [fileName, setFileName] = useState<any>(undefined)

  const onSubmit: SubmitHandler<PurplelistValue> = (data) => {
    if (Boolean(watch("purplelistEnabled")) === false) {
      setTokenDetails({
        ...tokenDetails,
        purplelistEnabled: false,
        purplelistData: [],
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    } else {
      if (
        Boolean(
          !!watch("purplelistEnabled") && fileIsInvalid === false && !!fileName
        ) === false
      )
        return
      setTokenDetails({
        ...tokenDetails,
        purplelistEnabled: true,
        purplelistData: csvData,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    }
  }

  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        if (
          parseFloat(String(file?.size)) > parseFloat(String(10 * 1024 * 1024)) //file size validation done
        ) {
          setError("fileInput", { message: "File size exceeds 10MB" })
        } else {
          setFileName(file?.name)

          Papa.parse(file, {
            header: true,
            download: false,
            dynamicTyping: true,
            preview: 0,
            delimiter: ",",
            skipEmptyLines: true,
            complete: function (result) {
              if (result.data.length === 0) {
                setError("fileInput", { message: "File contains no data" })
                setFileIsInvalid(true)
              } else {
                const allValid = result?.data.every((data, index) => {
                  const isAddressChecked = data?.wallet_address?.includes("0x")
                  const isMaxAmountChecked =
                    typeof data?.max_token_allowance === "number"

                  if (!isAddressChecked || !isMaxAmountChecked) {
                    console.log(`Row ${index + 1} failed: `, data)
                  }

                  return isAddressChecked && isMaxAmountChecked
                })

                console.log("resultdata", result, allValid, result.data)
                if (allValid) {
                  setCsvData(result.data)
                  setFileIsInvalid(false)
                } else {
                  setFileIsInvalid(true)
                  setCsvData(undefined)
                }
              }
            },
          })
        }
      }
    })
  }

  const onDrop = useCallback(
    async (e) => {
      e.preventDefault()
      setFileDragging(false)

      const files = e.dataTransfer.files
      if (files.length > 0) {
        setValue("fileInput", files[0])
        const data = await parseCSV(files[0])

        setCsvData(data)
      }
    },
    [setValue]
  )

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setFileDragging(false)
    if (file) {
      setFileName(file.name)
      parseCSV(file)
      setValue("fileInput", file)
    }
  }

  const onDragOver = useCallback((e) => {
    setFileDragging(true)
    e.preventDefault()
  }, [])
  const onDragEnd = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setFileDragging(false)
  }, [])

  const fileUploaded =
    !!watch("purplelistEnabled") && fileIsInvalid !== undefined && !!fileName

  const changeStatusToOn = useCallback(
    (e?: any) => {
      e.preventDefault()
      setValue("purplelistEnabled", true)
    },
    [setValue]
  )

  const changeStatusTooff = useCallback(
    (e?: any) => {
      e.preventDefault()
      setValue("purplelistEnabled", false)
    },
    [setValue]
  )
  return (
    <ItemBody active={currentStep === 4}>
      <CounterColumn>
        <StepCounter active={currentStep === 4}>4</StepCounter>
      </CounterColumn>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragEnd}
      >
        <ItemColumn>
          <TitleContainer>
            <TitleRow>
              <ItemTitle>Purplelist</ItemTitle>
              {currentStep === 4 ? (
                <TitleToggle>
                  <InputGroup>
                    <PurplelistToggle
                      isToggled={watch("purplelistEnabled")}
                      toggleOnButton={changeStatusToOn}
                      toggleOffButton={changeStatusTooff}
                    />
                  </InputGroup>
                </TitleToggle>
              ) : null}
            </TitleRow>

            {currentStep === 4 ? (
              <ItemSubTitle>
                Optional — upload a CSV file with approved addreesses to only
                allow participation by certain wallets.
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 4 ? (
            <>
              <Cover active={!!watch("purplelistEnabled")}>
                <InputGroup>
                  <InputLabel>Upload</InputLabel>

                  <FormInputCover fileUploaded={fileUploaded}>
                    <FormUploadCover fileUploaded={fileUploaded} />
                    <UploadTextWrapper>
                      <UploadIcon />

                      {fileDragging ? (
                        <>
                          <UploadTitle>
                            <span>Drop your file here</span>
                          </UploadTitle>
                        </>
                      ) : (
                        <>
                          {" "}
                          {!!watch("purplelistEnabled") &&
                          fileIsInvalid !== undefined &&
                          !!fileName ? (
                            <>
                              <UploadTitle>
                                {" "}
                                <span>File uploaded successfully</span>
                              </UploadTitle>
                              <UploadText>CSV up to 10MB</UploadText>
                            </>
                          ) : (
                            <>
                              <UploadTitle>
                                {" "}
                                <span>Upload a file</span> or drag and drop
                              </UploadTitle>
                              <UploadText>CSV up to 10MB</UploadText>
                            </>
                          )}
                        </>
                      )}
                    </UploadTextWrapper>{" "}
                    <FormUploadLabel htmlFor="fileInput">
                      <FormUploadLabelText> </FormUploadLabelText>
                    </FormUploadLabel>
                    <FormUploadInput
                      type="file"
                      id="fileInput"
                      accept=".csv,text/csv"
                      {...register("fileInput", {
                        required:
                          !!watch("purplelistEnabled") &&
                          fileIsInvalid === undefined,
                      })}
                      onChange={handleFileChange}
                    />
                  </FormInputCover>
                  <>
                    {!!watch("purplelistEnabled") &&
                    fileIsInvalid !== undefined &&
                    !!fileName ? (
                      <FileInfoWrapper>
                        <FormInputName>{fileName}</FormInputName>
                      </FileInfoWrapper>
                    ) : null}
                  </>

                  <FileInfoWrapper>
                    <FormPurpleListName
                      fileError={
                        fileIsInvalid === true &&
                        !!watch("purplelistEnabled") &&
                        fileIsInvalid !== undefined &&
                        fileName !== undefined
                      }
                    >
                      {fileIsInvalid === true &&
                      !!watch("purplelistEnabled") &&
                      fileIsInvalid !== undefined &&
                      fileName !== undefined ? (
                        <>
                          Invalid input data! Please download the template
                          provided, and ensure you follow the format (including
                          column headers).
                        </>
                      ) : (
                        <>
                          {" "}
                          Please download the CSV template provided and fill in
                          your details. For decimals, use a dot "." symbol (not
                          a comma).
                        </>
                      )}
                    </FormPurpleListName>
                    <FileDownloadWrapper>
                      <DownloadHref
                        href={tangleswap_purplelist}
                        download="tangleswap_purplelist.csv"
                      >
                        <DownloadText>Download</DownloadText>
                        <DownloadIcon />
                      </DownloadHref>
                    </FileDownloadWrapper>
                  </FileInfoWrapper>
                  <ErrorMessage
                    active={Boolean(
                      `${
                        !!watch("purplelistEnabled") && errors.fileInput
                          ? errors.fileInput.message
                          : null
                      }`
                    )}
                  >
                    <>
                      {!!watch("purplelistEnabled") && errors.fileInput
                        ? errors.fileInput.message
                        : null}
                    </>
                  </ErrorMessage>
                </InputGroup>
              </Cover>
              <ActionContainer>
                <NextButton
                  disabled={
                    (!!watch("purplelistEnabled") && !watch("fileInput")) ||
                    (fileIsInvalid === true &&
                      !!watch("purplelistEnabled") &&
                      fileIsInvalid !== undefined &&
                      fileName !== undefined)
                  }
                  type="submit"
                >
                  Continue
                </NextButton>
                <BackButton
                  onClick={() => {
                    setCurrentStep((currentStep: number) => currentStep - 1)
                  }}
                >
                  Back
                </BackButton>
              </ActionContainer>
            </>
          ) : null}
        </ItemColumn>
      </form>
    </ItemBody>
  )
}

const UploadIcon = styled(LiaCloudUploadAltSolid)`
  width: 44px;
  height: 44px;
  margin: 0 0 8px 0;
  color: ${TangleColors.lightPurple};
`

const UploadTitle = styled(Caption)`
  color: ${TangleColors.white};
  span {
    color: ${TangleColors.lightPurple};
  }
`

const FormInputName = styled(Header6)`
  color: ${TangleColors.lighthover};
`

const FormPurpleListName = styled(CaptionSmall)<ListProps>`
  color: ${(props) =>
    props.fileError ? `${TangleColors.red}` : `${TangleColors.grayLight}`};
`

const UploadText = styled(CaptionTiny)`
  color: ${TangleColors.grayDark};
`

const UploadTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 5;

  span {
    color: ${TangleColors.lightPurple};
  }
`

const Cover = styled.div<ListProps>`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ErrorMessage = styled(Caption)<ListProps>`
  color: ${TangleColors.red};
  display: ${(props) => (props.active ? "flex" : "none")};
`

const FormUploadLabel = styled.label`
  color: ${TangleColors.white};
  margin: 0 0 6px 0;
`

const FormUploadLabelText = styled(Caption)`
  color: ${TangleColors.white};
`
const FormUploadInput = styled.input`
  width: 100%;
  max-width: 900px;

  min-height: 250px;
  color: ${TangleColors.white};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
`
const FormUploadCover = styled.div<ListProps>`
  width: 100%;
  max-width: 900px;
  border: ${(props) =>
    props.fileUploaded ? `0.5px solid ${TangleColors.white}` : `1`};
  display: ${(props) => (props.fileUploaded ? `flex` : `none`)};
  min-height: 250px;
  color: ${TangleColors.white};
  background: ${TangleColors.white};
  opacity: ${(props) => (props.fileUploaded ? `0.07` : `1`)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  z-index: 1;
`
const FormInputCover = styled.div<ListProps>`
  width: 100%;
  border-radius: 24px;
  border: ${(props) =>
    props.fileUploaded ? `none` : `1px dotted ${TangleColors.white}`};
  height: 250px;
  padding: 32px;
  max-width: 590px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  cursor: pointer;
  :hover {
    opacity: 0.8;
    background: ${TangleColors.heroCTA};
  }
`

const FileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;
  max-width: 590px;
`
const FileDownloadWrapper = styled.div`
  border-radius: 12px;
  width: 100%;
  max-width: 150px;
  height: 48px;
  background: ${TangleColors.lighthover};
  margin: 0 0 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.white};
  }
`
const DownloadHref = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const DownloadText = styled(Caption)`
  color: ${TangleColors.black};
`
const DownloadIcon = styled(AiOutlineCloudDownload)`
  width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  color: ${TangleColors.black};
`
const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const TitleToggle = styled.div`
  margin: 0 0 0 12px;
`
export default LaunchPadPurplelist
