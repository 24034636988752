import { Chains } from "@tangleswap/sdk"
import HideOnShimmer from "components/LaunchPad/HideOnShimmer"
import { useWSCContext } from "context/MilkomedaContext"
import styled from "styled-components"

import { useEffect } from "react"
import NFTDetailsContainer from "components/nfts/NFTDetailsContainer"
import ComingSoon from "components/utils/ComingSoon"

const NftStakingDetails = () => {
  useEffect(() => {
    document.title = "NFT Control Room"
  }, [])
  const { chainId } = useWSCContext()
  return (
    <>
      <Body>
        <HideOnShimmer show={chainId === Chains.SHIMMER} />

        {chainId === Chains.SHIMMER ? (
          <>
            <ComingSoon />
          </>
        ) : (
          <>
            {" "}
            <NFTDetailsContainer />
          </>
        )}
      </Body>
    </>
  )
}

const Body = styled.div``

export default NftStakingDetails
