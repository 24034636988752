import React, { FC } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"

interface BobyProps {
  children?: any
  dappsClassName?: any
}
const BubbleTail: FC<BobyProps> = (props) => {
  const location = useLocation()

  const dappsClassName =
    location.pathname === "/swap" || location.pathname === "/pools/add"
  return <TailBody dappsClassName={dappsClassName} />
}

const TailBody = styled.div<BobyProps>`
  height: 26px;
  width: 22px;

  background: url("/alien_BUBBLE_LIP.png")
    no-repeat center center/contain;
`
export default React.memo(BubbleTail)
