import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"
import LiquidityPositionsLoadingPlaceholder from "components/Liquidity/component/LiquidityPositionsLoadingPlaceholder"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

interface CriteriaProps {
  lowerTick?: number
  upperTick?: number
  isFixed?: any
  hardcoded?: boolean
  token0Symbol?: string
  token1Symbol?: string
}

const TopRewardsCriteria: FC<CriteriaProps> = (props) => {
  const { lowerTick, upperTick, hardcoded, token0Symbol, token1Symbol } = props

  return (
    <Body>
      <TextTitle>Rewards Range</TextTitle>
      {hardcoded ? (
        <>
          <Criteria>
            {formatNumber(1.36, 4)} — {formatNumber(5.44, 4)}
          </Criteria>
        </>
      ) : (
        <>
          <Criteria>
            {lowerTick !== undefined && upperTick !== undefined ? (
              <>
                {`${toSignificantDigits(
                  lowerTick,
                  4,
                  true,
                  18
                )} — ${toSignificantDigits(
                  upperTick,
                  4,
                  true,
                  18
                )} ${token1Symbol} per ${token0Symbol}`}
              </>
            ) : (
              <>
                <LiquidityPositionsLoadingPlaceholder />
              </>
            )}
          </Criteria>
        </>
      )}
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 0 2px 0;
`

const Criteria = styled(Caption)`
  color: ${TangleColors.white};
`

export default TopRewardsCriteria
