import {
  AUCTION_CREATION_ADDRESS,
  Chains,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  ROUTER_ADDRESS,
  VE_STAKING_ADDRESS,
  extractEvmFunctionName,
} from "@tangleswap/sdk"
import {
  BRIDGE_DEVNET_ADDRESS,
  BRIDGE_MAINNET_ADDRESS,
} from "constants/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import { ethers } from "ethers"
import { IWrapData, IWrapToken } from "interfaces/wscSwap.interface"
import { FC, useEffect, useRef } from "react"
import {
  erc20ABI,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi"
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  ButtonContainer,
  Link,
} from "components/milkomeda/styles"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { getEvmExplorerUrl } from "utils/milkomeda/transaction"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { playSound } from "../component/PlaySound"

interface ITokenAllowanceStep {
  nextStep: () => void
  tokenToCheck: IWrapToken
  actionType?: string
  evmFunction: any
  data: IWrapData
  auctionAddress?: string
}

const TokenAllowanceStep: FC<ITokenAllowanceStep> = (props) => {
  const {
    nextStep,
    tokenToCheck,
    actionType,
    evmFunction,
    data,
    auctionAddress,
  } = props
  const { chainId, account } = useWSCContext()

  const approvedAmount = ethers.constants.MaxUint256 // @dev: set approval to quasi-infinite to avoid multiple approval txs (cleaner UX)
  const approvedAddress =
    actionType === "Swap"
      ? ROUTER_ADDRESS[chainId]
      : actionType === "LP"
      ? NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]
      : actionType === "VEStaking"
      ? VE_STAKING_ADDRESS[chainId]
      : actionType === "IHub"
      ? auctionAddress
      : null
  if (!approvedAddress) throw new Error("Unrecognized actionType!")

  const {
    config: userConfig,
    isLoading: isUserPreparingLoading,
    isError: isUserPreparingError,
    error: userPreparingError,
  } = usePrepareContractWrite({
    address: tokenToCheck.address as `0x${string}`,
    abi: erc20ABI,
    functionName: "approve",
    args: [approvedAddress, approvedAmount],
    enabled: !!tokenToCheck,
    overrides: {
      gasLimit: ethers.BigNumber.from(500_000),
    },
  })

  const {
    data: userWriteData,
    write: userWrite,
    isLoading: isUserWritingContract,
    isIdle: isUserWritingIdle,
    isError: isUserContractWriteError,
    error: userContractWriteError,
  } = useContractWrite(userConfig)

  const {
    isLoading: isUserWaitingForTxLoading,
    isSuccess: isUserWriteSuccess,
    isError: isUserWaitingForTxError,
    error: waitForUserTransactionError,
  } = useWaitForTransaction({
    hash: userWriteData?.hash,
    enabled: !!userWriteData?.hash,
  })

  const isUserLoadingTx = isUserWritingContract || isUserWaitingForTxLoading
  const isUserError =
    isUserWaitingForTxError || isUserContractWriteError || isUserPreparingError

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (tangleSwapTransactions && !autoExecute.current && userWrite) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        executeStep()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, userWrite])

  useEffect(() => {
    const storageKey = `wscAllowance${chainId}${account}${tokenToCheck.address}${actionType}`
    if (isUserWriteSuccess) {
      // fetchAllowance(currentToken.address, true) // TODO: Pat to import this function
      const stepperParams = {
        completed: !isUserError,
        stepTitle: "Approve",
        subStep: 0,
        actionType,
        actionSubType: extractEvmFunctionName(evmFunction),
        txHash: userWriteData?.hash,
        data,
        timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      }
      dispatch(updateUserWSCProgress(stepperParams))
      sessionStorage.removeItem(storageKey)
      if (tangleSwapTXEffects) playSound()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserWriteSuccess])

  // useEffect(() => {
  //   if (!isBridgeWriteSuccess) return
  //   userWrite?.()
  // }, [isBridgeWriteSuccess])
  const dispatch = useAppDispatch()
  const executeStep = () => {
    userWrite?.()
    const stepperParams = {
      completed: false,
      stepTitle: "Approve",
      subStep: 0,
      actionType,
      actionSubType: extractEvmFunctionName(evmFunction),
      txHash: userWriteData?.hash,
      data: null,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }
  return (
    <>
      <ActionWrapper>
        {/* <div>Token Allowance</div>
        <div style={{ marginBottom: 30 }}>
          Authorize the seamless transfer of your assets between the EVM and
          Mainchain networks. Perform a token allowance transaction to enable
          the subsequent unwrapping and secure transfer of the asset back to
          your Mainchain wallet.
        </div> */}
        {isUserPreparingLoading && (
          <>
            <SpinnerCombined />
            <span>Preparing transaction</span>
          </>
        )}
        {isUserLoadingTx && (
          <>
            <SpinnerCombined />
            <span>Approving token allowance</span>
          </>
        )}
        {isUserError && (
          <div>
            Oops, something went wrong.
            {userContractWriteError?.message ?? ""}
            {waitForUserTransactionError?.message ?? ""}
            {userPreparingError?.message ?? ""}
          </div>
        )}

        {isUserWriteSuccess && (
          <>
            {isUserWriteSuccess && (
              <Link
                href={`${getEvmExplorerUrl(chainId)}/tx/${userWriteData?.hash}`}
              >
                Approved the token successfully!
              </Link>
            )}
            {isUserWriteSuccess && (
              <ButtonContainer>
                <ActionButton disabled={!isUserWriteSuccess} onClick={nextStep}>
                  Continue
                </ActionButton>
              </ButtonContainer>
            )}
            {isUserContractWriteError && (
              <ButtonContainer>
                <ActionButton onClick={() => userWrite?.()}>
                  Retry USER PROMPT
                </ActionButton>
              </ButtonContainer>
            )}
          </>
        )}
      </ActionWrapper>

      {(isUserWritingIdle || isUserContractWriteError) && (
        <ButtonContainer>
          <ActionButton disabled={!userWrite} onClick={executeStep}>
            Grant token allowance
          </ActionButton>
        </ButtonContainer>
      )}
    </>
  )
}

export default TokenAllowanceStep
