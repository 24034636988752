import { toHex } from "./useSwitchChain"
import {
  Chains,
  NATIVE_NAME,
  NATIVE_SYMBOL,
  WRAPPED_DECIMALS,
  chainIdsToNames,
  explorerURL,
  defaultProviderURL,
} from "@tangleswap/sdk"

const prefix =
  "https://raw.githubusercontent.com/TangleSwap/assets/main/chains/"
const suffix = "/info/logo.png"

const cardanoLogoURL = prefix + "cardano" + suffix
const cardanoTestLogoURL = prefix + "cardano-testnet" + suffix
const sepoliaTestLogoURL = prefix + "sepolia-testnet" + suffix
const shimmerTestLogoURL = prefix + "shimmer-testnet" + suffix
const shimmerLogoURL = prefix + "shimmer" + suffix

const addCardanoToMetamask: () => void = () => {
  const { ethereum } = window as any
  const hexNumber = toHex(Chains.CARDANO)
  if (ethereum) {
    try {
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `${hexNumber}`,
              chainName: chainIdsToNames[Chains.CARDANO],
              rpcUrls: [defaultProviderURL[Chains.CARDANO]],
              iconUrls: [cardanoLogoURL],
              blockExplorerUrls: [explorerURL[Chains.CARDANO]],
              nativeCurrency: {
                name: NATIVE_NAME[Chains.CARDANO],
                symbol: NATIVE_SYMBOL[Chains.CARDANO],
                decimals: 18,
              },
            },
          ],
        })
        .catch((error: any) => {
          if (error.code !== 4001) console.error(error)
        })
    } catch (error) {
      ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${hexNumber}` }],
      })
    }
  }
}

const addCardanoTestnetToMetamask: () => void = () => {
  const { ethereum } = window as any
  const hexNumber = toHex(Chains.CARDANO_TEST)
  if (ethereum) {
    try {
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `${hexNumber}`,
              chainName: chainIdsToNames[Chains.CARDANO_TEST],
              rpcUrls: [defaultProviderURL[Chains.CARDANO_TEST]],
              iconUrls: [cardanoTestLogoURL],
              blockExplorerUrls: [explorerURL[Chains.CARDANO_TEST]],
              nativeCurrency: {
                name: NATIVE_NAME[Chains.CARDANO_TEST],
                symbol: NATIVE_SYMBOL[Chains.CARDANO_TEST],
                decimals: 18,
              },
            },
          ],
        })
        .catch((error: any) => {
          if (error.code !== 4001) console.error(error)
        })
    } catch (error) {
      ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${hexNumber}` }],
      })
    }
  }
}

const addShimmerToMetamask: () => void = () => {
  const { ethereum } = window as any
  const hexNumber = toHex(Chains.SHIMMER)
  if (ethereum) {
    try {
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `${hexNumber}`,
              chainName: chainIdsToNames[Chains.SHIMMER],
              rpcUrls: [defaultProviderURL[Chains.SHIMMER]],
              iconUrls: [shimmerLogoURL],
              blockExplorerUrls: [explorerURL[Chains.SHIMMER]],
              nativeCurrency: {
                name: NATIVE_NAME[Chains.SHIMMER],
                symbol: NATIVE_SYMBOL[Chains.SHIMMER],
                decimals: 18,
              },
            },
          ],
        })
        .catch((error: any) => {
          if (error.code !== 4001) console.error(error)
        })
    } catch (error) {
      ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${hexNumber}` }],
      })
    }
  }
}

const addShimmerTestnetToMetamask: () => void = () => {
  const { ethereum } = window as any

  const hexNumber = toHex(Chains.SHIMMER_TEST)
  if (ethereum) {
    try {
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `${hexNumber}`,
              chainName: chainIdsToNames[Chains.SHIMMER_TEST],
              rpcUrls: [defaultProviderURL[Chains.SHIMMER_TEST]],
              iconUrls: [shimmerTestLogoURL],
              blockExplorerUrls: [explorerURL[Chains.SHIMMER_TEST]],
              nativeCurrency: {
                name: NATIVE_NAME[Chains.SHIMMER_TEST],
                symbol: NATIVE_SYMBOL[Chains.SHIMMER_TEST],
                decimals: 18,
              },
            },
          ],
        })
        .catch((error: any) => {
          if (error.code !== 4001) console.error(error)
        })
    } catch (error) {
      ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${hexNumber}` }],
      })
    }
  }
}

const addSepoliaToMetamask: () => void = () => {
  const { ethereum } = window as any

  const hexNumber = toHex(Chains.SEPOLIA_TEST)
  if (ethereum) {
    try {
      ethereum?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `${hexNumber}` }],
      })
    } catch (error) {
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `${hexNumber}`,
              chainName: chainIdsToNames[Chains.SEPOLIA_TEST],
              rpcUrls: [defaultProviderURL[Chains.SEPOLIA_TEST]],
              iconUrls: [sepoliaTestLogoURL],
              blockExplorerUrls: [explorerURL[Chains.SEPOLIA_TEST]],
              nativeCurrency: {
                name: NATIVE_NAME[Chains.SEPOLIA_TEST],
                symbol: NATIVE_SYMBOL[Chains.SEPOLIA_TEST],
                decimals: WRAPPED_DECIMALS[Chains.SEPOLIA_TEST],
              },
            },
          ],
        })
        .catch((error: any) => {
          if (error.code !== 4001) console.error(error)
        })
    }
  }
}

const addChainToMetamask = (chainID?: any) => {
  if (chainID === Chains.CARDANO) addCardanoToMetamask()
  else if (chainID === Chains.CARDANO_TEST) addCardanoTestnetToMetamask()
  else if (chainID === Chains.SHIMMER) addShimmerToMetamask()
  else if (chainID === Chains.SHIMMER_TEST) addShimmerTestnetToMetamask()
  else if (chainID === Chains.SEPOLIA_TEST) addSepoliaToMetamask()
}
export default addChainToMetamask
