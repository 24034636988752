import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body1, Header3Gothic } from "styles/TextStyles"
interface SiteHeroProps {
  backgroundImage?: any
  title?: string
  subText?: string
}
const SiteheroSection: FC<SiteHeroProps> = (props) => {
  const { backgroundImage, title, subText } = props
  return (
    <HeroBody backgroundImage={backgroundImage}>
      <HeroOverlay />
      <SiteHeroCover>
        <SiteHeroTitle>{title}</SiteHeroTitle>
        <SiteHeroSubText>{subText}</SiteHeroSubText>
      </SiteHeroCover>
    </HeroBody>
  )
}

const HeroBody = styled.div<SiteHeroProps>`
  min-height: 300px;
  width: 100%;
  position: relative;
  margin: -100px 0 64px 0;
  padding: 16px;
  background: url(${(props) => props.backgroundImage}) no-repeat center
    center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${TangleColors.lighthover};
`
const SiteHeroCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 1232px;
  width: 100%;
  margin: 64px auto 0 auto;
  background: transparent;
  position: relative;

  padding: 64px 0 0 0;
`

const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: inherit;
  height: 100%;
  content: "";
`
const SiteHeroTitle = styled(Header3Gothic)`
  color: transparent;
  text-transform: capitalize;


  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-align: left;
  margin: 0 0 4px 0;
  min-height: 46px;
`
const SiteHeroSubText = styled(Body1)`
  color: ${TangleColors.white};
  margin: 8px 0;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: ${TangleColors.cta};
    transition: 0.3s ease-in-out;

    :hover {
      color: ${TangleColors.primary};
    }
  }
`
export default SiteheroSection
