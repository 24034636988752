import { Contract } from "@ethersproject/contracts"
import type { Signer } from "@ethersproject/abstract-signer"
import type { Provider } from "@ethersproject/providers"
import { getAddress } from "@ethersproject/address"
import { AddressZero } from "@ethersproject/constants"
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers"
import { BigNumber, ethers } from "ethers"
import { ZERO } from "config/constants"
import { NATIVE_SYMBOL, defaultProviderURL } from "@tangleswap/sdk"

const NATIVE_TOKENS = Object.values(NATIVE_SYMBOL)

export function isAddress(value: any): string | false {
  try {
    if (NATIVE_TOKENS?.includes(value.toUpperCase())) {
      return value?.toUpperCase()
    }
    if (!value) return false

    return getAddress(value) // Returns the formatted address if valid
  } catch {
    return false // Not a valid Ethereum address
  }
}

export function getContract(
  address: string,
  chainId: number,
  ABI: any,
  signer?: Signer | Provider
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    return
  }
  const provider = new ethers.providers.JsonRpcProvider(
    defaultProviderURL[chainId]
  )
  return new Contract(address, ABI, signer ?? provider)
}

export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library?.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

export function displayLocaleTime(time: number): string {
  return new Date(time).toLocaleString()
}

// export function displayBalance(
//   value: ethers.BigNumber | string,
//   decimals?: number
// ): string {
//   return Number(
//     value instanceof ethers.BigNumber ? value.toString() : value
//   ).toLocaleString(undefined, {
//     minimumFractionDigits: 0,
//     maximumFractionDigits: decimals,
//   })
// }

export function calculateGasMargin(value: ethers.BigNumber): ethers.BigNumber {
  return value
    .mul(ethers.BigNumber.from(10000).add(ethers.BigNumber.from(1000)))
    .div(ethers.BigNumber.from(10000))
}

export function calculateInterest(
  timestamp: number,
  stakeTime: number,
  baseApy: number,
  principal: BigNumber,
  claimed: BigNumber
): BigNumber {
  // const claimDuration = 604800 // 7 days
  const claimDuration = 600 // 1 minutes
  const yearToSecond = 24 * 60 * 60 * 365

  const n = Math.floor((timestamp - stakeTime) / claimDuration)
  const interval = claimDuration / yearToSecond
  const daysRate = (interval * baseApy) / 100

  if (daysRate <= 0) return ZERO
  const interest = principal.mul(Math.floor(n * daysRate * 1e15)).div(1e15)
  return interest.sub(claimed)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  if (!address) {
    throw Error("Address is required")
  }

  const parsedAddress = isAddress(address)
  if (!parsedAddress) {
    throw Error("Invalid 'address' parameter")
  }

  return `${parsedAddress.slice(0, chars + 2)}...${parsedAddress.slice(-chars)}`
}
export function shortenWSCAddress(address?: string, chars = 4): string {
  // Make sure address is valid
  if (!address) return
  // Determine where to start the ending slice based on the address length
  const startSliceIndex = address.length > 15 ? 15 : 20

  return `${address.slice(0, 10)}...${address.slice(
    startSliceIndex,
    startSliceIndex + chars
  )}`
}

export function shortenTableAddress(address: string, chars = 4): string {
  // const parsed = isAddress(address)
  // if (address) {
  //   if (!parsed) {
  //     throw Error(`Invalid 'address' parameter `)
  //   }
  // }
  return `${address?.slice(0, 6)}......${address?.slice(34)}`
}

export function shortenTokenName(text: string): string {
  return text?.slice(0, 3)
}

export function paireEscapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}
