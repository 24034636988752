import React, { FC } from "react"
import styled from "styled-components"
import { AidropclaimButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header4, Subtitle } from "styles/TextStyles"
interface CardProps {
  leftValue?: string
  rightValue?: string
}
const InfoCard: FC<CardProps> = (props) => {
  const { leftValue, rightValue } = props

  return (
    <AccountWrapper>
      <AccountLiner />
      <AcccountOverlay />
      <DetailsWrapper>
        <AccountStatTop>
          <AccountStatTitle>Your Allocation</AccountStatTitle>
        </AccountStatTop>
        <AccountStateBottom>
          <AccountStatRow>
            <StatValue>{!!leftValue ? leftValue : <>—</>}</StatValue>

            <StatTitle>Total airdrop amount</StatTitle>
          </AccountStatRow>
          <AccountStatRow>
            <StatValue>{!!rightValue ? rightValue : <>—</>}</StatValue>
            <StatTitle>Your earned rewards</StatTitle>
          </AccountStatRow>
        </AccountStateBottom>

        <NoTransactionDone>
          <NoTransactionButtonCover disabled={true}>
            Claim Your Airdrop!
          </NoTransactionButtonCover>
        </NoTransactionDone>
      </DetailsWrapper>
    </AccountWrapper>
  )
}

const AccountWrapper = styled.div`
  border: 1px solid ${TangleColors.cta};
  background-color: ${TangleColors.modalBG};
  background-image: url("https://d3m3d54t409w7t.cloudfront.net/homepage/the-VOID-pilot.webp");
  background-size: 420px;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 280px;
  max-width: 800px;
  position: relative;
  border-radius: 20px;
  padding: 12px 12px 32px 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: 0.4s ease-in;
  :hover {
    background-position: left center;
    background-size: 750px;
  }
`

const AccountLiner = styled.div`
  margin: 0 auto;
  width: 100%;
  position: absolute;
  border-radius: 20px;
  top: 2%;
  left: 2%;
  transform: translate(0%, 0%);
  width: 96%;
  height: 94%;
  z-index: 3;
  opacity: 0;
  border: 1px solid ${TangleColors.white};
  transition: 0.4s ease-in;
  ${AccountWrapper}:hover & {
    opacity: 1;
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 12;
`

const AcccountOverlay = styled.div`
  position: absolute;
  border-radius: 20px;
  content: "";
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.75);
`

const AccountStatTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
`
const AccountStatTitle = styled(Header4)`
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
`
const AccountStateBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -13.5px 0 0 0;
`
const AccountStatRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StatValue = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
  transition: 0.4s ease-in;
`
const StatTitle = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0;
  transition: 0.4s ease-in;
`
const NoTransactionDone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
  margin: 0 auto;
`
const NoTransactionButtonCover = styled(AidropclaimButton)``

export default InfoCard
