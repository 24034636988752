import { createClient } from "wagmi"
import {
  CARDANO_ALLOWED_WALLETS,
  DEVNET_BLOCKFROST_ID,
  DEVNET_CHAINID,
  DEVNET_NETWORK,
  DEVNET_ORACLE_URL,
} from "./config"
import { supportedChains } from "./supportedChains"
import {
  MilkomedaNetworkName,
  getDefaultConfig,
} from "milkomeda-wsc-ui-test-beta"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"

export const supportedChain =
  supportedChains.find((chain) => chain.id === DEVNET_CHAINID) ??
  supportedChains[0]

export const metamaskConnector = new MetaMaskConnector({
  chains: [supportedChain],
})

export const wagmiClientTestnet = createClient(
  getDefaultConfig({
    oracleUrl: DEVNET_ORACLE_URL,
    blockfrostId: DEVNET_BLOCKFROST_ID,
    chains: [supportedChain],
    network: DEVNET_NETWORK as MilkomedaNetworkName,
    autoConnect: false,
    cardanoWalletNames: CARDANO_ALLOWED_WALLETS as any,
  })
)
