import React from "react"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"
import { HeaderNavbar } from "styles/TextStyles"
import networkIcon from "assets/network.svg"
import addChainToMetamask from "connect/switchChain"
import { Chains } from "@tangleswap/sdk"

const WrongNetwork = () => {
  return (
    <NetworkCover onClick={() => addChainToMetamask(Chains.SHIMMER)}>
      <NetworkLogo src={networkIcon} alt="Wrong network" />
      <NetworkText>Wrong Network</NetworkText>
    </NetworkCover>
  )
}

const NetworkCover = styled.div`
  height: 48px;
  max-width: 210px;
  width: 100%;
  margin: 0 0 0 8px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  background: ${TangleColors.red};
  @media only screen and (max-width: 850px) {
    max-width: 740px;
    margin: 12px auto;
    height: 64px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 32px;
    position: relative;
    z-index: 1;
  }
`
const NetworkLogo = styled.img`
  height: 12px;
  width: 12px;
  margin: 0 4px;
`
const NetworkText = styled(HeaderNavbar)`
  color: ${TangleColors.white};
  margin: 0 4px;
  white-space: nowrap;
`
export default WrongNetwork
