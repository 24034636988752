import { Chains } from "@tangleswap/sdk"
import { useTangleship } from "./useTangleship"
import { useQuery } from "react-query"

export default function useStakingArray(
  account: string,
  chainId: Chains
): {
  TangleswapUserInfo: any
  allStakingLoading: any
  refetchAllInfo?: any
  TangleswapStakingContractInfo?: any
  allStakingContractLoading?: any
  refetchAllContractInfo?: any
  TangleswapFetchRewards?: any
  refetchRewards?: any
  allRewardsLoading?: any
} {
  const { tangleship } = useTangleship()
  const fetchStakingUserInfo = async (userAddress: any) => {
    const userInfo = await tangleship?.getUserInfoVE(userAddress)
    return userInfo
  }
  const loadContractInfo = async () => {
    const avgLockTime = await tangleship?.getVEStakingAverageLockTime()
    const voidRewardInfo = await tangleship?.computeGlobalVEApyAndTvl()
    const globalVE = await tangleship?.getGlobalVE()

    return { avgLockTime, voidRewardInfo, globalVE }
  }

  const fetchRewards = async (userAddress: any) => {
    const pendingRewards = await tangleship?.getPendingRewardsVE(userAddress)
    return pendingRewards
  }
  const {
    data: TangleswapUserInfo,
    isLoading: allStakingLoading,
    refetch: refetchAllInfo,
  } = useQuery(
    ["tangleswapStakingUserInfo", account, chainId],
    () => fetchStakingUserInfo(account),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      refetchIntervalInBackground: true,
      staleTime: 1000 * 60 * 5, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!account && !!chainId, // Query is enabled conditionally
    }
  )
  const {
    data: TangleswapFetchRewards,
    isLoading: allRewardsLoading,
    refetch: refetchRewards,
  } = useQuery(
    ["tangleswapStakingFetchRewards", account, chainId],
    () => fetchRewards(account),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      refetchIntervalInBackground: true,
      staleTime: 1000 * 60 * 5, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!account && !!chainId, // Query is enabled conditionally
    }
  )

  const {
    data: TangleswapStakingContractInfo,
    isLoading: allStakingContractLoading,
    refetch: refetchAllContractInfo,
  } = useQuery(
    ["tangleswapStakingContractInfo", chainId],
    () => loadContractInfo(),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      refetchIntervalInBackground: true,
      staleTime: 1000 * 60 * 5, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!chainId, // Query is enabled conditionally
    }
  )

  return {
    TangleswapUserInfo,
    allStakingLoading,
    refetchAllInfo,
    TangleswapStakingContractInfo,
    allStakingContractLoading,
    refetchAllContractInfo,
    TangleswapFetchRewards,
    refetchRewards,
    allRewardsLoading,
  }
}
