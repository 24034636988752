import {
  useState,
  Fragment,
  FC,
  useEffect,
  useRef,
  RefObject,
  useContext,
} from "react"
import {
  ActionButton,
  Body,
  ButtonContainer,
  CloseIcon,
  CloseIconWrapper,
  CurrentStepWrapper,
  ModalTopBar,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  TopText,
  WrapContainer,
} from "../styles"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IWrapData } from "interfaces/wscSwap.interface"
import ModalTopIcon from "../component/ModalTopIcon"
import WSCModalCover from "../component/WSCModalCover"
import { useWSCContext } from "context/MilkomedaContext"
import WrapGas from "../transactionStepper/WrapGasStep"
import { REQUIRED_FEE_ADA } from "constants/milkomeda/transaction"

interface FinalizeModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IWrapData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  cancelTransaction?: () => void
}

const FinalizeModal: FC<FinalizeModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    wscModalRef,
    closeWSCContinue,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [needGasWrap, setNeedGasWrap] = useState<boolean>(false)

  const actionType = "IHub"
  const { destinationBalanceADA } = useWSCContext()

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData])

  useEffect(() => {
    const newSteps = []

    if (needGasWrap) {
      newSteps.push({
        number: 1,
        title: "Wrap",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            nextStep={nextStep}
            actionType={actionType}
            data={wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: needGasWrap ? 2 : 1,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          isBackup={false}
          toggleModal={toggleModal}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, needGasWrap])

  useEffect(() => {
    if (!destinationBalanceADA.data) return
    checkBalanceForGas()
  }, [destinationBalanceADA.data])

  const checkBalanceForGas = () => {
    if (REQUIRED_FEE_ADA > destinationBalanceADA.data) setNeedGasWrap(true)
  }

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Finalize Auction"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <TitleTextContainer>
          <TitleText>{steps[currentStep - 1]?.actionTitle}</TitleText>
        </TitleTextContainer>

        <StepWrapper>
          {steps.map((step, index) => (
            <Fragment key={step.number}>
              <StepContainer>
                <StepButton
                  active={currentStep === step.number}
                  onClick={() => {
                    if (currentStep === step.number) return
                    setCurrentStep(step.number)
                  }}
                >
                  {step.number}
                </StepButton>
                <StepTitle active={currentStep === step.number}>
                  {step.title}
                </StepTitle>
              </StepContainer>
              {index < steps.length - 1 && <StepDivider />}
            </Fragment>
          ))}
        </StepWrapper>
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useFinalizeModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default FinalizeModal
