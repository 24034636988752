import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Subtitle } from "styles/TextStyles"
import { GrClose } from "react-icons/gr"
import { SwapTokenButton } from "styles/ButtonStyles"
import { toSignificantDigits } from "utils/toSignificant"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface RowsProps {
  inputValue?: any
  inputSymbol?: any
  inputLogo?: any
  outputValue?: any
  outputSymbol?: any
  outputLogo?: any
  token0: string
  token1: string
  closeModal?: () => void
  handleSwap?: () => void
  lpModalRef?: any
  lpBodyClick?: (e: any) => void
  expectedAmount?: number | string
  expectedCurrency?: string
  price?: any
  amountIn: string
  showWrap: boolean
}
const ConfirmSwapModal: FC<RowsProps> = (props) => {
  const {
    closeModal,
    handleSwap,
    inputValue,
    inputSymbol,
    inputLogo,
    outputValue,
    outputSymbol,
    lpModalRef,
    outputLogo,
    expectedCurrency,
    expectedAmount,
    lpBodyClick,
    price,
    showWrap,
  } = props

  //calc variables

  return (
    <Body ref={lpModalRef} onClick={lpBodyClick}>
      <Cover>
        <LPNavigation>
          <IconText>Confirm Swap</IconText>

          <CloseIconWrapper onClick={closeModal}>
            {" "}
            <CloseIcon />
          </CloseIconWrapper>
        </LPNavigation>

        <InfoTierTop>
          {/* start of  box */}
          <LiquidityBox>
            <BoxBottom>
              <BowFirstRow>
                <LPBoxValue>
                  {toSignificantDigits(inputValue, 4, true, 18)}
                </LPBoxValue>
                <IconWrap>
                  {!!inputLogo ? (
                    <>
                      <BoxIcon src={inputLogo} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={inputSymbol} />
                      </CustomSizeWrapper>
                    </>
                  )}
                  <IconName>{inputSymbol}</IconName>
                </IconWrap>
              </BowFirstRow>

              <BowSecondRow>
                <LPBoxValue>
                  {toSignificantDigits(outputValue, 4, true, 18)}
                </LPBoxValue>
                <IconWrap>
                  {!!outputLogo ? (
                    <>
                      <BoxIcon src={outputLogo} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={outputSymbol} />
                      </CustomSizeWrapper>
                    </>
                  )}
                  <IconName>{outputSymbol}</IconName>
                </IconWrap>
              </BowSecondRow>
            </BoxBottom>
          </LiquidityBox>

          {/* end of  box */}
        </InfoTierTop>
        <PriceConversion>
          <LPBoxValueBold>Rate:</LPBoxValueBold>
          {showWrap ? (
            <LPBoxValue>
              {" "}
              1 {inputSymbol} = 1 {outputSymbol}
            </LPBoxValue>
          ) : (
            <LPBoxValue>
              {" "}
              1 {inputSymbol} = {toSignificantDigits(price, 4, true, 18)}{" "}
              {outputSymbol}
            </LPBoxValue>
          )}
        </PriceConversion>

        <CollectFeesWrapper>
          {!showWrap ? (
            <CollectFeesText>
              Output is estimated, you will receive at least{" "}
              <CollectTextBold>
                {" "}
                {isNaN(Number(expectedAmount)) || Number.isNaN(expectedAmount)
                  ? "-"
                  : toSignificantDigits(expectedAmount, 3, true, 18) + " "}
                {expectedCurrency}
                {""}{" "}
              </CollectTextBold>
              or the transaction will revert.
            </CollectFeesText>
          ) : null}

          <SwapButtons onClick={handleSwap}>
            <SwapButton>Confirm Swap</SwapButton>
          </SwapButtons>
        </CollectFeesWrapper>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 900;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
`
const Cover = styled.div`
  max-width: 418px;
  width: 100%;
  margin: 24px auto;
  border-radius: 24px;
  border: 1px solid ${TangleColors.lighthover};
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
`

const IconText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
`
const PriceConversion = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.lighthover};
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`

const BoxBottom = styled.div`
  height: 130px;
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const BowFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 8px 0;
`
const BowSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 6px 0 -1px 0;
`
const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const LPBoxValue = styled(Caption)``
const LPBoxValueBold = styled(Caption)`
  margin: 0 8px 0 0;
`
const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconName = styled(Caption)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 8px 0;
`

const SwapButton = styled(SwapTokenButton)``

const CollectFeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  margin: 32px 0 8px 0;
`
const CollectFeesText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  font-style: italic;
  line-height: 1.4 !important;
  margin: 4px 0;
`

const CollectTextBold = styled.b`
  color: ${TangleColors.white};
  font-style: bold;
  font-weight: 600 !important;
`
const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export default ConfirmSwapModal
