import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

interface ChainProps {
  networkLogo?: any
  networkLabel?: any
}
const NetworkExcludedChain: FC<ChainProps> = (props) => {
  const { networkLogo, networkLabel } = props
  return (
    <NetworkBody>
      <ChainCover>
        <ChainIcon src={networkLogo} alt={networkLabel} />
        <ChainName>{networkLabel}</ChainName>
      </ChainCover>
    </NetworkBody>
  )
}

const NetworkBody = styled.div<ChainProps>`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px auto;
`
const ChainCover = styled.div`
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px auto;
`
const ChainIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 999px;
`
const ChainName = styled(Subtitle)`
  color: ${TangleColors.black};
  width: 100%;
  margin: 0 0 0 8px;
`
export default NetworkExcludedChain
