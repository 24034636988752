import { motion } from "framer-motion"
import React, { FC, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { calcAmount, tickToPrice } from "@tangleswap/sdk"
import BottomRange from "./BottomRange"
import BottomTVLDisplay from "./BottomTVLDisplay"

import { useWSCContext } from "context/MilkomedaContext"
import StakedAction from "../../Actions/StakedAction"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { useQuery } from "react-query"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { useTangleship } from "utils/useTangleship"

interface BottomCardsProps {
  token0?: any
  token1?: any
  tvlMainValue?: number
  tvlQuoteValue?: number
  rangeFrom?: number
  rangeTo?: number
  nftId?: number
  // rewardsToken0?: TokenProps
  // rewardstoken1?: TokenProps
  formattedPrice?: number
  rewardsToken0?: any
  rewardstoken1?: any
  rewardsMainValue?: number
  rewardsQuoteValue?: number
  rewardTokens?: any
  tickUpper?: any
  tickLower?: any
  address?: string
  fee?: number
  liquidity?: any
  sqrtPriceX96?: number
  userData?: any

  token0Logo?: string
  token1Logo?: string
  token0Symbol?: string
  token1Symbol?: string
  farmAddress?: string
  status?: boolean
  isEnded?: boolean
  isFarmApproved?: any
}

const BottomPositionInfoRow: FC<BottomCardsProps> = (props) => {
  const {
    liquidity,
    token0,
    token1,
    tickUpper,
    tickLower,
    nftId,
    formattedPrice,
    sqrtPriceX96,
    farmAddress,
    status,
    isEnded,
    isFarmApproved,
  } = props

  const { tangleship } = useTangleship()
  const { chainId, l1ChainId } = useWSCContext()
  const [token0Amount, setToken0Amount] = useState<number>(0)
  const [token1Amount, settoken1Amount] = useState<number>(0)

  const { data: token0Info } = useQuery<TangleTokenProps, QueryError>(
    ["bottomToken0", token0, l1ChainId],
    () => fetchTangleCurrency(token0, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token0 && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: token1Info } = useQuery<TangleTokenProps, QueryError>(
    ["bottomToken1", token1, l1ChainId],
    () => fetchTangleCurrency(token1, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token1 && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const rangeFrom = tickToPrice(
    tickLower,
    token0Info?.decimals,
    token1Info?.decimals
  )
  const rangeTo = tickToPrice(
    tickUpper,
    token0Info?.decimals,
    token1Info?.decimals
  )
  // useEffect(() => {
  //   if (!rangeFrom || !rangeTo) return

  //   const poolToken0 =
  //     calcAmount(
  //       token0Info?.address,
  //       token1Info?.address,
  //       chainId,
  //       true,
  //       Number(liquidity),
  //       formattedPrice,
  //       rangeFrom,
  //       rangeTo,
  //       token0Info?.decimals,
  //       token1Info?.decimals
  //     ) /
  //     10 ** token0Info?.decimals

  //   const poolToken1 =
  //     calcAmount(
  //       token0Info?.address,
  //       token1Info?.address,
  //       chainId,
  //       false,
  //       Number(liquidity),
  //       formattedPrice,
  //       rangeFrom,
  //       rangeTo,
  //       token0Info?.decimals,
  //       token1Info?.decimals
  //     ) /
  //     10 ** token1Info?.decimals

  //   setToken0Amount(poolToken0)
  //   settoken1Amount(poolToken1)
  // }, [
  //   token0Info?.address,
  //   token0Info?.decimals,
  //   token1Info?.address,
  //   token1Info?.decimals,
  //   rangeTo,
  //   rangeFrom,
  //   liquidity,
  //   sqrtPriceX96,
  //   chainId,
  //   formattedPrice,
  // ])
  const poolTokens = useMemo(() => {
    if (
      !rangeFrom ||
      !rangeTo ||
      !token0Info?.decimals ||
      !token1Info?.decimals
    )
      return null

    const poolToken0 =
      calcAmount(
        token0Info?.address,
        token1Info?.address,
        chainId,
        true,
        Number(liquidity),
        formattedPrice,
        rangeFrom,
        rangeTo,
        token0Info?.decimals,
        token1Info?.decimals
      ) /
      10 ** token0Info?.decimals

    const poolToken1 =
      calcAmount(
        token0Info?.address,
        token1Info?.address,
        chainId,
        false,
        Number(liquidity),
        formattedPrice,
        rangeFrom,
        rangeTo,
        token0Info?.decimals,
        token1Info?.decimals
      ) /
      10 ** token1Info?.decimals

    return { poolToken0, poolToken1 }
  }, [
    token0Info?.address,
    token0Info?.decimals,
    token1Info?.address,
    token1Info?.decimals,
    rangeTo,
    rangeFrom,
    liquidity,
    sqrtPriceX96,
    chainId,
    formattedPrice,
  ])

  useEffect(() => {
    if (!poolTokens) return

    setToken0Amount(poolTokens.poolToken0)
    settoken1Amount(poolTokens.poolToken1)
  }, [poolTokens])

  return (
    <BottomBodyCoverRow>
      <BottomCoverRow>
        <FarmsCover>
          <BottomTVLDisplay
            token0Amount={token0Amount}
            token1Amount={token1Amount}
            token0Logo={token0Info?.logoURI}
            token1Logo={token1Info?.logoURI}
            token0Symbol={token0Info?.symbol}
            token1Symbol={token1Info?.symbol}
          />
          <BottomRange
            rangeNumber={nftId}
            farmAddress={farmAddress}
            lowerTick={rangeFrom}
            upperTick={rangeTo}
          />
        </FarmsCover>

        <FarmsActions>
          {isEnded && !status ? null : <Divider />}
          <StakedAction
            staked={status}
            isFarmApproved={isFarmApproved}
            isEnded={isEnded}
            address={farmAddress}
            tokenId={nftId}
            buttonsLoading={
              !token0Amount &&
              !token0Info?.symbol &&
              !token1Info?.symbol &&
              !token1Amount
            }
          />
        </FarmsActions>
      </BottomCoverRow>
    </BottomBodyCoverRow>
  )
}

const BottomBodyCoverRow = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  grid-gap: 8px;

  position: relative;

  padding: 4px 16px;
  margin: 6px 0;

  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;
`

const BottomCoverRow = styled(motion.div)`
  width: 100%;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  grid-gap: 12px;

  position: relative;

  padding: 20px;
  margin: 6px 0;
  color: ${TangleColors.white};
  border-bottom: 1px solid ${TangleColors.grayLight};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;
  :nth-last-child(1) {
    border-bottom: none;
  }
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const FarmsActions = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  max-width: 260px;

  @media only screen and (max-width: 850px) {
    max-width: 850px;
    margin: 12px 0;
  }
  /* @media only screen and (max-width: 850px) {
    grid-area: farmingstate;
    width: 100%;
    max-width: 900px;
  } */
`

const FarmsCover = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  margin: 0 64px 0 0;
  @media only screen and (max-width: 850px) {
    margin: 8px 0;
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    align-items: flex-start;
    justify-content: center;
  }
`

const Container = styled(motion.div)`
  width: 100%;
  max-width: 250px;
  /* @media only screen and (max-width: 850px) {
    grid-area: range;
    width: 100%;
    max-width: 900px;
    flex-basis: 1;
  } */
  @media only screen and (max-width: 850px) {
    max-width: 850px;
    margin: 8px 0;
  }
`

const Divider = styled(motion.hr)`
  width: 1px;
  height: 48px;
  background: #444;
  margin: 0 0.5em 0 -32px;
  @media (max-width: 1024px) {
    display: none;
  }
`

export default BottomPositionInfoRow
