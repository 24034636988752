import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, Subtitle } from "styles/TextStyles"
import { shortenWSCAddress } from "utils"

interface TransactionProps {
  text?: string
  subText?: any
  link?: any
  hash?: string
}

const TransactionHrefRow: FC<TransactionProps> = (props) => {
  const { text, link, hash } = props
  const href = `${link}${hash}`

  const openExternalLink = (url, target = "_blank") => {
    window.open(href, target)
  }
  return (
    <>
      <TransactionInfoCover>
        <TransactionTitle>{text}</TransactionTitle>:
        <TransactionDetailCover>
          <TransactionText>
            <TransactionHref
              onClick={openExternalLink}
              href={`${link}${hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BottomText>{shortenWSCAddress(hash)}</BottomText>
            </TransactionHref>
          </TransactionText>
        </TransactionDetailCover>
      </TransactionInfoCover>
    </>
  )
}
const TransactionInfoCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  color: ${TangleColors.white};
`
const TransactionDetailCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 0 12px;
  color: ${TangleColors.white};
`
const TransactionHref = styled.a`
  color: ${TangleColors.lighthover};
  width: 100%;
  cursor: pointer;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
  }
`
const TransactionTitle = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  margin: 0 4px 0 0;
`
const TransactionText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 0 4px;

  width: 100%;
  padding: 8px;
`

const BottomText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  width: 100%;
  ${TransactionText}:hover & {
    color: ${TangleColors.white};
  }
`
export default TransactionHrefRow
