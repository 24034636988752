import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { useWSCContext } from "context/MilkomedaContext"
import {
  populateMarketData,
  HIGHLIGHTED_AUCTIONS,
} from "utils/calls/investmentHub"
import { AuctionStatus, MarketProps } from "./types"
import AuctionCard from "./AuctionCard"
import { CaptionSmall, Header6Gothic, Subtitle } from "styles/TextStyles"
import { BsPlusLg } from "react-icons/bs"
import LoadingInvestmentState from "./LoadingInvestmentState"

import AuctionEmptyState from "./AuctionEmptyState"

import useAuctionArray from "utils/useAuctionArray"
import { CreateAuctionButton } from "styles/ButtonStyles"
import { Link } from "react-router-dom"
import { Chains } from "@tangleswap/sdk"

interface BodyProps {
  active?: boolean
  textColor?: string
  disabled?: boolean
}

const LaunchBody: FC<BodyProps> = (props) => {
  const [markets, setMarkets] = useState<any>([])
  const [filteredMarkets, setFilteredMarkets] = useState<any>([])
  const [highlightMarkets, setHighlightMarkets] = useState<any>([])
  const [activeFilter, setActiveFilter] = useState<"live" | "ended">("live")
  const [timeRemaining, setTimeRemaining] = useState<any>(undefined)

  const { chainId, l1ChainId } = useWSCContext()
  const { auctionsLoading, allTangleAuctions } = useAuctionArray()

  const showFiltered = async (marketsInfo: any) => {
    if (!marketsInfo || !marketsInfo.length || !activeFilter) return
    const marketData = marketsInfo
      ?.filter((data) => {
        if (activeFilter === "live") {
          return (
            AuctionStatus[data?.auctionStatus] === "LIVE" ||
            AuctionStatus[data?.auctionStatus] === "UPCOMING"
          )
        } else {
          return AuctionStatus[data?.auctionStatus] === "FINISHED"
        }
      })
      .sort((a, b) => {
        // If sorting by "live" and "upcoming"
        if (activeFilter === "live") {
          if (
            AuctionStatus[a?.auctionStatus] === "LIVE" &&
            AuctionStatus[b?.auctionStatus] === "UPCOMING"
          ) {
            return -1
          }
          if (
            AuctionStatus[a?.auctionStatus] === "UPCOMING" &&
            AuctionStatus[b?.auctionStatus] === "LIVE"
          ) {
            return 1
          }
          return Number(a?.endTime) - Number(b?.endTime)
        } else {
          // For "finished" status, sort by most recent ending time
          return Number(b?.endTime) - Number(a?.endTime)
        }
      })
    if (activeFilter !== "live") {
      setHighlightMarkets([])
      setFilteredMarkets(marketData)
      return
    }
    const highlightData = marketData?.filter((data) => {
      return HIGHLIGHTED_AUCTIONS.includes(data?.auctionAddress)
    })
    const openMarketData = marketData?.filter((data) => {
      return !HIGHLIGHTED_AUCTIONS.includes(data?.auctionAddress)
    })

    return { highlightData, openMarketData }
  }

  const transitionMarketStatus = (auctionStatus) => {
    return auctionStatus === AuctionStatus.UPCOMING
      ? AuctionStatus.LIVE
      : AuctionStatus.FINISHED
  }

  useEffect(() => {
    if (!allTangleAuctions || !allTangleAuctions?.length) return

    const res = populateMarketData(allTangleAuctions)
    if (!res) return

    // Handle state transitions (UPCOMING > LIVE > FINISHED) due to time
    if (
      timeRemaining !== undefined &&
      timeRemaining === 0 &&
      res?.auctionStatus
    )
      res.auctionStatus = transitionMarketStatus(res.auctionStatus)

    // Handle state transitions (LIVE > FINISHED) due to auction sold out
    if (
      res?.tokensRemaining !== undefined &&
      res?.tokensRemaining === 0 &&
      res?.auctionStatus === AuctionStatus.LIVE
    )
      res.auctionStatus = transitionMarketStatus(res.auctionStatus)

    setMarkets(res)
  }, [allTangleAuctions])

  useEffect(() => {
    if (!markets || !markets.length || !activeFilter) return
    showFiltered(markets).then((res) => {
      if (!res) return
      setHighlightMarkets(res?.highlightData)
      setFilteredMarkets(res?.openMarketData)
    })
  }, [markets, activeFilter])

  const handleTimeRemainingChange = (newTimeRemaining) => {
    setTimeRemaining(newTimeRemaining)
  }

  return (
    <Body>
      <Cover>
        <>
          <ActionsRow>
            <AuctionCover>
              <Counter>
                <>{filteredMarkets?.length} Results</>
              </Counter>
              <Actions>
                <FilterItem
                  active={activeFilter === "live"}
                  onClick={() => setActiveFilter("live")}
                >
                  <FilterItemText> Live & Upcoming</FilterItemText>
                </FilterItem>
                <FilterItem
                  active={activeFilter === "ended"}
                  onClick={() => setActiveFilter("ended")}
                >
                  <FilterItemText>Finished</FilterItemText>
                </FilterItem>
              </Actions>
            </AuctionCover>{" "}
            <>
              {![
                Chains.L1_CARDANO,
                Chains.L1_CARDANO_TEST,
                Chains.CARDANO,
                Chains.CARDANO_TEST,
              ].includes(l1ChainId) ? (
                <>
                  {" "}
                  <Link to="/invest/add">
                    <CreateNewButton>
                      <CreateNewButtonIcon />
                      Create New
                    </CreateNewButton>
                  </Link>
                </>
              ) : null}
            </>
          </ActionsRow>
        </>

        {!allTangleAuctions && auctionsLoading ? (
          <>
            <MarketWrapper>
              <LoadingInvestmentState />
            </MarketWrapper>
          </>
        ) : (
          <>
            {allTangleAuctions && filteredMarkets?.length > 0 ? (
              <>
                <MarketWrapper
                  disabled={
                    !!markets &&
                    !!filteredMarkets &&
                    !!highlightMarkets &&
                    highlightMarkets.length < 1
                  }
                >
                  <HeadlineRow
                    disabled={
                      !!markets &&
                      !!filteredMarkets &&
                      !!highlightMarkets &&
                      highlightMarkets.length < 1
                    }
                  >
                    <Headline>HIGHLIGHTS</Headline>
                  </HeadlineRow>
                  <AuctionGrid>
                    {!!markets &&
                      !!filteredMarkets &&
                      !!highlightMarkets &&
                      highlightMarkets?.map(
                        (data: MarketProps, index: number) => {
                          return (
                            <AuctionCard
                              key={index}
                              address={data?.auctionAddress}
                              auctionToken={data?.auctionToken}
                              currentProgress={data?.currentProgress}
                              totalCommitments={data?.totalCommitments}
                              category={data?.category}
                              logo={data?.documents?.logo}
                              auctionType={data?.auctionType}
                              auctionStatus={data?.auctionStatus}
                              currentPrice={data?.currentTokenPrice}
                              auctionPrice={data?.currentAuctionPrice}
                              isFinalized={data?.isFinalized}
                              isSuccessful={data?.isSuccessful}
                              amountForSale={data?.tokensForSale}
                              paymentToken={data?.paymentToken}
                              tokensRemaining={data?.tokensRemaining}
                              endTime={data?.endTime}
                              startTime={data?.startTime}
                              documentLogo={data?.documents?.logo}
                              isPurplelistEnabledStatus={
                                data?.isPurplelistEnabled
                              }
                              handleTimeRemainingChange={
                                handleTimeRemainingChange
                              }
                            />
                          )
                        }
                      )}
                  </AuctionGrid>
                </MarketWrapper>{" "}
                <Divider />
                <MarketWrapper>
                  {" "}
                  <HeadlineRow
                    disabled={
                      !!markets &&
                      !!filteredMarkets &&
                      !!highlightMarkets &&
                      highlightMarkets.length < 1
                    }
                  >
                    <Headline>OPEN MARKET</Headline>
                  </HeadlineRow>
                  <AuctionGrid>
                    {!!markets &&
                      !!filteredMarkets &&
                      filteredMarkets?.map(
                        (data: MarketProps, index: number) => {
                          return (
                            <AuctionCard
                              key={index}
                              address={data?.auctionAddress}
                              isPurplelistEnabledStatus={
                                data?.isPurplelistEnabled
                              }
                              auctionToken={data?.auctionToken}
                              currentProgress={data?.currentProgress}
                              totalCommitments={data?.totalCommitments}
                              category={data?.category}
                              logo={data?.documents?.logo}
                              auctionType={data?.auctionType}
                              auctionStatus={data?.auctionStatus}
                              currentPrice={data?.currentTokenPrice}
                              isSuccessful={data?.isSuccessful}
                              auctionPrice={data?.currentAuctionPrice}
                              isFinalized={data?.isFinalized}
                              amountForSale={data?.tokensForSale}
                              paymentToken={data?.paymentToken}
                              tokensRemaining={data?.tokensRemaining}
                              endTime={data?.endTime}
                              startTime={data?.startTime}
                              documentLogo={data?.documents?.logo}
                              handleTimeRemainingChange={
                                handleTimeRemainingChange
                              }
                            />
                          )
                        }
                      )}
                  </AuctionGrid>
                </MarketWrapper>
              </>
            ) : (
              <>
                <AuctionEmptyState />
              </>
            )}
          </>
        )}
      </Cover>
    </Body>
  )
}
const MarketWrapper = styled.div<BodyProps>`
  width: 100%;
  max-width: 1232px;
  margin: 24px auto;
  display: ${(props) => (props.disabled ? "none" : "flex")};
  flex-direction: column;
  position: relative;
`
const Body = styled.div`
  width: 100%;
  padding: 16px;
`

const Cover = styled.div`
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
`

const AuctionGrid = styled.div<BodyProps>`
  display: grid;
  max-width: 1232px;
  width: 100%;
  margin: 24px auto;
  background: ${TangleColors.swapBG};
  min-height: 550px;
  position: relative;
  border-radius: 18px;
  padding: 32px 16px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${TangleColors.lightPurple};
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 12px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 64px 0;
`

const Actions = styled.div`
  display: flex;
  color: ${TangleColors.white};
  gap: 4px;
  background: ${TangleColors.defi};
  padding: 6px;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 0 0 24px;
`

const Counter = styled(Subtitle)`
  color: ${TangleColors.lighterText};
`

const FilterItem = styled.div<BodyProps>`
  background: ${(props) =>
    props.active ? TangleColors.heroCTA : "transparent"};
  padding: 10px 18px;
  border-radius: 14px;
  cursor: pointer;
  color: ${TangleColors.white};
`
const FilterItemText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`

const HeadlineRow = styled.div<BodyProps>`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  margin: 48px auto;
  max-width: 600px;
  width: 100%;
`

const Headline = styled(Header6Gothic)<BodyProps>`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 32px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${TangleColors.darkText};
  margin: 32px 0;
`
const CreateNewButton = styled(CreateAuctionButton)``

const CreateNewButtonIcon = styled(BsPlusLg)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.black};
  margin: 0 8px 0 0;
  transition: 0.4s ease-in;

  ${CreateNewButton}:hover & {
    color: ${TangleColors.cta};
  }
`
const AuctionCover = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export default LaunchBody
