import React, { FC, useContext } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
import TransactionDetails from "./TransactionDetails"
import { useWSCContext } from "context/MilkomedaContext"

import EmptyOverviewState from "./EmptyOverviewState"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { PendingWSCDataProps } from "../utils/types"
import { CancelTransactionButton } from "styles/ButtonStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  openWSCProgressModal,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { toast as TangleToast } from "sonner"
import { CancelPendingContext } from "context/CancelModalContext"
import { PendingWscTxStepDataProps } from "components/milkomeda/types"
interface WalletAboutProps {
  active?: boolean
}
const OverviewPendingTransactions: FC<WalletAboutProps> = (props) => {
  const { active } = props
  const { pendingWSCTxs } = useWSCContext()
  const dispatch = useAppDispatch()
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    setOpenModalSettings(false)
  }
  const pendingWscTxStep: PendingWscTxStepDataProps = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )
  return (
    <StepperInfoCover active={active}>
      <StepperTopRow>
        {" "}
        <StepperRowTitle>
          Pending transactions between Cardano L1 {"<>"} L2
        </StepperRowTitle>{" "}
        {pendingWSCTxs?.length > 0 ? (
          <PendingCoverRow>
            <CardanoPendingCounter>
              <TextCounterRow>
                <CounterText>
                  <span>{pendingWSCTxs?.length}</span>
                  <span> pending {""} </span>
                  <span>
                    {" "}
                    {pendingWSCTxs?.length > 1
                      ? "transactions"
                      : "transactions"}
                  </span>
                </CounterText>
              </TextCounterRow>
              <CounterSpinnerWrapper>
                <SpinnerCombined
                  bigRadius={16}
                  smallRadius={10}
                  height={20}
                  width={20}
                />
              </CounterSpinnerWrapper>
            </CardanoPendingCounter>
            <CancelCoverTransaction>
              <CancelButtonTransaction onClick={cancelTransaction}>
                Cancel transaction
              </CancelButtonTransaction>
            </CancelCoverTransaction>
          </PendingCoverRow>
        ) : null}
        <StepperTopRow>
          {pendingWSCTxs?.length > 0 ? (
            <>
              {pendingWSCTxs?.map((data: PendingWSCDataProps, index) => (
                <StepperInfoRow key={index}>
                  <TransactionDetails
                    hash={data?.hash}
                    timestamp={data?.timestamp}
                    type={data?.type}
                    completed={data?.completed}
                  />
                </StepperInfoRow>
              ))}
            </>
          ) : (
            <>
              <EmptyOverviewState title="No pending transactions found." />
            </>
          )}{" "}
        </StepperTopRow>
      </StepperTopRow>
    </StepperInfoCover>
  )
}
const StepperInfoCover = styled.div<WalletAboutProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  padding: 12px 24px;
`
const StepperTopRow = styled.div`
  display: flex;
  width: 100%;
  margin: 12px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepperInfoRow = styled.div`
  width: 100%;
`
const StepperRowTitle = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12.5px auto 6px auto;
  max-width: 280px;
  text-align: center;
  width: 100%;
`
const CardanoPendingCounter = styled.div`
  display: flex;
  margin: 12px 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 10px 0 0 12px;
  text-align: left;
  align-self: flex-start;
  justify-self: flex-start;
`
const PendingCoverRow = styled.div`
  display: flex;
  margin: 12px 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const TextCounterRow = styled.div`
  display: flex;
  text-align: left;
  margin: 0 10px 0 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
const CounterSpinnerWrapper = styled.div`
  display: flex;
  margin: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const CounterText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 6px 0 0;
  text-align: left;
  width: 100%;
  span {
    margin: 0 6px 0 0;
  }
`
const CancelCoverTransaction = styled.div`
  max-width: 170px;
  width: 100%;
`
const CancelButtonTransaction = styled(CancelTransactionButton)`
  width: 100%;
`

export default OverviewPendingTransactions
