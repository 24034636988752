import {
  Chains,
  FIXED_RANGE_FARMS,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  ZERO_ADDRESS,
} from "@tangleswap/sdk"
import axios from "axios"
import { useQuery } from "react-query"
import { useTangleship } from "./useTangleship"
import { PoolsProps, QueryError } from "components/swap/types"

async function manualGetPositionsArray(
  account: string,
  chainId: number,
  tangleship?: any
) {
  const posBalance = await tangleship?.checkNFTsOwnedFromCollection(
    account,
    NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]
  )
  const farmIndexes = await getPosMappedToFarms(account, chainId, tangleship)
  const farmLPindexes = farmIndexes.posArray

  let freeLPindexes = []
  const totalPositions = Number(posBalance._hex) // + farmLPindexes.length
  const positionsIterator = Array.from({ length: totalPositions }, (_, i) => i)
  await Promise.all(
    positionsIterator.map(async (pos) => {
      const tokenId = await tangleship?.getTokensId(account, pos)
      freeLPindexes.push(Number(tokenId?._hex))
    })
  )
  const LPindexes = freeLPindexes.concat(farmLPindexes).sort((a, b) => a - b)
  const allUserPos = []

  await Promise.all(
    LPindexes.map(async (tokenId) => {
      await tangleship?.getPosition(tokenId).then((res) => {
        if (!res) return

        const stakedInFarm = findFarmByTokenId(farmIndexes.posObject, tokenId)

        allUserPos.push({
          blockNumber: tokenId * 1e10,
          chainId,
          feeTier: String(res.fee),
          id: String(tokenId),
          liquidity: String(Number(res.liquidity._hex)),
          origin: account,
          owner: stakedInFarm || account,
          tickLower: String(res.tickLower),
          tickUpper: String(res.tickUpper),
          timestamp: tokenId * 1e9,
          token0Address: res.token0,
          token1Address: res.token1,
          userWalletAddress: account,
        })
      })
    })
  )

  return allUserPos
}

let newTokenId: number | undefined
export function setNewTokenId(value: number | undefined) {
  newTokenId = value
}

export async function fetchTangleArray(
  account: string,
  chainId: number,
  tangleship?: any
) {
  if (!chainId || !account) return

  // if (!Chains[chainId].endsWith("_TEST")) { // TODO: change to this once we will have migrated the Cardano Subgraph from testnet to mainnet
  // if ([Chains.SHIMMER, Chains.CARDANO_TEST].includes(chainId)) {
  if ([Chains.SHIMMER].includes(chainId)) {
    const apiUrl = `https://backend.tangleswap.space/api/v1/chains/${chainId}/wallet/${account}/positions`
    const fetchAllUserPools = await axios.get(apiUrl)
    const positionsData = fetchAllUserPools?.data

    if (positionsData && Array.isArray(positionsData)) {
      // TODO: ideally, later on have Tangular's API return the updated `liquidity` and `owner` fields
      const updatedPos = await tangleship?.updatePositionsWithSubgraph(
        positionsData
      )
      const filteredPos = updatedPos.filter((pos) => pos.owner !== ZERO_ADDRESS) // filter burned positions

      if (newTokenId && !filteredPos.some((p) => p.id === String(newTokenId))) {
        const newPos = await getSinglePos(chainId, tangleship, account)
        filteredPos.unshift(newPos)
      }

      return filteredPos
    }
  }

  return manualGetPositionsArray(account, chainId, tangleship)
}

const findFarmByTokenId = (posObject, tokenId) => {
  for (const [farm, positions] of Object.entries(posObject)) {
    if ((positions as any).includes(Number(tokenId))) return farm
  }
  return null
}

export async function getSinglePos(
  chainId: number,
  tangleship: any,
  account?: string,
  existingTokenId?: number
) {
  if (account && !newTokenId) return

  const res = await tangleship?.getPosition(newTokenId || existingTokenId)
  if (!res) return

  return {
    blockNumber: 10e10,
    chainId,
    feeTier: String(res.fee),
    id: String(newTokenId),
    liquidity: String(Number(res.liquidity._hex)),
    origin: account || ZERO_ADDRESS,
    owner: account || ZERO_ADDRESS,
    tickLower: String(res.tickLower),
    tickUpper: String(res.tickUpper),
    timestamp: newTokenId * 10e9,
    token0Address: res.token0,
    token1Address: res.token1,
    userWalletAddress: account || ZERO_ADDRESS,
  }
}

const getPosMappedToFarms = async (userAddress, chainId, tangleship) => {
  const farmAddresses = FIXED_RANGE_FARMS[chainId]
  const promises = farmAddresses.map((farmAddress) =>
    tangleship?.getFarmTokenIds(farmAddress, userAddress)
  )
  const results = await Promise.all(promises)

  const mappedPositions = Object.fromEntries(
    results.map((farmTokens, i) => {
      return [
        farmAddresses[i],
        farmTokens.map((tokenId) => Number(tokenId._hex)),
      ]
    })
  )
  const posArray = results.flat().map((tokenId) => Number(tokenId._hex))
  const posObject = Object.entries(mappedPositions)
    .filter(([_, value]) => (value as any).length > 0)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

  return { posArray, posObject }
}

export default function useTangleArray(
  account: string,
  chainId: Chains
): {
  TangleswapUserPools: any
  allPoolsLoading: any
  refetchAllPool?: any
} {
  const { tangleship } = useTangleship()
  const {
    data: TangleswapUserPools,
    isLoading: allPoolsLoading,
    refetch: refetchAllUserPool,
  } = useQuery<PoolsProps[], QueryError>(
    ["tangleswapAllUserPools", account, chainId],
    () => fetchTangleArray(account, Number(chainId), tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes
      refetchIntervalInBackground: true,
      staleTime: 1000 * 60 * 5, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!account && !!chainId, // Query is enabled conditionally
    }
  )

  return {
    TangleswapUserPools,
    allPoolsLoading,
    refetchAllPool: refetchAllUserPool,
  }
}
