import HideOnShimmer from "components/LaunchPad/HideOnShimmer"
import styled from "styled-components"
import { useEffect } from "react"
import StakingContainer from "components/staking/StakingContainer"
import { useWSCContext } from "context/MilkomedaContext"
import { Chains } from "@tangleswap/sdk"
import ComingSoon from "components/utils/ComingSoon"

const Staking = () => {
  useEffect(() => {
    document.title = "Void Energy Staking"
  }, [])
  const { chainId } = useWSCContext()
  return (
    <>
      <Body>
        <HideOnShimmer show={Chains.SHIMMER === chainId} />

        {Chains.SHIMMER === chainId ? (
          <>
            <ComingSoon />
          </>
        ) : (
          <>
            {/* <ComingSoon /> */}
            <StakingContainer />
          </>
        )}
      </Body>
    </>
  )
}
const Body = styled.div`
  /* min-height: 100vh; */
`

export default Staking
