import PositionsLoadingPlaceholder from "components/Liquidity/component/PositionsLoadingPlaceholder"
import { useWSCContext } from "context/MilkomedaContext"
import LPIcon from "components/icons/LPIcon"
import LPHoverBox from "components/utils/LPHoverBox"
import TangleToggle from "components/utils/Toggle"

import addChainToMetamask from "connect/switchChain"
import React, { useEffect, useRef, useState } from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { BsPlusLg } from "react-icons/bs"
import { GoLightBulb } from "react-icons/go"
import { Link } from "react-router-dom"

import styled, { keyframes } from "styled-components"
import {
  ChainChangeButton,
  ExploreButton,
  PositionButton,
  SwapConnectButton,
} from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Body2, Caption, Header5, Header5Gothic } from "styles/TextStyles"
import { PositionRowData } from "./position"

import {
  updatePositionStatusMode,
  updateTradingProMode,
} from "store/actions/proActions"
import { useAppDispatch, useAppSelector } from "store/hooks"
import LearnMore from "../component/LearnMore"
import LiquidityRows from "../LiquidityRows/LiquidityRows"

import { useIsSupportedNetwork } from "constants/hooks"

import { Chains } from "@tangleswap/sdk"
import useTangleArray from "utils/useTangleArray"
import { PoolsProps } from "components/swap/types"
import ConnectWalletButton from "components/utils/ConnectWalletButton"

interface LiquidityProps {
  marginFix?: any
  showLoader?: boolean
}
const LiquidContainer = () => {
  const { account, chainId } = useWSCContext()
  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  const tanglePositionStatusMode = useAppSelector(
    (state) => state.tradingMode.tanglePositionMode
  )

  const dispatch = useAppDispatch()

  const [showHelpText, setShowHelpText] = useState<boolean>(false)

  const infoRef = useRef<any>()
  const textRef = useRef<any>()

  const toggleOffExpertMode = (e: any) => {
    e.preventDefault()
    dispatch(updateTradingProMode(false))
  }

  const toggleOnExpertMode = (e: any) => {
    e.preventDefault()
    dispatch(updateTradingProMode(true))
  }

  const { TangleswapUserPools, allPoolsLoading, refetchAllPool } =
    useTangleArray(account, chainId)

  const changePositionStatusToOn = (e?: any) => {
    e.preventDefault()

    dispatch(updatePositionStatusMode(Boolean(true)))
  }
  const changePositionStatusTooff = (e?: any) => {
    e.preventDefault()

    dispatch(updatePositionStatusMode(Boolean(false)))
  }

  const mouseLPEnter = (e: any) => {
    setShowHelpText(true)
  }
  const onMouseEnter = (e: any) => {
    setShowHelpText(true)
  }

  const mouseLPLeave = (e: any) => {
    if (infoRef.current !== e.target && textRef.current !== e.target) {
      setShowHelpText(false)
    }
  }
  const onMouseLeave = (e: any) => {
    setShowHelpText(false)
  }
  useEffect(() => {
    const closeHoverBox = (e: any) => {
      if (infoRef.current !== e?.target && textRef.current !== e.target) {
        setShowHelpText(false)
      }
    }
    document.body.addEventListener("mousedown", closeHoverBox)
    return () => {
      document.body.removeEventListener("mousedown", closeHoverBox)
    }
  }, [])

  const [openPositions, closedPositions] = TangleswapUserPools?.sort(
    (a, b) => b.id - a.id
  )?.reduce(
    (acc: any, p: any) => {
      acc[parseInt(p?.liquidity) === parseInt("0") ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  useEffect(() => {
    if (!TangleswapUserPools) return

    let needRefetch = false
    Object.keys(sessionStorage).forEach((key) => {
      if (key.startsWith("liquidityIDchange")) {
        needRefetch = true
        sessionStorage.removeItem(key)
      }
    })
    if (needRefetch) refetchAllPool()
  }, [])

  const filteredPositions = [
    ...openPositions,
    ...(Boolean(tanglePositionStatusMode) ? closedPositions : []),
  ]

  const isSupportedNetwork = useIsSupportedNetwork()
  return (
    <>
      <MainBody marginFix={!!account}>
        {" "}
        <Cover>
          <LPTop>
            <DesktopLPPositions>
              <ExploreHref
                href="https://info.tangleswap.exchange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LPExploreButton>
                  <LPBulbIcon />
                  Explore All Pools & Tokens
                </LPExploreButton>
              </ExploreHref>
            </DesktopLPPositions>
            <PositionsHeaderMiddle>
              {!!account && !!isSupportedNetwork && TangleswapUserPools ? (
                <>
                  {" "}
                  <MiddleRow>
                    <LiquidText>
                      Your Pools:
                      {TangleswapUserPools ? (
                        <>
                          <PositionsTextBody>
                            {filteredPositions && filteredPositions?.length}{" "}
                            positions
                          </PositionsTextBody>
                        </>
                      ) : null}
                    </LiquidText>
                  </MiddleRow>
                </>
              ) : (
                <MiddleRow>
                  <LoaderCover
                    showLoader={
                      !!account && !!isSupportedNetwork && TangleswapUserPools
                    }
                  >
                    <PositionsLoadingPlaceholder />
                  </LoaderCover>
                </MiddleRow>
              )}
            </PositionsHeaderMiddle>
            <MobileLPPositions>
              <ExploreHref
                href="https://info.tangleswap.exchange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LPExploreMobileButton>
                  <LPBulbIcon />
                  Explore All Pools & Tokens
                </LPExploreMobileButton>
              </ExploreHref>
            </MobileLPPositions>
            <HeaderButtonEnd>
              <Link to="/pools/add">
                <LPPositionButton>
                  <LPPositionIcon />
                  New Position
                </LPPositionButton>
              </Link>
            </HeaderButtonEnd>
          </LPTop>

          <LPBody>
            <LPCover>
              {account && isSupportedNetwork ? (
                <>
                  {allPoolsLoading ? (
                    <>
                      {allPoolsLoading &&
                        [1, 2, 3, 4].map((index) => (
                          <PositionsLoadingPlaceholder key={index} />
                        ))}
                    </>
                  ) : (
                    <>
                      <LiquidTop>
                        <LPModeCover>
                          <LPModeStatus>
                            <ModeText>
                              Pro Mode
                              <IconWrapper
                                ref={infoRef}
                                onMouseEnter={mouseLPEnter}
                                onMouseLeave={mouseLPLeave}
                              >
                                <LPHelpIcon />
                              </IconWrapper>
                            </ModeText>
                            <LPHoverBox
                              onMouseEnter={onMouseEnter}
                              onMouseLeave={onMouseLeave}
                              showHelpText={showHelpText}
                              textRef={textRef}
                              text="Switch between Lite and Pro mode. To learn more,"
                              linktext="please visit our docs."
                              link="https://docs.tangleswap.exchange/guides/step-by-step/providing-liquidity#lite-vs-pro"
                            />
                            <TangleToggle
                              isToggled={Boolean(userTradingMode)}
                              toggleOnButton={toggleOnExpertMode}
                              toggleOffButton={toggleOffExpertMode}
                            />
                          </LPModeStatus>
                        </LPModeCover>
                        <LPStatus>
                          <StatusToggle>Show Closed Positions</StatusToggle>
                          <TangleToggle
                            isToggled={Boolean(tanglePositionStatusMode)}
                            // toggleButton={onChangePositionStatus}
                            toggleOnButton={changePositionStatusToOn}
                            toggleOffButton={changePositionStatusTooff}
                          />
                        </LPStatus>
                      </LiquidTop>
                      {filteredPositions &&
                      closedPositions &&
                      filteredPositions?.length > 0 ? (
                        <>
                          {filteredPositions.map((data: PoolsProps, index) => (
                            <LiquidityRows
                              key={index}
                              poolsLoading={allPoolsLoading}
                              fee={data?.feeTier}
                              liquidity={data?.liquidity}
                              tokenId={data?.id}
                              token0={data?.token0Address}
                              token1={data?.token1Address}
                              tickLower={data?.tickLower}
                              tickUpper={data?.tickUpper}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {" "}
                          <>
                            <LPCover>
                              <LPIcon />
                              <LPTitle>
                                Your active liquidity positions will appear
                                here.
                              </LPTitle>
                            </LPCover>
                          </>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <LPCover>
                    <LPIcon />
                    <LPTitle>
                      Your active liquidity positions will appear here.
                    </LPTitle>
                    {account && !isSupportedNetwork ? (
                      <>
                        {" "}
                        <ChainButton
                          onClick={() => addChainToMetamask(Chains.SHIMMER)}
                        >
                          Switch to Shimmer
                        </ChainButton>
                      </>
                    ) : (
                      <>
                        {" "}
                        <ConnectWalletButton />
                      </>
                    )}
                  </LPCover>
                </>
              )}
            </LPCover>
          </LPBody>
          <LearnMore />
        </Cover>
      </MainBody>
    </>
  )
}

const MainBody = styled.div<LiquidityProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1056px;
  flex-direction: column;
  margin: -11px auto -110px auto;
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 8px 16px;
`

const LPTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`
const LiquidText = styled(Header5Gothic)`
  display: flex;
  max-width: 100px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`
const MiddleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 520px;
  width: 100%;
  margin: 12px auto;

  color: transparent;
  text-transform: capitalize;
  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    #695c32 0%,
    #e1d9b2 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`

const LoaderCover = styled.div<LiquidityProps>`
  display: ${(props) => (props.showLoader ? `flex` : `none`)};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const MobileLPPositions = styled.div`
  max-width: 800px;
  width: 100%;

  margin: 12px auto 24px auto;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 850px) {
    width: 100%;

    display: flex;
  }
`

const PositionsTextBody = styled(Header5)`
  color: ${TangleColors.white};
  font-style: italic;
  margin: 0 0 0 4px;
`
const LPTitle = styled(Body2)`
  color: ${TangleColors.white};
  margin: 16px 0;
`
const LPPositionButton = styled(PositionButton)``
const LPBody = styled.div`
  max-width: 1056px;
  width: 100%;
  margin: 24px auto 24px auto;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  border-radius: 24px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${TangleColors.white};

  position: relative;
  z-index: 10;
`
const DesktopLPPositions = styled.div`
  max-width: 270px;
  width: 100%;
  position: relative;
  z-index: 3;
  white-space: nowrap;
  @media only screen and (max-width: 800px) {
    max-width: 800px;
    display: none;
  }
`
const HeaderButtonEnd = styled.div`
  max-width: 200px;
  width: 100%;
  position: relative;
  z-index: 3;
  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
`
const LPCover = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const LPButtons = styled(SwapConnectButton)``
const LPPositionIcon = styled(BsPlusLg)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.black};
  margin: 0 8px 0 0;
  transition: 0.4s ease-in;

  ${LPPositionButton}:hover & {
    color: ${TangleColors.cta};
  }
`
const glow = keyframes`
  0% {
    box-shadow: 0 0 0c.5px #ffffff, 0 0 2px #ffffff, 0 0 2px ${TangleColors.lighthover}, 0 0 2px ${TangleColors.lighthover}, 0 0 2px ${TangleColors.lighthover}, 0 0 3px ${TangleColors.lighthover}, 0 0 5px ${TangleColors.lighthover};
  }
  100% {
    box-shadow: 0 0 3px #ffffff, 0 0 4px #ffffff, 0 0 5px ${TangleColors.lighthover}, 0 0 6px ${TangleColors.lighthover}, 0 0 7px ${TangleColors.lighthover}, 0 0 8px ${TangleColors.lightPurple}, 0 0 9px ${TangleColors.lighthover}, 0 0 10px ${TangleColors.lighthover};
  }
`

const LPExploreButton = styled(ExploreButton)`
  display: flex;
  width: 100%;
  animation: ${glow} 2s infinite alternate;
  @media only screen and (max-width: 850px) {
    /* display: none; */
    max-width: 800px;
  }
`
const LPExploreMobileButton = styled(ExploreButton)`
  display: none;
  width: 100%;
  @media only screen and (max-width: 850px) {
    display: flex;
    max-width: 800px;
  }
`

const ExploreHref = styled.a`
  width: 100%;
  max-width: 220px;
  @media only screen and (max-width: 850px) {
    display: flex;
    max-width: 800px;
  }
`
const LPBulbIcon = styled(GoLightBulb)`
  height: 20px;
  width: 20px;
  color: ${TangleColors.white};
  margin: 0 10px 0 0;
  transition: 0.4s ease-in;

  ${LPExploreButton}:hover & {
    color: ${TangleColors.cta};
  }
`

const LiquidTop = styled.div`
  margin: 12px auto 32px auto;

  max-width: 1016px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
  }
`

const LPStatus = styled.div`
  width: 100%;
  display: flex;
  max-width: 240px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 650px) {
    max-width: 210px;
    white-space: nowrap;
  }
`
const StatusToggle = styled(Caption)`
  margin: 0 4px 0 0;
`

const LPModeCover = styled.div`
  width: 100%;
  display: flex;
  max-width: 240px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;

  @media only screen and (max-width: 650px) {
    max-width: 600px;
    margin: 20px 0;
  }
`
const LPModeStatus = styled.div`
  width: 100%;
  display: flex;
  max-width: 170px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  @media only screen and (max-width: 650px) {
    max-width: 160px;
    white-space: nowrap;
  }
`
const ModeText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`
const LPHelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
`

const IconWrapper = styled.div`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const ChainButton = styled(ChainChangeButton)``

const PositionsHeaderMiddle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
  max-width: 300px;
`
export default React.memo(LiquidContainer)
