import styled from "styled-components"

export const LargeHeader = styled.h1`
  font-family: "CabinetGrotesk Bold";
  font-size: 88px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 42px;
  }
`
export const Header1 = styled.h1`
  font-family: "CabinetGrotesk Bold";
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 38px;
  }
`

export const Header2 = styled.h2`
  font-family: "CabinetGrotesk Medium";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 32px;
  }
`

export const Header3 = styled.h3`
  font-family: "CabinetGrotesk Medium";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 28px;
  }
`
export const Header3Gothic = styled.h3`
  font-family: "Allroundgothic Medium";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;

  @media only screen and (max-width: 650px) {
    font-size: 28px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`
export const Header4 = styled.h3`
  font-family: "CabinetGrotesk Medium";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
  }
`
export const Header4Gothic = styled.h3`
  font-family: "Allroundgothic Medium";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;

  @media only screen and (max-width: 650px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 20px;
  }
`
export const Header5 = styled.h4`
  font-family: "CabinetGrotesk Medium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`

export const Header5Bold = styled.h4`
  font-family: "CabinetGrotesk Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`
export const Header5Gothic = styled.h4`
  font-family: "Allroundgothic Medium";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 17px;
  }
`
export const Header6 = styled.h3`
  font-family: "CabinetGrotesk Bold";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
  }
`
export const Header6Gothic = styled.h3`
  font-family: "Allroundgothic Medium";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
  }
`

export const Header6Light = styled.h3`
  font-family: "CabinetGrotesk Medium";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 24px;
  }
`
export const Header7 = styled.h4`
  font-family: "CabinetGrotesk Bold";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 18px;
  }
`
export const Subtitle = styled.h5`
  font-family: "CabinetGrotesk Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const SubtitleThick = styled.h5`
  font-family: "CabinetGrotesk Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const Caption = styled.h5`
  font-family: "CabinetGrotesk Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

export const CaptionMedium = styled.h5`
  font-family: "CabinetGrotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`
export const CaptionLink = styled.li`
  font-family: "CabinetGrotesk Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`
export const CaptionDMLink = styled.li`
  font-family: "DM Sans Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 1.1;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

export const CaptionSmall = styled.h5`
  font-family: "CabinetGrotesk Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
`

export const CaptionSmallRegular = styled.h5`
  font-family: "CabinetGrotesk Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
`
export const CaptionSmallPara = styled.p`
  font-family: "CabinetGrotesk Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
`
export const CaptionSubtitleLittle = styled.h5`
  font-family: "CabinetGrotesk Medium";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 11px;
  }
`
export const CaptionTinyBold = styled.h5`
  font-family: "CabinetGrotesk Bold";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 10px;
  }
`
export const CaptionTiny = styled.h5`
  font-family: "CabinetGrotesk Medium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 10px;
  }
`
export const CaptionLittle = styled.h6`
  font-family: "CabinetGrotesk Medium";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 6px;
  }
`

export const HeroBody = styled.p`
  font-family: "DM Sans Regular";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 550px) {
    font-size: 15px;
  }
`

export const Body1 = styled.p`
  font-family: "DM Sans Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const Body2 = styled.p`
  font-family: "DM Sans Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 550px) {
    font-size: 13px;
  }
`

export const Body3 = styled.p`
  font-family: "DM Sans Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 13px;
  }
`
export const Body4 = styled.p`
  font-family: "DM Sans Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 11px;
  }
`

export const BodyCaption = styled.p`
  font-family: "DM Sans Regular";
  font-size: 11px;
  font-weight: 400;
  line-height: 1.6;

  @media only screen and (max-width: 650px) {
    font-size: 10px;
  }
`

export const BubbleCaption = styled.h5`
  font-family: "Karla Medium";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;

  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

export const HeaderNavbar = styled.h3`
  font-family: "CabinetGrotesk Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;

  @media only screen and (max-width: 650px) {
    font-size: 20px;
  }
`
