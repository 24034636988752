import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
import WithdrawButton from "./components/WithdrawButton"
import StakeOverview from "./components/StakeOverview"
import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"
interface OverviewProps {
  voidValue?: number
  existingVoid?: number
  duration?: number
  existingDuration?: any
  startDate?: any
  isLockEnded?: boolean
  voidEnergyGenerated?: number
  newEstimatedRewards?: number
  toggleModalStatus?: () => void
  stakingLoading?:boolean
}
const StakingOverviewContainer: FC<OverviewProps> = (props) => {
  const {
    voidValue,
    existingVoid,
    duration,
    existingDuration,
    startDate,
    isLockEnded,
    voidEnergyGenerated,
    newEstimatedRewards,
    toggleModalStatus,
    stakingLoading,
  } = props
  return (
    <>
      {" "}
      <InfoColumn>
        {stakingLoading ? (
          <>
            <PositionsLoadingPlaceholder />
            <PositionsLoadingPlaceholder />
            <PositionsLoadingPlaceholder />
            <PositionsLoadingPlaceholder />
            <PositionsLoadingPlaceholder />
          </>
        ) : (
          <>
            {" "}
            <OverviewTitle>
              <DarkPurpleText>STAKE</DarkPurpleText> OVERVIEW
            </OverviewTitle>
            <StakeOverview
              total={voidValue > 0 ? voidValue ?? 0 : null}
              existingVoid={existingVoid > 0 ? existingVoid ?? 0 : null}
              duration={duration > 0 ? duration ?? 0 : null}
              existingDuration={
                existingDuration > 0 ? existingDuration ?? 0 : null
              }
              startDate={startDate ?? 0}
              unlock={isLockEnded ? 0 : duration > 0 ? duration ?? 0 : null}
              voidEnergyGenerated={
                voidEnergyGenerated > 0 ? voidEnergyGenerated ?? 0 : null
              }
              newEstimatedRewards={
                newEstimatedRewards > 0 ? newEstimatedRewards ?? 0 : null
              }
            />
            {isLockEnded && (
              <WithdrawButtonContainer>
                <WithdrawButton onClick={toggleModalStatus} />
              </WithdrawButtonContainer>
            )}
          </>
        )}
      </InfoColumn>
    </>
  )
}

const InfoColumn = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
const DarkPurpleText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
`
const OverviewTitle = styled.div`
  display: flex;
  color: ${TangleColors.white};
  gap: 4px;
  font-weight: 600;
`
const WithdrawButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 999px;
`
export default StakingOverviewContainer
