import styled from "styled-components"
import { motion } from "framer-motion"
import { HarvestButton } from "styles/ButtonStyles"
import { FC, useContext, useRef, useState } from "react"
import { useWSCContext } from "context/MilkomedaContext"

import HarvestFarmConfirmationModal from "components/confirmation/HarvestFarmConfirmationModal"
import LoadingDots from "components/utils/LoadingDots"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { checkUserFarmStatus } from "store/actions/FarmsTLPActions"
import { PendingStateContext } from "context/PendingContext"
import useFarmTangleArray from "utils/useFarmTangleArray"
import { toast as TangleToast } from "sonner"
import { useTangleship } from "utils/useTangleship"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import HarvestFarmModal, {
  useHarvestFarmModalControl,
} from "components/milkomeda/farm/HarvestFarmModal"
import { IFarmData } from "interfaces/wscSwap.interface"
import {
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
interface HarvestActionProps {
  harvestAvailable?: boolean
  id?: string
  farmAddress?: string | any
  isFixed?: any
  rewardToken0?: any
  rewardToken1?: any
}

const HarvestAction: FC<HarvestActionProps> = (props) => {
  const { harvestAvailable, farmAddress, id, rewardToken0, rewardToken1 } =
    props
  const { isWSCConnected } = useWSCContext()

  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }
  const [harvestApproved, setHarvestApproved] = useState<boolean>(false)

  const [harvestFarmTx, setHarvestFarmTx] = useState<any>(undefined)
  const [harvestTxPendModal, setHarvestTxPendModal] = useState<boolean>(false)
  const [harvestPending, setHarvestPending] = useState<boolean>(false)
  const [harvestLoading, setHarvestLoading] = useState<boolean>(false)

  const [modalTxError, setModalTxError] = useState<boolean>(false)

  const { tangleship } = useTangleship()
  const { setPendingTransaction } = useContext(PendingStateContext)
  const dispatch = useAppDispatch()
  const harvestModalRef = useRef<any>(null)
  const { refetchTangleFarms, refetchFarmPools } = useFarmTangleArray()

  const prepHarvestFarmFn = () => {
    const fn = tangleship?.harvestFarm
    const fnParams = [farmAddress] as const

    const fnFeedback = (params?) => {
      refetchTangleFarms()
      refetchFarmPools()
    }

    return { fn, fnParams, fnFeedback }
  }

  const handleHarvest = () => {
    setHarvestTxPendModal(true)
    setHarvestPending(true)
    setHarvestLoading(true)
    setModalTxError(false)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback } = prepHarvestFarmFn()

    fn?.(...fnParams)?.then((res) => {
      if (res?.tx !== null) {
        res.tx?.wait().then((receipt) => {
          fnFeedback()

          setHarvestApproved(true)
          setModalTxError(false)
          setHarvestPending(false)
          setHarvestLoading(false)
          setHarvestFarmTx(res?.hash)
          setHarvestTxPendModal(true)
          setPendingTransaction(false)

          TangleToast.success("Transaction successful", {
            description: `Harvest successful`,
          })
        })
      } else {
        setHarvestPending(false)
        setHarvestApproved(false)
        setModalTxError(true)
        dispatch(checkUserFarmStatus(false))
        setHarvestPending(true)
        setHarvestLoading(false)
        setHarvestFarmTx(res?.hash)
        setHarvestTxPendModal(true)
        setPendingTransaction(false)

        TangleToast.error("Transaction reverted!", {
          description: `User rejected transaction`,
        })
      }
    })
  }
  const closeharvestPendingModal = () => {
    setHarvestTxPendModal(false)
  }
  const closeharvestConfirmationModal = () => {
    setHarvestTxPendModal(false)
  }

  const closeharvestModal = (event?: any) => {
    if (harvestModalRef.current === event?.target) {
      setHarvestTxPendModal(false)
    }
  }

  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const [wrapData, setWrapData] = useState<IFarmData>(undefined)
  const {
    isVisible: isHarvestFarmWSCModalVisible,
    toggle: toggleHarvestFarmWSCModal,
  } = useHarvestFarmModalControl()

  const isWSCReady = () => isWSCConnected

  const fireHarvestFarmWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepHarvestFarmFn()
    if (!fn) return

    const outTokens = []

    if (rewardToken0) {
      const token = tangleswapTokenListOnChain.find(
        (token) => token.address === rewardToken0
      )
      outTokens.push(token)
    }

    if (rewardToken1) {
      const token = tangleswapTokenListOnChain.find(
        (token) => token.address === rewardToken1
      )
      outTokens.push(token)
    }

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        farmAddress: farmAddress,
        tokenId: Number(id),
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
        tokenOut: outTokens,
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleHarvestFarmWSCModal()
      }, 0)
    })
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    toggleHarvestFarmWSCModal()
  }
  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleHarvestFarmWSCModal()
  }
  return (
    <>
      {" "}
      {harvestTxPendModal ? (
        <>
          <HarvestFarmConfirmationModal
            pending={harvestPending}
            link={harvestFarmTx}
            modalRef={harvestModalRef}
            txError={modalTxError}
            closeModal={closeharvestModal}
            closePendingModal={closeharvestPendingModal}
            closeConfirmationModal={closeharvestConfirmationModal}
          />
        </>
      ) : null}
      {isWSCConnected && (
        <HarvestFarmModal
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          isVisible={isHarvestFarmWSCModalVisible}
          toggleModal={toggleHarvestFarmWSCModal}
          wrapData={wrapData}
        />
      )}
      <HarvestBtn
        harvestAvailable={harvestAvailable}
        onClick={
          isWSCConnected
            ? !!blockTransactionState
              ? handlePendingTxModal
              : () => fireHarvestFarmWSCModal()
            : handleHarvest
        }
      >
        <HarvestButtonCover
          blockTransactionState={!!blockTransactionState}
          disabled={harvestLoading && !harvestApproved}
        >
          {!!blockTransactionState ? (
            <>
              <SwapButtonSpinner />
            </>
          ) : null}
          {!!blockTransactionState ? (
            <>Continue</>
          ) : (
            <>
              {harvestLoading ? (
                <>
                  <LoadingDots />
                </>
              ) : (
                <> Harvest</>
              )}{" "}
            </>
          )}
        </HarvestButtonCover>
        <HarvestButtonCover>
          <> Harvest</>
        </HarvestButtonCover>
      </HarvestBtn>
    </>
  )
}
const HarvestBtn = styled(motion.a)<HarvestActionProps>`
  display: ${(props) => (props.harvestAvailable ? "flex" : "none")};
`
const HarvestButtonCover = styled(HarvestButton)``
export default HarvestAction
