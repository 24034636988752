import React from "react"
import styled from "styled-components"

import type { FC } from "react"
import { Subtitle } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
interface PaginationProps {
  maxPage?: number
  setPage?: (value: any) => void
  page?: number
  faded?: boolean
}

const TanglePagination: FC<PaginationProps> = (props) => {
  const { maxPage, setPage, page } = props

  return (
    <TokensTablePagination>
      <TokenIconsCover
        faded={page === 1 ? true : false}
        onClick={() => {
          setPage(page === 1 ? page : page - 1)
        }}
      >
        <TokenLeftArrow />
      </TokenIconsCover>
      <TokenTypeBody>{"Page " + page + " of " + maxPage}</TokenTypeBody>

      <TokenIconsCover
        faded={page === maxPage ? true : false}
        onClick={() => {
          setPage(page === maxPage ? page : page + 1)
        }}
      >
        <TokenRightArrow />
      </TokenIconsCover>
    </TokensTablePagination>
  )
}
const TokensTablePagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 32px 0 8px 0;
`

const TokenRightCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 4px 12px;
  width: 36px;
  height: 36px;
  background: ${TangleColors.defi};
  border-radius: 8px;
  margin: 0;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    background: ${TangleColors.lighthover};
    border: 1px solid ${TangleColors.black};
  }
`
const TokenIconsCover = styled.div<PaginationProps>`
  display: ${(props) => (props.faded ? `none` : `flex`)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 4px 12px;
  width: 36px;
  height: 36px;
  background: ${TangleColors.defi};
  border-radius: 8px;
  margin: 0;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    background: ${TangleColors.lighthover};
    border: 1px solid ${TangleColors.black};
  }
`

const TokenRightArrow = styled(BsArrowRight)`
  margin: 0;
  width: 20px;
  height: 20px;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  border: none !important;
  background: transparent !important;
  ${TokenIconsCover}:hover & {
    color: ${TangleColors.black};
  }
`

const TokenLeftArrow = styled(BsArrowLeft)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.white};
  margin: 0;
  transition: 0.4s ease-in;
  border: none !important;
  background: transparent !important;
  ${TokenIconsCover}:hover & {
    color: ${TangleColors.black};
  }
`

const TokenTypeBody = styled(Subtitle)`
  color: ${TangleColors.black};
  margin: 0 24px;
`

export default TanglePagination
