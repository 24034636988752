import { updateObject } from "store/utility"
import { ADA_TX_EFFECT, ADA_TRANSACTIONS } from "../actions/actionsTypes"

export interface SettingsProps {
  tangleSwapTransactions: boolean
  tangleSwapTXEffects: boolean
}
const initialState: SettingsProps = {
  tangleSwapTransactions: false,
  tangleSwapTXEffects: false,
}

const txEffectSelected = (state: any, action: any) => {
  return updateObject(state, {
    tangleSwapTXEffects: action.tangleSwapTXEffects,
  })
}
const transactionsSelected = (state: any, action: any) => {
  return updateObject(state, {
    tangleSwapTransactions: action.tangleSwapTransactions,
  })
}

const CardanoSettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADA_TX_EFFECT:
      return txEffectSelected(state, action)
    case ADA_TRANSACTIONS:
      return transactionsSelected(state, action)

    default:
      return state
  }
}

export default CardanoSettingsReducer
