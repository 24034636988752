import React, { FC } from "react"
import { toast as SonnerToast } from "sonner"
import { CommitmentProps } from "../types"
import styled from "styled-components"
import { Caption, Subtitle } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import { shortenAddress } from "utils"
import { convertDate, formatNumber } from "../utils/formatNumber"
import { FiExternalLink } from "react-icons/fi"
import { HiDuplicate } from "react-icons/hi"

import useExplorerInfo from "utils/useExplorerInfo"
interface TokenDataRowProps {
  index?: any
  paymentSymbol?: string
  commitmentData?: CommitmentProps
}
const CommitmentDataRow: FC<TokenDataRowProps> = (props) => {
  const { commitmentData, paymentSymbol } = props

  const CopyAddress = (addressCopied?: string) => {
    navigator.clipboard.writeText(addressCopied)
    SonnerToast.success("Address Copied")
  }
  const { explorerURL } = useExplorerInfo()
  return (
    <TokensDataTitle>
      <ClickableText onClick={() => CopyAddress(commitmentData?.account)}>
        <NumberWrap>{shortenAddress(commitmentData?.account)} </NumberWrap>
        <CopyAddressIconWrapper>
          <DuplicateIcon />
        </CopyAddressIconWrapper>
      </ClickableText>

      <ClickableText>
        <BoxDataName>
          {formatNumber(commitmentData?.amount, 3)}{" "}
          <SizeNumber>{paymentSymbol} </SizeNumber>
        </BoxDataName>
      </ClickableText>

      <ClickableText>
        <BoxDataName>
          <LinkWrapper
            href={`${explorerURL?.explorer}/tx/${commitmentData?.hash}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Number(commitmentData?.block)}
            <CopyAddressIconWrapper>
              <ExternalLinkIcon />
            </CopyAddressIconWrapper>{" "}
          </LinkWrapper>
        </BoxDataName>{" "}
      </ClickableText>
      <ClickableText>
        <BoxDataName>
          {convertDate(Number(commitmentData?.timestamp))}
        </BoxDataName>
      </ClickableText>
    </TokensDataTitle>
  )
}
const TokensDataTitle = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 0 0 8px 0;
  grid-template-columns: 1.5fr 3.5fr 1.2fr 3fr;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1.2fr 2.5fr 1.2fr 3fr;
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr repeat(1, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

const LinkWrapper = styled.a`
  text-decoration: underline;
  width: 100%;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  color: ${TangleColors.lighthover};
  text-underline-offset: 4px;
  transition: 0.4s ease-in;
  text-decoration-color: ${TangleColors.lighthover};
  -webkit-text-decoration-color: ${TangleColors.lighthover};
`

const BoxDataName = styled(Subtitle)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${TangleColors.white};
  text-align: left;
`

const ClickableText = styled.label<TokenDataRowProps>`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  padding: 16px 8px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  color: ${TangleColors.white};
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }

  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

const NumberWrap = styled(Subtitle)``
const SizeNumber = styled(Caption)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.grayDark};
  margin: 0 0 0 6px;
`
const ExternalLinkIcon = styled(FiExternalLink)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.lighthover};
`

const CopyAddressIconWrapper = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 16px;
`
const DuplicateIcon = styled(HiDuplicate)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.lightPurple};
`

export default CommitmentDataRow
