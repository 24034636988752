import React, { FC, Fragment, useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { Toaster } from "sonner"
import Navbar from "navbar/Navbar"
import Footer from "components/footer/Index"
import StarsBackground from "components/StarsBackground/StarsBackground"
import { useWSCContext } from "context/MilkomedaContext"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"

import { Chains } from "@tangleswap/sdk"

import ComingSoon from "components/utils/ComingSoon"
import LayoutDrawer from "./LayoutDrawer"
import useParsedQueryString from "utils/useParsedQueryString"
import { updateUserReferralInfo } from "store/actions/UserReferralActions"
import { txUpdateBlockedTransactionState } from "store/actions/SelectedWalletAction"
import { checkTangleWSCProgress } from "store/actions/WscProgressAction"
import WalletOverview from "components/wallet-overview/WalletOverview"
import ContinueTransactions from "components/modals/milkomeda/ContinueTransactions"
import PendingTxNotifications from "components/modals/milkomeda/PendingTxNotifications"
import FeedbackModals from "components/modals/FeedbackModals"
import OfflineComponent from "components/utils/OfflineComponent"
import SiteTestingWarning from "components/SiteTestingWarning"

interface LayoutProps {
  children?: any
}

interface TrueProps {
  active?: boolean
  homeTrue?: boolean
}
const Layout: FC<LayoutProps> = (props) => {
  const { children } = props

  const route = useLocation()
  const [isOnline, setIsOnline] = useState<any>(true)
  const { account, chainId, pendingWSCTxs, isWSCConnected } = useWSCContext()
  const [notificationActive, setNotificationActive] = useState<boolean>(false)
  const wscInterfaceOpen = useAppSelector(
    (state) => state.WscProgressReducer.wscInterfaceOpen
  )
  const parsedQs = useParsedQueryString()

  const homeTrue = route.pathname === "/"
  const homeClassName = route.pathname === "/" ? "homepage-gradient" : null
  const dappsGradientBG = !homeTrue

  const notLive = route.pathname === "/portfolio"
  const dappsWithSwapNLP =
    route.pathname === "/nft-staking" ||
    route.pathname === "/limit" ||
    route.pathname === "/stake" ||
    route.pathname === "/farm" ||
    route.pathname === "/airdrop" ||
    route.pathname.startsWith("/nft-staking") ||
    route.pathname === "/invest" ||
    route.pathname.startsWith("/invest") ||
    route.pathname === "/media"
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (pendingWSCTxs === undefined || !account) return

    const isPendingTx =
      pendingWSCTxs?.length > 0 &&
      pendingWSCTxs.some(
        (tx) => !(tx.isLastStep && (tx.completed || tx.type === "Unwrap"))
      )

    dispatch(
      txUpdateBlockedTransactionState(account && isWSCConnected && isPendingTx)
    )
  }, [pendingWSCTxs, account, isWSCConnected])

  useEffect(() => {
    if (!pendingWSCTxs || !account) return
    if (account && pendingWSCTxs?.length > 0 && isWSCConnected) {
      setNotificationActive(true)
    }
  }, [pendingWSCTxs, account, isWSCConnected])

  const updateReferralDetails = useCallback(
    (value?: any) => {
      dispatch(updateUserReferralInfo(value))
    },
    [dispatch]
  )
  const tangleCheckWSCProgress = useCallback(
    (value?: any) => {
      dispatch(checkTangleWSCProgress())
    },
    [dispatch]
  )
  useEffect(() => {
    tangleCheckWSCProgress()
  }, [tangleCheckWSCProgress])

  useEffect(() => {
    if (parsedQs.referral !== undefined) {
      updateReferralDetails(parsedQs?.referral)
    }
  }, [parsedQs.referral, chainId])

  useEffect(() => {
    const goOnline = () => setIsOnline(true)
    const goOffline = () => setIsOnline(false)

    window.addEventListener("online", goOnline)
    window.addEventListener("offline", goOffline)

    return () => {
      window.removeEventListener("online", goOnline)
      window.removeEventListener("offline", goOffline)
    }
  }, [])

  const closeNotification = () => {
    setNotificationActive(!notificationActive)
  }
  return (
    <StarsBackground>
      <LayoutDrawer />
      <BodyWrap>
        <Toaster
          position="top-right"
          richColors
          expand={false}
          visibleToasts={1}
          closeButton
        />
        {!!isWSCConnected && wscInterfaceOpen ? (
          <>
            {" "}
            <WalletOverview />
          </>
        ) : null}

        <ContinueTransactions />
        <SiteTestingWarning />
        <PendingTxNotifications
          closeNotification={closeNotification}
          notificationActive={notificationActive}
        />
        <FeedbackModals />
        <BodyCover
          className={`${
            !dappsGradientBG && !homeTrue
              ? homeClassName
              : chainId === Chains.SEPOLIA_TEST
              ? "dapps-sepolia-gradient"
              : chainId === Chains.SHIMMER || chainId === Chains.CARDANO //temp
              ? ""
              : // chainId === Chains.SHIMMER || to be brought back
              chainId === Chains.SHIMMER_TEST
              ? "dapps-shimmer-gradient"
              : "dapps-cardano-gradient"
          }`}
        >
          <Navbar />
          {!!isOnline ? (
            <>
              <BodyLayoutCover homeTrue={Boolean(homeTrue || dappsWithSwapNLP)}>
                {/* for production without swap and LP*/}
                {notLive ? (
                  <>
                    <ComingSoon />
                  </>
                ) : (
                  <>
                    <>{children}</>
                  </>
                )}
                {/*
            for production with swap */}
                {/* {dappsGradientBG && !homeTrue ? (
              <>
                <ComingSoon />
              </>
            ) : (
              <>
                <>{children}</>
              </>
            )} */}
                {/* for staging */}
                {/* <>{children}</> */}
              </BodyLayoutCover>{" "}
            </>
          ) : (
            <>
              <OfflineComponent />
            </>
          )}

          <Footer />
        </BodyCover>
      </BodyWrap>
    </StarsBackground>
  )
}
const BodyWrap = styled.div<TrueProps>`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${TangleColors.black};
  .dapps-sepolia-gradient {
    background: linear-gradient(to bottom right, black 20%, #031c2b 70%),
      linear-gradient(to bottom left, black 20%, #031c2b 70%);
    background-size: 50.1% 100%; /* X size should be 50%, Y size can be as you wish */
    background-position: left top, right top; /* don't change */
    background-repeat: no-repeat; /* don't change */
  }
  .dapps-cardano-gradient {
    background: linear-gradient(to bottom right, black 20%, #001856 70%),
      linear-gradient(to bottom left, black 20%, #001856 70%);
    background-size: 50.1% 100%; /* X size should be 50%, Y size can be as you wish */
    background-position: left top, right top; /* don't change */
    background-repeat: no-repeat; /* don't change */
  }
  .dapps-shimmer-gradient {
    background: linear-gradient(to bottom right, black 20%, #2b0039 70%),
      linear-gradient(to bottom left, black 20%, #2b0039 70%);
    background-size: 50.1% 100%; /* X size should be 50%, Y size can be as you wish */
    background-position: left top, right top; /* don't change */
    background-repeat: no-repeat; /* don't change */
  }
`
const BodyCover = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const BodyLayoutCover = styled.div<TrueProps>`
  margin: ${(props) => (props.homeTrue ? "0" : "72px 0 0 0")};
`

export default React.memo(Layout)
