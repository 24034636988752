import React, { FC, useContext, useState } from "react"
import { GrClose } from "react-icons/gr"
import styled from "styled-components"
import { SwapTokenButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Subtitle } from "styles/TextStyles"

import { toSignificantDigits } from "utils/toSignificant"
import VoidLogo from "assets/void.png"
import StakeContext from "./StakeContext"
import { useWSCContext } from "context/MilkomedaContext"

interface ModalProps {
  closeModal: () => void
}

const WithdrawModal: FC<ModalProps> = ({ closeModal }) => {
  const { reward, handleWithdraw, fireUnstakeWSCModal } =
    useContext(StakeContext)
  const { isWSCConnected } = useWSCContext()
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <Body onClick={() => closeModal()}>
      <Cover onClick={handleClick}>
        <ModalHeader>
          <IconText>Unstake</IconText>
          <CloseIcon onClick={() => closeModal()} />
        </ModalHeader>
        <ModalBody>
          <ModalBox>
            <WarningHeader>Important Warning</WarningHeader>
            <WarningText>
              Unstaking <ImportantText>ANY</ImportantText> amount (regardless of
              how small) from the VOID Staking Field will immeediately erase
              your VE (Void Energy) balance IN FULL — please proceed with
              extreme caution!! For more info,{" "}
              <MoreInfo href="#">click here</MoreInfo>.
            </WarningText>
            <Row>
              <UnstakeText>Unstake:</UnstakeText>
              <IconWrap>
                <BoxIcon src={VoidLogo} />
                <VoidAmount>
                  {toSignificantDigits(reward, 2, true, 18)} VOID
                </VoidAmount>
              </IconWrap>
            </Row>
          </ModalBox>
        </ModalBody>
        <ButtonContainer>
          <RemoveButton
            onClick={
              isWSCConnected
                ? () => fireUnstakeWSCModal()
                : () => handleWithdraw()
            }
          >
            Confirm
          </RemoveButton>
        </ButtonContainer>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 168;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
`

const Cover = styled.div`
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 40px;
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 24px;
  right: 30px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
`

const IconText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.3s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const ModalBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const ModalBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 0;
`

const WarningHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 16px 0;
  justify-content: center;
  color: ${TangleColors.red};
  font-weight: 600;
  text-decoration: underline;
  font-size: 32px;
  text-align: center;
`

const WarningText = styled.div`
  color: ${TangleColors.red};
`

const ImportantText = styled.span`
  color: ${TangleColors.red};
  font-weight: 600;
  text-decoration: underline;
`

const MoreInfo = styled.a`
  color: ${TangleColors.blue};
  font-weight: 600;
  text-decoration: underline;
`

const BoxIcon = styled.img`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`

const UnstakeText = styled.div``

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const VoidAmount = styled(Caption)`
  margin: 0 8px;
  font-weight: 500;
  color: ${TangleColors.white};
`

const ButtonContainer = styled.div`
  width: 100%;
  margin: 32px 0;
`

const RemoveButton = styled(SwapTokenButton)``

export default WithdrawModal
