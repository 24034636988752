import { FC, useEffect, useRef, useState } from "react"
import { ethers } from "ethers"
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  erc20ABI,
} from "wagmi"
import { IWrapMultipleData, IWrapToken } from "interfaces/wscSwap.interface"
import {
  AUCTION_CREATION_ADDRESS,
  Chains,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  ROUTER_ADDRESS,
  VE_STAKING_ADDRESS,
  extractEvmFunctionName,
} from "@tangleswap/sdk"
import {
  BRIDGE_DEVNET_ADDRESS,
  BRIDGE_MAINNET_ADDRESS,
} from "constants/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import {
  ActionButton,
  ActionWrapper,
  ButtonContainer,
  Link,
} from "components/milkomeda/styles"
import { getEvmExplorerUrl } from "utils/milkomeda/transaction"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { playSound } from "components/milkomeda/component/PlaySound"

interface IMultipleTokenAllowanceStep {
  nextStep?: () => void
  tokensToCheck?: IWrapToken[]
  actionType?: string
  subStep?: number
  evmFunction: any
  data: IWrapMultipleData
  auctionAddress?: string
}

const MultipleTokenAllowanceStep: FC<IMultipleTokenAllowanceStep> = (props) => {
  const { nextStep, tokensToCheck, actionType, subStep, evmFunction, data, auctionAddress } =
    props
  const { chainId, account } = useWSCContext()
  const [currentTokenIndex, setCurrentTokenIndex] = useState(subStep ?? 0)
  const currentToken = tokensToCheck[currentTokenIndex]

  const approvedAmount = ethers.constants.MaxUint256
  const approvedAddress =
    actionType === "Swap"
      ? ROUTER_ADDRESS[chainId]
      : actionType === "LP"
      ? NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]
      : actionType === "VEStaking"
      ? VE_STAKING_ADDRESS[chainId]
      : actionType === "IHub"
      ? auctionAddress
      : null
  if (!approvedAddress) throw new Error("Unrecognized actionType!")

  const {
    config: userConfig,
    isLoading: isUserPreparingLoading,
    isError: isUserPreparingError,
    error: userPreparingError,
  } = usePrepareContractWrite({
    address: currentToken.address as `0x${string}`,
    abi: erc20ABI,
    functionName: "approve",
    args: [approvedAddress, approvedAmount],
    enabled: !!currentToken,
    overrides: { gasLimit: ethers.BigNumber.from(500_000) },
  })

  const {
    data: userWriteData,
    write: userWrite,
    isLoading: isUserWritingContract,
    isIdle: isUserWritingIdle,
    isError: isUserContractWriteError,
    error: userContractWriteError,
  } = useContractWrite(userConfig)

  const {
    isSuccess: isUserWriteSuccess,
    isError: isUserWaitingForTxError,
    error: waitForUserTransactionError,
  } = useWaitForTransaction({
    hash: userWriteData?.hash,
    enabled: !!userWriteData?.hash,
  })
  const isUserError =
    isUserPreparingError || isUserContractWriteError || isUserWaitingForTxError

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (tangleSwapTransactions && !autoExecute.current && userWrite) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        executeStep()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, userWrite])

  useEffect(() => {
    if (isUserWriteSuccess && currentTokenIndex < tokensToCheck.length) {
      if (currentTokenIndex < tokensToCheck.length - 1) {
        setCurrentTokenIndex(currentTokenIndex + 1)
      } else {
        /* nextStep() */
      }
    }
  }, [isUserWriteSuccess, currentTokenIndex, tokensToCheck.length])

  useEffect(() => {
    if (currentTokenIndex !== 0 && currentTokenIndex < tokensToCheck.length) {
      executeStep()
    }
  }, [currentTokenIndex, tokensToCheck.length])

  useEffect(() => {
    const storageKey = `wscAllowance${chainId}${account}${currentToken.address}${actionType}`
    if (isUserWriteSuccess) {
      // fetchAllowance(currentToken.address, true) // TODO: Pat to import this function
      const stepperParams = {
        completed: !isUserError,
        stepTitle: "Approve",
        subStep: currentTokenIndex,
        actionType,
        actionSubType: extractEvmFunctionName(evmFunction),
        txHash: userWriteData?.hash,
        data,
        timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      }
      dispatch(updateUserWSCProgress(stepperParams))
      sessionStorage.removeItem(storageKey)
      if (tangleSwapTXEffects) playSound()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserWriteSuccess])

  const dispatch = useAppDispatch()
  const executeStep = () => {
    userWrite?.()

    // localStorage.setItem("pendingWscTxStep", "2")
    const stepperParams = {
      completed: false,
      stepTitle: "Approve",
      subStep: currentTokenIndex,
      actionType,
      actionSubType: extractEvmFunctionName(evmFunction),
      txHash: userWriteData?.hash,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }
  return (
    <ActionWrapper>
      {/* <div>Token Allowance</div>
      <div style={{ marginBottom: 30 }}>
        Authorize the seamless transfer of your assets between the EVM and
        Mainchain networks. Perform a token allowance transaction to enable the
        subsequent unwrapping and secure transfer of the asset back to your
        Mainchain wallet.
      </div> */}
      {isUserWritingContract && (
        <>
          <SpinnerCombined />
          <span>Preparing transaction</span>
        </>
      )}
      {userWriteData &&
        !isUserWriteSuccess &&
        currentTokenIndex !== tokensToCheck.length && (
          <>
            <SpinnerCombined />
            <span>Transaction Sent</span>
          </>
        )}
      {isUserContractWriteError && (
        <div>
          Oops, something went wrong.
          {userContractWriteError?.message ?? ""}
          {waitForUserTransactionError?.message ?? ""}
        </div>
      )}
      {isUserWriteSuccess && (
        <>
          <Link
            href={`${getEvmExplorerUrl(chainId)}/tx/${userWriteData?.hash}`}
          >
            Approved the token successfully!
          </Link>
          {currentTokenIndex === tokensToCheck.length - 1 && (
            <ButtonContainer>
              <ActionButton onClick={nextStep}>Continue</ActionButton>
            </ButtonContainer>
          )}
        </>
      )}

      {(isUserWritingIdle || isUserContractWriteError) && (
        <ButtonContainer>
          <ActionButton disabled={!userWrite} onClick={executeStep}>
            Grant token allowance
          </ActionButton>
        </ButtonContainer>
      )}
    </ActionWrapper>
  )
}

export default MultipleTokenAllowanceStep
