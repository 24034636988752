import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { AiOutlineInfoCircle, AiOutlineShareAlt } from "react-icons/ai"
import {
  BsFillLockFill,
  BsFillUnlockFill,
  BsInfoCircleFill,
} from "react-icons/bs"
import { toast as TangleToast } from "sonner"
import { BiWorld } from "react-icons/bi"

import { GrClose } from "react-icons/gr"
import { TickCircle } from "iconsax-react"

import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import { BsArrowLeft } from "react-icons/bs"
import { TangleColors } from "styles/ColorStyles"
import dutchSVG from "assets/auction01.svg"
import crowdSVG from "assets/auction02.svg"
import batchSVG from "assets/auction03.svg"
import {
  AuctionDetailsProps,
  AuctionStatus,
  CommitmentProps,
  MarketProps,
  TokenType,
} from "./types"
import {
  FaBook,
  FaDiscord,
  FaFileAlt,
  FaGithub,
  FaGlobe,
  FaMedium,
  FaReddit,
  FaTelegram,
  FaWeixin,
} from "react-icons/fa"
import "./TokenPage.module.css"

import {
  Body1,
  Caption,
  CaptionSmall,
  CaptionTiny,
  Header5,
  Header7,
  Subtitle,
} from "styles/TextStyles"
import { ParticipateMainButton } from "styles/ButtonStyles"
import { useForm } from "react-hook-form"
import {
  Chains,
  MAX_UINT256,
  NATIVE_SYMBOL,
  ZERO_ADDRESS,
  isMagic,
  wrapAddr,
} from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"

import { ONE_DAY, populateMarketData } from "utils/calls/investmentHub"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import AuctionDetailsTab from "./Tabs/AuctionDetailsTab"
import CommitmentsTab from "./Tabs/CommitmentsTab"
import AboutProjectTab from "./Tabs/AboutProjectTab"
import TimeRemaining from "./CreateAuction/components/TimeRemaining"
import { formatCountdown, formatNumber } from "./utils/formatNumber"
import { getCategoryColor } from "./AuctionCard"
import { useAppDispatch, useAppSelector } from "store/hooks"
import LiquidityGraph from "components/Liquidity/LiquidityDetails/LiquidityGraph"
import AuctionPageLoader from "./AuctionPageLoader"
import LoadingDots, { Dots } from "components/utils/LoadingDots"

import ReactConfetti from "./ReactConfetti"
import { ethers } from "ethers"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

import ApproveAuctionModal from "components/confirmation/ApproveAuctionModal"
import useAuctionArray from "utils/useAuctionArray"
import ParticipateAuctionModal from "components/confirmation/ParticipateAuctionModal"
import { PendingStateContext } from "context/PendingContext"
import FinalizeAuctionModal from "components/confirmation/FinalizeAuctionModal"
import AuctionWithdrawTokenModal from "components/confirmation/AuctionWithdrawTokenModal"
import { useQuery, useQueryClient } from "react-query"
import { QueryError, TangleTokenProps } from "components/swap/types"

import { useTangleship } from "utils/useTangleship"
import GoBackButton from "./CreateAuction/components/GoBackButton"
import AuctionDetailsConfetti from "./AuctionDetailsConfetti"
import AuctionParticipateModal from "components/Liquidity/component/AuctionParticipateModal"
import { updateAuctionToggleStatus } from "store/actions/AuctionToggleAction"
import CommitmentChart from "./chart/CommitmentChart"
import AuctionHoverBox from "./AuctionHoverBox"
import { liqLauncherDataSuccess } from "store/actions/AuctionDataAction"
import PurplelistTab from "./Tabs/PurplelistTab"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import ParticipateModal, {
  useParticipateModalControl,
} from "components/milkomeda/ihub/ParticipateModal"
import { ILaunchPadData } from "interfaces/wscSwap.interface"
import FinalizeModal, {
  useFinalizeModalControl,
} from "components/milkomeda/ihub/FinalizeModal"
import WithdrawModal, {
  useWithdrawModalControl,
} from "components/milkomeda/ihub/WithdrawModal"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import { sk } from "date-fns/locale"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"

interface FormProps {
  formError?: boolean
  active?: boolean
  disabled?: boolean
}
interface TokenProps {
  active?: boolean
  gap?: number
  align?: string
  disabled?: boolean
  status?: string
  category?: string
  color?: any
  auctionBg?: any
}
interface TokenMaxValue {
  amount?: number | string
}
const AuctionDetailsPage = () => {
  const { address: urlAuctionAddress } = useParams()
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState(1)
  const [activeGraphTab, setActiveGraphTab] = useState(2)
  const [paymentTokenAllowance, setPaymentTokenAllowance] =
    useState<any>(undefined)
  const [paymentAllowanceIsNotEnough, setPaymentAllowanceIsNotEnough] =
    useState<boolean>(false)
  const [market, setMarket] = useState<any>([])
  const [prevShowChangeState, setPrevShowChangeState] = useState<any>(undefined)
  const [auctionData, setAuctionData] = useState<any>({} as AuctionDetailsProps)
  const [tokenBalance, setTokenBalance] = useState<Number | undefined>(
    undefined
  )
  const [tokenBalanceError, setTokenBalanceError] = useState<any>(undefined)
  const [templateName, setTemplateName] = useState<any>(undefined)
  const [totalTokenSupply, setTotalTokenSupply] = useState<any>(undefined)
  const [allCommitments, setAllCommitments] = useState<any>(undefined)
  const [lastCommitment, setLastCommitment] = useState<any>(undefined)
  const [myCommitments, setMyCommitments] = useState<any>(undefined)
  const [liquidityAmountFormula, setLiquidityAmountFormula] =
    useState<any>(undefined)
  const { setPendingTransaction } = useContext(PendingStateContext)
  const [liquidityLocktime, setLiquidityLocktime] = useState<any>(undefined)
  const [purplelistAddress, setPurplelistAddress] = useState<string>(undefined)
  const [purplelistMaxAllocation, setPurplelistMaxAllocation] =
    useState<any>(undefined)
  const [purplelistStatus, setPurplelistStatus] = useState<any>(undefined)
  const [postAuctionLiqLauncher, setPostAuctionLiqLauncher] =
    useState<string>(undefined)
  const [liquidityNftId, setLiquidityNftId] = useState<number>(undefined)
  const [commitmentAmount, setCommitmentAmount] = useState<any>(undefined)
  const [maxInput, setMaxInput] = useState<any>(undefined)

  const buttonRef = useRef<any>(null)
  const [tokenApprovalTx, setTokenApprovalTx] = useState<any>(undefined)
  const [tokenApproving, setTokenApproving] = useState<boolean>(false)
  const [modalTXError, setModalTXError] = useState<boolean>(false)
  const [tokenApprovalModal, setTokenApprovalModal] = useState<boolean>(false)
  const [tokenApprovalPending, setTokenApprovalPending] =
    useState<boolean>(false)
  const [tokenApprovalApproved, setTokenApprovalApproved] =
    useState<boolean>(false)
  const [participateTx, setParticipateTx] = useState<any>(undefined)
  const [participatePending, setParticipatePending] = useState<boolean>(false)
  const [participateApproving, setParticipateApproving] =
    useState<boolean>(false)
  const [participateApproved, setParticipateApproved] = useState<boolean>(false)

  const [participateModal, setParticipateModal] = useState<boolean>(false)

  const [showChangeState, setShowChangeState] = useState<boolean>(false)

  const [timeRemaining, setTimeRemaining] = useState<any>(undefined)
  const [showConfetti, setShowConfetti] = useState<boolean>(false)
  const [needsToBeFinalized, setNeedsToBeFinalized] = useState<boolean>(false)
  const [showFinalizePopup, setShowFinalizePopup] = useState<boolean>(false)
  const [showWithdrawTokens, setShowWithdrawTokens] = useState<any>(undefined)
  const [showAlreadyWithdrawnTokens, setShowAlreadyWithdrawnTokens] =
    useState<any>(undefined)

  const [userAbleToFinalize, setUserAbleToFinalize] = useState<boolean>(null)

  const [isAdmin, setIsAdmin] = useState<boolean>(undefined)
  const [isOperator, setIsOperator] = useState<boolean>(undefined)

  const [timeUntilFinalize, setTimeUntilFinalize] = useState<number>(undefined)
  const [timeUntilFinalizeCounter, setTimeUntilFinalizeCounter] =
    useState<number>(undefined)
  const [tokensClaimable, setTokensClaimable] = useState<number>(undefined)
  const [tokensAlreadyClaimed, setTokensAlreadyClaimed] =
    useState<number>(undefined)
  const [claimableCurrency, setClaimableCurrency] = useState<any>(null)
  const [tokensClaimableString, setTokensClaimableString] =
    useState<string>(null)
  const [tokensClaimedString, setTokensClaimedString] = useState<string>(null)

  const [withdrawTokenTx, setWithdrawTokenTx] = useState<any>(undefined)

  const [withdrawTokenApproving, setWithdrawTokenApproving] =
    useState<boolean>(false)
  const [withdrawTokenPending, setWithdrawTokenPending] =
    useState<boolean>(false)
  const [withdrawTokenApproved, setWithdrawTokenApproved] =
    useState<boolean>(false)
  const [withdrawTokenModal, setWithdrawTokenModal] = useState<boolean>(false)
  const [finalizeAuctionApproving, setFinalizeAuctionApproving] =
    useState<boolean>(false)
  const [finalizeAuctionPending, setFinalizeAuctionPending] =
    useState<boolean>(false)
  const [finalizeAuctionApproved, setFinalizeAuctionApproved] =
    useState<boolean>(false)
  const [finalizeAuctionModal, setFinalizeAuctionModal] =
    useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [finalizeAuctionTx, setFinalizeAuctionTx] = useState<any>(undefined)
  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  const [auctionQueryData, setAuctionQueryData] = useState<any>(undefined)

  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()
  const { tangleship } = useTangleship()

  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }
  const queryClient = useQueryClient()
  useEffect(() => {
    if (!chainId) return
    const auctionQuery: any = queryClient.getQueryData([
      "tangleswap-auction-info",
      [chainId],
    ])
    setAuctionQueryData(auctionQuery)
  }, [chainId, tangleship, queryClient])

  const mouseLeave = (e: any) => {
    setShowHelpText(false)
  }
  const mouseEnter = (e: any) => {
    setShowHelpText(true)
  }

  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const checkAgreement = useAppSelector(
    (state) => state.AuctionToggle.tangleAuctionStatus
  )
  const wscModalRef = useRef<any>(null)
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TokenMaxValue>({
    defaultValues: {
      amount: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const { singleAuctionLoading, singleTangleAuctions, refetchSingleAuction } =
    useAuctionArray(urlAuctionAddress)

  const launcherInfo = useAppSelector(
    (state) => state.auctionDataReducer.launcherInfo
  )

  const fetchLauncherInfo = async (refetch = false) => {
    const storageKey = `ihubLauncherInfo${chainId}${urlAuctionAddress}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue && storageValue !== "undefined" && !refetch) {
      return JSON.parse(storageValue)
    }

    const info = await tangleship?.getAllLauncherInfo(urlAuctionAddress)
    sessionStorage.setItem(storageKey, JSON.stringify(info))
    return info
  }

  const fetchLiqLauncherData = (refetch: boolean = false) => {
    return (dispatch: any) => {
      fetchLauncherInfo(refetch).then((info) => {
        dispatch(liqLauncherDataSuccess(info))
      })
    }
  }

  useEffect(() => {
    if (!tangleship || !chainId || !urlAuctionAddress) return
    dispatch(fetchLiqLauncherData(false))
  }, [tangleship, chainId, urlAuctionAddress])

  const lpModalRef = useRef<HTMLDivElement | any>(null)
  const tokenApprovalModalRef = useRef<any>(null)
  const participateModalRef = useRef<any>(null)
  const finalizeAuctionModalRef = useRef<any>(null)
  const withdrawTokenModalRef = useRef<any>(null)

  const closeTokenApprovalPendingModal = useCallback(() => {
    setTokenApprovalModal(false)
  }, [tokenApprovalModal])

  const closeTokenApprovalConfirmationModal = useCallback(() => {
    setTokenApprovalModal(false)
  }, [tokenApprovalModal])

  const closeTokenApprovalModal = useCallback(
    (event: any) => {
      if (tokenApprovalModalRef.current === event?.target) {
        setTokenApprovalModal(false)
      }
    },
    [tokenApprovalModal]
  )

  const closeParticipatePendingModal = useCallback(() => {
    setParticipateModal(false)
    setConfirmationModal(false)
  }, [participateModalRef])
  const closeParticipateConfirmationModal = useCallback(() => {
    setParticipateModal(false)
    setConfirmationModal(false)
  }, [participateModalRef])

  const closeParticipateModal = useCallback(
    (event: any) => {
      if (participateModalRef.current === event?.target) {
        setParticipateModal(false)
        setConfirmationModal(false)
      }
    },
    [participateModalRef]
  )
  const closeFinalizeAuctionPendingModal = useCallback(() => {
    setFinalizeAuctionModal(false)
  }, [finalizeAuctionModalRef])
  const closeFinalizeAuctionConfirmationModal = useCallback(() => {
    setFinalizeAuctionModal(false)
  }, [finalizeAuctionModalRef])

  const closeFinalizeAuctionModal = useCallback(
    (event: any) => {
      if (finalizeAuctionModalRef.current === event?.target) {
        setFinalizeAuctionModal(false)
      }
    },
    [finalizeAuctionModalRef]
  )

  const closeWithdrawTokenPendingModal = useCallback(() => {
    setWithdrawTokenModal(false)
  }, [withdrawTokenModalRef])
  const closeWithdrawTokenConfirmationModal = useCallback(() => {
    setWithdrawTokenModal(false)
  }, [withdrawTokenModalRef])

  const closeWithdrawTokenModal = useCallback(
    (event: any) => {
      if (withdrawTokenModalRef.current === event?.target) {
        setWithdrawTokenModal(false)
      }
    },
    [withdrawTokenModalRef]
  )

  useEffect(() => {
    if (!urlAuctionAddress || !chainId) return

    const marketData =
      auctionQueryData !== undefined // && !market
        ? auctionQueryData?.find((data: MarketProps) => {
            return (
              String(data?.auctionAddress).trim().toLowerCase() ===
              String(urlAuctionAddress).trim().toLowerCase()
            )
          })
        : singleTangleAuctions
    setAuctionData(marketData)

    const updatedMarket = populateMarketData(marketData)
    setMarket(updatedMarket)
  }, [
    auctionQueryData,
    tangleship,
    chainId,
    singleTangleAuctions,
    urlAuctionAddress,
  ])

  const { data: paymentCurrency } = useQuery<TangleTokenProps, QueryError>(
    ["tangleDetailedUserToken1Info", market?.paymentToken?.addr, l1ChainId],
    () =>
      fetchTangleCurrency(
        market?.paymentToken?.addr,
        l1ChainId,
        tangleship,
        market?.paymentToken
      ),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!market?.paymentToken && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: auctionCurrency } = useQuery<TangleTokenProps, QueryError>(
    ["tangleDetailedUserToken0Info", market?.auctionToken?.addr, l1ChainId],
    () =>
      fetchTangleCurrency(
        market?.auctionToken?.addr,
        l1ChainId,
        tangleship,
        market?.auctionToken
      ),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!market?.auctionToken && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const fetchUserBalance = async () => {
    const tokenAddress = market?.paymentToken?.addr?.toLowerCase()
    const addr = isWSCConnected
      ? wrapAddr(tokenAddress, chainId, l1ChainId).tokenAddress
      : tokenAddress
    const tokenBalanceFig = tangleswapTokenListBalances?.[addr?.toLowerCase()]
    const res = !tokenBalanceFig ? 0 : parseFloat(String(tokenBalanceFig))
    setTokenBalance(res)
  }

  useEffect(() => {
    if (!tangleswapTokenListBalances || !market?.paymentToken?.addr) return
    fetchUserBalance()
  }, [market?.paymentToken?.addr, tangleswapTokenListBalances])

  const calcMaxInput = (balance, maxPurplelist, maxCommitment) => {
    const tokenBalanceValue = Math.min(
      balance,
      maxPurplelist !== null ? maxPurplelist : balance,
      maxCommitment !== null ? maxCommitment : balance
    )
    setMaxInput(tokenBalanceValue)
  }

  useEffect(() => {
    if (
      tokenBalance === undefined ||
      purplelistMaxAllocation === undefined ||
      commitmentAmount === undefined
    )
      return
    calcMaxInput(tokenBalance, purplelistMaxAllocation, commitmentAmount)
  }, [tokenBalance, purplelistMaxAllocation, commitmentAmount])

  const handleMaxInput = useCallback(() => {
    setValue("amount", maxInput)
  }, [maxInput])

  const handleTimeRemainingChange = (newTimeRemaining) => {
    setTimeRemaining(newTimeRemaining)
  }

  const transitionMarketStatus = () => {
    if (market?.auctionStatus === AuctionStatus.UPCOMING) {
      setMarket((prevMarket) => ({
        ...prevMarket,
        auctionStatus: AuctionStatus.LIVE,
      }))
    } else if (market?.auctionStatus === AuctionStatus.LIVE) {
      setMarket((prevMarket) => ({
        ...prevMarket,
        auctionStatus: AuctionStatus.FINISHED,
      }))
    }
  }

  useEffect(() => {
    // Handle state transitions (UPCOMING > LIVE > FINISHED) due to time
    if (
      timeRemaining > 0 ||
      timeRemaining === undefined ||
      market?.auctionStatus === AuctionStatus.FINISHED
    )
      return

    setTimeout(() => {
      setTimeRemaining(123456789) // to avoid immediate re-rendering
      transitionMarketStatus()
    }, 1000) // wait 1 second for dramatic effect
  }, [timeRemaining])

  useEffect(() => {
    // Handle state transitions (LIVE > FINISHED) due to auction sold out
    if (
      market?.tokensRemaining === undefined ||
      market?.tokensRemaining > 0 ||
      market?.auctionStatus !== AuctionStatus.LIVE
    )
      return

    transitionMarketStatus()
  }, [market?.tokensRemaining])
  // const updateBalanceError = async (amount?: any, balance?: any) => {
  //   const tokenBalanceError =
  //     parseFloat(String(amount).trim()) > parseFloat(String(balance).trim())
  //   return tokenBalanceError
  // }
  useEffect(() => {
    if (!watch("amount") || !tokenBalance) return
    const balanceInfo =
      parseFloat(String(watch("amount")).trim()) >
      parseFloat(String(tokenBalance).trim())

    setTokenBalanceError(balanceInfo)
  }, [tokenBalance, watch("amount")])

  const checkOwner = useCallback(async () => {
    const auctionType = market?.auctionType
    const auctionAddr = market?.auctionAddress

    if (!auctionAddr || !auctionType || !chainId) return
    if (!account) {
      setIsAdmin(null)
      setIsOperator(null)

      return
    }

    const storageKey0 = `ihubIsAdmin${chainId}${account}${auctionAddr}`
    const storageKey1 = `ihubIsOperator${chainId}${account}${auctionAddr}`
    const storageKey2 = `ihubIsFundReceive${chainId}${account}${auctionAddr}`
    const storageValue0 = sessionStorage.getItem(storageKey0)
    const storageValue1 = sessionStorage.getItem(storageKey1)
    const storageValue2 = sessionStorage.getItem(storageKey2)

    const isStorageAdmin = storageValue0 === "true"
    const isStorageOperator = storageValue1 === "true"
    if (
      storageValue0 !== null &&
      storageValue1 !== null &&
      storageValue2 !== null
    ) {
      setIsAdmin(isStorageAdmin)
      setIsOperator(isStorageOperator)

      return
    }

    const [isAdmin, isOperator, isFundsReceiver] = await Promise.all([
      tangleship?.hasAdminRole(account, auctionType, auctionAddr),
      tangleship?.hasOperatorRole(account, auctionType, auctionAddr),
      tangleship?.hasFundsReceiverRole(auctionType, auctionAddr),
    ])

    if (isAdmin === null || isOperator === null || isFundsReceiver === null) {
      setIsAdmin(null)
      setIsOperator(null)

      return
    }
    sessionStorage.setItem(storageKey0, String(isAdmin))
    sessionStorage.setItem(storageKey1, String(isOperator))
    sessionStorage.setItem(storageKey2, String(isFundsReceiver === account))
    setIsAdmin(isAdmin)
    setIsOperator(isOperator)
    // setIsFundsReceiver(isFundsReceiver === account)
  }, [
    account,
    chainId,
    market?.auctionAddress,
    market?.auctionType,
    tangleship,
  ])

  useEffect(() => {
    checkOwner()
  }, [checkOwner])

  const [countdownTimer, setCountdownTimer] = useState<number>(0)
  useEffect(() => {
    if (countdownTimer > 0) return
    setCountdownTimer(1)
    setInterval(() => setCountdownTimer((prevTimer) => prevTimer + 1), 1000)
  }, [])

  useEffect(() => {
    if (!market?.endTime) return

    const endOrSoldOut =
      AuctionStatus[market?.auctionStatus] === "FINISHED" &&
      lastCommitment?.timestamp
        ? Number(lastCommitment?.timestamp)
        : Number(market?.endTime)
    const end = new Date(endOrSoldOut).getTime()
    const expiry = 7 * ONE_DAY
    const now = Math.floor(Date.now() / 1000)
    const timeUntilExpire = Math.max(0, end + expiry - now)

    if (timeUntilExpire) {
      if (!timeUntilFinalize) setTimeUntilFinalize(timeUntilExpire)
      setTimeUntilFinalizeCounter(timeUntilExpire)
    }
  }, [
    market?.endTime,
    market?.auctionStatus,
    lastCommitment?.timestamp,
    countdownTimer,
  ])

  useEffect(() => {
    if (
      !launcherInfo ||
      !paymentCurrency?.symbol ||
      !auctionCurrency?.symbol ||
      !market?.tokensForSale
    )
      return

    const postAuctionLiqLauncher = launcherInfo?.postAuctionLiqLauncher ?? null
    const liquidityNftId = launcherInfo?.liquidityNftId
    const locktime = launcherInfo?.locktime
    const symbolAuction = auctionCurrency?.symbol
    const symbolPayment = paymentCurrency?.symbol
    const liqFraction = Number(launcherInfo?.liquidityPercent) / 10000
    const amountLiquidity =
      liqFraction * parseFloat(String(market?.tokensForSale))
    const liqAuction = formatNumber(amountLiquidity, 3)
    const liqPercent = formatNumber(liqFraction * 100, 1)

    const liquidityAmount = liqAuction
      ? `${liqAuction} ${symbolAuction}  +  ${liqPercent}% of ${symbolPayment}`
      : null

    setPostAuctionLiqLauncher(postAuctionLiqLauncher)
    setLiquidityNftId(liquidityNftId)
    setLiquidityLocktime(locktime)
    setLiquidityAmountFormula(liquidityAmount)
  }, [
    auctionCurrency?.symbol,
    launcherInfo,
    market?.tokensForSale,
    paymentCurrency?.symbol,
    chainId,
  ])

  const selectGraphTab = (number: number) => {
    setActiveGraphTab(number)
  }

  const calculateAllowance = useCallback(
    async (refetch = false) => {
      const addr = market?.paymentToken?.addr
      const auctionAddr = market?.auctionAddress
      const decimals = market?.paymentToken?.decimals
      if (!account || !chainId || !addr || !auctionAddr || !decimals) return

      if (isWSCConnected) {
        setPaymentTokenAllowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const storageKey = `ihubDetailsAllowance${chainId}${account}${auctionAddr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setPaymentTokenAllowance(Number(storageValue))
        return
      }

      const tokAddr = isMagic(addr, chainId) ? NATIVE_SYMBOL[chainId] : addr
      const res = await tangleship?.getAllowance(tokAddr, account, auctionAddr)
      const allowance = Number(res?._hex) / 10 ** decimals
      sessionStorage.setItem(storageKey, allowance.toString())
      setPaymentTokenAllowance(allowance)
    },
    [
      account,
      chainId,
      market?.auctionAddress,
      market?.paymentToken?.addr,
      market?.paymentToken?.decimals,
      tangleship,
    ]
  )
  useEffect(() => {
    calculateAllowance(false)
  }, [calculateAllowance])

  const checkTokenTemplate = useCallback(async () => {
    const addr = market?.auctionToken?.addr?.trim()?.toLowerCase()
    if (!addr) return

    const storageKey = `ihubTokenTemplate${chainId}${addr}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setTemplateName(Number(storageValue))
      return
    }

    const data = await tangleship?.getTokenFactoryInfo(addr)
    const template = Number(data?.templateId?._hex)
    localStorage.setItem(storageKey, template.toString())
    setTemplateName(template)
  }, [chainId, market?.auctionAddress, tangleship])

  useEffect(() => {
    checkTokenTemplate()
  }, [checkTokenTemplate])

  const checkSupply = useCallback(async () => {
    const addr = market?.auctionToken?.addr?.trim()?.toLowerCase()
    if (!addr) return

    const storageKey = `ihubTokenSupply${chainId}${addr}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setTotalTokenSupply(Number(storageValue))
      return
    }

    const supply = await tangleship?.getTokenSupply(addr)
    sessionStorage.setItem(storageKey, String(supply))
    setTotalTokenSupply(Number(supply))
  }, [chainId, market?.auctionAddress, tangleship])

  useEffect(() => {
    checkSupply()
  }, [checkSupply])

  const checkPurplelist = useCallback(async () => {
    const auctionType = market?.auctionType
    const addr = market?.auctionAddress
    if (!addr || !auctionType) return

    const storageKey = `ihubPurplelist${chainId}${addr}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setPurplelistAddress(storageValue)
      return
    }

    const purplelist = await tangleship?.getPurplelistAddress(auctionType, addr)
    localStorage.setItem(storageKey, purplelist)
    setPurplelistAddress(purplelist)
  }, [chainId, market?.auctionAddress, market?.auctionType, tangleship])

  useEffect(() => {
    checkPurplelist()
  }, [checkPurplelist])

  const checkMaxAllocation = useCallback(
    async (refetch = false) => {
      const addr = purplelistAddress
      if (!addr || purplelistAddress === ZERO_ADDRESS) {
        setPurplelistMaxAllocation(null)
        setPurplelistStatus("public")
        return
      }
      if (!market?.paymentToken?.decimals) return

      const storageKey = `ihubMaxAlloc${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setPurplelistMaxAllocation(Number(storageValue))
        setPurplelistStatus(Number(storageValue) > 0 ? "restricted" : "private")
        return
      }

      const data = await tangleship?.checkPurplelistMaxAllocation(account, addr)
      const allocation =
        Number(data?._hex) / 10 ** market?.paymentToken?.decimals || 0
      sessionStorage.setItem(storageKey, String(allocation))
      setPurplelistMaxAllocation(allocation)
      setPurplelistStatus(allocation > 0 ? "restricted" : "private")
    },
    [
      account,
      chainId,
      market?.paymentToken?.decimals,
      purplelistAddress,
      tangleship,
    ]
  )

  useEffect(() => {
    checkMaxAllocation(false)
  }, [checkMaxAllocation])

  useEffect(() => {
    if (!watch("amount") || paymentTokenAllowance === undefined) return
    setPaymentAllowanceIsNotEnough(
      !!watch("amount") &&
        Number(paymentTokenAllowance) < Number(watch("amount"))
    )
  }, [paymentTokenAllowance, watch("amount")])

  const approveAuctionCreator = () => {
    setModalTXError(false)
    setTokenApprovalPending(true)
    setTokenApprovalModal(true)
    setPendingTransaction(true)
    tangleship
      .approveContract(market?.paymentToken?.addr, market?.auctionAddress)
      .then((res) => {
        setModalTXError(false)
        setTokenApproving(true)
        setTokenApprovalPending(false)
        setTokenApprovalApproved(false)
        setTokenApprovalModal(true)

        if (res?.tx !== null) {
          setModalTXError(false)
          setTokenApprovalTx(res?.hash)
          res.tx?.wait().then((receipt) => {
            calculateAllowance(true)

            setModalTXError(false)
            setPaymentAllowanceIsNotEnough(false)
            setTokenApproving(false)
            setTokenApprovalPending(false)
            setTokenApprovalApproved(true)
            setTokenApprovalModal(false)
            setPendingTransaction(false)

            TangleToast.success("Transaction successful", {
              description: `Token approval successful`,
            })
          })
        } else {
          setModalTXError(true)
          setTokenApproving(false)
          setTokenApprovalPending(false)
          setTokenApprovalApproved(true)
          setTokenApprovalModal(true)
          setTokenApprovalTx(res?.hash)
          setPendingTransaction(false)
          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }

  const showConfirmationModal = () => {
    setModalTXError(false)
    setConfirmationModal(!confirmationModal)
  }

  const prepParticipateInAuctionFn = () => {
    const payDecimals = market?.paymentToken?.decimals
    const amount = Number(watch("amount")).toFixed(payDecimals)

    const fn = tangleship?.participateInAuction
    const fnParams = [
      String(amount),
      paymentCurrency?.address,
      payDecimals,
      market?.auctionType,
      market?.auctionAddress,
      account,
    ] as const

    const fnFeedbackParams = { purplelistMaxAllocation }
    const fnFeedback = (params) => {
      if (params.purplelistMaxAllocation) checkMaxAllocation(true)
      refetchSingleAuction()
      calculateMyCommitment(true)
      setValue("amount", "")
      // tangleship?.getTokenBalance(account, paymentCurrency?.address, market?.paymentToken?.decimals)
      //   .then((res) => setTokenBalance(parseFloat(res.toString()))) // already done via WSS subscription
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }

  const participateFunc = () => {
    setModalTXError(false)
    setParticipateApproving(true)
    setParticipatePending(true)
    setParticipateApproved(false)
    setParticipateModal(true)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback, fnFeedbackParams } =
      prepParticipateInAuctionFn()

    fn?.(...fnParams)?.then((res) => {
      setModalTXError(false)
      if (res?.tx !== null) {
        setModalTXError(false)
        res.tx?.wait().then((receipt) => {
          fnFeedback(fnFeedbackParams)

          setParticipateApproving(false)
          setParticipatePending(false)
          setParticipateApproved(true)
          setParticipateModal(true)
          setParticipateTx(res?.hash)
          setModalTXError(false)
          setPendingTransaction(false)

          TangleToast.success("Transaction successful", {
            description: `Auction commitment successful`,
          })

          if (!!checkAgreement) return
          dispatch(updateAuctionToggleStatus(true))
        })
      } else {
        setModalTXError(true)
        setParticipateApproving(false)
        setParticipatePending(false)
        setParticipateApproved(true)
        setParticipateModal(true)
        setParticipateTx(res?.hash)
        setPendingTransaction(false)

        TangleToast.error("Transaction rejected", {
          description: `User rejected transaction`,
        })
      }
    })
  }

  const prepWithdrawFromAuctionFn = () => {
    const fn = tangleship?.withdrawTokensFromAuction
    const fnParams = [market?.auctionType, market?.auctionAddress] as const

    const fnFeedback = (params?) => {
      refetchSingleAuction()
      // calculateAllCommitment()
      // calculateMyCommitment()
      userClaimable(true)
    }

    return { fn, fnParams, fnFeedback }
  }

  const withdrawTokensFromAuction = () => {
    setModalTXError(false)
    setWithdrawTokenApproving(true)
    setWithdrawTokenPending(true)
    setWithdrawTokenApproved(false)
    setWithdrawTokenModal(true)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback } = prepWithdrawFromAuctionFn()

    fn?.(...fnParams)?.then((res) => {
      setModalTXError(false)
      if (res?.tx !== null) {
        setModalTXError(false)
        res.tx?.wait().then((receipt) => {
          fnFeedback()

          setModalTXError(false)
          setWithdrawTokenApproving(false)
          setWithdrawTokenPending(false)
          setWithdrawTokenApproved(true)
          setWithdrawTokenModal(true)
          setWithdrawTokenTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.success("Transaction successful", {
            description: `Withdrawal successful`,
          })
        })
      } else {
        setModalTXError(true)
        setWithdrawTokenApproving(false)
        setWithdrawTokenPending(false)
        setWithdrawTokenApproved(true)
        setWithdrawTokenModal(true)
        setWithdrawTokenTx(res?.hash)
        setPendingTransaction(false)
        setPendingTransaction(false)

        TangleToast.error("Transaction rejected", {
          description: `User rejected transaction`,
        })
        return
      }
    })
  }

  const prepFinalizeAuctionFn = () => {
    const isFinalizeWithLauncher =
      !!postAuctionLiqLauncher && (isAdmin || isOperator)

    let fn, fnParams
    if (isFinalizeWithLauncher) {
      fn = tangleship?.finalizeLPLauncher
      fnParams = [
        postAuctionLiqLauncher,
        market?.currentTokenPrice,
        auctionCurrency?.address,
        paymentCurrency?.address,
        auctionCurrency?.decimals,
        market?.paymentToken?.decimals,
        launcherInfo?.feeTier,
      ] as const
    } else {
      fn = tangleship?.finalizeAuction
      fnParams = [market?.auctionType, market?.auctionAddress] as const
    }

    const fnFeedback = (params?) => {
      refetchSingleAuction()
      userClaimable(true)
    }

    return { fn, fnParams, fnFeedback }
  }

  const finalizeAuction = () => {
    setModalTXError(false)
    setFinalizeAuctionApproving(true)
    setFinalizeAuctionPending(true)
    setFinalizeAuctionApproved(false)
    setFinalizeAuctionModal(true)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback } = prepFinalizeAuctionFn()

    fn?.(...fnParams)?.then((res) => {
      if (res?.tx !== null) {
        res.tx?.wait().then((receipt) => {
          fnFeedback()

          setModalTXError(false)
          setNeedsToBeFinalized(false)
          setFinalizeAuctionApproving(false)
          setFinalizeAuctionPending(false)
          setFinalizeAuctionApproved(true)
          setFinalizeAuctionModal(true)
          setFinalizeAuctionTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.success("Transaction successful", {
            description: `Finalize auction successful`,
          })
        })
      } else {
        setModalTXError(true)
        setFinalizeAuctionApproving(false)
        setFinalizeAuctionPending(false)
        setFinalizeAuctionApproved(true)
        setFinalizeAuctionModal(true)
        setFinalizeAuctionTx(res?.hash)
        setPendingTransaction(false)
        TangleToast.error("Transaction rejected", {
          description: `User rejected transaction`,
        })
        return
      }
    })
  }

  const calculateCommitment = useCallback(
    async (refetch = false) => {
      const auctionType = market?.auctionType
      const addr = market?.auctionAddress
      if (
        !addr ||
        !auctionType ||
        !tokenBalance ||
        !market?.paymentToken?.decimals
      )
        return

      const storageKey = `ihubCalcCommit${chainId}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setCommitmentAmount(Number(storageValue))
        return
      }

      const data = await tangleship?.calculateCommitment(
        auctionType,
        addr,
        ethers.utils.parseUnits(
          String(tokenBalance),
          market?.paymentToken?.decimals
        )
      )

      if (data === null) {
        setCommitmentAmount(null)
        return
      }
      const maxCommitment = ethers.utils.formatUnits(
        data,
        market?.paymentToken?.decimals
      )
      sessionStorage.setItem(storageKey, maxCommitment)
      setCommitmentAmount(Number(maxCommitment))
    },
    [
      chainId,
      market?.auctionAddress,
      market?.auctionType,
      tokenBalance,
      market?.paymentToken?.decimals,
      tangleship,
    ]
  )

  useEffect(() => {
    calculateCommitment(false)
  }, [calculateCommitment])

  const calculateAllCommitment = useCallback(
    async (refetch = false) => {
      const auctionType = market?.auctionType
      const addr = market?.auctionAddress
      if (!addr || !auctionType || !market?.paymentToken?.decimals) return

      const storageKey = `ihubAllCommit${chainId}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        const commitments = JSON.parse(storageValue)
        setAllCommitments(commitments)
        setLastCommitment(commitments && commitments[commitments?.length - 1])
        return
      }

      const commitments = await tangleship?.getAllCommitments(
        addr,
        market?.paymentToken?.addr,
        market?.paymentToken?.decimals
      )
      if (commitments?.length !== undefined)
        sessionStorage.setItem(storageKey, JSON.stringify(commitments))
      setAllCommitments(commitments)
      setLastCommitment(commitments && commitments[commitments?.length - 1])
    },
    [
      chainId,
      market?.auctionAddress,
      market?.auctionType,
      market?.paymentToken?.addr,
      market?.paymentToken?.decimals,
      tangleship,
    ]
  )

  useEffect(() => {
    calculateAllCommitment(false)
  }, [calculateAllCommitment])

  const calculateMyCommitment = useCallback(
    async (refetch = false) => {
      const auctionType = market?.auctionType
      const addr = market?.auctionAddress
      if (!addr || !account || !auctionType || !market?.paymentToken?.decimals)
        return

      const storageKey = `ihubMyCommit${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setMyCommitments(Number(storageValue))
        return
      }

      const data = await tangleship?.getUserCommitments(
        account,
        auctionType,
        addr
      )
      const userCommitments =
        Number(data?._hex) / 10 ** market?.paymentToken?.decimals
      sessionStorage.setItem(storageKey, String(userCommitments))
      setMyCommitments(userCommitments)
    },
    [
      account,
      chainId,
      market?.auctionAddress,
      market?.auctionType,
      market?.paymentToken?.decimals,
      tangleship,
    ]
  )

  useEffect(() => {
    calculateMyCommitment(false)
  }, [calculateMyCommitment])

  const userClaimable = useCallback(
    async (refetch = false) => {
      const addr = market?.auctionAddress
      const decimals = auctionCurrency?.decimals
      if (!addr || !decimals) return
      if (!account) {
        setTokensClaimable(null)
        setTokensAlreadyClaimed(null)
        return
      }

      const storageKey0 = `ihubTokensClaimable${chainId}${addr}`
      const storageKey1 = `ihubTokensClaimed${chainId}${addr}`
      const storageValue0 = sessionStorage.getItem(storageKey0)
      const storageValue1 = sessionStorage.getItem(storageKey1)
      if (storageValue0 !== null && storageValue1 !== null && !refetch) {
        setTokensClaimable(Number(storageValue0))
        setTokensAlreadyClaimed(Number(storageValue1))
        return
      }

      const data = await tangleship?.getUserMarketInfo(account, addr)
      if (!data) {
        setTokensClaimable(null)
        setTokensAlreadyClaimed(null)
        return
      }

      const var0 = data?.tokensClaimable ?? BigInt("0")
      const var1 = data?.claimed ?? BigInt("0")
      const claimable = ethers.utils.formatUnits(var0, decimals)
      const claimed = ethers.utils.formatUnits(var1, decimals)

      sessionStorage.setItem(storageKey0, claimable)
      sessionStorage.setItem(storageKey1, claimed)
      setTokensClaimable(Number(claimable))
      setTokensAlreadyClaimed(Number(claimed))
    },
    [
      account,
      auctionCurrency?.decimals,
      chainId,
      market?.auctionAddress,
      tangleship,
    ]
  )

  useEffect(() => {
    userClaimable(false)
  }, [userClaimable])

  useEffect(() => {
    if (
      tokensClaimable === undefined ||
      tokensAlreadyClaimed === undefined ||
      !auctionCurrency?.symbol ||
      !paymentCurrency?.symbol
    )
      return

    const claimable = formatNumber(tokensClaimable, 3)
    const claimed = formatNumber(tokensAlreadyClaimed, 3)
    const claimableCurrency = showConfetti ? auctionCurrency : paymentCurrency
    const currencySymbol = claimableCurrency?.symbol
    const tokenClaimableValue =
      tokensClaimable > 0 ? `${claimable} ${currencySymbol}` : null
    const tokensClaimedValue =
      tokensAlreadyClaimed > 0 ? `${claimed} ${currencySymbol}` : null

    setClaimableCurrency(claimableCurrency)
    setTokensClaimableString(tokenClaimableValue)
    setTokensClaimedString(tokensClaimedValue)
  }, [
    auctionCurrency?.symbol,
    paymentCurrency?.symbol,
    showConfetti,
    tokensAlreadyClaimed,
    tokensClaimable,
  ])

  useEffect(() => {
    if (
      market?.auctionStatus === undefined ||
      market?.isFinalized === undefined ||
      market?.isSuccessful === undefined ||
      isAdmin === undefined ||
      timeUntilFinalize === undefined ||
      isOperator === undefined
    )
      return

    setUserAbleToFinalize(isAdmin || isOperator || timeUntilFinalize === 0)
    const isShowFinalize =
      AuctionStatus[market?.auctionStatus] === "FINISHED" &&
      market?.isFinalized === false
    setNeedsToBeFinalized(isShowFinalize)
    setShowFinalizePopup(isShowFinalize)

    setShowConfetti(market?.isSuccessful)
    setShowWithdrawTokens(market?.isFinalized && tokensClaimable > 0)
    setShowAlreadyWithdrawnTokens(
      market?.isFinalized && tokensAlreadyClaimed > 0
    )
  }, [
    isAdmin,
    isOperator,
    market?.auctionStatus,
    market?.isFinalized,
    market?.isSuccessful,
    timeUntilFinalize,
    tokensAlreadyClaimed,
    tokensClaimable,
  ])

  const closeFinalize = () => {
    setShowFinalizePopup(false)
  }
  const closeFinalizeModal = useCallback(
    (event: any) => {
      if (buttonRef.current !== event?.target) {
        setShowFinalizePopup(false)
      }
    },
    [buttonRef]
  )

  const findDocumentData = (info?: any) => {
    const data = market?.documents?.find((data) => {
      const value =
        String(data.name).trim().toLowerCase() ===
        String(info).trim().toLowerCase()
      return value
    })
    return data
  }

  const checkAuctionStatusConfetti = async () => {
    return AuctionStatus[market?.auctionStatus]
  }
  useEffect(() => {
    if (
      !AuctionStatus[market?.auctionStatus] ||
      prevShowChangeState === AuctionStatus[market?.auctionStatus]
    )
      return
    if (!prevShowChangeState) {
      setPrevShowChangeState(AuctionStatus[market?.auctionStatus])
      return
    }
    checkAuctionStatusConfetti().then((res) => {
      if (!res) return
      setPrevShowChangeState(res)
      setShowChangeState(true)
      setTimeout(() => {
        setShowChangeState(false)
      }, 5000)
    })
  }, [market?.auctionStatus])

  const lpBodyClick = (e: any) => {
    if (e?.target === lpModalRef.current) {
      setConfirmationModal(!confirmationModal)
    }
  }

  const formattedCommitmentData = useMemo(() => {
    if (allCommitments) {
      let cumulativeAmount = 0
      return allCommitments
        .sort((a, b) => Number(a?.timestamp) - Number(b?.timestamp))
        ?.map((data: CommitmentProps, index: number) => {
          cumulativeAmount += Number(data?.amount) // Accumulate the amounts
          return {
            value: cumulativeAmount,
            time: Number(data?.timestamp),
            amount: Number(data?.amount),
            hash: data.hash,
            currency: paymentCurrency?.symbol,
          }
        })
    } else {
      return []
    }
  }, [allCommitments, paymentCurrency?.symbol])

  useEffect(() => {
    if (allCommitments && allCommitments?.length < 1) {
      setActiveGraphTab(1)
    } else {
      setActiveGraphTab(2)
    }
  }, [allCommitments])

  const lastQueuedCommitment = useRef({
    timerId: null,
    queuedTxCounter: null,
  })

  const registerNewCommitment = (txCounter) => {
    if (lastQueuedCommitment.current.timerId) {
      lastQueuedCommitment.current.queuedTxCounter = txCounter
      return
    }

    refetchSingleAuction()
    setTimeout(() => calculateAllCommitment(true), 5000) // the API can be a bit slow sometimes
    calculateCommitment(true)

    lastQueuedCommitment.current.timerId = setTimeout(() => {
      if (lastQueuedCommitment.current.queuedTxCounter !== null) {
        registerNewCommitment(lastQueuedCommitment.current.queuedTxCounter)
        lastQueuedCommitment.current.queuedTxCounter = null
      }
      lastQueuedCommitment.current.timerId = null
    }, 1000 * 5) // only allow RPC calls at most every 5 seconds to avoid getting throttled
  }

  useEffect(() => {
    if (!market?.auctionType || !market?.auctionAddress) return

    tangleship?.subscribeAuctionCommit(
      market.auctionType,
      market.auctionAddress,
      registerNewCommitment
    )

    const timerId = lastQueuedCommitment.current.timerId
    return () => {
      if (timerId) clearTimeout(timerId)
      // unsubscribe()
    }
  }, [market?.auctionType, market?.auctionAddress, registerNewCommitment])

  const [wrapData, setWrapData] = useState<ILaunchPadData>(undefined)
  const {
    isVisible: isParticipateWSCModalVisible,
    toggle: toggleParticipateWSCModal,
  } = useParticipateModalControl()
  const {
    isVisible: isFinalizeWSCModalVisible,
    toggle: toggleFinalizeWSCModal,
  } = useFinalizeModalControl()
  const {
    isVisible: isWithdrawWSCModalVisible,
    toggle: toggleWithdrawWSCModal,
  } = useWithdrawModalControl()

  const isWSCReady = () => isWSCConnected

  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const fireParticipateWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback, fnFeedbackParams } =
      prepParticipateInAuctionFn()
    if (!fn) return

    const tokenAddress = [Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(
      l1ChainId
    )
      ? wrapAddr(market?.paymentToken?.addr, chainId, l1ChainId).tokenAddress
      : market?.paymentToken?.addr

    console.log("token check", {
      tokenAddress,
      tangleswapTokenListOnChain,
    })

    const WrapCurrency = tangleswapTokenListOnChain?.find(
      (token: any) => token.address.toLowerCase() === tokenAddress.toLowerCase()
    )

    console.log("WrapCurrency", WrapCurrency)
    console.log("Token", market?.paymentToken)

    fn?.(...fnParams).then((res: any) => {
      const tokenInData = {
        ...WrapCurrency,
        amount: Number(watch("amount")),
        unit: getTokenUnit(WrapCurrency.l1Address),
      }

      setWrapData({
        tokenIn: tokenInData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: fnFeedbackParams,
        },
        auctionAddress: market?.auctionAddress,
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        setConfirmationModal(false)
        toggleParticipateWSCModal()
      }, 0)
    })
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    setConfirmationModal(false)
    toggleParticipateWSCModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleParticipateWSCModal()
    }
  }

  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    setConfirmationModal(false)
    toggleParticipateWSCModal()
  }
  const fireFinalizeWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepFinalizeAuctionFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
        auctionAddress: market?.auctionAddress,
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleFinalizeWSCModal()
      }, 0)
    })
  }

  const fireWithdrawWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepWithdrawFromAuctionFn()
    if (!fn) return

    const UnwrapCurrency = tangleswapTokenListOnChain?.find(
      (token: any) =>
        String(token.address).toLowerCase() ===
        String(claimableCurrency?.address).toLowerCase()
    )

    fn?.(...fnParams).then((res: any) => {
      const tokenOutData = {
        ...UnwrapCurrency,
        amount: tokensClaimable,
        unit: getTokenUnit(claimableCurrency?.l1Address),
      }

      setWrapData({
        tokenOut: tokenOutData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
        auctionAddress: market?.auctionAddress,
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWithdrawWSCModal()
      }, 0)
    })
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWithdrawWSCModal()
    setOpenModalSettings(false)
  }

  return (
    <AuctionBodyWrapper>
      {!!confirmationModal ? (
        <>
          <AuctionParticipateModal
            closeRemoveModal={showConfirmationModal}
            lpBodyClick={lpBodyClick}
            lpModalRef={lpModalRef}
            clickParticipate={
              isWSCConnected ? fireParticipateWSCModal : participateFunc
            }
            inputLogo={paymentCurrency?.logoURI}
            inputSymbol={paymentCurrency?.symbol}
            outputLogo={auctionCurrency?.logoURI}
            outputSymbol={auctionCurrency?.symbol}
            inputValue={watch("amount")}
            modalTXError={modalTXError}
            outputValue={
              Number(watch("amount")) / Number(market?.currentAuctionPrice)
            }
          />
        </>
      ) : null}
      {isWSCConnected && (
        <ParticipateModal
          isVisible={isParticipateWSCModalVisible}
          toggleModal={toggleParticipateWSCModal}
          wrapData={wrapData}
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          cancelTransaction={cancelTransaction}
          wscModalRef={wscModalRef}
          closeWSCContinue={closeWSCContinue}
        />
      )}
      {isWSCConnected && (
        <FinalizeModal
          isVisible={isFinalizeWSCModalVisible}
          toggleModal={toggleFinalizeWSCModal}
          wrapData={wrapData}
          cancelTransaction={cancelTransaction}
          wscModalRef={wscModalRef}
          closeWSCContinue={closeWSCContinue}
        />
      )}
      {isWSCConnected && (
        <WithdrawModal
          isVisible={isWithdrawWSCModalVisible}
          toggleModal={toggleWithdrawWSCModal}
          wrapData={wrapData}
          cancelTransaction={cancelTransaction}
          wscModalRef={wscModalRef}
          closeWSCContinue={closeWSCContinue}
        />
      )}
      <AuctionDetailsConfetti showFinalize={showChangeState} />
      {tokenApprovalModal ? (
        <>
          <ApproveAuctionModal
            pending={tokenApprovalPending}
            link={tokenApprovalTx}
            modalTXError={modalTXError}
            modalRef={tokenApprovalModalRef}
            closeModal={closeTokenApprovalModal}
            closePendingModal={closeTokenApprovalPendingModal}
            closeConfirmationModal={closeTokenApprovalConfirmationModal}
            tokenSymbol={paymentCurrency?.symbol}
          />
        </>
      ) : null}
      {participateModal ? (
        <>
          <ParticipateAuctionModal
            pending={participatePending}
            link={participateTx}
            modalTXError={modalTXError}
            modalRef={participateModalRef}
            closeModal={closeParticipateModal}
            closePendingModal={closeParticipatePendingModal}
            closeConfirmationModal={closeParticipateConfirmationModal}
            tokenSymbol={paymentCurrency?.symbol}
            tokenAmount={formatNumber(watch("amount"), 4)}
          />
        </>
      ) : null}
      {finalizeAuctionModal ? (
        <>
          <FinalizeAuctionModal
            pending={finalizeAuctionPending}
            link={finalizeAuctionTx}
            modalTXError={modalTXError}
            modalRef={finalizeAuctionModalRef}
            closeModal={closeFinalizeAuctionModal}
            closePendingModal={closeFinalizeAuctionPendingModal}
            closeConfirmationModal={closeFinalizeAuctionConfirmationModal}
          />
        </>
      ) : null}

      {withdrawTokenModal ? (
        <>
          <AuctionWithdrawTokenModal
            pending={withdrawTokenPending}
            link={withdrawTokenTx}
            modalTXError={modalTXError}
            modalRef={withdrawTokenModalRef}
            closeModal={closeWithdrawTokenModal}
            closePendingModal={closeWithdrawTokenPendingModal}
            closeConfirmationModal={closeWithdrawTokenConfirmationModal}
            tokenSymbol={auctionCurrency?.symbol}
            tokenAmount={formatNumber(tokensClaimable, 3)}
          />
        </>
      ) : null}
      <TopBar status={AuctionStatus[market?.auctionStatus]}>
        <Cover>
          {showFinalizePopup ? (
            <>
              <ReactConfetti
                finalizeState={
                  finalizeAuctionApproving && !finalizeAuctionApproved
                }
                buttonRef={buttonRef}
                timeUntilFinalizeWindowExpires={timeUntilFinalizeCounter}
                closeFinalize={closeFinalize}
                finalizeAuction={
                  isWSCConnected
                    ? () => {
                        closeFinalize()
                        fireFinalizeWSCModal()
                      }
                    : finalizeAuction
                }
                success={showConfetti}
                showFinalize={showFinalizePopup}
                userAbleToFinalize={userAbleToFinalize}
                closeFinalizeModal={closeFinalizeModal}
                showWithdrawTokens={showWithdrawTokens}
                showAlreadyWithdrawnTokens={showAlreadyWithdrawnTokens}
                isAdmin={isAdmin}
                isOperator={isOperator}
              />
            </>
          ) : null}

          <TopRow>
            <TopBasicInfo>
              <RowLeft>
                <GoBackButton link="/invest" />
              </RowLeft>
              {!!market &&
              !!auctionCurrency &&
              isAdmin !== undefined &&
              tokensAlreadyClaimed !== undefined ? (
                <TopName>
                  {!!market?.document?.logo ? (
                    <>
                      <TopSymbolIcon src={market?.document?.logo} />
                    </>
                  ) : (
                    <CustomSizeWrapper>
                      <ConvertTokenSymbol text={auctionCurrency?.symbol} />
                    </CustomSizeWrapper>
                  )}

                  <span>
                    <TopSymbol>{auctionCurrency?.symbol}</TopSymbol>
                    {auctionCurrency?.name}
                  </span>
                </TopName>
              ) : (
                <AuctionPageLoader />
              )}
            </TopBasicInfo>

            {(market && market?.length > 0 && !singleAuctionLoading) ||
            (AuctionStatus[market?.auctionStatus] !== undefined &&
              !singleAuctionLoading) ||
            (market?.endTime !== undefined && !singleAuctionLoading) ||
            (!!market &&
              AuctionStatus[market?.auctionStatus] !== undefined &&
              market?.endTime !== undefined &&
              !singleAuctionLoading &&
              isAdmin !== undefined &&
              tokensAlreadyClaimed !== undefined) ? (
              <>
                <SaleInfoContainer>
                  <TopSaleIndicatorText
                    status={AuctionStatus[market?.auctionStatus]}
                  >
                    SALE {AuctionStatus[market?.auctionStatus]}
                  </TopSaleIndicatorText>
                  {AuctionStatus[market?.auctionStatus] ===
                  "FINISHED" ? null : (
                    <SaleTimer>
                      <SaleTimerNumber>
                        <TimeRemaining
                          targetTimestamp={
                            AuctionStatus[market?.auctionStatus] === "UPCOMING"
                              ? market?.startTime
                              : market?.endTime
                          }
                          onTimeRemainingChange={handleTimeRemainingChange}
                        />
                      </SaleTimerNumber>
                    </SaleTimer>
                  )}
                </SaleInfoContainer>
              </>
            ) : (
              <>
                <AuctionPageLoader />
              </>
            )}
            <TopAuctionInfo>
              {isAdmin || isOperator ? (
                <Link to={`/invest/${market?.auctionAddress}/edit`}>
                  <EditButton>Edit Auction</EditButton>
                </Link>
              ) : null}
              {!!market?.currentAuctionPrice ? (
                <>
                  <TopAuctionText>
                    AUCTION PRICE
                    {/* <HelpIcon /> */}
                  </TopAuctionText>

                  <TopAuctionPrice>
                    {formatNumber(Number(market?.currentAuctionPrice), 3)}{" "}
                    {paymentCurrency?.symbol}
                  </TopAuctionPrice>
                </>
              ) : null}
            </TopAuctionInfo>
          </TopRow>
        </Cover>
      </TopBar>
      <Body>
        <Cover>
          <MainContainer>
            <MainLeft>
              {!!market &&
              isAdmin !== undefined &&
              tokensAlreadyClaimed !== undefined ? (
                <>
                  <Informations>
                    <Row>
                      {!!market?.category ? (
                        <>
                          <CategoryBubble category={market?.category}>
                            {market?.category}
                          </CategoryBubble>
                        </>
                      ) : null}
                      {!!market?.auctionType ? (
                        <>
                          {" "}
                          {market?.auctionType === "dutch" ? (
                            <AuctionTypeImage auctionBg={dutchSVG} />
                          ) : market?.auctionType === "batch" ? (
                            <AuctionTypeImage auctionBg={batchSVG} />
                          ) : (
                            <AuctionTypeImage auctionBg={crowdSVG} />
                          )}
                        </>
                      ) : null}

                      <AuctionTypeText>
                        {!!market?.auctionType
                          ? market?.auctionType === "dutch"
                            ? "Dutch Auction"
                            : market?.auctionType === "batch"
                            ? "Fair Launch"
                            : auctionData.auctionType === "crowdsale"
                            ? "Crowdsale"
                            : "Not Set" || "NOT FOUND"
                          : null}
                      </AuctionTypeText>
                      {!!purplelistStatus ? (
                        <>
                          {" "}
                          <RestrictedRow
                            color={
                              purplelistStatus === "public"
                                ? TangleColors.tangleGreen
                                : purplelistStatus === "private"
                                ? TangleColors.red
                                : TangleColors.tangleYellow
                            }
                          >
                            {purplelistStatus === "public" ? (
                              <>
                                {" "}
                                <GlobleMainIcon
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                />{" "}
                                <RestrictedText
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                >
                                  {" "}
                                </RestrictedText>
                              </>
                            ) : null}
                            {purplelistStatus === "private" ? (
                              <>
                                {" "}
                                <GlobleLockIcon
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                />
                                <RestrictedText
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                >
                                  Private
                                </RestrictedText>
                              </>
                            ) : null}
                            {purplelistStatus === "restricted" ? (
                              <>
                                {" "}
                                <GlobleUnlockIcon
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                />
                                <RestrictedText
                                  color={
                                    purplelistStatus === "public"
                                      ? TangleColors.tangleGreen
                                      : purplelistStatus === "private"
                                      ? TangleColors.red
                                      : TangleColors.tangleYellow
                                  }
                                >
                                  Restricted
                                </RestrictedText>
                              </>
                            ) : null}
                          </RestrictedRow>
                        </>
                      ) : null}
                    </Row>

                    <Technicals>
                      {market?.documents?.length > 0 ? (
                        <>
                          <Row>
                            <TechnicalInfoText>
                              Technical Information
                            </TechnicalInfoText>
                          </Row>
                          <Row gap={32}>
                            {!!market?.documents &&
                            findDocumentData("website")?.data ? (
                              <>
                                <TechnicalBlock>
                                  <WebsiteIcon />
                                  <TechnicalItem
                                    href={findDocumentData("website")?.data}
                                  >
                                    Website
                                  </TechnicalItem>
                                </TechnicalBlock>
                              </>
                            ) : null}
                            {!!market?.documents &&
                            findDocumentData("whitepaper")?.data ? (
                              <>
                                <TechnicalBlock>
                                  <WhitepaperIcon />
                                  <TechnicalItem
                                    href={findDocumentData("whitepaper")?.data}
                                  >
                                    Whitepaper
                                  </TechnicalItem>
                                </TechnicalBlock>
                              </>
                            ) : null}

                            {!!market?.documents &&
                            findDocumentData("tokenomics")?.data ? (
                              <>
                                <TechnicalBlock>
                                  <WhitepaperIcon />
                                  <TechnicalItem
                                    href={findDocumentData("tokenomics")?.data}
                                  >
                                    Tokenomics
                                  </TechnicalItem>
                                </TechnicalBlock>
                              </>
                            ) : null}
                          </Row>
                        </>
                      ) : null}
                    </Technicals>
                    {market?.documents?.length > 0 && !singleAuctionLoading ? (
                      <>
                        <Socials>
                          <Row>
                            <SocialInfoText>Socials</SocialInfoText>
                          </Row>
                          <Row gap={32}>
                            {!!market?.documents &&
                            findDocumentData("github")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialGithub />
                                  <SocialText
                                    href={findDocumentData("github")?.data}
                                  >
                                    GitHub
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}
                            {!!market?.documents &&
                            findDocumentData("telegram")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialTelegram />
                                  <SocialText
                                    href={findDocumentData("telegram")?.data}
                                  >
                                    Telegram
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}
                            {!!market?.documents &&
                            findDocumentData("wechat")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialWechat />
                                  <SocialText
                                    href={findDocumentData("wechat")?.data}
                                  >
                                    WeChat
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}
                            {!!market?.documents &&
                            findDocumentData("discord")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialDiscord />
                                  <SocialText
                                    href={findDocumentData("discord")?.data}
                                  >
                                    Discord
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}
                            {!!market?.documents &&
                            findDocumentData("medium")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialMedium />
                                  <SocialText
                                    href={findDocumentData("medium")?.data}
                                  >
                                    Medium
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}

                            {!!market?.documents &&
                            findDocumentData("reddit")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialReddit />
                                  <SocialText
                                    href={findDocumentData("reddit")?.data}
                                  >
                                    Reddit
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}

                            {!!market?.documents &&
                            findDocumentData("docs")?.data ? (
                              <>
                                <SocialBlock>
                                  <SocialDocs />
                                  <SocialText
                                    href={findDocumentData("docs")?.data}
                                  >
                                    Documents
                                  </SocialText>
                                </SocialBlock>
                              </>
                            ) : null}
                          </Row>
                        </Socials>
                      </>
                    ) : null}
                  </Informations>
                </>
              ) : (
                <>
                  {" "}
                  <AuctionPageLoader />
                  <AuctionPageLoader />
                  <AuctionPageLoader />
                </>
              )}

              <Participate>
                {AuctionStatus[market?.auctionStatus] === "FINISHED" ? (
                  <>
                    <AuctionClaimRow>
                      <AutionClaimTitle>Claim Tokens</AutionClaimTitle>
                      <AuctionClaimSubText>
                        {needsToBeFinalized ? (
                          <>
                            {showConfetti ? (
                              <>
                                {userAbleToFinalize ? (
                                  <>
                                    This auction succeeded in meeting the
                                    minimum raise! You can now finalize this
                                    auction, after which{" "}
                                    {tokensClaimableString
                                      ? `you will be able to claim your ${tokensClaimableString}.`
                                      : `everyone will be able to claim their tokens.`}
                                  </>
                                ) : (
                                  <>
                                    This auction suceeded to reach its minimum
                                    raise! Before{" "}
                                    {tokensClaimableString
                                      ? `you can claim your ${tokensClaimableString}`
                                      : `anyone can claim their tokens`}
                                    , the admin needs to finalize the auction
                                    within 7 days of its end date. After this
                                    time, anyone will be able to finalize it.
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {userAbleToFinalize ? (
                                  <>
                                    This auction failed to reach its minimum
                                    raise. You can now finalize this auction,
                                    after which{" "}
                                    {tokensClaimableString
                                      ? `you will be able to claim your ${tokensClaimableString}.`
                                      : `everyone will be able to claim their tokens.`}
                                  </>
                                ) : (
                                  <>
                                    This auction failed to reach its minimum
                                    raise. Before{" "}
                                    {tokensClaimableString
                                      ? `you can claim your ${tokensClaimableString}`
                                      : `anyone can claim their tokens`}
                                    , the admin needs to finalize the auction
                                    within 7 days of its end date. After this
                                    time, anyone will be able to finalize it.
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <>
                              {market?.isSuccessful === true ? (
                                <>
                                  This auction finished successfully
                                  {showWithdrawTokens
                                    ? `! You can
                                  now claim your well-earned ${tokensClaimableString} tokens.`
                                    : showAlreadyWithdrawnTokens
                                    ? `, and you already claimed all your ${tokensClaimedString} tokens.`
                                    : "."}
                                </>
                              ) : (
                                <>
                                  This auction did not raise the minimum funds
                                  required
                                  {showWithdrawTokens
                                    ? `. You can
                                  now claim your ${tokensClaimableString} payment tokens.`
                                    : showAlreadyWithdrawnTokens
                                    ? `, and you already retrieved all your ${tokensClaimedString} tokens.`
                                    : "."}
                                </>
                              )}
                            </>
                          </>
                        )}
                      </AuctionClaimSubText>
                    </AuctionClaimRow>
                  </>
                ) : (
                  <>
                    {" "}
                    {!!paymentCurrency &&
                    !singleAuctionLoading &&
                    isAdmin !== undefined &&
                    tokensAlreadyClaimed !== undefined ? (
                      <>
                        {" "}
                        <ParticipateTextRow>
                          <ParticipateText>Participate</ParticipateText>
                          <BalanceText>
                            Balance:{" "}
                            <BalanceNumber>
                              {" "}
                              {formatNumber(Number(tokenBalance ?? 0), 3)}{" "}
                              {paymentCurrency?.symbol}
                            </BalanceNumber>
                          </BalanceText>
                        </ParticipateTextRow>
                        <ParticipateInputRow
                          formError={errors.amount ? true : false}
                        >
                          {!!paymentCurrency && !!paymentCurrency?.logoURI ? (
                            <>
                              {" "}
                              <PaymentTokenLogo
                                src={paymentCurrency?.logoURI}
                              />
                            </>
                          ) : (
                            <CustomSizeWrapper>
                              <ConvertTokenSymbol
                                text={paymentCurrency?.symbol}
                              />
                            </CustomSizeWrapper>
                          )}
                          <PaymentTokenName>
                            {paymentCurrency?.symbol}
                          </PaymentTokenName>
                          <ParticipateInput
                            type="text"
                            {...register("amount", {
                              required: false,
                            })}
                            onInput={(e: any) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              )

                              const parts = e.target.value.split(".")

                              if (parts.length > 2) {
                                e.target.value =
                                  parts[0] + "." + parts.slice(1).join("")
                              }

                              if (
                                parseFloat(e.target.value) >
                                parseFloat(maxInput)
                              ) {
                                e.target.value = maxInput
                              }
                            }}
                            autoComplete="off"
                            placeholder="1,000"
                          />
                          <MaxButton
                            disabled={parseFloat(String(tokenBalance)) === 0}
                            onClick={handleMaxInput}
                          >
                            MAX{" "}
                            <InfoBodyCover>
                              {showHelpText && (
                                <>
                                  <AuctionHoverBox text="The maximum amount is the lowest of: (1) your token balance, (2) your purplelist allocation, (3) the remaining tokens for sale in this auction." />
                                </>
                              )}
                              <InfoIconWrapper
                                onMouseEnter={mouseEnter}
                                onMouseLeave={mouseLeave}
                              >
                                <InfoIcon />
                              </InfoIconWrapper>
                            </InfoBodyCover>
                          </MaxButton>
                        </ParticipateInputRow>
                      </>
                    ) : (
                      <AuctionPageLoader />
                    )}
                  </>
                )}
                <ParticipateButtonCover>
                  {" "}
                  {AuctionStatus[market?.auctionStatus] === "FINISHED" ? (
                    <>
                      <ParticipateButton
                        blockTransactionState={!!blockTransactionState}
                        onClick={
                          !!isWSCConnected && !!blockTransactionState
                            ? handlePendingTxModal
                            : needsToBeFinalized
                            ? isWSCConnected
                              ? fireFinalizeWSCModal
                              : finalizeAuction
                            : isWSCConnected
                            ? fireWithdrawWSCModal
                            : withdrawTokensFromAuction
                        }
                        disabled={
                          (needsToBeFinalized && !userAbleToFinalize) ||
                          tokensAlreadyClaimed > 0 ||
                          (market?.isFinalized && !tokensClaimable) ||
                          (withdrawTokenApproving && !withdrawTokenApproved) ||
                          (finalizeAuctionApproving &&
                            !finalizeAuctionApproved) ||
                          (participateApproving && !participateApproved) ||
                          isAdmin === undefined ||
                          tokensAlreadyClaimed === undefined
                        }
                      >
                        {!!blockTransactionState ? (
                          <>
                            <SwapButtonSpinner />
                          </>
                        ) : null}
                        {!!blockTransactionState ? (
                          <>Continue Previous Transaction</>
                        ) : (
                          <>
                            {" "}
                            {needsToBeFinalized ? (
                              <>
                                {" "}
                                {userAbleToFinalize ? (
                                  <>
                                    {finalizeAuctionApproving &&
                                    !finalizeAuctionApproved ? (
                                      <>
                                        <LoadingDots />
                                      </>
                                    ) : (
                                      <> Finalize Auction </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isAdmin || isOperator ? (
                                      <>
                                        {finalizeAuctionApproving &&
                                        !finalizeAuctionApproved ? (
                                          <>
                                            <LoadingDots />
                                          </>
                                        ) : (
                                          <> Finalize Auction </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        Time Left:{" "}
                                        {formatCountdown(
                                          timeUntilFinalizeCounter
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {showWithdrawTokens &&
                                market?.isSuccessful === true ? (
                                  <>
                                    {withdrawTokenApproving &&
                                    !withdrawTokenApproved ? (
                                      <>
                                        {" "}
                                        <LoadingDots />
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        Claim {formatNumber(
                                          tokensClaimable,
                                          3
                                        )}{" "}
                                        {auctionCurrency?.symbol}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {showWithdrawTokens &&
                                    market?.isSuccessful === false ? (
                                      <>
                                        {withdrawTokenApproving &&
                                        !withdrawTokenApproved ? (
                                          <>
                                            <LoadingDots />
                                          </>
                                        ) : (
                                          <>
                                            {withdrawTokenApproving &&
                                            !withdrawTokenApproved ? (
                                              <>
                                                <LoadingDots />
                                              </>
                                            ) : (
                                              <>
                                                Withdraw{" "}
                                                {formatNumber(
                                                  tokensClaimable,
                                                  3
                                                )}{" "}
                                                {paymentCurrency?.symbol}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {showAlreadyWithdrawnTokens ? (
                                          <>
                                            Already Claimed{" "}
                                            {formatNumber(
                                              tokensAlreadyClaimed,
                                              3
                                            )}{" "}
                                            {!!market?.isSuccessful
                                              ? auctionCurrency?.symbol
                                              : paymentCurrency?.symbol}
                                          </>
                                        ) : (
                                          <>Auction Ended</>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </ParticipateButton>
                    </>
                  ) : (
                    <>
                      {" "}
                      <ParticipateButton
                        blockTransactionState={!!blockTransactionState}
                        onClick={
                          !!isWSCConnected && !!blockTransactionState
                            ? handlePendingTxModal
                            : !!paymentAllowanceIsNotEnough
                            ? approveAuctionCreator
                            : showConfirmationModal
                        }
                        disabled={
                          (tokenApproving && !tokenApprovalApproved) ||
                          (participateApproving && !participateApproved) ||
                          (!!watch("amount") &&
                            (AuctionStatus[market?.auctionStatus] ===
                              "FINISHED" ||
                              (AuctionStatus[market?.auctionStatus] ===
                                "UPCOMING" &&
                                !paymentAllowanceIsNotEnough) ||
                              (!!tokenBalanceError &&
                                !paymentAllowanceIsNotEnough) ||
                              (!market && !paymentAllowanceIsNotEnough) ||
                              (Number(watch("amount")) === 0 &&
                                !paymentAllowanceIsNotEnough) ||
                              (!!tokenApproving &&
                                !paymentAllowanceIsNotEnough))) ||
                          !watch("amount") ||
                          isAdmin === undefined ||
                          tokensAlreadyClaimed === undefined
                        }
                      >
                        {!!blockTransactionState ? (
                          <>
                            <SwapButtonSpinner />
                          </>
                        ) : null}
                        {!!blockTransactionState ? (
                          <>Continue Previous Transaction</>
                        ) : (
                          <>
                            {" "}
                            {!!watch("amount") &&
                            !!paymentAllowanceIsNotEnough ? (
                              <>
                                {tokenApproving && !tokenApprovalApproved ? (
                                  <>
                                    Approving {paymentCurrency?.symbol}
                                    <ApproveDotWrapper>
                                      <Dots />
                                    </ApproveDotWrapper>
                                  </>
                                ) : (
                                  <>Approve {paymentCurrency?.symbol} </>
                                )}
                              </>
                            ) : (
                              <>
                                {!!watch("amount") && !!tokenBalanceError ? (
                                  <>
                                    Insufficient {""}
                                    {paymentCurrency?.symbol}
                                    {""} Balance
                                  </>
                                ) : (
                                  <>
                                    <>
                                      {" "}
                                      {!!watch("amount") ? (
                                        <>
                                          {" "}
                                          <>
                                            {participateApproving &&
                                            !participateApproved ? (
                                              <>
                                                <LoadingDots />
                                              </>
                                            ) : (
                                              <>
                                                {AuctionStatus[
                                                  market?.auctionStatus
                                                ] === "UPCOMING"
                                                  ? "Wait until auction starts"
                                                  : "Participate"}
                                              </>
                                            )}
                                          </>
                                        </>
                                      ) : (
                                        <>
                                          {!!watch("amount") &&
                                          watch("amount") >
                                            purplelistMaxAllocation ? (
                                            <>
                                              Insufficient Purplelist Allocation
                                            </>
                                          ) : (
                                            <>Enter an amount</>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </ParticipateButton>
                    </>
                  )}
                </ParticipateButtonCover>
              </Participate>
            </MainLeft>
            <MainRight>
              <InfoRow>
                {" "}
                {account && !singleAuctionLoading ? (
                  <>
                    {(!!market ||
                      (!!paymentCurrency && !singleAuctionLoading)) &&
                    isAdmin !== undefined &&
                    tokensAlreadyClaimed !== undefined ? (
                      <>
                        <InfoColumn>
                          <ValueKey>You Committed</ValueKey>
                          <>
                            <Row gap={2} align="flex-end">
                              {!!myCommitments ? (
                                <>
                                  <ValueText>
                                    {formatNumber(
                                      Number(myCommitments),
                                      3,
                                      false,
                                      true
                                    )}
                                  </ValueText>
                                  <ValueSuffix>
                                    {paymentCurrency?.symbol}
                                  </ValueSuffix>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <ValueText>0</ValueText>
                                  <ValueSuffix>
                                    {paymentCurrency?.symbol}
                                  </ValueSuffix>
                                </>
                              )}
                            </Row>
                          </>
                        </InfoColumn>
                      </>
                    ) : (
                      <AuctionPageLoader />
                    )}
                  </>
                ) : null}
                <InfoColumn>
                  <ValueKey>Current Token Price</ValueKey>
                  {((!!market && !singleAuctionLoading) ||
                    (!!paymentCurrency && !singleAuctionLoading)) &&
                  isAdmin !== undefined &&
                  tokensAlreadyClaimed !== undefined ? (
                    <>
                      {" "}
                      <Row gap={2} align="flex-end">
                        <ValueText>
                          {formatNumber(market?.currentTokenPrice, 3)}
                        </ValueText>
                        <ValueSuffix>{paymentCurrency?.symbol}</ValueSuffix>
                      </Row>
                    </>
                  ) : (
                    <>
                      <AuctionPageLoader />
                    </>
                  )}
                </InfoColumn>
                <InfoColumn>
                  <ValueKey>Amount Raised</ValueKey>
                  {((!!market && !singleAuctionLoading) ||
                    (!!market?.totalCommitments && !singleAuctionLoading) ||
                    (!!paymentCurrency && !singleAuctionLoading)) &&
                  isAdmin !== undefined &&
                  tokensAlreadyClaimed !== undefined ? (
                    <>
                      <Row gap={2} align="flex-end">
                        <ValueText>
                          {formatNumber(
                            market?.totalCommitments,
                            3,
                            !!market?.totalCommitments
                          )}
                        </ValueText>
                        <ValueSuffix>{paymentCurrency?.symbol}</ValueSuffix>
                      </Row>
                    </>
                  ) : (
                    <>
                      {" "}
                      <AuctionPageLoader />
                    </>
                  )}
                </InfoColumn>
                <InfoColumn>
                  <ValueKey>Remaining Tokens</ValueKey>
                  {!!market &&
                  !singleAuctionLoading &&
                  isAdmin !== undefined &&
                  tokensAlreadyClaimed !== undefined ? (
                    <>
                      {" "}
                      <Row gap={2} align="flex-end">
                        {market?.auctionType === "batch" ? (
                          <>
                            {AuctionStatus[market?.auctionStatus] ===
                            "FINISHED" ? (
                              <ValueText>0%</ValueText>
                            ) : (
                              <ValueText>100%</ValueText>
                            )}
                          </>
                        ) : (
                          <ValueText>
                            {" "}
                            {formatNumber(
                              market?.tokensRemaining * 100,
                              3,
                              true
                            )}
                            {""}%
                          </ValueText>
                        )}
                      </Row>
                    </>
                  ) : (
                    <>
                      <AuctionPageLoader />
                    </>
                  )}
                </InfoColumn>
              </InfoRow>
              <ChartBackground>
                <ChartTopCover>
                  <Row>
                    {market?.isSuccessful !== undefined ? (
                      <>
                        {market?.isFinalized ? (
                          <>
                            <AuctionStateRow>
                              Succeeded:
                              {!!showConfetti ? (
                                <>
                                  <AuctionStatusWrapper>
                                    <AuctionSuccessful />
                                  </AuctionStatusWrapper>
                                </>
                              ) : (
                                <>
                                  <AuctionStatusWrapper>
                                    <AuctionFailed />
                                  </AuctionStatusWrapper>
                                </>
                              )}
                            </AuctionStateRow>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Row>
                  <ChartTopRow>
                    <ChartText
                      onClick={() => selectGraphTab(1)}
                      active={activeGraphTab === 1}
                    >
                      Price
                    </ChartText>
                    <ChartText
                      onClick={() => selectGraphTab(2)}
                      active={activeGraphTab === 2}
                    >
                      Funds Raised
                    </ChartText>
                  </ChartTopRow>
                </ChartTopCover>
                <ChartMainCover>
                  {activeGraphTab === 1 ? (
                    <>
                      {" "}
                      {((!!market && !singleAuctionLoading) ||
                        (!!paymentCurrency && !singleAuctionLoading)) &&
                      isAdmin !== undefined &&
                      tokensAlreadyClaimed !== undefined ? (
                        <>
                          {" "}
                          <LiquidityGraph
                            color={
                              AuctionStatus[market?.auctionStatus] === "LIVE"
                                ? TangleColors.tangleGreen
                                : AuctionStatus[market?.auctionStatus] ===
                                  "UPCOMING"
                                ? TangleColors.tangleYellow
                                : TangleColors.tangleRed
                            }
                            startPrice={0}
                            endPrice={1}
                            currentLevel={formatNumber(
                              market?.currentProgress,
                              4
                            )}
                            currentPrice={formatNumber(
                              market?.currentAuctionPrice,
                              3
                            )}
                            width={700}
                            height={280}
                            auctionType={market?.auctionType === "dutch"}
                            currentPriceSymbol={paymentCurrency?.symbol}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <AuctionPageLoader />
                          <AuctionPageLoader />
                          <AuctionPageLoader />
                          <AuctionPageLoader />
                          <AuctionPageLoader />
                        </>
                      )}
                    </>
                  ) : (
                    <ChartSection>
                      {allCommitments?.length > 0 ? (
                        <ChartMainWrapper>
                          {" "}
                          <CommitmentChart data={formattedCommitmentData} />
                        </ChartMainWrapper>
                      ) : (
                        <ChartMainWrapper>
                          <ChartBodyText>No commitments yet!</ChartBodyText>
                        </ChartMainWrapper>
                      )}
                    </ChartSection>
                  )}
                </ChartMainCover>
                <ChartPriceInfo>
                  {market?.auctionType === "dutch" && !singleAuctionLoading ? (
                    <>
                      {" "}
                      <GraphDoubleTextRow>
                        <GraphDoubleCover>
                          <GraphBodyTitle>Starting Price</GraphBodyTitle>{" "}
                          <GraphBodyText>
                            {formatNumber(market?.startPrice, 3)}{" "}
                            {paymentCurrency?.symbol}
                          </GraphBodyText>
                        </GraphDoubleCover>
                        <GraphDoubleCover>
                          <GraphBodyTitle>Ending Price</GraphBodyTitle>{" "}
                          <GraphBodyText>
                            {formatNumber(market?.minimumPrice, 3)}{" "}
                            {paymentCurrency?.symbol}
                          </GraphBodyText>
                        </GraphDoubleCover>
                      </GraphDoubleTextRow>
                    </>
                  ) : (
                    <>
                      <GraphTextRow>
                        <GraphBodyTitle>Price</GraphBodyTitle>{" "}
                        <GraphBodyText>
                          {formatNumber(market?.currentAuctionPrice, 3)}{" "}
                          {paymentCurrency?.symbol}
                        </GraphBodyText>
                      </GraphTextRow>
                    </>
                  )}
                </ChartPriceInfo>
              </ChartBackground>
            </MainRight>
          </MainContainer>
          <SubContainer>
            <InfoTabs>
              <TabItem active={activeTab === 1} onClick={() => setActiveTab(1)}>
                <TabText> Auction Details</TabText>
                <TabIndicator active={activeTab === 1} />
              </TabItem>
              <TabItem active={activeTab === 2} onClick={() => setActiveTab(2)}>
                <TabText>Commitments</TabText>{" "}
                <TabIndicator active={activeTab === 2} />
              </TabItem>

              {!!market?.documents &&
              (!!findDocumentData("bannedCountries")?.data ||
                !!findDocumentData("description")?.data ||
                !!findDocumentData("warningMessage")?.data) ? (
                <>
                  <TabItem
                    active={activeTab === 3}
                    onClick={() => setActiveTab(3)}
                  >
                    <TabText>About Project</TabText>{" "}
                    <TabIndicator active={activeTab === 3} />
                  </TabItem>
                </>
              ) : null}
              {!!market && purplelistStatus !== "public" ? (
                <>
                  {" "}
                  <TabItem
                    active={activeTab === 4}
                    onClick={() => setActiveTab(4)}
                  >
                    <TabText>Purplelist</TabText>{" "}
                    <TabIndicator active={activeTab === 4} />
                  </TabItem>
                </>
              ) : null}
            </InfoTabs>
            {activeTab === 1 && (
              <AuctionDetailsTab
                minimumCommitmentAmount={Number(
                  market?.minimumCommitmentAmount
                )}
                postAuctionLiqLauncher={postAuctionLiqLauncher}
                totalTokenSupply={totalTokenSupply}
                startPrice={market?.startPrice}
                endPrice={market?.minimumPrice}
                tokenAddress={market?.auctionToken?.addr}
                tokensForSale={market?.tokensForSale}
                startTime={market?.startTime}
                endTime={market?.endTime}
                auctionAddress={market?.auctionAddress}
                auctionType={market?.auctionType}
                crowdSaleRate={Number(market?.rate)}
                crowdSaleGoal={Number(market?.goal)}
                templateName={TokenType[templateName]}
                liquidityAmount={liquidityAmountFormula}
                lockedFor={Number(liquidityLocktime) / 60 / 60 / 24}
                liquidityID={liquidityNftId}
                currencySymbol={paymentCurrency?.symbol}
                tokenSymbol={auctionCurrency?.symbol}
              />
            )}
            {activeTab === 2 && (
              <CommitmentsTab
                allCommitments={allCommitments}
                currencySymbol={paymentCurrency?.symbol}
              />
            )}
            {activeTab === 3 &&
            !!market?.documents &&
            (!!findDocumentData("bannedCountries")?.data ||
              !!findDocumentData("description")?.data ||
              !!findDocumentData("warningMessage")?.data) ? (
              <AboutProjectTab
                description={findDocumentData("description")?.data}
                bannedWarning={findDocumentData("warningMessage")?.data}
                bannedCountries={findDocumentData("bannedCountries")?.data}
              />
            ) : null}
            {activeTab === 4 && purplelistStatus !== "public" ? (
              <PurplelistTab
                market={market}
                purplelistAddress={purplelistAddress}
                tangleship={tangleship}
                paymentCurrencySymbol={paymentCurrency?.symbol}
              />
            ) : null}
          </SubContainer>
        </Cover>
      </Body>
    </AuctionBodyWrapper>
  )
}

const AuctionBodyWrapper = styled.div`
  width: 100%;
  margin: 64px 0 0 0;
`

const Body = styled.div`
  width: 100%;
  padding: 16px;
`

const Cover = styled.div`
  max-width: 1232px;
  margin: 16px auto 32px auto;
  width: 100%;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  position: relative;
  z-index: 10;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  margin-bottom: 48px;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  position: relative;
  z-index: 10;
`

const InfoTabs = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${TangleColors.darkerText};
  width: 100%;
`

const TabItem = styled.div<TokenProps>`
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
  max-width: 200px;
  width: 100%;
  padding: 2px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  cursor: pointer;
  transition: 0.4s ease-in;

  &:hover {
    color: ${TangleColors.lightPurple};
  }
`
const TabIndicator = styled.span<TokenProps>`
  height: 2%.4;
  width: 100%;
  max-width: 200px;
  padding: 2px 0 0 0;
  margin: 8px auto -3px auto;
  background: ${TangleColors.lighthover};
  display: ${(props) => (props.active ? "flex" : "none")};
`
const TabText = styled(Subtitle)``

const MainLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 4;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const MainRight = styled.div`
  width: 60%;
  position: relative;
  z-index: 4;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Row = styled.div<TokenProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.align || "flex-start"};
  gap: ${(props) => props.gap || 0}px;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 32px auto;
  position: relative;
  z-index: 4;
  min-height: 110px;
  border-radius: 14px;
  max-width: 720px;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  padding: 12px 20px;
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const ValueKey = styled(Caption)`
  text-align: right;
  margin: 0 0 16px 0;
  color: ${TangleColors.darkerText};
`

const ValueText = styled(Subtitle)`
  color: ${TangleColors.lighterText};
`

const ValueSuffix = styled(CaptionTiny)`
  color: ${TangleColors.darkerText};
  margin: 0 0 0 6px;
`

const ChartBackground = styled.div`
  background: ${TangleColors.swapBG};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  min-height: 550px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  max-height: 450px;
  border: 1px solid ${TangleColors.lighthover};
  padding: 24px;

  @media only screen and (max-width: 850px) {
    min-width: 250px;
  }
`

const GraphTextRow = styled.div`
  display: flex;
  flex-direction: column;
`

const ChartTopCover = styled.div`
  display: flex;
  flex-direction: row;

  text-align: center;
  justify-content: space-between;
  align-items: center;

  z-index: 12;
  width: 100%;
`
const ChartTopRow = styled.div`
  display: flex;
  flex-direction: row;

  text-align: center;
  justify-content: space-between;
  align-items: center;

  z-index: 12;

  background: ${TangleColors.dockBG};
  padding: 4px;
  border-radius: 999px;
`
const ChartText = styled(CaptionSmall)<TokenProps>`
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.grayDark}`};

  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  white-space: nowrap;
  border-radius: 999px;
  background: ${(props) =>
    props.active ? `${TangleColors.heroCTA}` : `transparent`};
`

const GraphDoubleTextRow = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`
const GraphDoubleCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 300px;
  width: 100%;
`

const GraphBodyTitle = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  margin: 0;
  max-width: 300px;
  width: 100%;
`
const GraphBodyText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0 0 0;
  text-align: left;
  white-space: normal;
  max-width: 300px;
  width: 100%;
`
const ChartSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 32px auto -8px 28px;
  width: 100%;
`

const ChartMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`

const ChartBodyText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 auto;
  text-align: center;
  white-space: normal;
  padding: 8px 14px;
  cursor: pointer;
`

const Participate = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 800;
  width: 100%;
`

const ParticipateButtonCover = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px auto;
  max-width: 1232px;
  background: ${TangleColors.swapBG};
  border-radius: 999px;
  overflow: hidden;
`
const ChartPriceInfo = styled.div`
  margin: 72px 0 -6px 0;

  width: 100%;
  min-height: 30px;
`
const ChartMainCover = styled.div`
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  min-height: 250px;
  max-width: 800px;
  width: 100%;
  margin: -40px auto -80px auto;
  max-height: 400px;
  padding: 24px 32px;
`

const ParticipateTextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 800;
`

const ParticipateText = styled(Subtitle)`
  color: ${TangleColors.lighterText};
  margin: 0 0 4px 0;
`

const BalanceText = styled(CaptionTiny)`
  margin: 0 0 4px 0;
  color: ${TangleColors.darkerText};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ParticipateInputRow = styled.div<FormProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${TangleColors.tangleBlack};
  gap: 8px;
  position: relative;
  z-index: 800;
  padding: 8px;
  border-radius: 14px;
  margin: 8px 0 14px 0;
  width: 100%;
  border: ${(props) =>
    props.formError ? ` 0.4px solid ${TangleColors.red}` : ` none`};
`

const PaymentTokenLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  margin: 0 0 0 12px;
`

const PaymentTokenName = styled(Caption)`
  color: ${TangleColors.lighterText};
`

const ParticipateInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 8px;
  padding: 8px;
  font-family: "CabinetGrotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  max-width: 600px;
  width: 100%;

  height: 48px;
  color: ${TangleColors.white};
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

const MaxButton = styled.button`
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  cursor: pointer;
  display: flex;
  border-radius: 9999px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.black};
    background: ${TangleColors.white};
  }
  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.grayLight};

    border: none;
  }
`

const ParticipateButton = styled(ParticipateMainButton)`
  margin: 0;
  position: relative;
`

const Informations = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Socials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  position: relative;
  z-index: 20;
`

const SocialText = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  text-decoration: underline;
  font-weight: 600;
`

const SocialBlock = styled.div`
  display: flex;
`

const SocialGithub = styled(FaGithub)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialTelegram = styled(FaTelegram)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialWechat = styled(FaWeixin)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialDiscord = styled(FaDiscord)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialMedium = styled(FaMedium)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialReddit = styled(FaReddit)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialDocs = styled(FaBook)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #fff;
`

const SocialInfoText = styled(Subtitle)`
  color: ${TangleColors.white};
`

const Technicals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  position: relative;
  z-index: 10;
`

const TechnicalInfoText = styled(Subtitle)`
  color: ${TangleColors.white};
`

const TechnicalItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  text-decoration: underline;
  font-weight: 600;
`

const TechnicalBlock = styled.div`
  display: flex;
`

const WebsiteIcon = styled(FaGlobe)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${TangleColors.white};
`

const WhitepaperIcon = styled(FaFileAlt)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${TangleColors.white};
`

const AuctionTypeImage = styled.div<TokenProps>`
  width: 72px;
  height: 72px;
  border-radius: 9999px;
  margin: 0 8px;
  background: url(${(props) => props.auctionBg && props.auctionBg}) no-repeat
    center center/65%;
`

const AuctionTypeText = styled(Subtitle)`
  color: ${TangleColors.white};
`

const CategoryBubble = styled(CaptionSmall)<TokenProps>`
  display: flex;
  ${(props) => getCategoryColor(props.category || "")}
  border-radius: 9999px;
  padding: 8px 12px;
`

const TopBar = styled.div<TokenProps>`
  padding: 24px 0;
  background: ${(props) =>
    props.status === "LIVE"
      ? TangleColors.dockBG
      : props.status === "UPCOMING"
      ? TangleColors.stake
      : props.status === "FINISHED"
      ? TangleColors.farmBG
      : TangleColors.modalBG};
  min-height: 250px;
  border-top: 2px solid ${TangleColors.white};
  border-bottom: 2px solid ${TangleColors.white};
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
`

const TopBasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const TopSymbol = styled(Caption)`
  color: ${TangleColors.darkerText};
  white-space: nowrap;
`
const TopSymbolIcon = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
  border-radius: 999px;
  margin: 0 8px 0 0;
`
const TopName = styled(Subtitle)`
  color: ${TangleColors.lighterText};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 6px 0 0 6px;
  white-space: nowrap;
  width: 100%;
`

const AuctionWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const ShareIcon = styled(AiOutlineShareAlt)`
  width: 100%;
  height: 32px;
  color: ${TangleColors.lighterText};
  cursor: pointer;
  max-width: 56px;
`

const TopSaleIndicatorText = styled(Header7)<TokenProps>`
  color: ${(props) =>
    props.status === "LIVE"
      ? TangleColors.tangleGreen
      : props.status === "UPCOMING"
      ? TangleColors.tangleYellow
      : TangleColors.tangleRed};
  letter-spacing: 0.75em;
`

const SaleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`

const SaleTimer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
`

const SaleTimerNumber = styled(Header5)`
  color: ${TangleColors.lighterText};
`

const TopAuctionInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

const HelpIcon = styled(BsInfoCircleFill)`
  width: 12px;
  height: 12px;
  color: ${TangleColors.white};
  cursor: pointer;
`

const TopAuctionText = styled(CaptionTiny)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const TopAuctionPrice = styled(Header7)`
  color: ${TangleColors.lighterText};
  margin: 8px 0 0 0;
`

const EditButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 999px;
  background: ${TangleColors.defi};
  color: ${TangleColors.white};
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 16px;

  &:hover {
    filter: brightness(120%);
  }
`

const RowLeft = styled.div`
  margin: 0;
  width: 100%;
  max-width: 120px;
`
const GoBack = styled(Link)`
  max-width: 120px;
  width: 100%;
  height: 40px;
  border-radius: 24px;
  margin: 0 0 12px 0;
  background: ${TangleColors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
`
const BackIcon = styled(BsArrowLeft)`
  margin: 0 4px 0 0;
  width: 24px;
  height: 24px;
  color: ${TangleColors.black};
`
const BackText = styled(Caption)`
  color: ${TangleColors.black};
`

const GlobleUnlockIcon = styled(BsFillUnlockFill)<TokenProps>`
  margin: 0 4px 0 0;
  width: 22px;
  height: 22px;
  color: ${(props) => props.color};
`
const GlobleLockIcon = styled(BsFillLockFill)<TokenProps>`
  margin: 0 4px 0 0;
  width: 22px;
  height: 22px;
  color: ${(props) => props.color};
`
const GlobleMainIcon = styled(BiWorld)<TokenProps>`
  margin: 0 4px 0 0;
  width: 22px;
  height: 22px;
  color: ${(props) => props.color};
`
const RestrictedText = styled(Caption)<TokenProps>`
  color: ${(props) => props.color};
`
const RestrictedRow = styled.div<TokenProps>`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6px;
  color: ${(props) => props.color};
`
const AuctionStateRow = styled.div<TokenProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${TangleColors.white};
  white-space: nowrap;
`

const AuctionStatusWrapper = styled(Caption)`
  max-width: 40px;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 12px;
`
const AuctionSuccessful = styled(TickCircle)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.tangleGreen};

  cursor: pointer;
`

const AuctionFailed = styled(GrClose)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.red};

  cursor: pointer;
  path {
    stroke: ${TangleColors.red};
    fill: ${TangleColors.red};
    transition: 0.4s ease-in;
  }
`
const AuctionClaimRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: 8px 0;
  text-align: left;
`
const AutionClaimTitle = styled(Header5)`
  text-align: left;
  color: ${TangleColors.white};
`
const AuctionClaimSubText = styled(Body1)`
  margin: 4px 0;
  text-align: left;
  color: ${TangleColors.grayDark};
`
const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 12px;
`
const BalanceNumber = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
  cursor: pointer;
`

const InfoIcon = styled(AiOutlineInfoCircle)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  transition: 0.4s ease-in;
  ${MaxButton}:hover & {
    color: ${TangleColors.black};
  }
`
const InfoBodyCover = styled.div`
  position: relative;
`
const InfoIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 0 0 4px;
  color: ${TangleColors.lighthover};
  display: flex;
  justify-items: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${MaxButton}:hover & {
    color: ${TangleColors.lighthover};
  }
`

const ApproveDotWrapper = styled.div`
  margin: 6px 0 4px 0;
`
export default AuctionDetailsPage
