import React, { FC, useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Header7, Subtitle } from "styles/TextStyles"

import { PurpleListFormInput } from "styles/InputStyles"
import { PurpleListMainButton } from "styles/ButtonStyles"
import { ZERO_ADDRESS } from "@tangleswap/sdk"
import { useForm } from "react-hook-form"
import { useWSCContext } from "context/MilkomedaContext"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { isAddress } from "ethers/lib/utils.js"
import { toSignificantDigits } from "utils/toSignificant"

interface PurpleListFormData {
  userAddress?: string
}
interface PurplelistTabProps {
  market?: any
  tangleship?: any
  purplelistAddress?: any
  paymentCurrencySymbol?: string
  purplelistMaxAllocation?: any
}
const PurplelistTab: FC<PurplelistTabProps> = (props) => {
  const { market, tangleship, purplelistAddress, paymentCurrencySymbol } = props
  const [purplelistMaxAllocation, setPurplelistMaxAllocation] =
    useState<number>(null)
  const [purplelistLoading, setPurplelistLoading] = useState<any>(undefined)

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PurpleListFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const { account, chainId } = useWSCContext()
  const onSubmit = async (refetch = false) => {
    setPurplelistLoading(true)
    const acc = watch("userAddress").trim().toLowerCase()
    const addr = purplelistAddress
    if (
      !addr ||
      purplelistAddress === ZERO_ADDRESS ||
      !acc ||
      !isAddress(acc) ||
      !market?.paymentToken?.decimals
    ) {
      setPurplelistMaxAllocation(undefined)
      setPurplelistLoading(false)
      return
    }

    const storageKey = `ihubMaxAlloc${chainId}${acc}${addr}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      setPurplelistMaxAllocation(Number(storageValue))
      setPurplelistLoading(false)
      return
    }

    const data = await tangleship?.checkPurplelistMaxAllocation(acc, addr)
    const allocation =
      Number(data?._hex) / 10 ** market?.paymentToken?.decimals || 0
    sessionStorage.setItem(storageKey, String(allocation))
    setPurplelistMaxAllocation(allocation)
    setPurplelistLoading(false)
  }
  return (
    <Container>
      <InfoRow>
        <PurpleListForm onSubmit={handleSubmit(onSubmit)}>
          {" "}
          <PurpleListInput
            type="text"
            {...register("userAddress", {
              required: true,
            })}
            autoComplete="off"
            placeholder="Enter address"
          />
          <ListButtonWrapper>
            <ListFormButton
              disabled={!watch("userAddress") || purplelistLoading}
              type="submit"
            >
              {purplelistLoading ? (
                <>
                  <SpinnerCombined />
                </>
              ) : (
                <> Search</>
              )}
            </ListFormButton>
          </ListButtonWrapper>
        </PurpleListForm>
      </InfoRow>

      <ListRow>
        <ListSection>
          {!!watch("userAddress") && purplelistLoading === false ? (
            <>
              <ItemKey>Result:</ItemKey>
              <ItemValue purplelistMaxAllocation={purplelistMaxAllocation}>
                {purplelistMaxAllocation === undefined ? (
                  <>Invalid address!</>
                ) : purplelistMaxAllocation > 0 ? (
                  <>
                    Success! Address is in the purplelist, with a remaining
                    maximum allocation of{" "}
                    {`${toSignificantDigits(
                      purplelistMaxAllocation,
                      5,
                      true,
                      18
                    )} ${paymentCurrencySymbol}`}
                  </>
                ) : (
                  <>Address is *not* in the purplelist.</>
                )}
              </ItemValue>
            </>
          ) : null}
        </ListSection>
      </ListRow>
    </Container>
  )
}

const Container = styled.div<PurplelistTabProps>`
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 950px;
  width: 100%;
`

const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
`
const PurpleListForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PurpleListInput = styled(PurpleListFormInput)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 12px 0;
`
const ListButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const ListFormButton = styled(PurpleListMainButton)`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ListRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
`
const ListSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 450px;
`

const ItemKey = styled(Subtitle)`
  color: ${TangleColors.grayDark};
  width: 100%;
`

const ItemValue = styled(Header7)<PurplelistTabProps>`
  display: flex;
  align-items: center;
  width: 100%;

  color: ${(props) =>
    props.purplelistMaxAllocation === undefined
      ? `${TangleColors.red}`
      : `${
          props.purplelistMaxAllocation === true ||
          props.purplelistMaxAllocation > 0
            ? TangleColors.tangleGreen
            : TangleColors.tangleYellow
        }`};
`

export default PurplelistTab
