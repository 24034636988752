import React, { FC } from "react"

import { GrClose } from "react-icons/gr"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ParticipateMainButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header6Gothic } from "styles/TextStyles"
import shipbg from "assets/shipbg.png"
import { useWSCContext } from "context/MilkomedaContext"
import { Chains } from "@tangleswap/sdk"
interface ShimmerProps {
  show?: boolean
}
const HideOnShimmer: FC<ShimmerProps> = (props) => {
  const { show } = props
  const navigate = useNavigate()
  const location = useLocation()
  const isNftStaking = location.pathname.startsWith("/nft-staking")
  const isVEStaking = location.pathname === "/stake"
  const { chainId } = useWSCContext()
  return (
    <>
      <ConfettiBackground show={show}>
        <ConfettiCover>
          <HeroOverlay /> <ConfettiImageBackground />
          <CloseIcon onClick={() => navigate("/swap")} />
          <ConfettiTextRowCover>
            <ConfettiTitle>
              {chainId === Chains.SHIMMER ? "Not Available" : "Coming Soon"}
              {""}
            </ConfettiTitle>
            {isNftStaking ? (
              <>
                {" "}
                <ConfettiSubtextCover>
                  {" "}
                  <ConfettiSubtext>
                    The NFT Staking app is not available . We suggest you enjoy
                    instead any of our other dApps on Shimmy. ✨
                  </ConfettiSubtext>
                  <ConfettiSubtext>
                    Or if you really want (need?) to stake your NFTs, feel free
                    to do so on any of our other chains!
                  </ConfettiSubtext>
                </ConfettiSubtextCover>
              </>
            ) : null}
            {isVEStaking ? (
              <>
                {" "}
                <ConfettiSubtextCover>
                  {" "}
                  <ConfettiSubtext>
                    The VE Staking app is not available. We suggest you enjoy
                    instead any of our other dApps on Shimmy. ✨
                  </ConfettiSubtext>
                  <ConfettiSubtext>
                    Or if you really can't help the need to generate Void
                    Energy, feel free to do so on any of our other chains!
                  </ConfettiSubtext>
                </ConfettiSubtextCover>
              </>
            ) : null}

            <ConfettiButton onClick={() => navigate("/swap")}>
              Back to the Tangleship
            </ConfettiButton>
          </ConfettiTextRowCover>
        </ConfettiCover>
      </ConfettiBackground>
    </>
  )
}

const ConfettiBackground = styled.div<ShimmerProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.show ? "flex" : "none")};
  z-index: 90;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 12px;
  background-color: rgba(
    255,
    255,
    255,
    0.02
  ); // Semi-transparent fallback for browsers without backdrop-filter support
  backdrop-filter: blur(20px); // This will blur the background
`
const ConfettiCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 550px;
  max-width: 550px;
  padding: 24px;
  background-color: ${TangleColors.swapBG};

  overflow: hidden;

  border: 1px solid ${TangleColors.lighthover};
  border-radius: 24px;
  margin: 24px auto;
`
const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;

  width: 100%;
  border-radius: inherit;
  position: absolute;
  height: 100%;
  content: "";
  z-index: 3;
`
const ConfettiImageBackground = styled.div`
  background-image: url(${shipbg});

  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  mix-blend-mode: luminosity; // or another blend mode based on your needs
`
const ConfettiTextRowCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: 64px auto 24px auto;
  text-align: center;
  padding: 24px;
  position: relative;
  z-index: 6;
`
const ConfettiTitle = styled(Header6Gothic)`
  text-align: center;
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: capitalize;
`
const ConfettiSubtext = styled(Caption)`
  color: ${TangleColors.white};
  margin: 12px 0;
  text-align: left;
`
const ConfettiSubtextCover = styled.div`
  color: ${TangleColors.white};
  margin: 16px 0 24px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CloseIcon = styled(GrClose)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  z-index: 6;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
const ConfettiButton = styled(ParticipateMainButton)`
  margin: 32px auto;
  max-width: 350px;
  width: 100%;

  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.grayLight};

    border: none;
  }
`

export default HideOnShimmer
