import { Link } from "react-router-dom"
import styled from "styled-components"
import { TangleColors } from "./ColorStyles"
interface ConnectWalletProps {
  isNavbar?: boolean
  blockTransactionState?: boolean
  tangleswapWalletState?: boolean
}
export const MainButton = styled.button`
  max-width: 180px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const NavConnectButton = styled.button`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.tangle10};
  border-radius: 56px;
  outline: none;
  border: none;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 850px) {
    max-width: 800px;
    height: 64px !important;
    width: 100%;
    font-size: 22px;
  }
`
export const ConnectButton = styled.button`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.tangle10};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 36px;
    max-width: 140px;
  }
`
// export const NavConnectButton = styled.button`
//   max-width: 180px;
//   width: 100%;
//   height: ;
//   color: ${TangleColors.white};
//   background: ${TangleColors.tangle10};
//   border-radius: 56px;
//   outline: none;
//   border: none;
//   -moz-user-select: none; /* firefox */
//   -webkit-user-select: none; /* Safari */
//   -ms-user-select: none; /* IE*/
//   user-select: none; /* Standard syntax */
//   cursor: pointer;
//   font-family: "CabinetGrotesk Medium";
//   font-style: normal;
//   font-weight: 500;
//   font-size: ;
//   line-height: 100%;

//   text-align: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   transition: 0.4s ease-in;
//   :hover {
//     color: ${TangleColors.cta};
//     background: ${TangleColors.white};
//   }

//   @media only screen and (max-width: 850px) {
//     max-width: 800px;
//     height: 64px !important;
//     width: 100%;
//     font-size: 22px;
//   }
// `
export const SwapConnectButton = styled.button<ConnectWalletProps>`
  max-width: ${(props) => (props.isNavbar ? `230px` : `800px`)};
  width: 100%;
  height: ${(props) => (props.isNavbar ? `56px` : `80px`)};
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  color: ${(props) =>
    props.tangleswapWalletState
      ? `${TangleColors.black}`
      : `${TangleColors.white}`};
  background: ${(props) =>
    props.tangleswapWalletState
      ? `${TangleColors.lighthover}`
      : `${TangleColors.tangle10}`};
  border-radius: ${(props) => (props.isNavbar ? `999px` : `24px`)};
  outline: none;
  border: none;
  padding: 16px 32px;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.isNavbar ? `16px` : `24px`)};
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${(props) =>
      props.tangleswapWalletState
        ? `${TangleColors.black}`
        : `${TangleColors.cta}`};
    background: ${(props) =>
      props.tangleswapWalletState
        ? `${TangleColors.tangleYellow}`
        : `${TangleColors.white}`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`
export const ChainChangeButton = styled.button`
  max-width: 800px;
  width: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  height: 80px;
  color: ${TangleColors.white};
  background: ${TangleColors.red};
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.tangleDarkRed};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`

export const SwapTokenButton = styled.button<ConnectWalletProps>`
  max-width: 800px;
  width: 100%;
  height: 80px;
  color: ${TangleColors.white};
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.heroCTA} !important`};
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.cta}!important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important`
        : `${TangleColors.white} !important`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`

export const MainLinkButton = styled(Link)`
  max-width: 180px;
  width: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const HeroLinkButton = styled(Link)`
  max-width: 880px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.white};
    color: ${TangleColors.white};
    background: ${TangleColors.black};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const GridLinkButton = styled.a`
  max-width: 880px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.heroCTA};
  background: ${TangleColors.white};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  border: 2px solid transparent;
  :hover {
    border: 2px solid ${TangleColors.heroCTA};
    color: ${TangleColors.white};
    background: ${TangleColors.dockBG};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const SmallLinkButton = styled(Link)`
  max-width: 180px;
  width: 100%;
  height: 40px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const FarmAddLiquidityButton = styled.button<ConnectWalletProps>`
  max-width: 800px;
  width: 100%;
  height: 48px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 16px !important;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.black};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const MainLink = styled(Link)`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
    border: 1px solid ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const MainHRefLink = styled.a`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
    border: 1px solid ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const BigButton = styled.button`
  max-width: 280px;
  width: 100%;
  height: 72px;
  color: ${TangleColors.white};
  background: ${TangleColors.icon};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.icon};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const MediumButton = styled(Link)`
  max-width: 280px;
  width: 100%;
  height: 64px;
  color: ${TangleColors.white};
  background: ${TangleColors.icon};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.icon};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const FormButton = styled.button`
  max-width: 560px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
    border: 1px solid ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const SecondaryButton = styled.a`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: transparent;
  border: 1px solid ${TangleColors.cta};
  border-radius: 56px;
  outline: none;

  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const TertiaryButton = styled.a`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.cta};
  background: transparent;
  border: 1px solid ${TangleColors.cta};
  border-radius: 56px;
  outline: none;

  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.dark};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const SectionButton = styled.button`
  max-width: 180px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
    border: 1px solid ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const SwapMainButton = styled.button`
  max-width: 700px;
  width: 100%;
  height: 64px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.icon};
    border: none;
    outline: none;
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`
export const SmallButton = styled.button`
  width: 100%;
  max-width: 160px;
  height: 36px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  padding: 10px 16px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.icon};
    border: none;
    outline: none;
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`
export const FarmConnectButton = styled.button`
  max-width: 360px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`
export const LPConnectButton = styled.button`
  max-width: 660px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 48px;
  }
`
export const HarvestButton = styled.button<ConnectWalletProps>`
  /* max-width: 120px;
  width: 100%; */
  height: 48px;
  color: ${TangleColors.white};
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.cta} !important`};
  border-radius: 14px;
  padding: 8px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.cta} !important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important`
        : `${TangleColors.white} !important`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`
export const FarmingButton = styled.button`
  /* max-width: 120px;
  width: 100%; */
  height: 48px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 14px;
  padding: 8px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`

export const PositionButton = styled.button`
  max-width: 200px;
  width: 100%;
  height: 44px;
  color: ${TangleColors.black};
  background: ${TangleColors.feeRateText};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding: 12px 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 800px) {
    max-width: 800px;
    height: 56px;
  }
`

export const CreateAuctionButton = styled.button`
  max-width: 280px;
  width: 100%;
  height: 44px;
  color: ${TangleColors.black};
  background: ${TangleColors.feeRateText};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding: 12px 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 800px) {
    max-width: 800px;
    height: 56px;
  }
`
export const ExploreButton = styled.button`
  max-width: 250px;
  width: 100%;
  height: 44px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  padding: 12px 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    max-width: 800px;
  }
`

export const ModalButton = styled.a`
  max-width: 430px;
  width: 100%;
  height: 80px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
    height: 48px;
  }
`
export const CustomTokenModalButton = styled.button`
  max-width: 430px;
  width: 100%;
  height: 68px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 18px;
    height: 40px;
  }
`

export const NoTransactionButton = styled.a`
  max-width: 320px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.cta};
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
    height: 40px;
  }
`

export const StakeButton = styled.a`
  max-width: 320px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.cta};
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
    height: 40px;
  }
`
export const StakingMainButton = styled.button<ConnectWalletProps>`
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${TangleColors.white};
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.heroCTA} !important`};
  border-radius: 16px;
  padding: 16px 24px;
  outline: none;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.cta} !important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important`
        : `${TangleColors.white} !important`};
  }
`
export const AddTokenModalButton = styled.button`
  height: 34px;
  color: ${TangleColors.lighthover};
  background: ${TangleColors.tangle10};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding: 10px 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const AddTokenMetamaskModalButton = styled.button`
  /* width: 100%; */
  height: 34px;
  color: ${TangleColors.lighthover};
  background: ${TangleColors.tangle10};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  padding: 10px 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const IncreaseLiquidityButton = styled.button`
  max-width: 190px;
  width: 100%;
  height: 40px;
  color: ${TangleColors.black};
  background: ${TangleColors.tangleGreen};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.tangleDarkGreen};
  }
  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 16px;
    max-width: 700px;
  }
`

export const RemoveLiquidityButton = styled.button`
  max-width: 190px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  width: 100%;
  height: 40px;
  color: ${TangleColors.white};
  background: ${TangleColors.tangleRed};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.tangleDarkRed};
  }
  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 16px;
    max-width: 700px;
  }
`

export const CollectFeesButton = styled.button`
  max-width: 140px;
  width: 100%;
  height: 40px;
  color: ${TangleColors.black};
  background: ${TangleColors.feeRateText};
  border-radius: 12px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    max-width: 100px;
    width: 100%;
    height: 36px;
    font-size: 15px !important;
  }
`

export const StakingButton = styled.button`
  max-width: 660px;
  width: 100%;
  height: 72px;
  color: ${TangleColors.black};
  background: ${TangleColors.lighthover};
  border-radius: 16px;
  outline: none;
  position: relative;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  border: 1px solid transparent;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }

  :hover {
    border: 1px solid ${TangleColors.white};
    color: ${TangleColors.white};
    background: ${TangleColors.tangle10};
  }

  @media only screen and (max-width: 650px) {
    font-size: 18px;
    height: 56px;
  }
`

export const StakingClaimButton = styled.button`
  max-width: 660px;
  position: relative;
  width: 100%;
  height: 72px;
  color: ${TangleColors.black};
  background: ${TangleColors.tangleGreen};
  border-radius: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  border: 1px solid transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.white} !important;
    border: 1px solid ${TangleColors.white} !important;
    background: ${TangleColors.tangleDarkGreen} !important;
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 56px;
  }
`

export const BlogButtonMain = styled.a`
  max-width: 660px;
  position: relative;
  width: 100%;
  height: 72px;
  color: ${TangleColors.black};
  background: ${TangleColors.white};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  border: 1px solid transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;

  :hover {
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
    background: ${TangleColors.heroCTA};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 56px;
  }
`

export const BlogButtonSecondary = styled.a`
  max-width: 660px;
  position: relative;
  width: 100%;
  height: 40px;
  color: ${TangleColors.black};
  background: ${TangleColors.white};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border: 1px solid transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  box-shadow: 0 0 0 1px #3d414980, 0 0 0 3px #25272bcc, 0 0 0 4px #2d2f36cc;
  :hover {
    color: ${TangleColors.white};

    background: ${TangleColors.blogBg};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 56px;
  }
`

export const DevButtonMain = styled.a`
  max-width: 660px;
  position: relative;
  width: 100%;
  height: 48px;
  color: ${TangleColors.black};
  background: ${TangleColors.white};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  border: 1px solid transparent;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;

  :hover {
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
    background: ${TangleColors.heroCTA};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 56px;
  }
`

export const GuideLaterPrimaryButton = styled.a`
  max-width: 880px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: transparent;
  border: 1px solid ${TangleColors.cta};
  border-radius: 56px;
  outline: none;

  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const GuideStartedPrimaryButton = styled.a`
  max-width: 880px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border: none;
  border-radius: 56px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
    color: ${TangleColors.lighthover};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const GuideLaterSecondaryButton = styled.a`
  max-width: 880px;
  width: 100%;
  height: 52px;
  color: ${TangleColors.white};
  background: transparent;
  border: 1px solid ${TangleColors.cta};
  border-radius: 56px;
  outline: none;

  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const GuideStartedSecondaryButton = styled.a`
  max-width: 880px;
  width: 100%;
  height: 52px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border: none;
  border-radius: 56px;
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
    color: ${TangleColors.lighthover};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
export const InvestHubNextButton = styled.button`
  max-width: 1000px !important;
  width: 100%;
  height: 64px !important;
  border-radius: 20px !important;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};

  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`
export const InvestHubToken1ApprovalButton = styled.button`
  max-width: 1000px !important;
  width: 100%;
  height: 64px !important;
  border-radius: 20px !important;
  color: ${TangleColors.white};
  background: ${TangleColors.dockBG};

  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.defi};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`
export const AuctionBackButton = styled.button`
  max-width: 1000px !important;
  width: 100%;
  height: 64px !important;
  border-radius: 20px !important;
  color: ${TangleColors.white};
  background: ${TangleColors.black} !important;

  outline: none;
  border: 1px solid ${TangleColors.white};
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.white} !important;
    background: ${TangleColors.dockBG} !important;
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`

export const ParticipateMainButton = styled.button<ConnectWalletProps>`
  max-width: 700px;
  width: 100%;
  height: 64px;
  color: ${TangleColors.white} !important;
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.heroCTA} !important`};
  border-radius: 56px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.cta} !important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important`
        : `${TangleColors.white} !important`};
  }
  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`

export const FarmTokenButton = styled.button<ConnectWalletProps>`
  max-width: 800px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${(props) =>
    props.blockTransactionState
      ? ` ${TangleColors.dockBG} !important`
      : ` ${TangleColors.heroCTA} !important`};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? ` ${TangleColors.white} !important`
        : ` ${TangleColors.cta} !important `};
    background: ${(props) =>
      props.blockTransactionState
        ? ` ${TangleColors.tangleDarkGreen} !important `
        : ` ${TangleColors.white} !important`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const StopFarmTokenButton = styled.button<ConnectWalletProps>`
  max-width: 800px;
  width: 100%;
  height: 56px;
  color: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.white} !important `
      : `${TangleColors.black} !important`};
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.lighthover} !important`};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.white} !important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important `
        : `${TangleColors.lightPurple} !important`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`

export const AidropclaimButton = styled.button`
  max-width: 320px;
  width: 100%;
  height: 56px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    border: 1px solid ${TangleColors.cta};
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
    height: 40px;
  }
`

export const AuctionSaveButton = styled.button`
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  width: 100%;
  background: ${TangleColors.cta};
  color: ${TangleColors.white};
  border-radius: 16px;
  padding: 16px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  cursor: pointer;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    border: 1px solid ${TangleColors.white};
    background: ${TangleColors.feeRateBg};
  }
`

export const PurpleListMainButton = styled.button`
  /* max-width: 120px;
  width: 100%; */
  height: 48px;
  color: ${TangleColors.black};
  background: ${TangleColors.lighthover};
  border-radius: 14px;
  padding: 8px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 8px auto;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.cta};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
    height: 40px;
  }
`

export const NFTWSCTokenButton = styled.button<ConnectWalletProps>`
  max-width: 1200px;
  width: 100%;
  height: 80px;
  color: ${TangleColors.white};
  background: ${(props) =>
    props.blockTransactionState
      ? `${TangleColors.dockBG} !important`
      : `${TangleColors.heroCTA} !important`};
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.white} !important`
        : `${TangleColors.cta} !important`};
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.tangleDarkGreen} !important`
        : `${TangleColors.white} !important`};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`
export const ChainCardanoChangeButton = styled.button`
  max-width: 800px;
  width: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  height: 80px;
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  border-radius: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.cta};
  }

  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`

export const ContinueProgressButton = styled.button`
  max-width: 800px;
  width: 100%;
  height: 32px;
  color: ${TangleColors.white};
  background: ${TangleColors.cta};
  border-radius: 10px !important;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.black};
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 650px) {
    font-size: 13px;
  }
`

export const CancelTransactionButton = styled.button`
  max-width: 800px;
  width: 100%;
  height: 34px;
  color: ${TangleColors.white};
  background: ${TangleColors.tangleRed};
  border-radius: 10px !important;
  outline: none;

  cursor: pointer;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  :disabled {
    background: ${TangleColors.grayDark} !important;
    border: none !important;
    :hover {
      cursor: not-allowed !important;
      color: ${TangleColors.white} !important;
      background: ${TangleColors.grayDark} !important;
    }
  }
  :hover {
    color: ${TangleColors.white};
    background: ${TangleColors.red};
    border: 1px solid ${TangleColors.tangleRed};
  }

  @media only screen and (max-width: 650px) {
    font-size: 16px;
  }
`
