export interface AlienProps {
  route: string
  name: string
  id: number
  alienHref: string
  alienImage: string
  alienText: string
}

export const aliensInfo = [
  {
    route: "/swap",
    name: "swap",
    id: 1,
    alienHref:
      "https://docs.tangleswap.exchange/guides/step-by-step/swapping-tokens",
    alienText: "Need help, Explorer?",
    alienImage: "/alien_soul-purple.png",
  },
  {
    id: 2,
    route: "/pools/add",
    name: "add liquidity",
    alienHref:
      "https://docs.tangleswap.exchange/guides/step-by-step/providing-liquidity",
    alienText: "Join the LP side...",
    alienImage: "/alien_soul-purple.png",
  },
  {
    id: 3,
    route: "/stake",
    name: "stake",
    alienHref: "https://docs.tangleswap.exchange/dapps/stake",
    alienText: "Master the art of Void Energy?",
    alienImage: "/alien_soul-purple.png",
  },
  {
    id: 4,
    route: "/invest",
    name: "investment-hub",
    alienHref: "https://docs.tangleswap.exchange/dapps/invest",
    alienText: "Learn to navigate the Hub?",
    alienImage: "/alien_soul-purple.png",
  },
  {
    id: 5,
    route: "/nft-staking",
    name: "nft staking",
    alienHref: "https://docs.tangleswap.exchange/dapps/nft",
    alienText: "It all started with The Big Bang...",
    alienImage: "/alien_soul-purple.png",
  },
  {
    id: 6,
    route: "/farm",
    name: "farm",
    alienHref: " https://docs.tangleswap.exchange/dapps/farm",
    alienText: "Asteroid Farming... what is it?",
    alienImage: "/alien_soul-green.png",
  },
]
