import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { StakingMainButton } from "styles/ButtonStyles"
import { DurationMainInput } from "styles/InputStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { useWSCContext } from "context/MilkomedaContext"
import { useAppSelector } from "store/hooks"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
interface InputActiveProps {
  active?: boolean
}
interface ExtendStakingProps {
  handleDurationSelect?: (val: number) => void

  handleStakeAction?: (type: string) => void

  stakeDisable?: boolean
  register: any
  durationInputValue: string
}

const ExtendStaking: React.FC<ExtendStakingProps> = (props) => {
  const {
    handleDurationSelect,
    durationInputValue,
    handleStakeAction,

    register,
  } = props
  const { isWSCConnected } = useWSCContext()
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }
  return (
    <MainContainerCover>
      <Inputs>
        <DurationContainer>
          <WeekSelectRow>
            <WeekSelectItem
              active={Number(durationInputValue) === 1}
              onClick={() => handleDurationSelect(1)}
            >
              <WeekSelectorText>1W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 4}
              onClick={() => handleDurationSelect(4)}
            >
              <WeekSelectorText>4W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 12}
              onClick={() => handleDurationSelect(12)}
            >
              <WeekSelectorText>12W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={Number(durationInputValue) === 52}
              onClick={() => handleDurationSelect(52)}
            >
              <WeekSelectorText>52W</WeekSelectorText>
            </WeekSelectItem>
            <WeekSelectItem
              active={
                Number(durationInputValue) === 196 ||
                Number(durationInputValue) === 200 ||
                Number(durationInputValue) > 52
              }
              onClick={() => handleDurationSelect(200)}
            >
              <WeekSelectorText>MAX</WeekSelectorText>
            </WeekSelectItem>
          </WeekSelectRow>
          <InputContainer>
            <Duration>
              <StakeWrapperRow>
                <DurationInput
                  autoComplete="off"
                  placeholder="69"
                  {...register("durationInputValue")}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                    const parts = e.target.value.split(".")

                    if (parts.length > 2) {
                      e.target.value = parts[0] + "." + parts.slice(1).join("")
                    }
                  }}
                />
                <StakeErrorText>
                  {!!durationInputValue && Number(durationInputValue) < 1 ? (
                    <>Select at least 1 week</>
                  ) : null}
                </StakeErrorText>
              </StakeWrapperRow>
              <WeeksText>Weeks</WeeksText>
            </Duration>
          </InputContainer>
        </DurationContainer>
      </Inputs>
      <Action>
        <StakeButton
          disabled={
            !durationInputValue ||
            (!!durationInputValue && Number(durationInputValue) < 1)
          }
          onClick={
            !!blockTransactionState
              ? handlePendingTxModal
              : () => handleStakeAction("extend")
          }
          blockTransactionState={!!blockTransactionState}
        >
          {!!blockTransactionState ? (
            <>
              <SwapButtonSpinner />
            </>
          ) : null}
          {!!blockTransactionState ? <>Continue</> : <>Extend Duration</>}
        </StakeButton>
      </Action>
    </MainContainerCover>
  )
}

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const WeekSelectRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`

const WeekSelectItem = styled.div<InputActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.active
      ? `1px solid ${TangleColors.lighthover}`
      : `1px solid transparent`};
  background: ${(props) =>
    props.active ? `${TangleColors.cta}` : `${TangleColors.dockBG}`};
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.tangleYellow}`};
  border-radius: 9999px;
  padding: 4px 12px;
  cursor: pointer;
`
const DurationInput = styled(DurationMainInput)``

const Duration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`

const WeeksText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 2px 0 0 2px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StakeButton = styled(StakingMainButton)`
  margin: 0 0 3px 8px;
`

const MainContainerCover = styled.div`
  margin: 12px 0;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-end;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const WeekSelectorText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`
const StakeWrapperRow = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`
const StakeErrorText = styled(CaptionSmall)`
  color: ${TangleColors.red};
  margin: 3px 0 0 0;
  white-space: nowrap;
`
export default ExtendStaking
