export const TANGLE_PRO_MODE = "TANGLE_PRO_MODE"
export const TANGLE_POSITION_STATUS_MODE = "TANGLE_POSITION_STATUS_MODE"
export const TANGLE_WALLET_NETWORK = "TANGLE_WALLET_NETWORK"
//swap redux actions
export const REPLACE_SWAP_STATE = "REPLACE_SWAP_STATE"
export const SELECT_CURRENCY = "SELECT_CURRENCY"
export const SWITCH_CURRENCIES = "SWITCH_CURRENCIES"
export const TYPE_INPUT = "TYPE_INPUT"
export const SET_RECIPIENT = "SET_RECIPIENT"
export const UPDATE_PAIR_DATA = "UPDATE_PAIR_DATA"
export const UPDATE_DERIVED_DATA = "UPDATE_DERIVED_DATA"
export const UPDATE_DEFAULT_TOKEN = "UPDATE_DEFAULT_TOKEN"
//liquidity redux actions
export const REPLACE_LIQUIDITY_STATE = "REPLACE_LIQUIDITY_STATE"
export const SELECT_LIQUIDITY_CURRENCY = "SELECT_LIQUIDITY_CURRENCY"
export const SWITCH_LIQUIDITY_CURRENCIES = "SWITCH_LIQUIDITY_CURRENCIES"
export const TYPE_LIQUIDITY_INPUT = "TYPE_LIQUIDITY_INPUT"
export const SET_LIQUIDITY_RECIPIENT = "SET_LIQUIDITY_RECIPIENT"
export const UPDATE_LIQUIDITY_PAIR_DATA = "UPDATE_LIQUIDITY_PAIR_DATA"
export const UPDATE_LIQUIDITY_DERIVED_DATA = "UPDATE_LIQUIDITY_DERIVED_DATA"
export const UPDATE_CURRENCYIDA = "UPDATE_CURRENCYIDA"
export const UPDATE_CURRENCYIDB = "UPDATE_CURRENCYIDB"

//settings redux
export const UPDATE_USER_SLIPPAGE_TOLERANCE = "UPDATE_USER_SLIPPAGE_TOLERANCE"
export const UPDATE_USER_DEADLINE_MINUTE = "UPDATE_USER_DEADLINE_MINUTE"

//auction toggle redux
export const UPDATE_AUCTION_AGREEMENT = "UPDATE_AUCTION_AGREEMENT"

//pair redux
export const LP_PAIR_TYPEINPUT = "LP_PAIR_TYPEINPUT"

//token balance
export const TOKEN_BALANCE = "TOKEN_BALANCE"

//token balance
export const SLOT0_PRICE = "SLOT0_PRICE"

//increase liquidity redux actions
export const TYPE_INCREASE_LIQUIDITY_INPUT = "TYPE_INCREASE_LIQUIDITY_INPUT"
export const SELECT_INCREASE_LIQUIDITY_CURRENCY =
  "SELECT_INCREASE_LIQUIDITY_CURRENCY"

//left and right input redux
export const RIGHT_RANGE_TYPED_VALUE = "RIGHT_RANGE_TYPED_VALUE"
export const LEFT_RANGE_TYPED_VALUE = "LEFT_RANGE_TYPED_VALUE"
export const RANGE_TYPE_INPUT = "RANGE_TYPE_INPUT"

//fetch tokens
export const FETCH_TOKENS = "FETCH_TOKENS"
export const FETCH_TOKENS_LOADING = "FETCH_TOKENS_LOADING"

//Block number
export const BLOCK_NUMBER_UPDATE = "BLOCK_NUMBER_UPDATE"

//JazzIcon
export const SET_JAZZ_PNG = "SET_JAZZ_PNG"
//JazzIcon
export const TANGLE_SITEWARNING = "TANGLE_SITEWARNING"

//liquidity redux actions
export const CHAIN_CHANGE = "CHAIN_CHANGE"

//fetch unknown tokens
export const FETCH_UNKNOWN_TOKEN_INFO = "FETCH_UNKNOWN_TOKEN_INFO"
export const FETCH_UNKNOWN_TOKEN_FIELD = "FETCH_UNKNOWN_TOKEN_FIELD"

//fetch user transaction history
export const FETCH_USER_TRANSACTION = "FETCH_USER_TRANSACTION"

//fetch user transaction history
export const TANGLE_FARMS_TLP_STATUS = "TANGLE_FARMS_TLP_STATUS"
export const TANGLE_FARMS_REFRESH_STATUS = "TANGLE_FARMS_REFRESH_STATUS"

//fetch user transaction history
export const TANGLESWAP_USER_REFERRAL = "TANGLESWAP_USER_REFERRAL"

//fetch user transaction history
export const TOKEN_DECIMAL = "TOKEN_DECIMAL"

//fetch user selectedwallet
export const SELECTED_WALLET = "SELECTED_WALLET"
export const USER_WALLET_CONNECTION = "USER_WALLET_CONNECTION"
export const USER_WALLET_STATE = "USER_WALLET_STATE"
export const BLOCK_TRANSACTION_STATE = "BLOCK_TRANSACTION_STATE"
export const WALLET_MODAL_STATE = "WALLET_MODAL_STATE"
//fetch farm data
export const FARM_LIST = "FARM_LIST"
export const FARM_LIST_LOADING = "FARM_LIST_LOADING"

//fetch farm data
export const AUCTION_LIST = "AUCTION_LIST"
export const LIQ_LAUNCHER_INFO = "LIQ_LAUNCHER_INFO"
export const SINGLE_AUCTION_LIST = "SINGLE_AUCTION_LIST"
export const AUCTION_LIST_LOADING = "AUCTION_LIST_LOADING"

//mobile nav
export const MOBILE_NAV_OPEN = "MOBILE_NAV_OPEN"
export const MOBILE_NETWORK_OPEN = "MOBILE_NETWORK_OPEN"
export const MOBILE_ADDRESS_OPEN = "MOBILE_ADDRESS_OPEN"

//user account
export const ACCOUNT_TRADED_VOLUME = "ACCOUNT_TRADED_VOLUME"
export const ACCOUNT_REFERRAL_TRADE_VOLUME = "ACCOUNT_REFERRAL_TRADE_VOLUME"
export const ACCOUNT_REFERRALS = "ACCOUNT_REFERRALS"

//WSC Action
export const CHECK_WSC_STEP = "CHECK_WSC_STEP"
export const UPDATE_WSC_STEP = "UPDATE_WSC_STEP"
export const OPEN_WSC_STEP_MODAL = "OPEN_WSC_STEP_MODAL"
export const CHECK_WALLET_OVERVIEW_STEP = "CHECK_WALLET_OVERVIEW_STEP"
export const OPEN_WSC_INTERFACE = "OPEN_WSC_INTERFACE"
//Cardano Settings
export const ADA_TRANSACTIONS = "ADA_TRANSACTIONS"
export const ADA_TX_EFFECT = "ADA_TX_EFFECT"
