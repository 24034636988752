import AccountContainer from "components/account/AccountContainer"
import { useEffect } from "react"
import styled from "styled-components"

const AccountOverview = () => {
  useEffect(() => {
    document.title = "Account Overview"
  }, [])
  return (
    <>
      <Body>
        <AccountContainer />
      </Body>
    </>
  )
}

const Body = styled.div`
  padding: 12px 16px;
`

export default AccountOverview
