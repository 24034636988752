import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionLittle } from "styles/TextStyles"
interface PNGProps {
  text?: string
}
const ConvertTokenSymbol: FC<PNGProps> = (props) => {
  const { text } = props
  return (
    <TokenTradeCover>
      <TokenName>{String(text)?.slice(0, 3)}</TokenName>
    </TokenTradeCover>
  )
}
const TokenTradeCover = styled.div`
  height: 30px;
  width: 30px;
  border: 1px solid ${TangleColors.white};
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  background: ${TangleColors.black};
`
const TokenName = styled(CaptionLittle)`
  margin: 0;
  text-transform: uppercase;
  color: ${TangleColors.white};
`
export default ConvertTokenSymbol
