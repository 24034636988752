import { LiquidityLauncherTemplate } from "components/LaunchPad/types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { toast as TangleToast } from "sonner"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"
import { IoWarningOutline } from "react-icons/io5"
import {
  CounterColumn,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
// import { getAbicoderAddress, getAuctionAddress } from "utils/addressHelper"

import {
  ONE_DAY,
  MarketTemplateId,
  IHubTokenFactoryTemplateId,
} from "utils/calls/investmentHub"
import { useWSCContext } from "context/MilkomedaContext"
import { Caption, CaptionSmall, Header7 } from "styles/TextStyles"
import {
  formatNumber,
  reviewConvertDate,
} from "components/LaunchPad/utils/formatNumber"
import {
  MAGIC_ADDRESS,
  NATIVE_ETH_ADDRESS,
  ZERO_ADDRESS,
  isAddressNative,
  isInvalid,
} from "@tangleswap/sdk"
import {
  AuctionBackButton,
  InvestHubNextButton,
  InvestHubToken1ApprovalButton,
} from "styles/ButtonStyles"

import { parseUnits } from "ethers/lib/utils"
import { toSignificantDigits } from "utils/toSignificant"
import CreateAuctionConfirmationModal from "./CreateAuctionConfirmationModal"
import { useNavigate } from "react-router-dom"
import LoadingDots from "components/utils/LoadingDots"
import { PendingStateContext } from "context/PendingContext"
import axios from "axios"
import useAuctionArray from "utils/useAuctionArray"
import { useTangleship } from "utils/useTangleship"

interface ReviewsProps {
  selected?: boolean
  active?: boolean
  hideSection?: boolean
  layout?: boolean
}
const LaunchPadReview = () => {
  const { tokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)

  const { account, chainId } = useWSCContext()
  const { setPendingTransaction } = useContext(PendingStateContext)
  const [createAuctionError, setCreateAuctionError] = useState<any>(undefined)
  const [tokenAllowance, setTokenAllowance] = useState<any>(undefined)
  const [tokenAllowanceState, setTokenAllowanceState] = useState<boolean>(false)
  const [modalTxError, setModalTxError] = useState<boolean>(false)

  const navigate = useNavigate()

  const { tangleship } = useTangleship()
  const { refetchAuctions } = useAuctionArray()

  const fetchAllowance = useCallback(
    async (refetch = false) => {
      const addr = tokenDetails?.tokenAddress
      const decimals = tokenDetails?.tokenDecimals
      if (!account || !chainId || !addr || !decimals) return

      // if (isWSCConnected) { // this screen is only available with L2 wallets, so this clause is not needed!
      //   setTokenAllowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
      //   return
      // }

      const storageKey = `ihubRewiewAllowance${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setTokenAllowance(Number(storageValue))
        return
      }

      const res = await tangleship?.getAllowance(addr, account, "IHub")
      const allowance = Number(res?._hex) / 10 ** decimals
      sessionStorage.setItem(storageKey, allowance.toString())
      setTokenAllowance(allowance)
    },
    [
      account,
      chainId,
      tokenDetails?.tokenAddress,
      tokenDetails?.tokenDecimals,
      tangleship,
    ]
  )

  useEffect(() => {
    fetchAllowance(false)
  }, [fetchAllowance])

  useEffect(() => {
    if (isInvalid([tokenAllowance, chainId])) return
    const totalTokens =
      Number(tokenDetails?.tokensForSale) * 10 ** tokenDetails?.tokenDecimals
    const initialized =
      Number(tokenAllowance) === 0 || Number(tokenAllowance) < totalTokens
    setTokenAllowanceState(initialized)
  }, [
    tokenAllowance,
    chainId,
    account,
    tokenDetails?.tokensForSale,
    tokenDetails?.tokenDecimals,
  ])

  const createAuction = async () => {
    let {
      paymentCurrencyAddress,
      paymentCurrencyDecimals,
      tokenSupply,
      tokensForSale,
      minimumRaised,
      startPrice,
      endPrice,
      fixedPrice,
      purplelistData,
      startDate,
      endDate,
      tokenAddress,
      tokenDecimals,
      tokenType,
      tokenName,
      tokenSymbol,
      auctionType,
      feeTier,
      liqPercentage,
      liqLauncherEnabled,
      liqLockTime,
    } = tokenDetails

    const isNative = isAddressNative(paymentCurrencyAddress, chainId)
    const isMagic = paymentCurrencyAddress === MAGIC_ADDRESS[chainId]
    const paymentAddress =
      isNative || isMagic
        ? NATIVE_ETH_ADDRESS // reference: https://github.com/TangleSwap/tangleswap-investment-hub/blob/main/test/2_AuctionCreation.test.js#L8
        : paymentCurrencyAddress
    const paymentDecimals = isMagic ? 18 : paymentCurrencyDecimals

    const isDeployed = tokenDetails?.tokenSetupType === "owned_token"

    const auctionTokenConvert = [tokenSupply, tokensForSale]
    const [_tokenSupply, _tokensForSale] = auctionTokenConvert.map((item) =>
      parseUnits(parseFloat(item).toFixed(tokenDecimals), tokenDecimals)
    )

    const payTokenConvert = [minimumRaised, startPrice, endPrice, fixedPrice]
    const [_minimumRaised, _startPrice, _endPrice, _fixedPrice] =
      payTokenConvert.map((item) =>
        parseUnits(item?.toFixed(paymentDecimals) || "0", paymentDecimals)
      )

    const startTime = Math.floor(new Date(startDate).getTime() / 1000)
    const endTime = Math.floor(new Date(endDate).getTime() / 1000)

    const purplelistAddresses = purplelistData.map(
      (entry) => entry.wallet_address
    )
    // const purplelistAmounts = purplelistData.map((entry) => {
    //   const amount = entry.max_amount.toString()
    //   return parseUnits(amount, paymentDecimals)
    // })

    const purplelistAmounts = purplelistData.map((entry) => {
      if (entry.max_token_allowance == null)
        throw new Error("max_amount is undefined or null")
      const amount = entry.max_token_allowance.toString()
      return parseUnits(amount, paymentDecimals)
    })

    const promiseTokenFactoryData = tangleship?.encodeTokenFactoryData(
      isDeployed,
      isDeployed ? tokenAddress : ZERO_ADDRESS,
      Number(IHubTokenFactoryTemplateId[tokenType]) || 1,
      tokenName,
      tokenSymbol,
      isDeployed ? _tokensForSale : _tokenSupply
    )

    const promiseMData =
      auctionType === "crowdsale"
        ? tangleship?.encodeIHubCrowdsaleAuctionMData(
            _tokensForSale,
            startTime,
            endTime,
            paymentAddress,
            feeTier,
            _fixedPrice,
            _minimumRaised
          )
        : auctionType === "batch"
        ? tangleship?.encodeIHubBatchAuctionMData(
            _tokensForSale,
            startTime,
            endTime,
            paymentAddress,
            feeTier,
            _minimumRaised
          )
        : auctionType === "dutch"
        ? tangleship?.encodeIHubDutchAuctionMData(
            _tokensForSale,
            startTime,
            endTime,
            paymentAddress,
            feeTier,
            _startPrice,
            _endPrice
          )
        : // : auctionType === "hyperbolic"
          // ? tangleship?.encodeIHubCrowdsaleAuctionMData(
          //     _tokensForSale,
          //     startTime,
          //     endTime,
          //     paymentAddress,
          //     feeTier,
          //     _startPrice,
          //     _endPrice
          //   )
          null

    const promiseLauncherData = tangleship?.encodeLauncherData(
      LiquidityLauncherTemplate.PostAuctionLauncher,
      liqPercentage * 100, // adjust based on LIQUIDITY_PRECISION = 10000
      liqLauncherEnabled ? liqLockTime * ONE_DAY : 0
    )

    const [tokenFactoryData, mData, launcherData] = await Promise.all([
      promiseTokenFactoryData,
      promiseMData,
      promiseLauncherData,
    ])

    const marketData = await tangleship?.encodeMarketData(
      Number(MarketTemplateId[auctionType]),
      mData
    )

    setReviewAuctionModal(true)
    setReviewAuctionPending(true)
    setReviewAuctionLoading(true)
    setPendingTransaction(true)
    setModalTxError(false)

    await tangleship
      .createAuction(
        tokenFactoryData,
        purplelistAddresses,
        purplelistAmounts,
        marketData,
        launcherData
      )
      .then((res) => {
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            // Make a backend API call to pre-update with the new auction:
            const auctionCreateLog = receipt.logs.find(
              (log) =>
                log.topics[0].startsWith("0x7824ca06") || // "AuctionDeployed" event for Existing Tokens
                log.topics[0].startsWith("0xb7f7543b") // "AuctionDeployed" event for New Tokens
            )
            console.log("sevNewAuction", auctionCreateLog, res, receipt)
            const newAuctionAddress = auctionCreateLog.address
            const url = `https://backend.tangleswap.space/api/v1/chains/${chainId}/auctions/${newAuctionAddress}`

            axios.get(url).then((info) => {
              refetchAuctions()

              setReviewAuctionPending(false)
              setReviewAuctionLoading(false)
              setReviewAuctionTx(res?.hash)
              setReviewAuctionModal(true)
              setPendingTransaction(true)
              setModalTxError(false)
              setPendingTransaction(false)
              setModalTxError(false)
              TangleToast.success("Transaction successful", {
                description: `Auction creation successful`,
              })
            })
          })
        } else {
          setModalTxError(true)
          setReviewAuctionPending(false)
          setReviewAuctionApproved(false)
          setReviewAuctionLoading(false)
          setReviewAuctionTx(res?.hash)
          setReviewAuctionModal(false)
          setPendingTransaction(false)
          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }

  const approveAuctionCreator = () => {
    setPendingTransaction(true)
    setReviewAuctionLoading(true)

    // tangleship?.approveContract(
    //   tokenDetails?.tokenAddress,
    //   IHUB_MARKET_ADDRESS[chainId]
    // )
    tangleship
      .approveContract(
        tokenDetails?.tokenAddress,
        "IHub" // AUCTION_CREATION_ADDRESS[chainId]
      )
      .then((res) => {
        res.tx?.wait().then((receipt) => {
          setPendingTransaction(false)
          setReviewAuctionLoading(false)
          if (receipt.status === 1) setTokenAllowance(res)
        })
      })
  }

  const [reviewAuctionTx, setReviewAuctionTx] = useState<any>()
  const [reviewAuctionPending, setReviewAuctionPending] = useState<any>()
  const [reviewAuctionLoading, setReviewAuctionLoading] = useState<any>()
  const [reviewAuctionModal, setReviewAuctionModal] = useState<any>()
  const [reviewAuctionApproved, setReviewAuctionApproved] = useState<any>()
  const reviewModalRef = useRef<any>(null)

  const closeReviewAuctionPendingModal = useCallback(() => {
    setReviewAuctionModal(false)
  }, [reviewAuctionModal])

  const closeReviewAuctionConfirmationModal = useCallback(() => {
    navigate("/invest")
    setReviewAuctionModal(false)
  }, [reviewAuctionModal])

  const closeReviewAuctionModal = useCallback(
    (event: any) => {
      if (reviewModalRef.current === event?.target) {
        setReviewAuctionModal(false)
      }
    },
    [reviewAuctionModal]
  )

  return (
    <>
      {reviewAuctionModal ? (
        <>
          <CreateAuctionConfirmationModal
            pending={reviewAuctionPending}
            link={reviewAuctionTx}
            modalRef={reviewModalRef}
            closeModal={closeReviewAuctionModal}
            txError={modalTxError}
            closePendingModal={closeReviewAuctionPendingModal}
            closeConfirmationModal={closeReviewAuctionConfirmationModal}
          />
        </>
      ) : null}
      <ItemBody active={currentStep === 5}>
        <CounterColumn>
          <StepCounter active={currentStep === 5}>5</StepCounter>
        </CounterColumn>
        <ItemColumn>
          <TitleContainer>
            <ItemTitle>Review</ItemTitle>
            {currentStep === 5 ? (
              <ItemSubTitle>
                Please make sure to review the details of your auction
                thoroughly!
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 5 ? (
            <>
              <DetailsWrapper>
                <DetailsTitle>Token Details</DetailsTitle>
                <Divider />
                <Row>
                  <Box>
                    <Title>Type</Title>
                    <Value>
                      {tokenDetails?.tokenSetupType === "owned_token"
                        ? "Existing Token"
                        : tokenDetails.tokenType === "fixed"
                        ? "New Token: Fixed Supply"
                        : "New Token: Mintable Supply"}
                    </Value>
                  </Box>
                  <Box>
                    <Title>Name</Title>
                    <Value>{tokenDetails?.tokenName}</Value>
                  </Box>
                  <Box>
                    <Title>Symbol</Title>
                    <Value>{tokenDetails?.tokenSymbol}</Value>
                  </Box>
                  <Box>
                    <Title>Supply</Title>
                    <Value>{formatNumber(tokenDetails?.tokenSupply, 3)}</Value>
                  </Box>
                  <Box>
                    <Title>Tokens for Sale</Title>
                    <Value>
                      {formatNumber(tokenDetails?.tokensForSale, 3)}
                    </Value>
                  </Box>
                </Row>
              </DetailsWrapper>

              <DetailsWrapper>
                <DetailsTitle>General Details</DetailsTitle>
                <Divider />
                <Row>
                  <Box>
                    <Title>Payment Currency</Title>
                    <Value>{tokenDetails?.paymentCurrencySymbol}</Value>
                  </Box>
                  <Box>
                    <Title>Start Date</Title>
                    <Value>
                      {tokenDetails?.startDate
                        ? reviewConvertDate(Number(tokenDetails?.startDate))
                        : null}
                    </Value>
                  </Box>
                  <Box>
                    <Title>End Date</Title>
                    <Value>
                      {tokenDetails?.endDate
                        ? reviewConvertDate(tokenDetails?.endDate)
                        : null}
                    </Value>
                  </Box>
                </Row>
              </DetailsWrapper>

              <DetailsWrapper>
                <DetailsTitle>Auction Details</DetailsTitle>
                <Divider />
                <Row>
                  <Box>
                    <Title>Auction Type</Title>
                    <Value>
                      {tokenDetails.auctionType === "crowdsale" ? (
                        <>Crowdsale</>
                      ) : tokenDetails.auctionType === "dutch" ? (
                        <>Dutch Auction</>
                      ) : (
                        <>
                          {tokenDetails.auctionType === "batch" ? (
                            <> Fair Launch</>
                          ) : (
                            <> &mdash;</>
                          )}
                        </>
                      )}
                    </Value>
                  </Box>
                  {tokenDetails?.auctionType === 1 ? (
                    <>
                      <Box>
                        <Title>Fixed Price</Title>
                        <Value>
                          {formatNumber(tokenDetails?.fixedPrice, 3)}
                        </Value>
                      </Box>
                      <Box>
                        <Title>Minimum percentage sold</Title>
                        <Value>
                          {formatNumber(tokenDetails?.minimumTarget, 3)}
                        </Value>
                      </Box>
                    </>
                  ) : tokenDetails?.auctionType === 2 ? (
                    <>
                      <Box>
                        <Title>Start Price</Title>
                        <Value>
                          {formatNumber(tokenDetails?.startPrice, 3)}
                        </Value>
                      </Box>
                      <Box>
                        <Title>End Price</Title>
                        <Value>{formatNumber(tokenDetails?.endPrice, 3)}</Value>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <Title>Minimum Raise Amount</Title>
                        <Value>
                          {formatNumber(tokenDetails?.minimumRaised, 3)}{" "}
                          {tokenDetails?.paymentCurrencySymbol}
                        </Value>
                      </Box>
                      {tokenDetails?.maximumRaised && (
                        <Box>
                          <Title>Maximum Raise Amount</Title>
                          <Value>
                            {formatNumber(tokenDetails?.maximumRaised, 3)}{" "}
                            {tokenDetails?.paymentCurrencySymbol}
                          </Value>
                        </Box>
                      )}
                    </>
                  )}
                </Row>
              </DetailsWrapper>

              <DetailsWrapper
                hideSection={
                  Boolean(
                    String(formatNumber(tokenDetails?.tokenForLiquidity, 3)) ===
                      "0" &&
                      String(formatNumber(tokenDetails?.liqLockTime, 3)) === "0"
                  ) ||
                  String(formatNumber(tokenDetails?.tokenForLiquidity, 3)) ===
                    "0" ||
                  String(formatNumber(tokenDetails?.liqLockTime, 3)) === "0" ||
                  isNaN(Number(tokenDetails?.tokenForLiquidity)) ||
                  isNaN(Number(tokenDetails?.liqLockTime))
                }
              >
                <DetailsTitle>Liquidity</DetailsTitle>
                <Divider />
                <Row>
                  <Box>
                    <Title>Tokens for Liquidity Pool</Title>
                    <Value>
                      {formatNumber(tokenDetails?.tokenForLiquidity, 3)}{" "}
                      {tokenDetails.tokenSymbol}
                    </Value>
                  </Box>
                  <Box>
                    <Title>Lockup Period</Title>
                    <Value>
                      {formatNumber(tokenDetails?.liqLockTime, 3)}{" "}
                      {String(formatNumber(tokenDetails?.liqLockTime, 3)) ===
                      "0"
                        ? ""
                        : "days"}
                    </Value>
                  </Box>
                  <Box>
                    <Title>Fee Tier</Title>
                    <Value>
                      {toSignificantDigits(
                        tokenDetails?.feeTier / 10000,
                        1,
                        true
                      )}{" "}
                      {""}%
                    </Value>
                  </Box>
                </Row>
              </DetailsWrapper>

              <DetailsWrapper
                hideSection={Boolean(
                  String(
                    formatNumber(tokenDetails?.purplelistData?.length, 3)
                  ) === "0" ||
                    String(
                      formatNumber(tokenDetails?.purplelistData?.length, 3)
                    ) === "0" ||
                    isNaN(Number(tokenDetails?.purplelistData?.length))
                )}
              >
                <DetailsTitle>Purplelist</DetailsTitle>
                <Divider />
                <Row>
                  <Box>
                    <Title>Number of Whitelisted Addresses</Title>
                    <Value>
                      {formatNumber(tokenDetails?.purplelistData?.length, 3)}
                    </Value>
                  </Box>
                </Row>
              </DetailsWrapper>

              <ActionButtonsContainer
              // layout={true}
              >
                {tokenDetails?.tokenSetupType === "owned_token" &&
                tokenAllowance !== undefined &&
                !!tokenAllowanceState ? (
                  <ApproveButtonWrapper>
                    <CreateToken0AuctionButton
                      disabled={reviewAuctionLoading}
                      onClick={approveAuctionCreator}
                    >
                      {reviewAuctionLoading ? (
                        <>
                          <LoadingDots />
                        </>
                      ) : (
                        <> Approve {tokenDetails?.tokenSymbol}</>
                      )}
                    </CreateToken0AuctionButton>
                    {/* <CreateToken1AuctionButton>
                      Approve {tokenDetails?.tokenSymbol} Approve iHub
                    </CreateToken1AuctionButton> */}
                  </ApproveButtonWrapper>
                ) : (
                  <>
                    <CreateAuctionButton
                      disabled={reviewAuctionLoading && !reviewAuctionApproved}
                      onClick={createAuction}
                    >
                      {reviewAuctionLoading ? (
                        <>
                          <LoadingDots />
                        </>
                      ) : (
                        <> Create Auction</>
                      )}
                    </CreateAuctionButton>
                  </>
                )}

                <BackAuctionButton
                  onClick={() => {
                    setCurrentStep((currentStep: number) => currentStep - 1)
                  }}
                >
                  Back
                </BackAuctionButton>
              </ActionButtonsContainer>
              {createAuctionError !== undefined ? (
                <>
                  {" "}
                  <ErrorMessage active={createAuctionError !== undefined}>
                    <ErrorWarning />
                    <>{createAuctionError}</>
                  </ErrorMessage>
                </>
              ) : null}
            </>
          ) : null}
        </ItemColumn>
      </ItemBody>
    </>
  )
}

const DetailsWrapper = styled.div<ReviewsProps>`
  display: ${(props) => (props.hideSection ? "none" : "flex")};
  flex-direction: column;
  width: 100%;
  padding: 28px 16px;
  border-radius: 8px;
  border: 1px solid ${TangleColors.offWhite};
  background: ${TangleColors.swapBG};
`

const DetailsTitle = styled(Header7)`
  color: ${TangleColors.white};
`

const Divider = styled.div`
  height: 1.3px;
  width: 100%;
  background: ${TangleColors.offWhite};
  margin: 8px 0 12px 0;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const Title = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
`

const Value = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 2px 0 0 0;
`
const ErrorMessage = styled(Caption)<ReviewsProps>`
  color: ${TangleColors.red};
  display: ${(props) => (props.active ? "flex" : "none")};
  justify-content: flex-start;
  align-items: center;
  position: relative;
`
const ErrorWarning = styled(IoWarningOutline)`
  height: 24px;
  width: 24px;
  margin: 0 6px 0 0;
  color: ${TangleColors.lighthover};
`
const CreateAuctionButton = styled(InvestHubNextButton)`
  background: ${TangleColors.heroCTA};
  margin: 10px 0;
  position: relative;
  z-index: 9;
`
const ApproveButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
`
const CreateToken0AuctionButton = styled(InvestHubNextButton)`
  margin: 0;
  position: relative;
  z-index: 9;
`
const CreateToken1AuctionButton = styled(InvestHubToken1ApprovalButton)`
  margin: 0;
  position: relative;
  z-index: 9;
  border: 1px solid ${TangleColors.lighthover};
`

const BackAuctionButton = styled(AuctionBackButton)`
  background: ${TangleColors.heroCTA};
  margin: 0;
  position: relative;
  z-index: 9;
`

const ActionButtonsContainer = styled.div<ReviewsProps>`
  display: flex;
  flex-direction: ${(props) => (props.layout ? `column` : `row`)};
  gap: 24px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  max-width: 500px;
`
export default LaunchPadReview
