import { createReducer } from "@reduxjs/toolkit"
import {
  selectCurrency,
  switchCurrencies,
  typeInput,
} from "store/actions/SwapAction"
import {
  Chains,
  Field,
  isInvalid,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
} from "@tangleswap/sdk"

export interface SwapState {
  readonly independentField?: Field
  readonly typedValue?: any
  readonly [Field.INPUT]?: {
    readonly currencyId: string | undefined | null
  }
  readonly [Field.OUTPUT]?: {
    readonly currencyId: string | undefined | null
  }
  chainId?: Chains
  // the typed recipient address or ENS name, or null if swap should go to sender
}

const initialState: SwapState = {
  independentField: Field.INPUT,
  typedValue: "",
  [Field.INPUT]: {
    currencyId: "",
  },
  [Field.OUTPUT]: {
    currencyId: "",
  },
}

const swapReducer = createReducer<SwapState>(initialState, (builder) => {
  builder

    .addCase(
      selectCurrency,
      (state, { payload: { field, currencyAddress, chainId } }) => {
        const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT

        if (isInvalid([chainId, currencyAddress, field])) return

        const token0 = String(currencyAddress)?.trim()?.toLowerCase()
        const token1 = String(state[otherField]?.currencyId)
          ?.trim()
          ?.toLowerCase()

        const tokenSwitch =
          String(currencyAddress)?.trim()?.toLowerCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toLowerCase() ||
          String(currencyAddress)?.trim()?.toUpperCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toUpperCase()

        // the case where we have to swap the order
        if (
          String(currencyAddress)?.trim()?.toLowerCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toLowerCase() ||
          String(currencyAddress)?.trim()?.toUpperCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toUpperCase()
        ) {
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [otherField]: { currencyId: currencyAddress },
            [field]: {
              currencyId: state[field].currencyId,
            },
          }
        }
        if (
          String(currencyAddress)?.trim()?.toLowerCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toLowerCase() ||
          String(currencyAddress)?.trim()?.toUpperCase() ===
            String(state[otherField]?.currencyId)?.trim()?.toUpperCase()
        ) {
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [otherField]: { currencyId: state[field]?.currencyId },
            [field]: {
              currencyId: currencyAddress,
            },
          }
        }
        if (tokenSwitch && !token0 && !token1) {
          return {
            ...state,
            independentField:
              state.independentField === Field.INPUT
                ? Field.OUTPUT
                : Field.INPUT,
            [otherField]: { currencyId: state[field]?.currencyId },
            [field]: {
              currencyId: currencyAddress,
            },
          }
        }
        // the normal case
        return {
          ...state,
          [field]: { currencyId: currencyAddress },
        }
      }
    )

    .addCase(switchCurrencies, (state) => {
      return {
        ...state,
        independentField:
          state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT]?.currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT]?.currencyId },
      }
    })
    .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue: typedValue,
      }
    })
})
export default swapReducer
