import React, { FC, useRef } from "react"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"
import { AiOutlinePlus } from "react-icons/ai"
import { GrClose } from "react-icons/gr"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionMedium, Header5Bold } from "styles/TextStyles"
import { motion } from "framer-motion"

import { Link, useNavigate } from "react-router-dom"
interface GridCardProps {
  link?: string
  title?: string
  backgroundColor?: any
  backgroundImage?: any
  maxWidth?: number
  minHeight?: number
  paragraph?: any
  pressedIcon?: boolean
  number?: number
  togglePressInfo?: (value?: any) => void
  specialName?: any
}
const HomeGridCards: FC<GridCardProps> = (props) => {
  const {
    link,
    title,
    backgroundColor,
    specialName,
    backgroundImage,
    number,
    maxWidth,
    minHeight,
    paragraph,
    pressedIcon,
    togglePressInfo,
  } = props
  const gridCoverRef = useRef<HTMLDivElement | any>()
  const gridPressableRef = useRef<HTMLDivElement | any>()
  const navigate = useNavigate()

  const goToLink = (e?: any) => {
    if (
      e?.target === gridCoverRef.current &&
      e?.target !== gridPressableRef.current
    ) {
      navigate(`/${link}`)
    }
  }
  return (
    <GridCover
      backgroundColor={backgroundColor}
      maxWidth={maxWidth}
      minHeight={minHeight}
      onClick={goToLink}
      ref={gridCoverRef}
    >
      <GridContainerImage src={backgroundImage} />
      <GridTop>
        <GridTitleInfo>
          {" "}
          <GridTitle>{title}</GridTitle>
          {pressedIcon ? (
            <>
              <GridTitleParagraph
                initial={{ opacity: 0, y: -12 }}
                exit={{ opacity: 0, y: -12 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
              >
                {paragraph}
              </GridTitleParagraph>
            </>
          ) : null}
        </GridTitleInfo>

        <GridPressable ref={gridPressableRef}>
          <PressableCover
            onClick={() => togglePressInfo?.(number)}
            pressedIcon={pressedIcon}
          >
            {pressedIcon ? (
              <IconCover
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 3.2 }}
              >
                <PressedIcon />
              </IconCover>
            ) : (
              <IconCover
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 3.2 }}
              >
                {" "}
                <UnPressedIcon />
              </IconCover>
            )}
          </PressableCover>
        </GridPressable>
      </GridTop>

      <GridBottom>
        <GridLink to={`/${link}`}>
          <GridBottomButton>
            <ButtonGridCover>
              <ButtonText>{specialName}</ButtonText> <ButtonArrow />
            </ButtonGridCover>{" "}
          </GridBottomButton>
        </GridLink>
      </GridBottom>
    </GridCover>
  )
}

const GridLink = styled(Link)``
const GridCover = styled.div<GridCardProps>`
  width: 100%;

  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "0")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "0")};
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 24px;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;
  cursor: pointer;
  background-image: ${(props) =>
    props.backgroundImage && `${props.backgroundImage}`};
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: bottom -32px right -32px;
  background-color: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor}` : "transparent"};

  box-shadow: transparent;
  transition: 0.4s ease-in;
  :hover {
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.25);
  }
  :nth-child(1) {
    grid-column: span 2;
    grid-row: span 3;
  }
  :nth-child(2) {
    grid-column: span 1;
    grid-row: span 3;
  }
  :nth-child(3) {
    grid-column: span 1;
    grid-row: span 4;
  }
  :nth-child(4) {
    grid-column: span 1;
    grid-row: span 1;
  }
  :nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
  }
  :nth-child(6) {
    grid-column: span 2;
    grid-row: span 2;
  }
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "0")};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "0")};

  color: ${TangleColors.white};
  @media only screen and (max-width: 850px) {
    :nth-child(1) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
    :nth-child(2) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
    :nth-child(3) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
    :nth-child(4) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
    :nth-child(5) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
    :nth-child(6) {
      grid-column: span 3;
      grid-row: span 3;
      max-width: 900px !important;
    }
  }
`
const GridContainerImage = styled.img`
  position: absolute;
  bottom: -64px;
  right: -56px;
  width: 100%;
  max-width: 300px;
  object-fit: contain;
  min-height: 250px;
`
const GridTop = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
`
const GridTitleInfo = styled.div`
  display: flex;

  flex-direction: column;
  padding: 0 0 0 16px;
`
const GridTitle = styled(Header5Bold)``

const GridTitleParagraph = styled(motion(CaptionMedium))``
const GridPressable = styled.div``
const PressableCover = styled.div<GridCardProps>`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: ${(props) =>
    props.pressedIcon
      ? `2px solid ${TangleColors.white}`
      : `2px solid ${TangleColors.lighthover}`};
  background: ${(props) =>
    props.pressedIcon ? `${TangleColors.cta}` : `${TangleColors.heroCTA}`};
`

const IconCover = styled(motion.div)`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const PressedIcon = styled(GrClose)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
  }
`
const UnPressedIcon = styled(AiOutlinePlus)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
`
const GridBottom = styled.div``
const GridBottomButton = styled.div<GridCardProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px 8px 16px;
  border-radius: 32px;
  transition: 0.4s ease-in;
  max-width: 220px;
  color: ${TangleColors.white};
  background: transparent;
  ${GridCover}:hover & {
    color: ${TangleColors.black};
    background: ${TangleColors.lighthover};
  }
`
const ButtonGridCover = styled(Caption)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ButtonText = styled.div`
  margin: 0 4px;
  white-space: nowrap;
`
const ButtonArrow = styled(BsArrowRight)`
  height: 24px;
  width: 24px;
  margin: 0 0 0 8px;
`

export default HomeGridCards
