import { TANGLESWAP_USER_REFERRAL } from "./actionsTypes"

export const updateTangleswapUserReferral = (tangleswapUserReferral) => {
  return {
    type: TANGLESWAP_USER_REFERRAL,
    tangleswapUserReferral: tangleswapUserReferral,
  }
}

export const updateUserReferralInfo = (tangleswapUserReferral) => {
  return (dispatch) => {
    localStorage.setItem("tangleswapUserReferral", tangleswapUserReferral)
    dispatch(updateTangleswapUserReferral(tangleswapUserReferral))
  }
}

export const checkTangleswapUserReferralInfo = () => {
  return (dispatch: any) => {
    const tangleswapUserReferral = localStorage.getItem(
      "tangleswapUserReferral"
    )
    if (tangleswapUserReferral === null || tangleswapUserReferral === "false") {
      dispatch(updateUserReferralInfo(undefined))
    } else {
      // Assuming you've stored as "true" string for true values
      dispatch(updateUserReferralInfo(tangleswapUserReferral))
    }
  }
}
