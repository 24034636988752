import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3 } from "styles/TextStyles"
import { GrClose } from "react-icons/gr"
import { motion, AnimatePresence } from "framer-motion"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateSiteWarningMode } from "store/actions/SiteWarningActions"
import { useLocation } from "react-router-dom"
import { useWSCContext } from "context/MilkomedaContext"
import { Chains } from "@tangleswap/sdk"
interface WarningProps {
  siteWarningState?: boolean
}
const SiteTestingWarning = () => {
  const siteWarningState = useAppSelector(
    (state) => state.siteWarning.siteWarning
  )
  const dispatch = useAppDispatch()

  const turnOffSiteWarning = () => {
    dispatch(updateSiteWarningMode(true))
  }
  const location = useLocation()
  const homeClassName = location.pathname === "/"
  const { l1ChainId } = useWSCContext()

  return (
    <>
      {Boolean(!homeClassName) &&
      [
        Chains.L1_CARDANO,
        Chains.L1_CARDANO_TEST,
        Chains.CARDANO,
        Chains.CARDANO_TEST,
      ].includes(l1ChainId) ? (
        <>
          {" "}
          <AnimatePresence>
            <Body
              siteWarningState={Boolean(siteWarningState)}
              exit={{ scale: 0.2, y: -100 }}
              transition={{
                duration: 0.3,
                ease: "easeOut",
              }}
              style={{ transformOrigin: "top left" }}
            >
              <Cover>
                <WarningImageWrapper>
                  <WarningIcon />
                </WarningImageWrapper>

                <WarningText>
                  Welcome aboard, Explorer! Please note that TangleSwap is
                  currently in public testnet phase, with a particular focus on
                  testing
                  <a
                    href="https://docs.tangleswap.exchange/special/wrapped-smart-contracts"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    novel WSC technology.
                  </a>
                  To participate in the
                  <a
                    href="https://twitter.com/TangleSwap/status/1729124501357519152"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Treasures of TangleSwap,
                  </a>
                  you can request test tokens from
                  <a
                    href="https://docs.tangleswap.exchange/guides/getting-started/cardano-testnet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    the official faucet.
                  </a>
                  Enjoy!
                </WarningText>
              </Cover>
              <CloseIconWrapper>
                <CloseIcon onClick={turnOffSiteWarning} />
              </CloseIconWrapper>
            </Body>
          </AnimatePresence>
        </>
      ) : null}
    </>
  )
}
const Body = styled(motion.div)<WarningProps>`
  height: 90px;
  width: 100%;
  background-color: ${TangleColors.heroCTA};
  display: ${(props) => (props.siteWarningState ? `none` : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${TangleColors.lighthover};
  .mobilewarning {
    display: none;

    @media only screen and (max-width: 750px) {
      display: flex;
    }
  }
  .desktopwarning {
    @media only screen and (max-width: 750px) {
      display: none;
    }
  }
  @media only screen and (max-width: 850px) {
    min-height: 90px;
    height: 100%;
  }
`

const Cover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  overflow: hidden;

  @media only screen and (max-width: 750px) {
    /* max-width: 80%; */
    padding: 8px 40px 8px 8px;
  }
`
const WarningIcon = styled.div`
  width: 160px;
  height: 120px;
  background-image: url("/alien_soul-purple.png");
  background-size: 170px;
  background-repeat: no-repeat;
  background-position: top -30% center;
  background-color: ${TangleColors.heroCTA};
`
const WarningImageWrapper = styled.div`
  max-width: 130px;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`

const WarningText = styled(Body3)`
  color: ${TangleColors.white};
  margin: 0 0 0 8px;
  padding: 8px;
  /* @media only screen and (max-width: 650px) {
    padding: 16px;
  } */
  a {
    /* color: ${TangleColors.white}; */
    margin: 0 2px 0 4px;
    /* text-underline-offset: 4px; */
    color: ${TangleColors.lighthover};
    transition: 0.4s ease-in;
    text-decoration-color: ${TangleColors.lighthover};
    -webkit-text-decoration-color: ${TangleColors.lighthover};
    :hover {
      color: ${TangleColors.tangleYellowPrimary};
      text-decoration-color: ${TangleColors.tangleYellowPrimary};
      -webkit-text-decoration-color: ${TangleColors.tangleYellowPrimary};
    }
  }
`
const CloseIcon = styled(GrClose)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};

  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 750px) {
    /* max-width: 80%; */
    right: 10px;
  }
`
export default SiteTestingWarning
