import numbro from "numbro"
// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num: any, digits = 2, round = true) => {
  if (num === 0) return "$0.00"
  if (!num) return "-"
  if (num < 0.001 && digits <= 3) {
    return "<$0.001"
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  })
}
