import React, { FC, useEffect, useState } from "react"

import styled from "styled-components"
import { FarmAddLiquidityButton } from "styles/ButtonStyles"

import { useWSCContext } from "context/MilkomedaContext"
import { Caption, CaptionSmall, Header7, Subtitle } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import EmptyOverviewState from "../components/EmptyOverviewState"
import WalletInformation from "../components/WalletInformation"
import { useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import {
  BRIDGE_DEVNET_ADDRESS,
  REQUIRED_FEE_ADA,
  TxStatus,
} from "constants/milkomeda/transaction"
import BigNumber from "bignumber.js"
import LoaderSpinner from "components/LoaderSpinner"
import CardanoTxFeedback from "../components/CardanoTxFeedback"
import { useAppSelector } from "store/hooks"

import { DestinationDataProps } from "../utils/types"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import WalletNativeBalance from "../components/WalletNativeBalance"
import WalletLayout from "../components/WalletLayout"
import { walletOverviewUnwrapMessages } from "../utils/helper"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"
interface OverviewProps {
  active?: boolean
  token?: string
  amount?: string | number
  contract?: string
  grid?: boolean
}
const OverviewTransactionInfo: FC<OverviewProps> = (props) => {
  const { active } = props
  const {
    destinationTokens,
    destinationBalanceADA,
    account,
    originTokens: originBalance,
  } = useWSCContext()
  const [txHash, setTxHash] = useState<string | undefined>()
  const [walletBalance, setWalletBalance] = useState<any>(undefined)

  const [activeLayout, setActiveLayout] = useState<number | null>(1)
  const [transactionIndex, setTransactionIndex] = useState<number | null>(null)
  const [errorDisplay, setErrorDisplay] = useState<number | null>(null)

  const { wscProvider } = useWSCProvider()
  const [transactionList, setTransactionList] = useState<any>(undefined)

  const { txStatus, txStatusError, setTxStatusError, setTxStatus } =
    useTransactionStatus()

  const unwrapToken = async (index: number, token: any) => {
    if (!token || !wscProvider) return
    setErrorDisplay(null)
    setTransactionIndex(index)
    setTxStatus(TxStatus.Init)
    const isNativeToken = token.contractAddress === BRIDGE_DEVNET_ADDRESS

    const unwrapOptions = {
      destination: undefined,
      assetId: isNativeToken ? undefined : token.contractAddress,
      amount: new BigNumber(token.balance),
    }

    try {
      const txHash = await wscProvider.unwrap(
        unwrapOptions.destination,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        unwrapOptions.assetId!,
        unwrapOptions.amount
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)
    } catch (err) {
      console.error(err)
      setTxStatus(TxStatus.Error)
      if (err instanceof Error) {
        setTxStatusError(err.message)
      }
    }
  }

  // l1 data originBalance
  // assetName: "ADA"
  // bridgeAllowed: true
  // decimals: 6
  // fingerprint: "asset1xdz4yj4ldwlpsz2yjgjtt9evg9uskm8jrzjwhj"
  // has_nft_onchain_metadata: false
  // quantity: "2377619446"
  // unit: "lovelace"

  // l2 data destinationTokens
  // balance: "100000"
  // contractAddress: "0x5fa38625dbd065b3e336e7ef627b06a8e6090e8f"
  // decimals: "6"
  // name: "CPPm3s6"
  // symbol: "m3s6"
  // type: "ERC-20"

  // useEffect(() => {
  //   const infoData = destinationTokens?.data?.filter(
  //     (data) => data.type !== "ERC-721"
  //   )
  //   const transactiondata = [...infoData]

  //   const adaToken = {
  //     decimals: "6",
  //     name: "Cardano",
  //     symbol: "ADA",
  //     contractAddress: BRIDGE_DEVNET_ADDRESS,
  //     balance: walletBalance,
  //     type: "ERC-20",
  //   }

  //   const tokens = [adaToken, ...(transactiondata || [])]
  //   setTransactionList(tokens)
  // }, [destinationTokens?.data, walletBalance])
  useEffect(() => {
    const infoData = destinationTokens?.data?.filter(
      (data) => data.type !== "ERC-721"
    )
    const transactiondata = [...infoData]

    const adaToken = {
      decimals: 6, // already scaled
      name: "Cardano",
      symbol: "ADA",
      contractAddress: BRIDGE_DEVNET_ADDRESS,
      balance: convertTokensToWei({
        value: new BigNumber(walletBalance).dp(6),
        token: { decimals: 6 },
      }).toFixed(),
    }

    // Wrap adaToken in an array
    const tokens = [adaToken, ...(transactiondata || [])]
    setTransactionList(tokens)
  }, [destinationTokens?.data, walletBalance])

  const changeLayout = (value: number) => {
    setActiveLayout(value)
  }
  const openExternalLink = (address, type, target = "_blank") => {
    const isWrap = type === "Wrap"

    const explorerUrl = isWrap
      ? "https://preprod.cardanoscan.io/transaction/"
      : "https://explorer-devnet-cardano-evm.c1.milkomeda.com/address/"
    const href = `${explorerUrl}${address}`
    window.open(href, target)
  }

  useEffect(() => {
    if (!destinationBalanceADA) return

    const adaBalance: number = Number(destinationBalanceADA?.data)
    setWalletBalance(adaBalance)
  }, [destinationBalanceADA])

  const disableCardano = !!(Number(walletBalance) < REQUIRED_FEE_ADA)
  const calculateBalance = (balance: number, decimals: number) => {
    const tokenValue = balance / 10 ** decimals
    const tokenBalance = formatNumber(tokenValue)
    return tokenBalance
  }

  return (
    <OverviewStepperCover active={active}>
      <StepperTopRow>
        {" "}
        <StepperRowTitle> Assets in your Cardano L2 Wallet</StepperRowTitle>
        <WalletInformation account={account} />
        {disableCardano ? (
          <>
            <CardanoTxFeedback
              error={true}
              txStatus="You need L2 ADA to pay gas before you can unwrap your tokens."
            />
          </>
        ) : null}
        {transactionIndex !== null ? (
          <>
            {" "}
            <CardanoTxFeedback txStatus="Unwrapping may take a few minutes (~2-3 min)" />
          </>
        ) : null}
      </StepperTopRow>

      {/* <OverviewWrapper>
        {transactionList?.length > 0 ? (
          <>
            {" "}
            <TransactionInfoTable
              tableData={transactionList}
              txStatus={txStatus}
              transactionIndex={transactionIndex}
              unwrapToken={unwrapToken}
            />
          </>
        ) : (
          <>
            <EmptyOverviewState title="No tokens available" />
          </>
        )}
      </OverviewWrapper> */}

      <OverviewBalanceBody>
        <WalletLayout
          tokensLength={transactionList?.length}
          number={activeLayout}
          onClick={changeLayout}
        />
        {transactionList?.length > 0 ? (
          <>
            {transactionList?.map(
              (data: DestinationDataProps, index: number) => {
                const tokenBalance = calculateBalance(
                  Number(data.balance),
                  Number(data.decimals)
                )

                return (
                  <React.Fragment key={index}>
                    <OverviewButtonCover key={index}>
                      <OverviewCardanoTextRow grid={activeLayout === 1}>
                        <BalanceText grid={activeLayout === 1}>
                          Balance:{" "}
                          <BalanceNumber>
                            {" "}
                            {tokenBalance}{" "}
                            <BalanceTextRow
                              onClick={() =>
                                openExternalLink(
                                  data.contractAddress,
                                  data.type
                                )
                              }
                            >
                              {" "}
                              <CardanoTokenSymbol>
                                {data.symbol}
                              </CardanoTokenSymbol>{" "}
                            </BalanceTextRow>
                          </BalanceNumber>
                        </BalanceText>

                        <OverviewMainButton
                          grid={activeLayout === 1}
                          disabled={disableCardano}
                          onClick={() => unwrapToken(index, data)}
                        >
                          {disableCardano ? (
                            <>
                              {">"} {REQUIRED_FEE_ADA.toFixed(1)} ADA required{" "}
                            </>
                          ) : (
                            <>
                              {transactionIndex === index ? (
                                <>
                                  {walletOverviewUnwrapMessages[txStatus]}
                                  <LoaderSpinner />
                                </>
                              ) : (
                                <> Move to L1</>
                              )}
                            </>
                          )}
                        </OverviewMainButton>
                      </OverviewCardanoTextRow>

                      {errorDisplay === index ? (
                        <>
                          {" "}
                          <CardanoTxFeedback
                            error={true}
                            txStatus={txStatusError}
                          />
                        </>
                      ) : null}
                    </OverviewButtonCover>
                  </React.Fragment>
                )
              }
            )}
          </>
        ) : (
          <>
            {" "}
            <EmptyOverviewState title="No tokens available" />
          </>
        )}
      </OverviewBalanceBody>
    </OverviewStepperCover>
  )
}
const OverviewStepperCover = styled.div<OverviewProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.4s ease-in;
  padding: 8px;
  margin: 12px 0 0 0;
`

const StepperTopRow = styled.div`
  display: flex;
  width: 100%;
  margin: 12px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepperInfoRow = styled.div`
  display: flex;
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.lighthover};
`
const StepperRowTitle = styled(Header7)`
  color: ${TangleColors.white};
  margin: 4px 0;
`

const OverviewBalanceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
`

const OverviewButtonCover = styled.div`
  width: 100%;
  margin: 12px 0;
`
const OverviewMainButton = styled(FarmAddLiquidityButton)<OverviewProps>`
  margin: 12px 0;
  width: 100%;
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `250px` : `900px`)};
`

const OverviewCardanoTextRow = styled.div<OverviewProps>`
  display: flex;
  flex-direction: ${(props) => (props.grid ? `row` : `column`)};
  justify-content: space-between;
  align-items: ${(props) => (props.grid ? `center ` : `flex-start`)};
  transition: 0.4s ease-in;
  position: relative;
  z-index: 800;
  gap: 12px;
`
const BalanceText = styled(CaptionSmall)<OverviewProps>`
  margin: 0;
  color: ${TangleColors.darkerText};
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start;
  align-items: center; */
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `550px` : `900px`)};
`
const BalanceNumber = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 200px;
  width: 100%;
  /* justify-content: center;
  align-items: center; */
`
const CardanoTokenName = styled(Subtitle)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 4px;
  cursor: pointer;
`
const CardanoTokenSymbol = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
  cursor: pointer;
`
const BalanceTextRow = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export default OverviewTransactionInfo
