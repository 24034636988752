import { useEffect } from "react"
import farmHeroBg from "assets/hero-green.png"
import SiteheroSection from "components/SiteheroSection"
import FarmsContainer from "components/farm/FarmsContainer"
import styled from "styled-components"
import ComingSoon from "components/utils/ComingSoon"
import { useLocation } from "react-router-dom"
import { Chains } from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"
const Farming = () => {
  useEffect(() => {
    document.title = "Farms"
  }, [])
  const { chainId } = useWSCContext()
  return (
    <Body>
      <SiteheroSection
        backgroundImage={farmHeroBg}
        title="Asteroid Farms"
        subText="Leverage your LP positions to earn additional rewards."
      />

      {chainId === Chains.SHIMMER ? (
        <>
          {" "}
          <FarmsContainer />
        </>
      ) : (
        <>
          <FarmsContainer />
          {/* <ComingSoon /> */}
        </>
      )}
    </Body>
  )
}

const Body = styled.div`
  min-height: 100vh;
`
export default Farming
