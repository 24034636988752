import { useEffect } from "react"
import auctionHeroBg from "assets/hero-yellow.png"
import styled from "styled-components"
import SiteheroSection from "components/SiteheroSection"
import LaunchBody from "components/LaunchPad/LaunchBody"
import ComingSoon from "components/utils/ComingSoon"
import { Chains } from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"

const InvestmentHub = () => {
  useEffect(() => {
    document.title = "Investment Hub | TangleSwap"
  }, [])
  const { chainId } = useWSCContext()
  return (
    <Body>
      {/* <LaunchHero /> */}
      <SiteheroSection
        backgroundImage={auctionHeroBg}
        title="Deep Space Investment Hub"
        subText="Your Token Launchpad to jump early into the latest, hottest projects."
      />
      {/* <LaunchBody /> */}
      {chainId === Chains.SHIMMER || chainId === Chains.CARDANO ? (
        <>
          {" "}
          <ComingSoon />
        </>
      ) : (
        <>
          {" "}
          <LaunchBody />
        </>
      )}
    </Body>
  )
}

const Body = styled.div`
  height: 100%;
`
export default InvestmentHub
