import styled from "styled-components"
import InProgress from "./InProgress"
import { FC, useState } from "react"
import { useWSCContext } from "context/MilkomedaContext"
import { useAppSelector } from "store/hooks"
import { Chains } from "@tangleswap/sdk"
import { useLocation } from "react-router-dom"

interface ProgressFollowUpProps {
  pendingTxs?: any
  pendingTxsAvailable?: boolean
  notificationActive?: boolean
  closeNotification?: () => void
  coverState?: boolean
}
const ProgressFollowUp: FC<ProgressFollowUpProps> = (props) => {
  const { pendingTxs, notificationActive, closeNotification } = props
  const siteWarningState = useAppSelector(
    (state) => state.siteWarning.siteWarning
  )
  const [dismissedNotifications, setDismissedNotifications] = useState<
    string[]
  >([])

  const { account, pendingWSCTxs, isWSCConnected, l1ChainId } = useWSCContext()
  const showNotifications =
    !!account && !!isWSCConnected && pendingWSCTxs && pendingWSCTxs?.length > 0

  const handleDismissNotification = (hash: string) => {
    setDismissedNotifications((prev) => [...prev, hash])
    if (closeNotification) {
      closeNotification()
    }
  }

  const activeNotifications = pendingTxs?.filter(
    (data) => !dismissedNotifications.includes(data.hash)
  )
  const location = useLocation()
  const homeClassName = location.pathname === "/"
  const coverState =
    !siteWarningState &&
    Boolean(!homeClassName) &&
    [
      Chains.L1_CARDANO,
      Chains.L1_CARDANO_TEST,
      Chains.CARDANO,
      Chains.CARDANO_TEST,
    ].includes(l1ChainId)
  return (
    <>
      {notificationActive &&
        showNotifications &&
        activeNotifications.length > 0 && (
          <ProgressCover coverState={coverState}>
            {activeNotifications.map((transactionInfo, index) => (
              <InProgress
                key={transactionInfo.hash}
                pendingTx={transactionInfo}
                completed={transactionInfo.completed}
                number={index + 1}
                hash={transactionInfo.hash}
                closeNotification={() =>
                  handleDismissNotification(transactionInfo.hash)
                }
                txType={transactionInfo.type}
              />
            ))}
          </ProgressCover>
        )}
    </>
  )
}
const ProgressCover = styled.div<ProgressFollowUpProps>`
  position: fixed;

  top: ${(props) => (props.coverState ? `200px` : `125px`)};
  right: 32px;
  z-index: 120 !important;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export default ProgressFollowUp
