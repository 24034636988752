import { Tangleship, isInvalid } from "@tangleswap/sdk"
import { FETCH_USER_TRANSACTION } from "./actionsTypes"
import { TransactionHistoryGroup, createGroups } from "utils/parseRemote"
import { TransactionHistoryProps } from "navbar/types"

export const transactionHistoryCompleted = (
  transactionHistory: any,
  transactionListGroup: any
) => {
  return {
    type: FETCH_USER_TRANSACTION,
    transactionHistory: transactionHistory,
    transactionListGroup: transactionListGroup,
  }
}

export const fetchUserTransactionHistory = (
  account: any,
  tangleship: Tangleship
) => {
  return (dispatch: any) => {
    if (isInvalid([account])) return
    tangleship?.fetchWalletTxHistory(account, 30, false).then((res) => {
      const tokenData: TransactionHistoryProps = res
      const tokenInfo: TransactionHistoryGroup[] = createGroups(res)
      dispatch(transactionHistoryCompleted(tokenData, tokenInfo))
    })
  }
}
