import React from "react"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"
import networkIcon from "assets/network.svg"
import { Chains } from "@tangleswap/sdk"
import addChainToMetamask from "connect/switchChain"

const MobileWrongNetwork = () => {
  return (
    <NetworkCover onClick={() => addChainToMetamask(Chains.SHIMMER)}>
      <NetworkLogo src={networkIcon} alt="Wrong network" />
    </NetworkCover>
  )
}

const NetworkCover = styled.div`
  width: 80px;
  height: 48px;

  margin: 0 0 0 8px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 !important;
  background: ${TangleColors.red};
`
const NetworkLogo = styled.img`
  height: 12px;
  width: 12px;
  margin: 0 4px;
`

export default MobileWrongNetwork
