import {
  useState,
  Fragment,
  FC,
  useEffect,
  RefObject,
  useRef,
  useContext,
} from "react"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import UnwrapMultipleStep from "../transactionStepper/multiaction/UnwrapMultipleStep"
import {
  ActionButton,
  Body,
  ButtonContainer,
  CloseIcon,
  CloseIconWrapper,
  CurrentStepWrapper,
  ModalTopBar,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  TopText,
  WrapContainer,
} from "../styles"
import WSCModalCover from "../component/WSCModalCover"
import ModalTopIcon from "../component/ModalTopIcon"
import { useAppSelector } from "store/hooks"
import { useBackup } from "../component/Backup"
import { useWSCContext } from "context/MilkomedaContext"
import { REQUIRED_FEE_ADA } from "constants/milkomeda/transaction"
import WrapGas from "../transactionStepper/WrapGasStep"

interface CollectFeesModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IWrapMultipleData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  cancelTransaction?: () => void
}

const CollectFeesModal: FC<CollectFeesModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    closeWSCContinue,
    wscModalRef,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [steps, setSteps] = useState<any[]>([])
  const [backupSubStep, setBackupSubStep] = useState<number>(0)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [needGasWrap, setNeedGasWrap] = useState<boolean>(false)

  const modalSettingsRef = useRef<HTMLDivElement | any>()
  const actionType = "LP"
  const { data, backup } = useBackup(
    steps,
    actionType,
    setCurrentStep,
    setBackupSubStep
  ) as {
    data: IWrapMultipleData
    backup: boolean
  }
  const { destinationBalanceADA } = useWSCContext()

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    const newSteps = []

    if (needGasWrap) {
      newSteps.push({
        number: 1,
        title: "Wrap",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            nextStep={nextStep}
            actionType={actionType}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: needGasWrap ? 2 : 1,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isBackup={backup}
          toggleModal={toggleModal}
        />
      ),
    })

    newSteps.push({
      number: needGasWrap ? 3 : 2,
      title: "Unwrap",
      actionTitle: "Unwrapping",
      component: (
        <UnwrapMultipleStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, data, backup, needGasWrap])

  useEffect(() => {
    if (!destinationBalanceADA.data) return
    checkBalanceForGas()
  }, [destinationBalanceADA.data])

  const checkBalanceForGas = () => {
    if (REQUIRED_FEE_ADA > destinationBalanceADA.data) setNeedGasWrap(true)
  }

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Collect Fees"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <TitleTextContainer>
          <TitleText>{steps[currentStep - 1]?.actionTitle}</TitleText>
        </TitleTextContainer>

        <StepWrapper>
          {steps.map((step, index) => (
            <Fragment key={step.number}>
              <StepContainer>
                <StepButton
                  active={currentStep === step.number}
                  onClick={() => {
                    if (currentStep === step.number) return
                    setCurrentStep(step.number)
                  }}
                >
                  {step.number}
                </StepButton>
                <StepTitle active={currentStep === step.number}>
                  {step.title}
                </StepTitle>
              </StepContainer>
              {index < steps.length - 1 && <StepDivider />}
            </Fragment>
          ))}
        </StepWrapper>
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useCollectFeesModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default CollectFeesModal
