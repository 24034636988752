import React, {
  FC,
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useAccount, useConnect, useNetwork, useSigner } from "wagmi"
import { Chains, fetchInfo, sendError } from "@tangleswap/sdk"
import {
  useGetOriginAddress,
  useGetOriginTokens,
  useGetDestinationBalance,
  useGetWSCTokens,
  useGetPendingTxs,
} from "milkomeda-wsc-ui-test-beta"
import { metamaskConnector } from "config/milkomeda/wagmi"
import { WalletLoadingContext } from "./WalletContext"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateConnectWalletState } from "store/actions/SelectedWalletAction"
import {
  DEVNET_BLOCKFROST_ID,
  MAINNET_BLOCKFROST_ID,
} from "config/milkomeda/config"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"

interface MilkomedaProps {
  children?: ReactNode
}

export const WSCContext = createContext(null)
// export const defaultChainId = Chains.CARDANO_TEST
export const defaultChainId =
  Number(localStorage.getItem("defaultChainId")) || Chains.CARDANO_TEST

const MilkomedaContext: FC<MilkomedaProps> = ({ children }) => {
  const { chain } = useNetwork()

  const { connect, connectors } = useConnect()
  const {
    connector: activeConnector,
    // isConnected: isWalletConnected,
    address: account,
  } = useAccount()
  const { data: signer } = useSigner()
  const { setWalletLoading } = useContext(WalletLoadingContext)

  const pendingL1L2Txs = null
  // const pendingL1L2Txs = useGetPendingTxs()
  const [pendingWSCTxs, setPendingWSCTxs] = useState<any>(undefined)
  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  const originAddress = useGetOriginAddress()
  const rawOriginTokens = useGetOriginTokens()
  const [originTokens, setOriginTokens] = useState<any>(undefined)
  const destinationTokens = useGetWSCTokens()
  const destinationBalanceADA = useGetDestinationBalance()
  const listOnChain = useAppSelector((state) => state.tokenList.tokenList)

  useEffect(() => {
    if (!listOnChain || !rawOriginTokens) return

    const { data, ...rawWithoutData } = rawOriginTokens

    const updatedDataWithDecimals =
      data &&
      data.map((token) => {
        if (token.decimals !== 0) return token
        const realDecimals = listOnChain.find((tok) =>
          token.unit.startsWith(tok.l1Address)
        )?.l1Decimals
        return { ...token, decimals: realDecimals || 0 }
      })

    setOriginTokens({ ...rawWithoutData, data: updatedDataWithDecimals })
  }, [rawOriginTokens?.data, listOnChain])

  const [refetchInterval, setRefetchInterval] = useState<any>(false)
  useEffect(() => {
    const interval = setInterval(
      () => setRefetchInterval(!refetchInterval),
      1000 * 10
    ) // in seconds
    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    if (!isWSCConnected) return
    rawOriginTokens.refetch()
    destinationTokens.refetch()
    destinationBalanceADA.refetch()
  }, [refetchInterval])

  const [chainId, setChainId] = useState<any>(defaultChainId)
  const [l1ChainId, setL1ChainId] = useState<number | string>(null)
  const [l1Account, setL1Account] = useState<string>(null)
  const [stargate, setStargate] = useState<any>(null)

  const isWSCConnected = activeConnector?.id?.includes("wsc") ?? false
  const walletName = localStorage.getItem("connectedWallet")
  const l1Wallets = ["Nami", "Eternl"] // "Yoroi", "NuFi"]

  useEffect(() => {
    if (!isWSCConnected) return
    const loadWscProvider = async () => {
      try {
        const provider = await activeConnector?.getProvider()

        if (!provider) {
          throw new Error("No WSC Provider found")
        }

        const stargate = await provider.stargateObject()
        setStargate(stargate)
      } catch (e) {
        if (e instanceof Error) {
          console.error("Error while getting stargate: ", e)
        }
      }
    }
    loadWscProvider()
  }, [activeConnector])

  useEffect(() => {
    localStorage.setItem("tangleSwapTransactions", "false") // TODO: delete after Auto-Tx is fixed

    if (chainId) localStorage.setItem("defaultChainId", String(chainId))
  }, [chainId])

  useEffect(() => {
    const l1Acc = (isWSCConnected && originAddress?.originAddress) || account
    setL1Account(l1Acc)

    const chainID = !l1Acc
      ? defaultChainId
      : isWSCConnected && l1Acc?.startsWith("addr_test")
      ? Chains.CARDANO_TEST
      : isWSCConnected && l1Acc?.startsWith("addr")
      ? Chains.CARDANO
      : chain?.id
    setChainId(chainID as number)

    const isL1WalletLoading =
      l1Wallets.includes(walletName) &&
      ![Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(chainID as any)
    let l1Chain
    if (isL1WalletLoading)
      l1Chain =
        chainID === Chains.CARDANO_TEST
          ? Chains.L1_CARDANO_TEST
          : Chains.L1_CARDANO
    else
      l1Chain =
        chainID === undefined
          ? defaultChainId
          : isWSCConnected && chainID === Chains.CARDANO
          ? Chains.L1_CARDANO
          : isWSCConnected && chainID === Chains.CARDANO_TEST
          ? Chains.L1_CARDANO_TEST
          : chainID
    setL1ChainId(l1Chain)

    // setWalletLoading(false)
  }, [account, chain?.id, isWSCConnected, originAddress?.originAddress])

  useEffect(() => {
    if (!account || !l1Account) return
    if (!tangleProgress) {
      setPendingWSCTxs(null)
      return
    }

    const hash = tangleProgress?.txHash
    const isWrap = tangleProgress?.stepTitle === "Wrap"
    const isUnwrap = tangleProgress?.stepTitle === "Unwrap"
    const explorerUrl = isWrap
      ? "https://preprod.cardanoscan.io/transaction/"
      : "https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/"

    const recentEvmTx = 1000 * 15 // in seconds
    const recentWscTx = 1000 * 5 * 60 // TODO: delete once Milkomeda pendingL1L2txs will become more reliable
    const isRecentEvmTx =
      Date.now() - tangleProgress?.timestamp * 1000 < recentEvmTx
    const isRecentWscTx =
      Date.now() - tangleProgress?.timestamp * 1000 < recentWscTx

    const localPendingTx =
      hash === null
        ? []
        : [
            {
              completed:
                ((isWrap || isUnwrap) && tangleProgress?.completed) ||
                (!(isWrap || isUnwrap) && !isRecentEvmTx) ||
                ((isWrap || isUnwrap) &&
                  (!isRecentWscTx ||
                    (!isRecentEvmTx &&
                      pendingL1L2Txs &&
                      pendingL1L2Txs?.fetchStatus === "idle" &&
                      !pendingL1L2Txs?.isError &&
                      !pendingL1L2Txs?.data?.some(
                        (tx) => tx?.hash?.toLowerCase() === hash?.toLowerCase()
                      )))),
              data: tangleProgress?.data,
              hash,
              timestamp: tangleProgress?.timestamp,
              explorer: `${explorerUrl}${hash}`,
              type: tangleProgress?.stepTitle,
              actionType: tangleProgress?.actionType,
              actionSubType: tangleProgress?.actionSubType,
              subStep: tangleProgress?.subStep,
              destinationAddress: isUnwrap ? l1Account : account,
              isLastStep: tangleProgress?.isLastStep,
            },
          ]

    // if (!!tangleProgress?.isLastStep) dispatch(updateUserWSCProgress(null))

    console.log(
      "sevPendingWSCTxs",
      pendingL1L2Txs,
      localPendingTx,
      tangleProgress
    )
    setPendingWSCTxs(localPendingTx)
  }, [
    account,
    l1Account,
    tangleProgress,
    pendingL1L2Txs?.data,
    // pendingL1L2Txs?.fetchStatus,
    refetchInterval,
  ])

  // @dev: don't delete!!
  // const getCardanoTxMetadata = async (txHash: string, l1Chain: any) => {
  //   const storageKey = `adaTxMetadata${l1Chain}${txHash}`
  //   const storageValue = JSON.parse(sessionStorage.getItem(storageKey))
  //   if (storageValue) return storageValue

  //   const url = `https://${l1Chain}.blockfrost.io/api/v0/txs/${txHash}/metadata`
  //   const blockfrostApiKey =
  //     l1Chain === Chains.L1_CARDANO
  //       ? MAINNET_BLOCKFROST_ID
  //       : DEVNET_BLOCKFROST_ID
  //   const headers = { project_id: blockfrostApiKey }
  //   const data = await fetchInfo(url, 60, headers).catch(sendError)

  //   if (data) sessionStorage.setItem(storageKey, JSON.stringify(data))
  //   return data
  // }

  // @dev: don't delete!!
  // useEffect(() => {
  // if (
  //   !account ||
  //   !l1Account ||
  //   !l1ChainId ||
  //   !pendingL1L2Txs ||
  //   pendingL1L2Txs?.fetchStatus === "fetching"
  // )
  //   return

  // const duplicates = new Map()
  // const pendingTxData = !pendingL1L2Txs?.data
  //   ? []
  //   : pendingL1L2Txs.data.filter(
  //       (tx) =>
  //         tx &&
  //         !duplicates.has(tx?.hash?.toLowerCase()) &&
  //         duplicates.set(tx?.hash?.toLowerCase(), true)
  //     )

  // Promise.all(
  //   pendingTxData.map(async (tx) => {
  //     const txData = tx
  //     if (
  //       // (pendingL1L2Txs?.error as any)?.message ||
  //       // !txData.destinationAddress ||
  //       !txData.hash
  //     )
  //       return null

  //     if (txData.type === "Wrap") {
  //       const txMetadata = await getCardanoTxMetadata(txData.hash, l1ChainId)
  //       const isValidL2Tx = txMetadata?.some(
  //         (md) => md?.json_metadata === "devnet.cardano-evm.c1"
  //       )
  //       const isValidL2Address = txMetadata?.some(
  //         (md) => md?.json_metadata?.toLowerCase() === account?.toLowerCase()
  //       )
  //       return !txMetadata?.length || !isValidL2Tx || !isValidL2Address
  //         ? null
  //         : {
  //             hash: txData.hash,
  //             timestamp: txData.timestamp,
  //             explorer: txData.explorer,
  //             type: txData.type,
  //             destinationAddress: l1Account,
  //             completed: false,
  //           }
  //     } else if (txData.type === "Unwrap") {
  //       if (!txData.destinationAddress?.startsWith("addr")) return null
  //       return { ...txData, completed: false }
  //     }
  //   })
  // ).then((pendingTxs) => {
  // let validTxs: any = pendingTxs?.filter(Boolean)

  //   if (validTxs?.length === 0 && !!tangleProgress) {
  //     const recentTime = 1000 * 20 // in seconds
  //     const isRecent =
  //       Date.now() - tangleProgress?.timestamp * 1000 < recentTime

  //     const isWrap = tangleProgress?.stepTitle === "Wrap"
  //     const isUnwrap = tangleProgress?.stepTitle === "Unwrap"
  //     const explorerUrl = isWrap
  //       ? "https://preprod.cardanoscan.io/transaction/"
  //       : "https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/"

  //     validTxs = !tangleProgress?.txHash
  //       ? []
  //       : [
  //           {
  //             completed:
  //               // (isWrap && !isRecent) || isUnwrap
  //               //   ? true
  //               //   : tangleProgress?.completed,
  //               tangleProgress?.completed,
  //             hash: tangleProgress?.txHash,
  //             timestamp: tangleProgress?.timestamp,
  //             explorer: `${explorerUrl}${tangleProgress?.txHash}`,
  //             type: tangleProgress?.stepTitle,
  //             actionSubType: tangleProgress?.actionSubType,
  //             destinationAddress: isUnwrap ? l1Account : account,
  //           },
  //         ]

  //     if (!!tangleProgress?.isLastStep) dispatch(updateUserWSCProgress(null))
  //   }
  //   console.log("sevPendingWSCTxs", pendingL1L2Txs, validTxs, tangleProgress)
  //   setPendingWSCTxs(validTxs)
  // })

  // }, [account, l1Account, l1ChainId, pendingL1L2Txs?.fetchStatus])

  const wallet = useAppSelector((state) => state.wallet.tangleswapWalletState)
  const walletStateRef = useRef(wallet)

  useEffect(() => {
    if (!walletName) return

    if (walletName === "Metamask") connectToMetamask()
    else connectToWSC(walletName as any)

    setTimeout(() => {
      if (Boolean(walletStateRef.current)) return
      console.warn("Wallet connection timed out, please refresh and try again.")
      dispatch(updateConnectWalletState(true))
    }, 1000 * 10) // in seconds
  }, [])

  const connectToMetamask = async () => {
    try {
      await connect({
        connector: metamaskConnector,
      })
      localStorage.setItem("connectedWallet", "Metamask")
      setWalletLoading(false)
    } catch (error) {
      console.error("Error connecting to Metamask:", error)
    }
  }
  const dispatch = useAppDispatch()
  const connectToWSC = async (wallet: (typeof l1Wallets)[number]) => {
    dispatch(updateConnectWalletState(true))
    const name = `${wallet.toLowerCase()}-wsc`
    const wscConnector = connectors.find((connector) => connector.id === name)

    if (!wscConnector) return

    try {
      console.log("Connecting to wallet:", wallet)

      await connect({
        connector: wscConnector,
      })
      localStorage.setItem("connectedWallet", wallet)
      dispatch(updateConnectWalletState(false))
    } catch (error) {
      console.error(`Error connecting to ${wallet} WSC:`, error)
      dispatch(updateConnectWalletState(false))
    }
  }

  const value = {
    account,
    l1Account,
    chainId,
    l1ChainId,
    walletName,
    originTokens,
    destinationTokens,
    destinationBalanceADA,
    pendingWSCTxs,
    provider: signer?.provider,
    activeConnector,
    isWSCConnected,
    stargate,
    connectToMetamask,
    connectToWSC,
  }

  return <WSCContext.Provider value={value}>{children}</WSCContext.Provider>
}

export const useWSCContext = () => {
  const context = useContext(WSCContext)
  if (!context) throw new Error("Hook must be used within a Provider")
  return context
}

export default memo(MilkomedaContext)
