import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { AiOutlineInfoCircle, AiOutlineMinusCircle } from "react-icons/ai"
import { CaptionSmall } from "styles/TextStyles"

interface TypeProps {
  positionInfo: any
  closedLP: boolean
  outOfRange: boolean
}
const LPType: FC<TypeProps> = (props) => {
  const { positionInfo, closedLP, outOfRange } = props
  return (
    <LPTypeBody>
      {positionInfo && closedLP && (
        <>
          <LiquidityClosedStatus>
            <StatusSymbol>
              <StatusInfoIcon />
            </StatusSymbol>

            <StatusText>Closed</StatusText>
          </LiquidityClosedStatus>
        </>
      )}
      {positionInfo && (
        <>
          {" "}
          {outOfRange && !closedLP ? (
            <>
              <LiquidityOutStatus>
                <StatusSymbol>
                  <StatusOutInfoIcon />
                </StatusSymbol>

                <StatusOutText>Out of range </StatusOutText>
              </LiquidityOutStatus>
            </>
          ) : null}
          {!outOfRange && !closedLP ? (
            <>
              <LiquidityInStatus>
                <StatusSymbol>
                  <StatusDotIcon />
                </StatusSymbol>
                <StatusText>In range</StatusText>
              </LiquidityInStatus>
            </>
          ) : null}
        </>
      )}
    </LPTypeBody>
  )
}
const LPTypeBody = styled.div`
  margin: 0 0 0 4px;
  padding: 4px;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  align-items: flex-start;
  position: relative;
  z-index: 1;
`
const LiquidityOutStatus = styled.div`
  height: 32px;
  padding: 4px 6px;

  border-radius: 12px;
  background: ${TangleColors.tangleYellow};
  display: flex;
  color: ${TangleColors.black};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
const LiquidityInStatus = styled.div`
  margin: 3px 0;
  height: 32px;
  padding: 4px 6px;
  /* max-width: 96px;
  width: 100%; */
  border-radius: 12px;
  background: ${TangleColors.tangleBlack};
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
const StatusSymbol = styled.div``

const StatusInfoIcon = styled(AiOutlineMinusCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};
  border-radius: 9999px;
`
const StatusDotIcon = styled.div`
  height: 16px;
  width: 16px;

  border-radius: 9999px;
  background: ${TangleColors.tangleGreen};
`

const StatusText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 4px;
  white-space: nowrap;
`
const StatusOutText = styled(CaptionSmall)`
  color: ${TangleColors.black};
  margin: 0 4px;
  white-space: nowrap;
`

const StatusOutInfoIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.black};
  border-radius: 9999px;
`
const LiquidityClosedStatus = styled.div`
  height: 32px;

  border-radius: 12px;
  padding: 4px 6px;
  background: ${TangleColors.tangleBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
export default LPType
